<template>
  <VideoComponent v-if="items.length" :videos="items" />
  <div v-else-if="noData"><p>Материалы не найдены</p></div>
  <div v-else><i class="loader" /></div>
</template>

<script>
export default {
  props: {
    kpid: {
      type: String,
      require: true,
      default: ''
    }
  },
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  data() {
    return {
      items: [],
      noData: false
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/filmdata/sap?kpid=${this.kpid}`)
    if (!data.items.length) {
      this.noData = true
    } else {
      this.items = data.items
    }
  }
}
</script>
