<template>
  <div class="profile articles">
    <div>
      <h1>Create article</h1>

      <div class="form">
        <label for="poster">
          <strong>Шапка статьи <sup>*</sup></strong>
          <button @click="uploadPosterTrigger">Загрузить</button>
          <input
            id="poster"
            ref="uploadPoster"
            type="file"
            style="display:none;"
            accept="image/gif, image/png, image/jpeg, image/jpg"
            @change="uploadFile($event, 'poster')"
          >
        </label>

        <div class="prerender">
          <div class="poster" />
          <button @click="delPoster">Del poster</button>
        </div>

        <label for="title">
          <strong>Заголовок <sup>*</sup></strong>
          <input id="title" type="text" v-model="articleTitle">
        </label>

        <div class="editor">
          <label for="textarea">
            <strong>Краткое описание <sup>*</sup></strong>
            <textarea
              id="short"
              v-model="articleShort"
              rows="5"
              placeholder="Краткое описание"
            />
          </label>

          <div v-if="showSmiles" class="emojis">
            <i
              @click="showSmiles = !showSmiles"
              class="icon-close"
              title="Закрыть"
            />
            <div class="emoji-list">
              <img
                v-for="emo in emoji"
                :key="`comments-emoji-${emo}`"
                :src="`/static_files/onion/${emo}.gif`"
                @click="appendEmoji(emo)"
              />
            </div>
          </div>

          <ul class="bb-codes">
            <li title="Заголовок H2" @click="editor('h2')">H2</li>
            <li title="Заголовок H3" @click="editor('h3')">H3</li>
            <li title="Заголовок H4" @click="editor('h4')">H4</li>
            <li title="Выровнять по центру" @click="editor('center')">CENTER</li>
            <li title="Ссылка" @click="editor('a')">LINK</li>
            <li title="Жирный" @click="editor('b')">B</li>
            <li title="Курсив" @click="editor('i')">I</li>
            <li title="Подчеркнутый" @click="editor('u')">U</li>
            <li title="P" @click="editor('p')">P</li>
            <li title="col2" @click="editor('col2')">col2</li>
            <li title="ul" @click="editor('ul')">ul</li>
            <li title="Изображение" @click="editor('img')">IMG</li>
            <li title="YouTube" @click="editor('youtube')">YOUTUBE</li>
            <li title="Цвет">
              <input
                type="color"
                v-model="fontColor"
                @change="editor('color', fontColor)"
                class="color"
              />
            </li>
            <!-- <li @click="showSmiles = !showSmiles" title="Эмоции">
              <i
                :class="{ active: showSmiles }"
                class="icon-message-smile"
              />
            </li> -->
          </ul>

          <label for="textarea">
            <strong>Текст статьи <sup>*</sup></strong>
            <textarea
              id="text"
              v-model="articleText"
              rows="5"
              placeholder="Главное начать, у вас все получится"
            />
          </label>

          <ul class="bb-codes">
            <li title="Заголовок H2" @click="editor('h2')">H2</li>
            <li title="Заголовок H3" @click="editor('h3')">H3</li>
            <li title="Заголовок H4" @click="editor('h4')">H4</li>
            <li title="Выровнять по центру" @click="editor('center')">CENTER</li>
            <li title="Ссылка" @click="editor('a')">LINK</li>
            <li title="Жирный" @click="editor('b')">B</li>
            <li title="Курсив" @click="editor('i')">I</li>
            <li title="Подчеркнутый" @click="editor('u')">U</li>
            <li title="P" @click="editor('p')">P</li>
            <li title="col2" @click="editor('col2')">col2</li>
            <li title="ul" @click="editor('ul')">ul</li>
            <li title="Изображение" @click="editor('img')">IMG</li>
            <li title="YouTube" @click="editor('youtube')">YOUTUBE</li>
            <li title="Цвет">
              <input
                type="color"
                v-model="fontColor"
                @change="editor('color', fontColor)"
                class="color"
              />
            </li>
            <!-- <li @click="showSmiles = !showSmiles" title="Эмоции">
              <i
                :class="{ active: showSmiles }"
                class="icon-message-smile"
              />
            </li> -->
          </ul>
        </div>

        <label for="attach">
          <strong>Прикрепить изображение</strong>
          <button @click="uploadAttachTrigger">Загрузить</button>
          <input
            id="attach"
            ref="uploadAttach"
            type="file"
            style="display:none;"
            accept="image/gif, image/png, image/jpeg, image/jpg"
            @change="uploadFile($event, 'attach')"
          >
        </label>

        <div class="prerender">
          <div class="attach" />
        </div>

        <div class="tags">
          <strong>Теги</strong>
          <button @click="getTags">Get tags</button>
          <button @click="articleTags = []">Clean tags</button>
          <ul v-if="tags.length" class="tags-list">
            <li v-for="tag in tags" :key="`tag-${tag.id}`" @click="addTag(tag)">
              {{ tag.name }}
            </li>
          </ul>
          <div v-if="articleTags.length">
            <strong>Теги статьи</strong>
            {{ articleTags.map(el => el.name) }}
          </div>
        </div>

        <div class="tags">
          <strong>Ключи</strong>
          <input type="text" v-model="articleKeys" placeholder="Ключи статьи через запятую" />
        </div>

        <div class="tags">
          <strong>Кино в статье</strong>

          <ul v-if="articleFilms.length" class="films">
            <li v-for="item in articleFilms" :key="`film-${item.id}`" @click="removeFilm(item)">
              <span>X</span>
              <img :src="`https://kinopoiskapiunofficial.tech/images/posters/kp/${item}.jpg`" alt="">
            </li>
          </ul>

          <div class="search-hints">
            <input
              v-model="searchQuery"
              :class="{ active: searchQuery }"
              @input="trottled"
              type="search"
              autocomplete="off"
              placeholder="Введите название"
            >
            <div v-if="searchQuery.length > 2" class="datalist">
              <div v-if="!searchHints.length" class="no-match">
                <span>Ничего не найдено</span>
              </div>
              <ul v-else>
                <li
                  v-for="(item, i) in searchHints"
                  :key="`article-sh-${i}`"
                  @click="addToSet(item.id)"
                >
                  <div class="poster">
                    <img :src="item.poster" alt="">
                  </div>
                  <p>
                    <strong>{{ item.title }}</strong>
                    <span v-if="item.year">{{ item.year }}</span>
                    <span v-if="item.genres"> {{ item.genres.join(', ') }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="buttons">
          <button @click="createArticle" class="send">Опубликовать</button>
          <button @click="prerender" class="pre">Предпросмотр</button>
        </div>

        <Article v-if="article" :payload="article" />
      </div>
    </div>
  </div>
</template>

<script>
// import socket from '~/plugins/socket-client'
import { mapState } from 'vuex'

export default {
  middleware: 'auth',
  layout: 'clean',
  components: {
    Article: () => import('~/components/Article.vue')
  },
  filters: {
    filterDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: mapState(['user']),
  data() {
    const emoji = [
      '_)', '_D', '_-D', '_lol_', '_ololo_', '_evil_', '+_+', '_cool_', '_thumbup_',
      '_yahoo_', '_tea2_', '_star_', '_oh_', '_shy_', '_shy2_', '_hurray_', '_-P',
      '_roll_', '_!_', '_watching_', '_love_', '_love2_', '_bunch_', '_perveted_',
      '_(', '_very-sad_', '_depressed_', '_depressed2_', '_hopeless_', '_very-sad2_',
      '_-(', '_cry_', '_cry6_', '_Cry2_', '_Cry3_', '_Cry4_', '_-o', '_shock_',
      '_shock2_', '_scream_', '_dont-want_', '_noooo_', '_scared_', '_shocked2_',
      '_shocked3_', '_shocked4_', '_tea-shock_', '_frozen3_', '_angry4_', '_revenge_',
      '_evil2_', '_twisted_', '_angry_', '_angry3_', '_angry5_', '_angry6_', '_cold_',
      '_strange4_', '_ball_', '_evil3_', '_8)_', '_oh2_', '_ooph_', '_wink_',
      '_dunno_', '_dont-listen_', '_hypno_', '_advise_', '_bored_', '_disappointment_',
      '_hunf_', '_hot_', '_hot2_', '_hot3_', '_stress_', '_strange3_', '_strange2_',
      '_strange1_', '_Bath2_', '_strange_', '_hope_', '_hope3_', '_diplom_', '_hi_',
      '_bye_', '_sleep_', '_bow_', '_Warning_', '_Ban_', '_Im-dead_', '_sick_', '_s1_',
      '_s3_', '_s2_', '_happy_cry_', '_ill_', '_sad2_', '_bullied_', '_bdl2_',
      '_Happy-Birthday_', '_flute_', '_cry5_', '_gaze_', '_hope2_', '_sleepy_',
      '_study_', '_study2_', '_study3_', '_gamer_', '_animal_', '_caterpillar_',
      '_cold2_', '_shocked_', '_frozen_', '_frozen2_', '_kia_', '_interested_',
      '_happy_', '_happy3_', '_water_', '_dance_', '_liar_', '_prcl_', '_play_',
      '_s4_', '_s_', '_bath_', '_kiss_', '_whip_', '_relax_', '_smoker_', '_smoker2_',
      '_bdl_', '_cool2_', '_V_', '_V2_', '_V3_', '_sarcasm_', '_angry2_', '_kya_'
    ]
    return {
      searchQuery: '',
      searchHints: [],
      articles: [],
      tagName: '',
      tags: [],
      emoji,
      article: null,
      articleAuthor: '',
      articleId: Date.now(),
      articleEdit: false,
      articleTitle: '',
      articlePoster: null,
      articleShort: '',
      articleText: '',
      articleTags: [],
      articleFilms: [],
      articleKeys: '',
      attachI: 0,
      attachments: [],
      fontColor: '',
      showSmiles: false,
      newPoster: false
    }
  },
  mounted() {
    this.articleAuthor = this.user.id
  },
  methods: {
    removeFilm(id) {
      const films = this.articleFilms.filter(el => el !== id)
      this.articleFilms = films
    },
    addToSet(id) {
      this.articleFilms.push(id)
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`unotalone:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    },
    delPoster() {
      this.newPoster = true
      this.articlePoster = null
      const div = document.querySelector(`.prerender .poster`)
      div.innerHTML = ''
    },
    editArticle(article) {
      this.articleEdit = article._id
      this.articleTitle = article.title
      this.articlePoster = article.poster
      this.articleShort = article.short
      this.articleText = article.text
      this.articleKeys = article.keys.join(', ')
      this.articleFilms = article.films
      this.articles = []
      const div = document.querySelector(`.prerender .poster`)
      div.innerHTML = ''
      const img = document.createElement('img')
      img.src = this.articlePoster
      div.appendChild(img)
    },
    addTag(tag) {
      if (!this.articleTags.find(el => el.id === tag.id)) {
        this.articleTags.push(tag)
      } else {
        const list = this.articleTags.filter(el => el.id !== tag.id)
        this.articleTags = list
      }
    },
    async getTags() {
      const { data } = await this.$axios.get(`/api/articles/tags?key=${this.key}`)
      this.tags = data
    },
    prerender() {
      if (
        !this.articleTitle.trim() ||
        !this.articleShort.trim() ||
        !this.articleText.trim() ||
        !this.articleTags.length ||
        !this.articleKeys
      ) return

      const obj = {
        date: Date.now(),
        title: this.articleTitle.trim(),
        short: this.articleShort.trim(),
        text: this.articleText.trim(),
        tags: this.articleTags,
        keys: this.articleKeys.split(',').map(el => el.trim()),
        poster: this.articlePoster
      }

      this.article = {
        article: obj,
        recomendations: [],
        related: []
      }
    },
    appendEmoji(e) {
      // console.log(e)
      this.articleText += `[::${e}::] `
      const textarea = document.querySelector('#text')
      if (textarea) textarea.focus()
    },
    editor(tag, val = '') {
      const textarea = document.querySelector('#text')
      const start = textarea.selectionStart
      const finish = textarea.selectionEnd
      const sel = textarea.value.substring(start, finish)
      if (tag === 'color') {
        this.articleText = this.articleText.replace(sel, `<span style="color:${val};">${sel}</span>`)
      } else if (tag === 'img') {
        this.articleText = this.articleText.replace(sel, `<img src="${sel}" alt="${this.articleTitle}">`)
      } else if (tag === 'a') {
        this.articleText = this.articleText.replace(sel, `<a href="" class="link">${sel}</a>`)
      } else if (tag === 'col2') {
        this.articleText = this.articleText.replace(sel, `<div class="col-2"><div>${sel}</div></div>`)
      } else if (tag === 'ul') {
        this.articleText = this.articleText.replace(sel, `<ul><li>${sel}</li></ul>`)
      } else {
        this.articleText = this.articleText.replace(sel, `<${tag}${val ? `${val}` : ''}>${sel}</${tag}>`)
      }
    },
    // parseBB() {
    //   const list = document.querySelectorAll('article p')
    //   if (list) {
    //     Array.from(list).forEach((el) => {
    //       if (el.textContent.match(/\[:.*:\]/)) {
    //         const str = el.textContent
    //           .replace(/\[::/g, '<img src="/static_files/onion/')
    //           .replace(/::\]/g, '.gif" class="emoji" />')
    //         el.innerHTML = str
    //       }
    //     })
    //   }
    // },
    async createArticle() {
      if (
        !this.articlePoster ||
        !this.articleTitle.trim() ||
        !this.articleText.trim()
      ) {
        this.$store.commit('setError', 'Заполните все поля и загрузите изображения')
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (this.articleTitle.trim().length > 150) {
        this.$store.commit('setError', 'Cлишком длинное название (макс. 150 символов)')
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (!this.articleTags.length) {
        this.$store.commit('setError', 'Не указаны теги')
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (!this.articleKeys.length) {
        this.$store.commit('setError', 'Не указаны ключи')
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (!this.articleAuthor.length) {
        this.$store.commit('setError', 'Не указан автор статьи')
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      this.$store.commit('setError', { status: 'load', msg: 'Идет публикация статьи' })

      const obj = {
        title: this.articleTitle.trim(),
        short: this.articleShort.trim(),
        author: this.articleAuthor.trim(),
        text: this.articleText.trim(),
        tags: this.articleTags.map(el => el.id),
        films: this.articleFilms,
        keys: this.articleKeys.split(',').map(el => el.trim()),
        poster: ''
      }

      if (this.articleEdit && !this.newPoster) {
        obj.poster = this.articlePoster
      } else {
        await this.$axios({
          method: 'post',
          url: `/upload?type=articles`,
          data: this.articlePoster.bodyFormData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        obj.poster = `/uploads/articles/${this.articlePoster.fileName}`
      }

      if (this.attachments.length) {
        for(let i = 0; i < this.attachments.length; i++) {
          await this.$axios({
            method: 'post',
            url: `/upload?type=articles`,
            data: this.attachments[i],
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        }
      }

      const { data } = this.articleEdit
        ? await this.$axios.post(`/api/articles/edit/${this.articleEdit}`, obj)
        : await this.$axios.post(`/api/articles`, obj)

      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: 'Статья отправлена на проверку' })
        setTimeout(() => {
          this.$store.commit('clearError')
          location.reload()
        }, 2000)
      }
    },
    uploadAttachTrigger() {
      this.$refs.uploadAttach.click()
    },
    uploadPosterTrigger() {
      this.$refs.uploadPoster.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 1000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this
          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = function() {
              let maxWidth, maxHeight, finalFile

              if (imageExt === '.gif') {
                finalFile = reader.result
              } else {
                if (typeImg === 'blog_avatar') {
                  maxWidth = 200
                  maxHeight = 200
                } else {
                  maxWidth =  1920
                  maxHeight = 1200
                }

                let imageWidth = image.width
                let imageHeight = image.height

                if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                    imageHeight *= maxWidth / imageWidth
                    imageWidth = maxWidth
                } else if (imageHeight > maxHeight) {
                  imageWidth *= maxHeight / imageHeight
                  imageHeight = maxHeight
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight

                const ctx = canvas.getContext("2d");
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                finalFile = canvas.toDataURL(fileType)
              }

              const fileName = `${vm.articleId}_${++vm.attachI}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              if (typeImg === 'poster') {
                vm.articlePoster = {
                  fileName,
                  bodyFormData
                }
              } else if (typeImg === 'poll-attach') {
                const img = document.querySelector(`.answer[data-id="${vm.pollAnswerI}"] img`)
                img.src = finalFile
                img.dataset.name = fileName
                return
              } else if (
                typeImg === 'blog_avatar' ||
                typeImg === 'blog_header' ||
                typeImg === 'blog_bg'
              ) {
                vm.blogAttach[typeImg] = bodyFormData
              } else {
                vm.attachments.push(bodyFormData)
              }

              const div = document.querySelector(`.prerender .${typeImg}`)
              if (typeImg === 'poster') div.innerHTML = ''
              const img = document.createElement('img')
              img.src = finalFile
              img.onclick = () => {
                vm.articleText += `<a href="/uploads/articles/${fileName}"><img src="/uploads/articles/${fileName}" alt="${vm.articleTitle} - изображение ${vm.attachI}"></a>`
              }
              div.appendChild(img)
            }
          }
        } else {
          this.$store.commit(
            'setError',
            'Файл не соответствует условиям: формат .gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    }
  }
}
</script>

<style lang="stylus">
.profile.articles
  padding: 20px
  width: 1200px
  margin: auto
  background-color: #1f1b2f
  .attach img
    width: 200px
    cursor: pointer
  .search-hints
    margin-top: 10px
  .films
    margin-top: 10px
    li
      display: inline-block
      position: relative
      span
        position: absolute
        padding: 5px 20px
        margin: 0px
        top: 0px
        left: 0px
        font-size: 2rem
        color: #fff
        background-color: #000
        z-index: 1
      img
        width: 100px
  h1
    margin-bottom 20px
  .tags-list
    margin: 10px 0
    font-weight: 700
    font-size: 1.4rem
    li
      display: inline-block
      margin: 5px
      cursor: pointer
      &:hover
        color: red
  .form
    margin-bottom 30px
    padding 15px 0
    .buttons
      margin: 24px 0 14px
    span
      margin-left 10px
      font-size 1.4rem
      cursor pointer
    label
      display: block
      margin-bottom: 14px
    input[type="text"]
      width 50%
    sup
      color red
    .prerender
      img
        max-width 50%
    .editor
      textarea
        margin-top 10px
        width 100%
        min-height 100px
        padding 10px
        font-size 1.6rem
        resize none
        overflow hidden
        resize: vertical
      .emojis
        position relative
        top 0px
        width 100%
        background-color #fff
        padding 10px 30px 10px 5px
        border 2px solid #f1f1f1
        z-index 3
        i.icon-close
          display inline-block
          position absolute
          top 0
          right 0
          width 24px
          height 24px
          padding 12px
          background #f1f1f1 url('/static_files/icons/cancel.svg') center center no-repeat
          background-size 10px 10px
          cursor pointer
        .emoji-list
          // display grid
          // grid-template-columns repeat(10, 1fr)
          // grid-gap 5px
          overflow-y auto
          max-height 400px
          img
            margin 5px 4px
            // width 40px
            height 40px
            cursor pointer
            background-color #fff
            transition opacity .2s ease
            &:hover
              opacity .75
      .bb-codes
        margin 20px 0 10px 0px
        list-style none
        li
          display inline-block
          padding 5px 10px
          font-weight bold
          cursor pointer
          .color
            vertical-align bottom
            cursor pointer
          .icon-message-smile
            display inline-block
            width 20px
            height 20px
            background-image url('/static_files/icons/smile.svg')
            background-size 20px 20px
            vertical-align sub
  .article
    margin 10px 0
    padding 10px
  button
    font-size 1.4rem
  p
    margin 5px 0
</style>
