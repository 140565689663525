<template>
  <div class="videos">
    <nuxt-link
      v-for="(item, i) in videos"
      :key="`${$route.name}-${item.kinopoisk_id}-${i}`"
      :to="localePath(`/catalog/${postUrl(item.kinopoisk_id, item.title_ru)}`)"
      :data-video-id="item.kinopoisk_id"
      @click.right.prevent="contextMenu(item.kinopoisk_id)"
      class="item"
    >
      <div class="poster">
        <div class="hover">
          <Favs :payload="{
            film: {
              title: item.title_ru, title_en: item.title_en, poster: filmPoster(item),
              id: item.kinopoisk_id
            },
            button: false
          }" />
          <img src="/static_files/icons/play-button.svg?1" alt="Play video" class="play">
        </div>
        <img
          :data-src="filmPoster(item)"
          :alt="['ru', 'uk', 'be'].includes(lang) ? item.title_ru : item.title_en ? item.title_en : item.title_ru"
          src="/static_files/no-poster.png"
          loading="lazy"
          class="kp lazyload"
        >
      </div>
      <strong>{{ ['ru', 'uk', 'be'].includes(lang) ? item.title_ru : item.title_en ? item.title_en : item.title_ru }}</strong>
      <span v-if="item.material_data && item.material_data.genres && item.material_data.genres.length">
        {{ ['ru', 'uk', 'be'].includes(lang) ? item.material_data.genres.slice(0, 3).join(', ') : translateGenre(item.material_data.genres.slice(0, 3)) }}
      </span>
      <span v-else-if="item.expires">
        {{ item.expires | filterDate }}
      </span>
      <!-- <nuxt-link
        :to="`${
          item.type
            ? `/catalog/${postUrl(item.kinopoisk_id, item.title_ru)}`
            : `/vmeste?id=${item.kinopoisk_id}`
        }`"
        :title="`${item.title_ru}${item.title_en ? ` / ${item.title_en}` : ''}`"
        class="film-link"
      > -->
    </nuxt-link>

    <transition name="fadeInFast">
      <div v-if="filmInfo" class="ctx-menu">
        <div class="film-info">
          <div class="header">
            <div class="poster">
              <img
                :src="filmPoster(filmInfo)"
                :alt="`${filmInfo.title_ru}${filmInfo.title_en ? ` / ${filmInfo.title_en}` : ''}`"
              >
            </div>
            <div>
              <h4>
                <nuxt-link :to="`/catalog/${postUrl(filmInfo.kinopoisk_id, filmInfo.title_ru)}`">
                  <template v-if="filmInfo.title_ru">{{ filmInfo.title_ru }}</template>
                  <template v-if="filmInfo.title_ru && filmInfo.title_en">/</template>
                  <template v-if="filmInfo.title_en">{{ filmInfo.title_en }}</template>
                </nuxt-link>
              </h4>
              <div v-if="filmInfo.material_data.genres && filmInfo.material_data.genres.length" class="meta">
                <nuxt-link v-if="filmInfo.material_data.year" :to="`/catalog?page=1&year=${filmInfo.material_data.year}`">
                  {{ filmInfo.material_data.year }}
                </nuxt-link>
                <nuxt-link v-for="c in filmInfo.material_data.countries" :key="`film-country-${c}`" :to="`/catalog?page=1&country=${c}`">
                  {{ c }}
                </nuxt-link>
                <nuxt-link v-for="g in filmInfo.material_data.genres" :key="`film-genre-${g}`" :to="`/catalog?page=1&genre=${g}`">
                  {{ g }}
                </nuxt-link>
              </div>
            </div>
          </div>
          <div v-if="filmInfo.material_data.description" class="desc">
            {{ filmInfo.material_data.description }}
          </div>
        </div>
        <ul>
          <li>
            <Favs :payload="{
              film: {
                title: filmInfo.title_ru, title_en: filmInfo.title_en, poster: filmPoster(filmInfo),
                kinopoisk_id: filmInfo.kinopoisk_id, id: filmInfo.kinopoisk_id
              },
              button: true
            }" />
          </li>
          <li @click="filmShare(filmInfo)" class="film-share">
            <input
              :data-share-id="filmInfo.kinopoisk_id"
              :value="`https://unotalone.su/catalog/${postUrl(filmInfo.kinopoisk_id, filmInfo.title_ru)}`"
              type="text"
              readonly
            />
            <img src="/static_files/icons/reply.svg" alt="Reply">
            Поделиться с другом
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: { videos: { type: Array, require: true, default: () => ([]) } },
  filters: {
    filterDate(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(val).getDate()
      const month = new Date(val).getMonth()
      const year = new Date(val).getFullYear()
      const hour =
        new Date(val).getHours() >= 10
          ? new Date(val).getHours()
          : '0' + new Date(val).getHours()
      const min =
        new Date(val).getMinutes() >= 10
          ? new Date(val).getMinutes()
          : '0' + new Date(val).getMinutes()
      return val > Date.now()
        ? `до ${day} ${months[month]} ${hour}:${min}`
        : `${day} ${months[month]} ${year} ${hour}:${min}`
    }
  },
  components: {
    Favs: () => import('~/components/Favs.vue')
  },
  watch: {
    videos() {
      // this.$nextTick(() => this.lazyLoadImgs())
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    lang () {
      return this.$i18n.locale
    },
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      loading: false,
      filmInfo: null,
      filmsInfo: {}
    }
  },
  mounted() {
    // this.lazyLoadImgs()

    // window.addEventListener('scroll', this.ctxMenus)
    window.removeEventListener('scroll', this.ctxMenus)
    window.removeEventListener('click', this.ctxMenus)
    window.removeEventListener('mousemove', this.mouseCoord)

    window.addEventListener('scroll', this.ctxMenus)
    window.addEventListener('click', this.ctxMenus)
    window.addEventListener('mousemove', this.mouseCoord)
  },
  methods: {
    filmShare(film) {
      if (!this.isDesktop && navigator.share) {
        navigator.share({
          title: `${film.title_ru}`,
          text: `${film.title_ru} / ${film.title_en}`,
          url: `https://kioff.ru/catalog/${this.postUrl(film.kinopoisk_id, film.title)}`,
        })
        .then(() => console.log('Successfully share'))
        .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('Share not supported on this browser, do it the old way.')
        const input = document.querySelector(`[data-share-id="${film.kinopoisk_id}"]`)
        if (input) {
          input.select()
          document.execCommand('copy')
          this.$store.commit('setError', { status: 'ok', msg: 'Ссылка скопирована' })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        }
      }
    },
    ctxMenus() {
      // const list = document.querySelectorAll(`.ctx-menu`)
      // if (list.length) {
      //   list.forEach(el => {
      //     el.style.display = 'none'
      //   })
      // }
      this.filmInfo = null
    },
    mouseCoord(e) {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
    },
    contextMenu(id) {
      if (
        this.filmInfo && 
        (this.filmInfo.sh_id === id || this.filmInfo.kinopoisk_id === id)
      ) return

      this.filmInfo = null

      if (this.filmsInfo[id]) {
        this.filmInfo = this.filmsInfo[id]
      } else {
        if (this.loading) return
        this.loading = true

        const body = {
          id: id
        }

        this.$socket.emit(`unotalone:film:info`, body, (cb) => {
          this.loading = false

          if (cb.error) return

          this.filmInfo = cb
          this.filmsInfo[id] = this.filmInfo
        })
      }

      // this.$nextTick(() => {
      //   const elem = document.querySelector(`.ctx-menu`)
      //   if (elem) {
      //     elem.style.top = this.mouseY + 'px'
      //     const docWidth = document.documentElement.clientWidth
      //     const docHeight = document.documentElement.clientHeight
      //     if (this.mouseX + 590 > docWidth) {
      //       elem.style.left = (docWidth - 590) + 'px'
      //     } else {
      //       elem.style.left = this.mouseX + 'px'
      //     }
      //     if (this.mouseY + elem.clientHeight > docHeight) {
      //       elem.style.top = (docHeight - elem.clientHeight) - 10 + 'px'
      //     }
      //   }
      // })
    }
  }
}
</script>

<style src="~/assets/videos.styl" lang="stylus" />
