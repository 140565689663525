<template>
  <div class="content unsub">
    <h1>Отписаться от рассылки</h1>
    <div v-if="sub">
      <p>
        Ваш электронный адрес {{ userEmail }}
      </p>
      <p>
        Вы больше не будете получать сообщений от нашего сервиса
      </p>
      <button @click="unsub">Отписаться</button>
    </div>
    <div v-else-if="errorMsg">
      <p>
        {{ errorMsg }}
        <span style="display:block;font-weight:700;color:blue;cursor:pointer;" @click="subEmail">Возобновить подписку</span>
      </p>
    </div>
    <div v-else>
      <p>
        Вы успешно отписались от рассылки
        <span style="display:block;font-weight:700;color:blue;cursor:pointer;" @click="subEmail">Возобновить подписку</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  layout: 'clean',
  async asyncData({ query, $axios, error }) {
    if (!query.email) return error({ message: 'Invalid Email' })
    const { data } = await $axios.get(`/api/unsub?email=${query.email}`)
    let errorMsg = null
    let sub = true
    let email = null
    if (data.error) {
      errorMsg = data.error.message
      sub = false
    } else {
      email = data.email
    }
    return {
      query,
      errorMsg,
      sub,
      userEmail: email
    }
  },
  methods: {
    async subEmail() {
      const { data } = await this.$axios.delete(`/api/unsub?email=${this.query.email}`)
      if (data.error) {
        this.errorMsg = data.error.message
        this.sub = true
      }
    },
    async unsub() {
      const { data } = await this.$axios.post(`/api/unsub?email=${this.query.email}`)
      this.sub = false
      if (data.error) {
        this.errorMsg = data.error.message
        this.sub = false
      }
    }
  }
}
</script>

<style lang="stylus">
.unsub
  padding: 40px
  p
    margin: 10px 0
  button
    display: inline-block
    margin-top: 24px
    padding: 8px 28px
    font-size: 16px
    color: #fff
    background-color: rgba(251, 45, 127, 1)
    border: none
    border-radius: 4px
    cursor: pointer
    text-decoration: none
    transition: all 0.2s ease
  a
    display: block
    margin-top: 10px
    font-size: 1.4rem
    color: rgba(255 255 255 0.35)
    &:hover
      color: #fff
</style>
