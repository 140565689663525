<template>
  <main v-if="access" class="cp guess">
    <ModerMenu />
    <section>
      <h1>Guess</h1>
      <div class="share">
        <input
          :data-share-id="user.id"
          :value="`https://unotalone.su/guess?ref=${user.id}`"
          type="text"
          readonly
        />
        <button @click="filmShare">Copy link</button>
        <button @click="getData">Get guess</button>
      </div>
      <div id="player" class="iframe"></div>
      <div v-if="items.length" class="items">
        <div v-for="item in items" :key="`guess-${item._id}`" class="item">
          <div class="poster">
            <a :href="`/catalog/${item.kpid}`">
              <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item.kpid}.jpg`" alt="" style="width:60px;">
            </a>
            <button @click="removeGuess(item._id)">X</button>
          </div>
          <div>
            <span style="cursor:pointer;" @click="playVideo(item.youtube_id)">
              <b>YouTube ID (clickable)</b>
              {{ item.youtube_id }}
            </span>
            <span>
              <b>Answers</b>
              {{ item.answers }}
            </span>
            <span>
              <b>Right Answer</b>
              {{ item.right }}
            </span>
            <span>
              <b>Owner</b>
              {{ item.user_id }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Create guess</h2>
      <div class="form">
        <label for="Movie-id">
          <strong>Movie ID</strong>
          <input type="text" id="Movie-id" v-model="kpid" placeholder="Number">
        </label>
        <label for="id">
          <strong>YouTube VideoId</strong>
          <input type="text" id="id" v-model="guessYoutubeId" placeholder="String">
        </label>
        <label for="answer">
          <strong>Answer</strong>
          <input type="text" id="answer" v-model="guessAnswer" placeholder="Movie titles. Array{4}">
          <button @click="addAnswer">Add</button>
        </label>
        <div v-for="(a, i) in guessAnswers" :key="a">
          {{ i }}. {{ a }} <button @click="removeAnswer(a)">Remove</button>
        </div>
        <label for="right">
          <strong>Right Answer</strong>
          <input type="text" id="right" v-model="guessRightAnswer" placeholder="Number{0-3}">
        </label>
        <div>
          <button @click="createGuess">Create</button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  layout: 'clean',
  components: {
    ModerMenu: import('~/components/ModerMenu.vue')
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      player: null,
      items: [],
      kpid: '',
      guessYoutubeId: '',
      guessAnswer: '',
      guessAnswers: [],
      guessRightAnswer: null,
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
        const playerjs = document.createElement('script')
        playerjs.src = `/static_files/playerjs.js`
        document.head.appendChild(playerjs)
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    playVideo(id) {
      const params = {
        id:"player",
        file: `https://www.youtube.com/watch?v=${id}`,
        default_quality: '480p',
        cuid: id,
      }

      setTimeout(() => {
        this.$store.commit('setPlayer', true)
        /* eslint-disable */
        this.player = new Playerjs(params)
        // this.player.api('play')
      }, 200)
    },
    filmShare() {
      const input = document.querySelector(`[data-share-id="${this.user.id}"]`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'Ссылка скопирована' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    async createGuess() {
      if (
        !this.kpid ||
        !this.guessYoutubeId ||
        !this.guessAnswers.length ||
        this.guessRightAnswer === null
      ) {
        this.$store.commit('setError', { status: 'info', msg: 'Заполните все поля' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      const body = {
        kpid: this.kpid,
        youtube_id: this.guessYoutubeId,
        answers: this.guessAnswers,
        right: +this.guessRightAnswer,
      }
      await this.$axios.post(`/api/moder/guess?key=${this.key}`, body)
      this.getData()
    },
    addAnswer() {
      if (
        !this.guessAnswer.trim() ||
        this.guessAnswers.includes(this.guessAnswer.trim()) ||
        this.guessAnswers.length >= 4
      ) {
        return
      }
      this.guessAnswers.push(this.guessAnswer.trim())
      this.guessAnswer = ''
    },
    removeAnswer(a) {
      const answers = this.guessAnswers.filter(el => el !== a)
      this.guessAnswers = answers
    },
    async removeGuess(id) {
      await this.$axios.delete(
        `/api/moder/guess?key=${this.key}&id=${id}`
      )
      this.getData()
    },
    async getData() {
      const { data } = await this.$axios.get(
        `/api/moder/guess?key=${this.key}`
      )
      if (!data.length) {
        this.$store.commit('setError', { status: 'info', msg: 'Ничего не найдено' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
      this.items = data
    }
  }
}
</script>

<style lang="stylus">
.guess
  .share input
    width: 380px
    margin: 10px 0px
  .iframe
    width: 560px
    height: 320px
    background-color: #000
  .items
    margin-top: 20px
    .item
      margin: 10px 0px
      display: grid
      grid-template-columns: 60px auto
      grid-gap: 10px
      align-items: center
      .poster
        position: relative
        button
          position: absolute
          top: 0px
          left: 0px
          padding: 4px 8px
          font-size: 1.2rem
          z-index: 1
      span
        display: inline-block
        padding: 5px 10px
        margin: 5px
        border: 1px solid rgba(255, 255, 255, 0.25)
        border-radius: 4px
        b
          display: block
          font-size: 1.2rem
          color: rgba(255, 255, 255, 0.5)
  .form
    margin: 20px 0px
    label
      display: block
      margin: 10px 0px
      strong
        display: inline-block
        width: 120px
</style>
