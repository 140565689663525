<template>
  <div class="content">
    <h1>{{ obj.user_name }}: список избранного</h1>
    <videoComponent v-if="obj.favs" :videos="obj.favs" />
  </div>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  head() {
    return {
      title: `${this.obj.user.name}: список избранного`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://unotalone.su/favorites/${this.obj.user._id}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${this.obj.user.name}: список избранного`
        }
      ]
    }
  },
  async asyncData({ $axios, params, error }) {
    const { data } = await $axios.get(`/api/favs/${params.id}`)
    if (data.error) {
      return error(data.error)
    }
    return {
      obj: data
    }
  }
}
</script>
