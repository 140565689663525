<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <section class="feedbck">
      <input type="button" value="Get feedbacks" @click="getData">
      <h1>feedback</h1>
      <ul v-if="feedback.length">
        <li v-for="fb in feedback" :key="fb.date">
          <nuxt-link v-if="fb.user" :to="`/users/${fb.user.id}`" class="user">
            <div class="avatar">
              <img :src="fb.user.photo" alt="">
            </div>
            <strong>{{ fb.user.name }}</strong>
          </nuxt-link>
          <p>
            {{ fb.date | date }}, {{ scores[fb.score] }} | {{ fb.text }} | {{ fb.contact }} | {{ fb.url }}
          </p>
          <img v-if="fb.img" :src="fb.img" alt="Img" class="attach">
          <button @click="removeFb(fb._id)">
            Удалить
          </button>
        </li>
      </ul>
    </section>
  </main>
  <main v-else>
    <h1>Access denied</h1>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    date(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const year = new Date(val).getFullYear()
      const month = new Date(val).getMonth()
      const day = new Date(val).getDate()
      const hour = new Date(val).getHours()
      return `${day} ${months[month]} ${year} в ${hour}.00`
    }
  },
  data() {
    return {
      access: false,
      feedback: [],
      scores: ['Отлично', 'Средне', 'Плохо'],
      loading: false
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async getData() {
      const { data } = await this.$axios.get(
        `/api/admin/feedback?key=${this.key}`
      )
      if (data.error) {
        console.log(data.error)
      } else {
        this.feedback = data.sort((a, b) => (a.date > b.date ? -1 : 1))
      }
    },
    async removeFb(id) {
      await this.$axios.delete(`/api/admin/feedback?key=${this.key}&id=${id}`)
      this.getData()
    }
  }
}
</script>

<style lang="stylus">
.cp .feedbck
  .user
    display inline-block
    .avatar
      display inline-block
      width 40px
      height 40px
      border-style solid
      border-width 2px
      border-radius 50%
      overflow hidden
      vertical-align middle
      img
        width 100%
        height auto
  ul
    list-style none
    li
      margin 10px 5px
      border 1px solid
      padding 10px
  .attach
    display block
    width 200px
    margin-top 5px
</style>
