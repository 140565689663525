<template>
  <main class="activity">
    <section>
      <h1>История активности</h1>

      <div class="stats">
        <div class="row">
          <strong>Баллы активности всего</strong>
          <span>{{ activity.points.toFixed(2) }}</span>
        </div>
        <div class="row">
          <strong>Время просмотра</strong>
          <span v-if="activity.watch">{{ activity.watch | filterActionTimeline }}</span>
          <span v-else>0 сек.</span>
        </div>
      </div>
      
      <div>
        <!-- <p v-if="!items.length">
          Начните проявлять активность уже сейчас.
        </p> -->
        <!-- <h3>
          За что начисляются баллы активности.
        </h3> -->
        <!-- <ol>
          <li>
            Просто <nuxt-link to="/catalog">смотрите кино</nuxt-link>
          </li>
          <li>
            Посещайте <nuxt-link to="/vmeste?common">сеансы в 21:00</nuxt-link> по МСК
          </li>
          <li>
            <nuxt-link to="/guess">Угадывайте кино</nuxt-link> по видео
          </li>
          <li>
            Присоединяйтесь к нам в соц.сетях
            <a href="https://vk.com/unotaloneru" target="_blank">ВК</a> и
            <a href="https://t.me/unotaloneru" target="_blank">ТГ</a>
          </li>
        </ol> -->

        <Hello class="inject" />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  middleware: 'auth',
  components: {
    Hello: () => import('~/components/Hello.vue'),
  },
  head() {
    const fullPath = `https://unotalone.su/profile/activity`
    return {
      title: `История активности`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `История активности`
        }
      ]
    }
  },
  filters: {
    filterActionTimeline(val) {
      val = Number(val);
      const h = Math.floor(val / 3600)
      const m = Math.floor(val % 3600 / 60)
      const s = Math.floor(val % 3600 % 60)

      const hDisplay = h > 0 ? h + (h === 1 ? " ч. " : " ч. ") : ""
      const mDisplay = m > 0 ? m + (m === 1 ? " мин. " : " мин. ") : ""
      const sDisplay = s > 0 ? s + (s === 1 ? " сек." : " сек.") : ""
      return hDisplay + mDisplay + sDisplay 
    },
    // filterDate(val) {
    //   const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
    //   const month = new Date(val).getMonth() + 1 < 10 ? `0${new Date(val).getMonth() + 1}` : new Date(val).getMonth() + 1
    //   const year = new Date(val).getFullYear() < 10 ? `0${new Date(val).getFullYear()}` : new Date(val).getFullYear()

    //   return `${day}.${month}.${year}`
    // },
    // filterDateShort(val) {
    //   const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
      
    //   return `${day}`
    // },
  },
  async asyncData({ $axios, redirect, error }) {
    const { data } = await $axios.get(`/api/user/activity`)
    if (data.error) return error(data.error)
    return {
      activity: data,
    }
  },
}
</script>

<style lang="stylus">
.activity
  padding: 10px
  .stats
    margin-bottom: 40px
    .row
      margin-bottom: 10px
      display: grid
      grid-template-columns: 160px auto
      grid-gap: 10px
  .hello.inject
    position: relative
    width auto
    height auto
    background: rgba(0, 0, 0, 0)
    z-index: 1
    .modal
      position: relative
      width auto
      height auto
      top: auto
      left: auto
      margin: 0px
      padding: 0px
      background: rgba(0, 0, 0, 0)
      border: none
      height: auto
      overflow: auto
      .close
        display: none
  h1
    margin-bottom: 30px
  h3
    margin-bottom: 10px
  ol
    margin-left: 20px
    li
      margin: 5px 0px
</style>