<template>
  <main v-if="access" class="cp cp-desc">
    <cp-menu />
    <h1>Descriptions</h1>
    <section>
      <input
        ref="ssearch"
        v-model="searchQuery"
        @input="trottled"
        :class="{ active: searchQuery }"
        type="search"
        name="ssearch"
        autocomplete="off"
        placeholder="Название фильма"
        autofocus
      >
      <ul v-if="searchQuery.length > 2" class="datalist">
        <li v-if="searchHints.length">
          Возможно вы искали
        </li>
        <li v-else>
          Ничего не найдено.
        </li>
        <li v-for="film in searchHints" :key="film.kinopoisk_id" @click="copyId(film.kinopoisk_id)">
          <span>{{ film.title }}</span>
          <span> (<span>{{ film.genre }}</span>, {{ film.year }})</span>
          <span>{{ film.kinopoisk_id }}</span>
        </li>
      </ul>
    </section>
    <section>
      <input type="button" value="getDescs" @click="getDescs">
      <input type="button" value="getReviews" @click="getReviews">
      <input type="text" v-model="filmId" placeholder="film id">
    </section>
    <textarea v-model="filmDesc" cols="30" rows="10"></textarea>
    <div>
      <input type="button" value="postDesc" @click="postDesc">
      <input type="button" value="postReview" @click="postReview">
    </div>
    <section v-if="descs.length">
      <h2>Descs</h2>
      <ul class="ya-albums">
        <li v-for="item in descs" :key="item._id">
          <a :href="`/catalog/${item.kinopoisk_id}`" target="_blank">
            <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item.kinopoisk_id}.jpg`" alt="" style="width:60px;">
          </a>
          <div style="display:inline-block;vertical-align:top;">
            {{ item.text }}
            <div>
              <button @click="editDesc(item)" class="mini">Edit</button>
              <button @click="removeDesc(item._id)" class="mini">Remove</button>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <section v-if="reviews.length">
      <h2>Reviews</h2>
      <ul class="ya-albums">
        <li v-for="item in reviews" :key="item._id">
          <a :href="`/catalog/${item.kinopoisk_id}`" target="_blank">
            <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item.kinopoisk_id}.jpg`" alt="" style="width:60px;">
          </a>
          <div style="display:inline-block;vertical-align:top;">
            {{ item.text }}
            <div>
              <button @click="editReview(item)" class="mini">Edit</button>
              <button @click="removeReview(item._id)" class="mini">Remove</button>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      descs: [],
      reviews: [],
      filmId: '',
      filmDesc: '',
      searchQuery: '',
      searchHints: []
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    copyId(id, token) {
      this.filmId = id
      this.searchQuery = ''
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 300)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const { data } = await this.$axios.get(
        `/api/sh?value=${encodeURIComponent(
          this.searchQuery
        )}`
      )
      this.searchHints = data.sort((a, b) => (a.year > b.year ? -1 : 1))
    },
    postDesc() {
      if (this.filmId && this.filmDesc) {
        const obj = {
          kinopoisk_id: this.filmId,
          text: this.filmDesc
        }
        this.$axios.post(`/api/admin/desc?key=${this.key}`, obj).then(res => {
          console.log('postDesc')
          this.getDescs()
          this.filmDesc = ''
        })
      }
    },
    postReview() {
      if (this.filmId && this.filmDesc) {
        const obj = {
          kinopoisk_id: this.filmId,
          text: this.filmDesc
        }
        this.$axios.post(`/api/admin/reviews?key=${this.key}`, obj).then(res => {
          console.log('postReview')
          this.getReviews()
          this.filmDesc = ''
        })
      }
    },
    editDesc(i) {
      this.filmId = i.kinopoisk_id
      this.filmDesc = i.description
    },
    editReview(i) {
      this.filmId = i.kinopoisk_id
      this.filmDesc = i.review
    },
    removeDesc(id) {
      this.$axios
        .delete(`/api/admin/desc?key=${this.key}&id=${id}`)
        .then(res => {
          console.log('removeDesc', id)
          this.getDescs()
        })
    },
    removeReview(id) {
      this.$axios
        .delete(`/api/admin/reviews?key=${this.key}&id=${id}`)
        .then(res => {
          console.log('removeReview', id)
          this.getReviews()
        })
    },
    async getDescs() {
      const { data } = await this.$axios.get(`/api/admin/desc?key=${this.key}`)
      if (data.length) {
        this.descs = data.sort((a, b) => (a.date > b.date ? -1 : 1))
      } else {
        this.descs = []
      }
    },
    async getReviews() {
      const { data } = await this.$axios.get(`/api/admin/reviews?key=${this.key}`)
      if (data.length) {
        this.reviews = data.sort((a, b) => (a.date > b.date ? -1 : 1))
      } else {
        this.reviews = []
      }
    },
  }
}
</script>

<style lang="stylus">
.cp-desc
  textarea
    width 100%
  .ya-albums
    li
      display: grid
      grid-template-columns: 60px auto
      grid-gap: 10px
      margin: 15px 0px
</style>
