<template>
  <main class="donate">
    <section>
      <client-only>
        <Premium />
      </client-only>
    </section>

    <section>
      <!-- <h1>Поддержка проекта</h1> -->

      <div id="donate" class="header">
        <div class="bill-form">
          <!-- <img src="/static_files/QIWI.png?1" alt=""> -->
          <label for="bill-amount">
            <!-- <h1>Поддержка проекта</h1> -->
            <h1>Поддержать проект</h1>
            <div>

              <div style="margin:0px 0px 10px;">
                <p style="color:red;font-size:1.8rem;">
                  Не забудьте указать свой ID в сообщение к донату!
                </p>
                <p v-if="user">
                  {{ user.id }}
                </p>
              </div>

              <div style="position:relative;display:inline-block;">
                <input id="bill-amount" type="number" v-model="billAmount" placeholder="Сумма пожертвования" />
                <span :style="`position:absolute;right:20px;top:24px;font-size:2rem;opacity:0.35;`">руб.</span>
              </div>
              <div style="display:inline-block;position:relative;top:3px;">
                <button @click="createBill" class="wi">
                  <img src="/static_files/icons/check.png" alt="">
                  Отправить
                </button>
                <!-- <muxt-link v-else :to="`${payUrl}`" class="wi">
                  <img src="/static_files/icons/chevron-right.png" alt="">
                  Оплатить
                </muxt-link> -->
              </div>
            </div>
          </label>

          <div style="margin:0px 0px 10px;">
            <div>Вернется на баланс
              <div class="sitecoin">
                <i />
                <span>{{ billAmount }} Монет</span>
              </div>
            </div>
            <p style="font-size:1.2rem;color:rgba(255, 255, 255, 0.5);">1 руб = 1 Монета</p>
          </div>

          <div style="margin:30px 0px 10px;">
            Либо перейдите по ссылке
            <a href="https://pay.cloudtips.ru/p/8230d670" rel="nofollow noreferrer noopener">https://pay.cloudtips.ru/p/8230d670</a>

            <!-- <div style="margin:10px 0px;width: 120px;height: 120px;">
              <img src="https://static.donationalerts.ru/uploads/qr/11693478/qr_5c3923872f11fbf4442737f3d184b318.png" alt="" style="width: 100%;">
            </div> -->
          </div>

          <div style="margin:0px 0px 10px;">
            <p>
              Если Монеты не поступили на баланс после оплаты в течение 10 минут, обратитесь к Администрации.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="donaters">
      <h2>
        <i class="crown" />
        Великолепная 20-ка
      </h2>

      <div v-if="donaters.length" class="list">
        <div
          v-for="(user, i) in donaters"
          :key="`donater-${i}-${user.name}`"
          :style="`opacity: ${1 - (0.03 * i)}`"
          class="item"
        >
          <div class="pos">
            {{ i + 1 }}
          </div>
          <div class="wrapper" :class="{ 'premium': user.group === 777 }">
            <div class="avatar">
              <img :src="user.photo" :alt="user.name">
            </div>
          </div>
          <strong>
            {{ user.name }}
          </strong>
          <span>{{ user.amount }}</span>
        </div>
      </div>
      <p v-else>
        Будь первым кто поддержит сайт! <a href="#donate">Поддержать</a>
      </p>
    </section>
          
    <!-- <div class="bhint">
      <strong>Если Вы столкнулись с проблемой при оплате счета, напишите нам напрямую:</strong>
      <ul>
        <li>ВКонтакте <a href="https://vk.com/r0n1x" target="_blank">https://vk.com/r0n1x</a></li>
        <li>Телеграм <a href="https://t.me/r0n1x" target="_blank">https://t.me/r0n1x</a></li>
      </ul>
    </div> -->
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  meta: {
    i18n: false,
  },
  components: {
    Premium: () => import('~/components/Premium.vue'),
  },
  head() {
    return {
      title: `Поддержка сайта`,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: 'https://unotalone.su/dobro'
      //   }
      // ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Поддержи проект и получи Premium - преимущества.`
        }
      ]
    }
  },
  filters: {
    filterBillDate(val) {
      // const months = [
      //   'января',
      //   'февраля',
      //   'марта',
      //   'апреля',
      //   'мая',
      //   'июня',
      //   'июля',
      //   'августа',
      //   'сентября',
      //   'октября',
      //   'ноября',
      //   'декабря'
      // ]
      const year = new Date(val).getFullYear()
      const month = new Date(val).getMonth() + 1 < 10
        ? `0${new Date(val).getMonth()}`
        : new Date(val).getMonth() + 1
      const day = new Date(val).getDate() < 10
        ? `0${new Date(val).getDate()}`
        : new Date(val).getDate()
      const hour = new Date(val).getHours() < 10
        ? `0${new Date(val).getHours()}`
        : new Date(val).getHours()
      const minute = new Date(val).getMinutes() < 10
        ? `0${new Date(val).getMinutes()}`
        : new Date(val).getMinutes()
      const seconds = new Date(val).getSeconds() < 10
        ? `0${new Date(val).getSeconds()}`
        : new Date(val).getSeconds()
      return `${day}.${month}.${year} ${hour}:${minute}:${seconds}`
    }
  },
  computed: {
    ...mapState(['user'])
  },
  async asyncData({ $axios, error }) {
    const { data } = await $axios.get(`/api/donaters`)
    if (data.error) error(error)
    return {
      donaters: data,
      billAmount: 50,
    }
  },
  methods: {
    createBill() {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      const result = confirm('Помните что нужно указать свой ID в текст сообщения?')
      if (!result) return
      location.href = "https://pay.cloudtips.ru/p/8230d670"
    },
  }
}
</script>

<style src="~/assets/donate.styl" lang="stylus">
