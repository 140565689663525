<template>
  <div />
</template>

<script>
export default {
  async asyncData({ redirect, $axios }) {
    const { data } = await $axios.get(`/api/common`)
    return data ? redirect(`/vmeste/${data._id}`) : redirect(`/`)
  }
}
</script>