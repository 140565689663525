<template>
  <div style="padding: 20px;">
    <i class="loader" style="width:16px;height:16px;background-size:16px;" /> {{ msg }}
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // layout: 'clean',
  middleware: 'auth',
  head() {
    const fullPath = 'https://unotalone.su/vmeste/'
    return {
      title: this.$t('room_creating'),
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ]
    }
  },
  computed: mapState(['user']),
  mounted() {
    this.msg = this.$t('room_creating')
    
    if (this.$route.query.id) {
      const body = {
        common: Object.keys(this.$route.query).includes('common'),
        id: this.$route.query.id,
        hidden: !Object.keys(this.$route.query).includes('common'),
        vmeste: false,
        se: {
          s: this.$route.query.season ? this.$route.query.season : '',
          e: this.$route.query.episode ? this.$route.query.episode : '',
          t: this.$route.query.translation ? this.$route.query.translation : ''
        }
      }
      this.$axios.post(`/api/cinema`, body).then(({ data }) => {
        // console.log('data', data)
        if (data.error) {
          this.msg = data.error.message
          setTimeout(() => {
            this.$router.push(this.localePath(`/`))
          }, 3000)
        } else {
          this.msg = data.msg
          setTimeout(() => {
            this.$router.push(this.localePath(`/vmeste/${data.id}`))
          }, 1000)
        }
      })
    } else {
      this.$router.push(this.localePath(`/`))
    }
  },
  data() {
    return {
      msg: '',
    }
  }
}
</script>
