<template>
  <main class="content article-page" itemscope="itemscope" itemtype="https://schema.org/Article">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="`/articles`" itemprop="item">
          <span itemprop="name">Статьи</span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="`/tag/${article.article.tags[0].id}-${article.article.tags[0].name}`" itemprop="item">
          <span itemprop="name">{{ article.article.tags[0].name }}</span>
        </nuxt-link>
        <meta itemprop="position" content="3">
      </div>
    </div>

    <section class="header">
      <Article :payload="article" />
      <aside>
        <client-only>
          <div v-if="isDesktop" class="banner">
            <Rsya :payload="{ blockid:'R-A-1970027-1', page: 1 }" />
          </div>
        </client-only>

        <div class="read-now">
          <h3>Сегодня читают</h3>

          <hr>

          <div v-if="readNow.length" class="list">
            <div v-for="item in readNow" :key="`article-${item._id}`" class="item">
              <div class="poster">
                <img
                  :data-src="item.poster"
                  src="/static_files/no-poster.png"
                  :alt="item.title"
                  loading="lazy"
                  class="lazyload"
                >
              </div>
              <div class="title">
                {{ item.title }}
              </div>
              <nuxt-link :to="`/articles/${item.pretty}`" class="link">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </aside>
    </section>

    <section v-for="(item, index) in moreNews" :key="`more-news-${+index + 1}`" class="more-news">
      <div v-if="(!user || user.group === 1)" class="ads">
        <div class="banner">
          <Rsya :payload="{ blockid: `R-A-2003630-7`, page: +index + 1 }" />
        </div>
      </div>

      <div class="header">
        <Article :payload="item" />
        <aside>
          <client-only>
            <div v-if="isDesktop && (!user || user.group === 1)" class="banner sticky">
              <Rsya :payload="{ blockid: `R-A-2003630-4`, page: +index + 1 }" />
            </div>
          </client-only>
        </aside>
      </div>
    </section>

    <div class="load-more" />
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Rsya: () => import('~/components/Rsya.vue'),
    Article: () => import('~/components/Article.vue'),
  },
  head() {
    const { article } = this.article
    const fullPath = `https://unotalone.su/articles/${article.pretty}`

    return {
      title: article.title + ' | UNOTALONE',
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: article.keys ? article.keys.join(', ') : ''
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: article.title
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: article.poster
        },
        {
          hid: 'description',
          name: 'description',
          content: article.short
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: article.short
        }
      ]
    }
  },
  computed: {
    ...mapState(['isDesktop', 'user'])
  },
  async asyncData({ $axios, params, error, query, redirect }) {
    if (Object.keys(query).length) {
      return redirect(`/articles/${params.id}`)
    }
    const { data } = await $axios.get(`/api/articles/a/${params.id}`)
    if (data.error) {
      error(data.error)
    } else {
      return {
        article: data.article,
        readNow: data.read_now,
        loaded: true
      }
    }
  },
  data() {
    return {
      gets: {},
      moreNews: []
    }
  },
  mounted() {
    if (this.isDesktop) window.addEventListener('scroll', this.stickyAds)
    window.addEventListener('scroll', this.loadMore)
    // this.lazyLoadImgs()
    setTimeout(() => (this.loaded = false), 1000)
  },
  beforeDestroy() {
    if (this.isDesktop) window.removeEventListener('scroll', this.stickyAds)
    window.removeEventListener('scroll', this.loadMore)
  },
  methods: {
    async loadMore() {
      if (this.loaded || this.gets.loadMore) return
      const docHeight = document.documentElement.clientHeight
      const elem = document.querySelector('.load-more')
      const topPosition = docHeight - elem.getBoundingClientRect().top
      if (+topPosition > 0) {
        this.gets.loadMore = true
        const { data } = await this.$axios.get(`/api/articles/next-news?length=${
          this.moreNews.length
        }&id=${this.article.article._id}`)
        if (!data.error) {
          this.moreNews.push(data)
          // this.$nextTick(() => this.lazyLoadImgs())
          setTimeout(() => {
            delete this.gets.loadMore
          }, 2000)
        }
      }
    },
    stickyAds() {
      const adsList = document.querySelectorAll('aside .banner.sticky')
      adsList.forEach((el, i) => {
        const parentBlock = el.parentElement.parentElement
        const parentBlockTop = parentBlock.getBoundingClientRect().top
        const parentBlockHeight = parentBlock.clientHeight
        const parentBlockPos = parentBlockTop + parentBlockHeight

        if (parentBlockTop < 60 && parentBlockPos > 660) {
          el.classList.add('fix')
          el.classList.remove('bottom')
        } else if (parentBlockPos < 660) {
          el.classList.remove('fix')
          el.classList.add('bottom')
        } else {
          el.classList.remove('fix')
          el.classList.remove('bottom')
        }
      })
    }
  }
}
</script>

<style src="~/assets/articles-page.styl" lang="stylus" />
