<template>
  <main v-if="access" class="cp">
    <cp-menu />
  </main>
  <main v-else>
    <input type="text" v-model="token">
    <input type="button" value="Enter" @click="auth">
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  data() {
    return {
      access: false,
      token: ''
    }
  },
  methods: {
    auth() {
      if (this.token) {
        this.$axios.get(`/api/admin?key=${this.token}`).then(({ data }) => {
          // console.log('data', data)
          if (data.result) {
            localStorage.setItem('auth_token', this.token)
            this.access = true
          }
        })
      } else {
        console.log('Auth needed')
        this.$store.commit('setError', 'Access denied')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    }
  }
}
</script>
