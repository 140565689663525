<template>
  <div class="content tag-page">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>
    </div>

    <h1>Статьи по теме - {{ tag.name }}</h1>
    <div class="articles">
      <div v-for="item in articles" :key="`tag-article-${item._id}`" class="item">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="item.poster" :alt="item.title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ item.date | filterDate }}</time>
          <div class="h1">{{ item.title }}</div>
        </div>
        <nuxt-link :to="`/article/${item.pretty}`" />
      </div>
    </div>

    <div v-if="!lastPage && articles.length >= 12" class="show-more">
      <button @click="showMore(++page)">
        Больше материалов
      </button>
    </div>
  </div>
</template>

<script>
export default {
  head() {
    const fullPath = `https://unotalone.su/tag/${this.tag.id}-${this.tag.name}`

    return {
      title: `Статьи по теме - ${this.tag.name}${+this.page > 1 ? `. Страница ${this.page}` : ''} | LALEA`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${this.tag.name}`
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Статьи по теме - ${this.tag.name}${+this.page > 1 ? `. Страница ${this.page}` : ''} | LALEA`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'description',
          name: 'description',
          content: `Полный список статей нашего портала по теме ${this.tag.name}${+this.page > 1 ? `. Страница ${this.page}` : ''}.`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Полный список статей нашего портала по теме ${this.tag.name}${+this.page > 1 ? `. Страница ${this.page}` : ''}.`
        }
      ]
    }
  },
  filters: {
    filterDate(value) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  async asyncData({ $axios, query, redirect, params, error }) {
    if (Object.keys(query).length) {
      return redirect(`/`)
    }
    const page = query.page ? query.page : 1
    const tagId = +params.id.slice(0, params.id.indexOf('-'))
    const tagName = params.id.slice(params.id.indexOf('-') + 1)

    const body = {
      id: tagId,
      name: tagName,
      page
    }

    const { data } = await $axios.post(`/api/articles/tags`, body)
    if (data.error) {
      return error(data.error)
    }
    return {
      page,
      tag: {
        id: tagId,
        name: tagName
      },
      lastPage: false,
      articles: data,
      gets: {}
    }
  },
  mounted() {
    // this.lazyLoadImgs()
  },
  methods: {
    async showMore(page) {
      if (this.gets.showMore) return
      this.gets.showMore = true
      const body = {
        id: this.tag.id,
        name: this.tag.name,
        page
      }
      const { data } = await this.$axios.post(`/api/articles/tags`, body)
      if (data.length < 12) this.lastPage = true
      const docs = this.articles.concat(data)
      this.articles = docs
      delete this.gets.showMore
    }
  }
}
</script>

<style src="~/assets/tag-id.styl" lang="stylus" />
