<template>
  <div class="users-stats">
    <div class="catmenu">
      <div @click="sortArr('new')" :class="{ active: type === 'new' }" class="p">
        {{ $t('stats_newbie') }}
        <sup v-if="items.new_today">+{{ items.new_today }}</sup>
      </div>
      <div @click="sortArr('active')" :class="{ active: type === 'active' }" class="p">
        {{ $t('stats_activists') }}
      </div>
      <div @click="sortArr('watchers')" :class="{ active: type === 'watchers' }" class="p">
        {{ $t('stats_watchers') }}
      </div>
      <p v-if="type === 'new'">{{ $t('stats_newreg') }}</p>
      <p v-else-if="type === 'active'">
        <nuxt-link to="/profile/activity">{{ $t('stats_how') }}</nuxt-link>
      </p>
      <p v-else-if="type === 'watchers'">
        {{ $t('stats_kinomans') }}
      </p>
    </div>
    <div class="wrap">
      <div v-if="sortItems.length" :style="`width:${(sortItems.length) * sliderWidth}px`" class="list">
        <div v-for="item in sortItems" :key="`us-${item._id}`" @click="getInfo(item)" class="item">
          <div class="wrapper" :class="{ 'premium': item.group === 777 }">
            <div class="avatar">
              <img class="lazyload" src="/static_files/no-poster.png" :data-src="item.photo" :alt="item.name">
            </div>
          </div>
          <strong>{{ item.name }}</strong>
        </div>
      </div>
      <div v-else :style="`width:${20 * sliderWidth}px`" class="list">
        <div v-for="item in 20" :key="`us-${item}`" class="item fake">
          <div class="wrapper">
            <div class="avatar">
              <img src="/static_files/no-poster.png" alt="Username">
            </div>
          </div>
          <strong></strong>
        </div>
      </div>
    </div>

    <transition name="toTop">
      <div v-if="userInfo" class="popup">
        <div @click="userInfo = null" class="close" />
        <div v-if="userInfo" class="user-info">
          <div class="name">
            <strong v-if="userInfo.name">{{ userInfo.name }}</strong>
            <span v-if="userInfo.age && new Date().getFullYear() - new Date(userInfo.age).getFullYear()">
              {{ new Date().getFullYear() - new Date(userInfo.age).getFullYear() }}
            </span>
          </div>
          <div style="margin: 10px 0px;">
            <button @click="addFriend(userInfo.id)" class="wi fill">
              <img src="/static_files/icons/add-friend.png" alt="">
              {{ $t('befriends') }}
            </button>
          </div>
          <div class="body">
            <div v-if="userInfo.address && userInfo.address.value" class="row">
              <span>Живет в </span>
              <strong>{{ userInfo.address.value }}</strong>
            </div>
            <div v-if="userInfo.about" class="row">
              <strong>Обо мне</strong>
              <p>{{ userInfo.about }}</p>
            </div>
            <div v-if="userInfo.interests" class="interests row">
              <strong>Мои интересы</strong>
              <span
                v-for="int in userInfo.interests"
                :key="`userinfo-int-${int.id}`"
              >
                {{ int.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    items: { type: Object, require: true, default: () => ({ new: [], active: [], watchers: [] }) }
  },
  computed: mapState(['user', 'isDesktop']),
  data() {
    return {
      sliderWidth: 145,
      loading: false,
      sortItems: [],
      type: 'new',
      userInfo: null,
    }
  },
  mounted() {
    if (!this.isDesktop) {
      this.sliderWidth = 95
    }
    this.sortItems = this.items[this.type]
  },
  methods: {
    async addFriend(userId) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
      } else {
        const { data } = await this.$axios.post('/api/friends/friendly', { to: userId })
        if (data.error) {
          this.$store.commit('setError', { status: 'info', msg: data.error.message })
        } else {
          this.friendly = data
          this.$store.commit('setError', { status: 'ok', msg: 'Запрос отправлен' })
          this.$socket.emit(`unotalone:friendly`, { to: userId, from: { name: this.user.name, id: this.user.id } })
        }
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async getInfo(user) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/friends/info?user_id=${user._id}`)
      this.loading = false
      if (data) {
        if (data.name) {
          data.name += ` aka ${user.name}`
        } else {
          data.name = user.name
        }
        this.userInfo = data
      } else {
        this.userInfo = {
          id: user._id,
          name: user.name
        }
      }
    },
    sortArr(type) {
      this.type = type
      this.sortItems = this.items[this.type]
    }
  },
}
</script>

<style lang="stylus">
.users-stats
  .popup
    position: fixed
    padding: 20px
    top: 50%
    left: 50%
    margin: -250px 0px 0px -300px
    width: 600px
    height: 500px
    background-color: #1f1b2f
    border: 2px solid rgba(255, 255, 255, 0.15)
    border-radius: 4px
    overflow-x: hidden
    overflow-y: auto
    z-index: 10
    -webkit-overflow-scrolling touch
    &::-webkit-scrollbar-track
      width: 17px
      background rgba(255, 255, 255, 0)
    &::-webkit-scrollbar
      width: 17px
    &::-webkit-scrollbar-thumb
      background rgba(255, 255, 255, 0.4)
    &:hover::-webkit-scrollbar-thumb
      background rgba(255, 255, 255, 0.4)
    .close
      display: inline-block
      position: absolute
      top: 10px
      right: 10px
      width: 10px
      height: 10px
      background-image: url('/static_files/icons/cancel-dark.svg')
      background-size: 10px
      cursor: pointer
      opacity: 0.75
      transition: opacity 0.2s ease
      &:hover
        opacity: 1
    .user-info
      .name
        strong
          font-size: 2rem
        span
          font-size: 2rem
          margin-left: 10px
      .body
        margin-top: 20px
        .row
          margin: 20px 0px
        .interests
          span
            display: inline-block
            padding: 2px 12px
            margin: 5px
            font-size: 1.2rem
            color: rgba(255, 255, 255, 1)
            border: 1px solid rgba(255, 255, 255, 1)
            border-radius: 20px
  .catmenu
    .p
      display: inline-block
      margin-right: 10px
      padding-bottom: 2px
      font-size: 2rem
      color: rgba(255, 255, 255, 0.5)
      border-bottom: 1px solid rgba(255, 255, 255, 0)
      transition: all 0.2s ease
      cursor: pointer
      &.active
        color: rgba(255, 255, 255, 1)
        border-bottom: 1px solid rgba(251, 45, 127, 1)
      &:hover
        color: rgba(255, 255, 255, 1)
    p
      font-size: 1.4rem
      color: rgba(255, 255, 255, 0.75)
      a
        color: rgba(255, 255, 255, 0.75)
  button.fill
    padding: 4px 8px
    font-size: 1.2rem
  .wrap
    padding-bottom: 10px
    margin-bottom: 30px
    width: 100%
    cursor: pointer
    overflow-x: auto
    scroll-behavior smooth
    -webkit-overflow-scrolling touch
    &::-webkit-scrollbar-track
      height: 17px
      background rgba(255, 255, 255, 0)
    &::-webkit-scrollbar
      height: 17px
    &::-webkit-scrollbar-thumb
      background rgba(255, 255, 255, 0.4)
    &:hover::-webkit-scrollbar-thumb
      background rgba(255, 255, 255, 0.4)
    .list
      margin-top: 20px
      .item
        display: inline-block
        margin: 0px 5px
        padding: 10px
        border: 2px solid rgba(255, 255, 255, 0.15)
        border-radius: 10px
        text-align: center
        transition: all 0.2s ease
        &:hover
          border: 2px solid rgba(255, 255, 255, 0.5)
        &.add
          opacity: 0.5
          &:hover
            opacity: 1
        &.fake
          strong
            height: 30px
        .wrapper
          width 100px
          height 100px
          padding: 4px
          background-color: rgba(0, 0, 0, 0.11)
          border-radius 50%
          &.premium
            padding: 7px
            background-image: url('/static_files/a54483bd82b6a251b78d381b66a6a4c5.svg')
            background-repeat: no-repeat
            background-size: 100px
            .avatar
              width: 86px
              height: 86px
          .avatar
            width: 92px
            height: 92px
            border-radius: 50%
            // border: 2px solid rgba(255, 255, 255, 0.5)
            overflow: hidden
            img
              width: 100%
              min-height: 100%
              object-fit: cover
        strong
          display: block
          padding: 5px
          width: 100px
          text-align: center
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
</style>