<template>
  <main class="donate">
    <section>
      <client-only>
        <Premium />
      </client-only>
    </section>

    <section>
      <!-- <h1>Поддержка проекта</h1> -->

      <div id="donate" class="header">
        <div class="bill-form">
          <!-- <img src="/static_files/QIWI.png?1" alt=""> -->
          <label for="bill-amount">
            <!-- <h1>Поддержка проекта</h1> -->
            <h1>Поддержать проект</h1>
            <div>
              <div style="position:relative;display:inline-block;">
                <input id="bill-amount" type="number" v-model="billAmount" placeholder="Сумма пожертвования" />
                <span :style="`position:absolute;right:20px;top:24px;font-size:2rem;opacity:0.35;`">руб.</span>
              </div>
              <div style="display:inline-block;position:relative;top:3px;">
                <button @click="createBill" class="wi">
                  <img src="/static_files/icons/check.png" alt="">
                  Отправить
                </button>
                <!-- <muxt-link v-else :to="`${payUrl}`" class="wi">
                  <img src="/static_files/icons/chevron-right.png" alt="">
                  Оплатить
                </muxt-link> -->
              </div>
            </div>
          </label>

          <div style="margin:0px 0px 10px;">
            <div>Вернется на баланс
              <div class="sitecoin">
                <i />
                <span>{{ billAmount }} Монет</span>
              </div>
            </div>
            <p style="font-size:1.2rem;color:rgba(255, 255, 255, 0.5);">1 руб = 1 Монета</p>
          </div>
          
          <div>
            <label for="privatebill">
              <input id="privatebill" type="checkbox" v-model="privateBill" />
              <span>Показать в списке донатеров</span>
            </label>
          </div>

          <div style="margin: 10px 0px 20px;">
            <button :class="{ 'active': billAmount === 30 }" @click="billAmount = 30">30</button>
            <button :class="{ 'active': billAmount === 50 }" @click="billAmount = 50">50</button>
            <button :class="{ 'active': billAmount === 100 }" @click="billAmount = 100">100</button>
            <button :class="{ 'active': billAmount === 500 }" @click="billAmount = 500">500</button>
          </div>

          <!-- <p v-if="$device.isSafari" style="margin:0px 0px 20px;">
            <strong>Кажется у вас Safari?</strong> Данный браузер не поддерживает открытие доп.окон, поэтому
            совершить перевод не получится. Рекомендуем использовать
            <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a> на всех устройствах.
          </p> -->

          <!-- <div style="margin:20px 0 40px;">
            <strong style="font-size:1.6rem;">Вернется на баланс</strong>
            <div style="display:block;margin-top:10px;">
              <div class="sitecoin">
                <i />
                <span><b style="color:red;">+</b>{{ billAmount }} Монет</span>
              </div>
              <div class="sitecoin exp">
                <i />
                <span><b style="color:red;">+</b>{{ billAmount * 20 }} Кармы</span>
              </div>
            </div>
          </div> -->

          <div style="margin:0px 0px 10px;">
            <p>
              Если Монеты не поступили на баланс после оплаты, откройте <b>Список переводов</b> и найдите нужный перевод, нажмите <b>Проверить</b>.
            </p>
          </div>

          <div>
            <div style="display:inline-block;">
              <button @click="getBills" class="wi green" style="font-size:1.2rem;padding:4px 8px;">
                <img src="/static_files/icons/list.png" alt="">
                Список переводов
              </button>
            </div>
            <div style="display:inline-block;">
              <button @click="showManualBlock = !showManualBlock" class="wi" style="font-size:1.2rem;padding:4px 8px;">
                <img src="/static_files/icons/question.png" alt="">
                Как сделать пожертвование
              </button>
            </div>
          </div>

          <div class="bills-list">
            <ul v-if="bills.length">
              <li v-for="b in bills" :key="`donate-bill-${b.bid}`">
                <span :class="b.status.toLowerCase()">{{ b.status }}</span>
                <strong>{{ b.date | filterBillDate }} - {{ b.amount }} RUB</strong>
                <button v-if="b.status === 'WAITING'" @click="getBillInfo(b.bid)" class="dbtn blue mini">
                  Проверить
                </button>
                <button v-if="b.status === 'WAITING'" @click="payBill(b.pay_url)" class="dbtn green mini">
                  Оплатить
                </button>
                <button v-if="b.status === 'WAITING'" @click="deleteBill(b._id)" class="dbtn red mini">
                  X
                </button>
              </li>
            </ul>
            <div v-if="bills.length">
              <p>
                Оплатить счет можно <strong>в течение 1 часа</strong> с момента создания.
              </p>
              <p>
                <span style="padding:2px 5px;background-color:#97b73a;color:#fff;font-size:1rem;">PAID</span> - счет оплачен
                <span style="padding:2px 5px;background-color:orange;color:#fff;font-size:1rem;">WAITING</span> - в ожидании оплаты
              </p>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <nuxt-link to="/help?p=9">Подробнее читайте в разделе Помощь.</nuxt-link>
          </div>

          <div v-if="showManualBlock" class="bill-manual">
            <ul>
              <li>
                <strong
                  @click="showManual.full = !showManual.full"
                  class="spoiler"
                >
                  {{ !showManual.full ? 'Подробная инструкция (рекомендуется к ознакомлению)' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.full">
                  <li>
                    Вводим желаемую сумму пожертвования в поле выше, минимум 1 рубль (или эквивалент в другой валюте).
                  </li>
                  <li>
                    После нажатия на кнопку
                    <strong style="padding:2px 5px;background-color:#97b73a;color:#fff;">Пожертвовать</strong>
                    будет открыто окно с формой совершения перевода. Счет действителен 1 час.
                  </li>
                  <li>
                    Выберите способ оплаты: Банковская карта, QIWI кошелек или баланс мобильного телефона
                    (доступно после авторизации в сервисе QIWI).
                    <a href="/bill/bill_1.jpg" target="_blank">
                      <img src="/static_files/bill/bill_1.jpg" alt="">
                    </a>
                    <p style="font-size:1.4rem;opacity:0.75;">
                      Для более удобного перевода средств рекомендуем
                      <a href="https://qiwi.com" target="_blank">создать QIWI-кошелек</a> (кликабельно).<br>
                      <strong>Оплата картой любого банка</strong>
                      Клиенты QIWI до 2 000 ₽ — комиссия 1 %, свыше — 0 %. Для остальных — комиссия 2 %.
                    </p>
                  </li>
                  <li>
                    Проверьте правильность введенных данных и нажмите кнопку
                    <strong style="padding:2px 5px;background-color:orange;color:#fff;">Перевести</strong>.
                    Далее следуйте инструкциям платежной системы.
                  </li>
                  <li>
                    После совершения перевода дождитесь перенаправления обратно на сайт или
                    закройте окно и вернитесь
                    <a href="https://unotalone.su/dobro">на страницу пожервований</a> самостоятельно.
                  </li>
                  <li>
                    Нажмите кнопку
                    <strong style="padding:2px 5px;background-color:#03a9f4;color:#fff;">Список переводов</strong>.
                    В данном блоке отображаются все ваши переводы
                    (как оплаченные
                    <span style="padding:2px 5px;background-color:#97b73a;color:#fff;font-size:1rem;">PAID</span>
                    , так и со статусом
                    <span style="padding:2px 5px;background-color:orange;color:#fff;font-size:1rem;">WAITING</span>
                    (в ожидании оплаты)).
                  </li>
                  <li>
                    Найдите нужный перевод (ориентируйтесь на дату и сумму), если он имеет статус
                    <span style="padding:2px 5px;background-color:orange;color:#fff;font-size:1rem;">WAITING</span>
                    нажмите
                    <strong style="padding:2px 5px;background-color:#03a9f4;color:#fff;">Проверить</strong>.
                    Если оплата еще не была произведена, нажмите
                    <strong style="padding:2px 5px;background-color:#97b73a;color:#fff;">Оплатить</strong>.
                    и повторите Шаг 3 и далее.
                  </li>
                  <li>
                    После проверки перевода, в случае успешной оплаты, на баланс
                    вашего аккаунта поступит поощрение в виде
                    <strong><i class="sitecoin" /> монет</strong> равных
                    по количеству сумме вашего пожервования (в рублях, 1 рубль = 1 Монета).
                    <nuxt-link to="/store">&#128302; Внутрисайтовый магазин</nuxt-link>
                  </li>
                  <li>
                    Обратите внимание, Неоплаченные (или ошибочно сформированные) счета можно удалить
                    <strong style="padding:5px 10px;background-color:#b65e54;color:#fff;">X</strong>
                  </li>
                </ol>
              </li>
              <li>
                <strong
                  @click="showManual.QIWICreate = !showManual.QIWICreate"
                  class="spoiler"
                >
                  {{ !showManual.QIWICreate ? 'Регистрация QIWI кошелька при помощи сайта' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.QIWICreate">
                  <li>
                    Зайдите в Интернет и выберите VISA QIWI Wallet, на главной странице есть раздел «Создать кошелёк», кликайте на него мышкой.
                    <a href="/bill/qiwi_create_1.jpg" target="_blank">
                      <img src="/static_files/bill/qiwi_create_1.jpg" alt="">
                    </a>
                  </li>
                  <li>
                    В появившемся окошке введите свой номер телефона в форме +7 ХХХ ХХ ХХ, пройдите защитный тест, нажмите на клавишу «Продолжить» и согласитесь с условиями договора.
                    <a href="https://avatars.mds.yandex.net/get-zen_doc/1930013/pub_5fa64c311aeb58326c146109_5fa64c3647a34812ce2ef0c5/scale_1200" target="_blank">
                      <img src="https://avatars.mds.yandex.net/get-zen_doc/1930013/pub_5fa64c311aeb58326c146109_5fa64c3647a34812ce2ef0c5/scale_1200" alt="">
                    </a>
                  </li>
                  <li>
                    Введите проверочный код из SMS, придумайте пароль и укажите его дважды. Обязательно совершите вход в личный кабинет и проверьте, правильно ли вы зарегистрировались.
                    <a href="https://avatars.mds.yandex.net/get-zen_doc/3630864/pub_5fa64c311aeb58326c146109_5fa64c3647a34812ce2ef0c6/scale_1200" target="_blank">
                      <img src="https://avatars.mds.yandex.net/get-zen_doc/3630864/pub_5fa64c311aeb58326c146109_5fa64c3647a34812ce2ef0c6/scale_1200" alt="">
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <strong
                  @click="showManual.mobileToQIWI = !showManual.mobileToQIWI"
                  class="spoiler"
                >
                  {{ !showManual.mobileToQIWI ? 'Как пополнить QIWI кошелек с баланса телефона' : 'Все понятно, закрыть' }}
                </strong>

                <div v-if="showManual.mobileToQIWI">
                  <h4 style="margin-top:10px;">
                    Пополнение QIWI кошелька с баланса телефона через официальный сайт
                  </h4>
                  <a href="https://qiwisupport.com/sites/default/files/inline-images/popolnit-qiwi-so-scheta-telefona-cherez-website.jpg" target="_blank">
                    <img src="https://qiwisupport.com/sites/default/files/inline-images/popolnit-qiwi-so-scheta-telefona-cherez-website.jpg" alt="">
                  </a>
                  <ol>
                    <li>
                      Войдите в свой QIWI Кошелек -
                      <a href="https://qiwi.com/signin" target="_blank">https://qiwi.com/signin</a>
                    </li>
                    <li>
                      Зайдите в раздел <strong>Пополнение</strong>.
                    </li>
                    <li>
                      Выберите пункт <strong>С баланса телефона</strong>.
                    </li>
                    <li>
                      Пополнение произойдет с номера телефона, на который зарегистрирован кошелек.
                    </li>
                    <li>
                      После этого нужно написать сумму перевода.
                    </li>
                    <li>
                      В конце следует нажать <strong>Оплатить</strong> и подтвердить перевод.
                    </li>
                  </ol>
                </div>

                <div v-if="showManual.mobileToQIWI">
                  <h4 style="margin-top:20px;">
                    Пополнение Киви с баланса телефона через мобильное приложение
                  </h4>
                  <a href="https://qiwisupport.com/sites/default/files/inline-images/popolnit-qiwi-so-scheta-telefona-cherez-prilojenie.jpg" target="_blank">
                    <img src="https://qiwisupport.com/sites/default/files/inline-images/popolnit-qiwi-so-scheta-telefona-cherez-prilojenie.jpg" alt="">
                  </a>
                  <ol>
                    <li>
                      Установите приложение QIWI Кошелек для
                      <a href="https://s.qiwi.com/2lG-BGv_yhG" target="_blank">
                        Android
                      </a>
                      или
                      <a href="https://itunes.apple.com/ru/app/id350905609?mt=8" target="_blank">
                        iOS
                      </a>
                      , авторизуйтесь и на главном экране нажмите кнопку <strong>Пополнить кошелек</strong>.
                    </li>
                    <li>
                      После этого следует выбрать <strong>С баланса телефона</strong>.
                    </li>
                    <li>
                      Пополнить можно с того телефона, на который зарегистрирован кошелек
                    </li>
                    <li>
                      Потом нужно указать сумму, нажать <strong>Оплатить</strong> и подтвердить перевод.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <strong
                  @click="showManual.wmToQiwiNumber = !showManual.wmToQiwiNumber"
                  class="spoiler"
                >
                  {{ !showManual.wmToQiwiNumber ? 'Перевод WMP на QIWI кошелек по номеру телефона, через WebMoney Keeper' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.wmToQiwiNumber">
                  <li>
                    Авторизуйтесь в Вашем кошельке на <a href="https://wallet.webmoney.ru/" target="_blank">https://wallet.webmoney.ru/</a>
                  </li>
                  <li>
                    Затем на Главной нажмите на кнопку <strong>"Перевести" - "По номеру телефона" - "в Qiwi"</strong>
                    <a href="https://wiki.webmoney.ru/files/2020/12/201214191451_standard_qiwi_new_1.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2020/12/201214191451_standard_qiwi_new_1.png" alt="">
                    </a>
                    <a href="https://wiki.webmoney.ru/files/2021/02/210201131315_standard_qiwi_new_2.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/02/210201131315_standard_qiwi_new_2.png" alt="">
                    </a>
                  </li>
                  <li>
                    В открывшемся диалоговом окне введите сумму пополнения и подтвердите операцию
                    <img src="https://wiki.webmoney.ru/files/2021/02/210209183551_standard_qiwi_new_3.png" alt="">
                  </li>
                  <li>
                    Аналогичные действия можно произвести, если в списке кошельков выбрать WMP, далее нажать на кнопку <strong>"Перевести" - "По номеру телефона" - "в Qiwi"</strong>
                  </li>
                </ol>
              </li>
              <li>
                <strong
                  @click="showManual.wmToQiwiSBP = !showManual.wmToQiwiSBP"
                  class="spoiler"
                >
                  {{ !showManual.wmToQiwiSBP ? 'Перевод WM на Qiwi по Системе быстрых платежей' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.wmToQiwiSBP">
                  <li>
                    Авторизуйтесь в Вашем кошельке на <a href="https://wallet.web.money" target="_blank">https://wallet.web.money</a>
                  </li>
                  <li>
                    На главной нажмите Перевести" выберите пункт меню "По номеру телефона" - через Систему быстрых платежей. Или кликните на карточку P-кошелька. Затем нажмите на кнопку "Перевести" выберите пункт меню "По номеру телефона", "через Систему быстрых платежей".
                    <a href="https://wiki.webmoney.ru/files/2021/03/210319144839_sbp_qiwi_1.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/03/210319144839_sbp_qiwi_1.png" alt="">
                    </a>
                  </li>
                  <li>
                    Введите номер телефона, выберите Qiwi кошелек (Киви банк), в качестве банка получателя, при необходимости добавьте сообщение, укажите сумму перевода и нажмите кнопку "Далее"
                    <a href="https://wiki.webmoney.ru/files/2021/03/210319143719_sbp_qiwi_2.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/03/210319143719_sbp_qiwi_2.png" alt="">
                    </a>
                  </li>
                  <li>
                    Проверьте детали платежа и нажмите кнопку "Ок".
                    <a href="https://wiki.webmoney.ru/files/2021/03/210319144110_sbp_qiwi_3.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/03/210319144110_sbp_qiwi_3.png" alt="">
                    </a>
                  </li>
                  <li>
                    Подтвердите перевод средств.
                    <a href="https://wiki.webmoney.ru/files/2021/03/210319144432_sbp_qiwi_4.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/03/210319144432_sbp_qiwi_4.png" alt="">
                    </a>
                  </li>
                  <li>
                    Перевод средств совершен успешно. При необходимости сохраните шаблон.
                    <a href="https://wiki.webmoney.ru/files/2021/03/210319144720_sbp_qiwi_5.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2021/03/210319144720_sbp_qiwi_5.png" alt="">
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <strong
                  @click="showManual.wmToQiwiExchanger = !showManual.wmToQiwiExchanger"
                  class="spoiler"
                >
                  {{ !showManual.wmToQiwiExchanger ? 'Перевод (WMZ, WMR, WME, WMB, WMK и WMY) на Qiwi кошелек через секцию emoney.exchanger или авторизованные обменные пункты' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.wmToQiwiExchanger">
                  <li>
                    Всем участникам Системы доступен взаимный обмен WM на Qiwi кошелек и обратно с использованием секции
                    <a href="https://exchanger.money/emoney/home/qiwi" target="_blank">Emoney.Exchanger</a> или авторизованные обменные пункты.
                    Для успешного обмена необходимо осуществить привязку Qiwi кошелька в
                    <a href="https://exchanger.money/bindings/qiwi" target="_blank">личном кабинете</a> сервиса Exchanger
                    <a href="https://wiki.webmoney.ru/files/2020/12/201214191451_exc_yoomoney.png" target="_blank">
                      <img src="https://wiki.webmoney.ru/files/2020/12/201214191451_exc_yoomoney.png" alt="">
                    </a>
                  </li>
                  <li>
                    С описанием и алгоритмом работы секции вы можете ознакомиться <a href="https://exchanger.money/pages/rules/2" target="_blank">здесь</a>
                  </li>
                  <li>
                    В каталоге <a href="http://www.megastock.ru/Resources.aspx?gid=2" target="_blank">Мегасток</a> приведен список авторизованных обменных пунктов.
                  </li>
                </ol>
              </li>
              <li>
                <strong
                  @click="showManual.yooMoneyToQIwi = !showManual.yooMoneyToQIwi"
                  class="spoiler"
                >
                  {{ !showManual.yooMoneyToQIwi ? 'Онлайн-перевод из кошелька YooMoney (ЮMoney) в кошелёк QIWI (Киви)' : 'Все понятно, закрыть' }}
                </strong>

                <ol v-if="showManual.yooMoneyToQIwi">
                  <li>
                    Перейдите на сайт <a href="https://yoomoney.ru/oplata/popolnenie-koshelka-qiwi" target="_blank">YooMoney</a> для совершения перевода
                  </li>
                  <li>
                    <strong>Для пополнения нужна идентификация.</strong>
                    Пожалуйста, <a href="https://yoomoney.ru/id/methods" target="_blank">пройдите идентификацию</a> в ЮMoney (если ещё нет такого статуса) — иначе перевести деньги пока не получится.
                    Это временное ограничение: скоро пополнять кошелёк QIWI смогут все, у кого есть именной статус в ЮMoney.
                  </li>
                  <li>
                    <strong>Проверьте телефон получателя.</strong>
                    Внимательно проверьте номер телефона, по которому отправляете перевод. Если деньги уйдут не тому человеку, нет никаких гарантий, что их получится вернуть.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="donaters">
      <h2>
        <i class="crown" />
        Великолепная 20-ка
      </h2>

      <div v-if="user">
        <button @click="inTop(false)" class="mini">Не показывать меня</button>
        <button @click="inTop(true)" class="mini">Показывать</button>
      </div>

      <div v-if="donaters.length" class="list">
        <div
          v-for="(user, i) in donaters"
          :key="`donater-${i}-${user.name}`"
          :style="`opacity: ${1 - (0.03 * i)}`"
          class="item"
        >
          <div class="pos">
            {{ i + 1 }}
          </div>
          <div class="wrapper" :class="{ 'premium': user.group === 777 }">
            <div class="avatar">
              <img :src="user.photo" :alt="user.name">
            </div>
          </div>
          <strong>
            {{ user.name }}
          </strong>
          <span>{{ user.amount }}</span>
        </div>
      </div>
      <p v-else>
        Будь первым кто поддержит сайт! <a href="#donate">Поддержать</a>
      </p>
    </section>
          
    <!-- <div class="bhint">
      <strong>Если Вы столкнулись с проблемой при оплате счета, напишите нам напрямую:</strong>
      <ul>
        <li>ВКонтакте <a href="https://vk.com/r0n1x" target="_blank">https://vk.com/r0n1x</a></li>
        <li>Телеграм <a href="https://t.me/r0n1x" target="_blank">https://t.me/r0n1x</a></li>
      </ul>
    </div> -->
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Premium: () => import('~/components/Premium.vue'),
  },
  head() {
    return {
      title: `Поддержка сайта`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://unotalone.su/dobto'
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Поддержи проект и получи Premium - преимущества.`
        }
      ]
    }
  },
  filters: {
    filterBillDate(val) {
      // const months = [
      //   'января',
      //   'февраля',
      //   'марта',
      //   'апреля',
      //   'мая',
      //   'июня',
      //   'июля',
      //   'августа',
      //   'сентября',
      //   'октября',
      //   'ноября',
      //   'декабря'
      // ]
      const year = new Date(val).getFullYear()
      const month = new Date(val).getMonth() + 1 < 10
        ? `0${new Date(val).getMonth()}`
        : new Date(val).getMonth() + 1
      const day = new Date(val).getDate() < 10
        ? `0${new Date(val).getDate()}`
        : new Date(val).getDate()
      const hour = new Date(val).getHours() < 10
        ? `0${new Date(val).getHours()}`
        : new Date(val).getHours()
      const minute = new Date(val).getMinutes() < 10
        ? `0${new Date(val).getMinutes()}`
        : new Date(val).getMinutes()
      const seconds = new Date(val).getSeconds() < 10
        ? `0${new Date(val).getSeconds()}`
        : new Date(val).getSeconds()
      return `${day}.${month}.${year} ${hour}:${minute}:${seconds}`
    }
  },
  computed: {
    ...mapState(['user'])
  },
  async asyncData({ $axios, error }) {
    const { data } = await $axios.get(`/api/donaters`)
    if (data.error) error(error)
    return {
      donaters: data
    }
  },
  data() {
    return {
      gets: {},
      privateBill: true,
      billAmount: 50,
      bills: [],
      showManualBlock: false,
      showManual: {
        full: false,
        wmToQiwiNumber: false,
        wmToQiwiSBP: false,
        wmToQiwiExchanger: false,
        yooMoneyToQIwi: false,
        QIWICreate: false,
        mobileToQIWI: false
      }
    }
  },
  methods: {
    async inTop(value = false) {
      const { data } = await this.$axios.put(`/api/user/premium`, { intop: value })

      if (!data.error) {
        this.$store.commit('setError', { status: 'ok', msg: data.message })
      } else {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
      }

      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async deleteBill(id) {
      if (!this.user) {
        this.$store.commit('setShowAuthForm', true)
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      const { data } = await this.$axios.delete(`/api/p2p/bills/${id}`)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const findIndex = this.bills.findIndex(el => el._id === id)
        this.bills.splice(findIndex, 1)
      }
    },
    payBill(url) {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=900,height=600,left=10,top=10`
      window.open(url, `Пожертвования сайту`, params)
    },
    async getBills() {
      if (!this.user) {
        this.$store.commit('setShowAuthForm', true)
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.bills.length) {
        this.bills = []
        return
      }
      if (this.gets.getBills) return
      this.gets.getBills = true
      const { data } = await this.$axios.get('/api/p2p/bills')
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.bills = data
      }
      delete this.gets.getBills
    },
    async getBillInfo(bid) {
      if (!this.user) {
        this.$store.commit('setShowAuthForm', true)
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.gets.getBillInfo) return
      this.gets.getBillInfo = true
      this.$store.commit('setError', { status: 'load', msg: 'Идет проверка' })
      const { data } = await this.$axios.get(`/api/p2p/billinfo?id=${bid}`)
      delete this.gets.getBillInfo

      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (data.status === 'ok') {
        this.$store.commit('setError', { status: 'ok', msg: 'Перевод совершен' })
        const findIndex = this.bills.findIndex(el => el.bid === bid)
        this.bills[findIndex].status = 'PAID'
        setTimeout(() => {
          this.$store.commit('clearError')
          location.reload()
        }, 2000)
      }
    },
    async createBill() {
      // this.$store.commit('setError', { status: 'info', msg: 'Временно не доступно' })
      // setTimeout(() => this.$store.commit('clearError'), 2000)
      if (!this.user) {
        this.$store.commit('setShowAuthForm', true)
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (!+this.billAmount || +this.billAmount < 1) {
        this.$store.commit('setError', { status: 'warn', msg: `Неверная сумма` })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.gets.billAmount) return
      this.gets.billAmount = true
      const obj = {
        intop: this.privateBill,
        amount: +this.billAmount
      }
      this.$store.commit('setError', { status: 'load', msg: `Формирование счета` })
      const { data } = await this.$axios.post(`/api/p2p/createbill`, obj)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
      } else {
        // const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        // width=900,height=870,left=10,top=10`
        // window.open(data, `Пожертвования сайту`, params)
        location.href = data
      }
      delete this.gets.billAmount
      setTimeout(() => this.$store.commit('clearError'), 2000)
    }
  }
}
</script>

<style src="~/assets/donate.styl" lang="stylus">
