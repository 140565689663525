<template>
  <main>
    <section class="copyright">
      <h1>Авторские права и отказ от ответственности</h1>
      <div class="content">
        <p>
          Ресурс ANIME777.RU является <strong>веб-приложением для работы со сторонним API в образовательных целях</strong>.
        </p>
        <p>
          Весь размещенный на сайте видеоконтент представляет собой <strong>записи, находящиеся на стороннем сервере и полученные по средствам API</strong>.
        </p>
        <p>
          <strong>Администрация проекта ANIME777.RU не несет никакой ответственности за использование посетителями материалов</strong>, отображенных на сайте.
          Администрация сайта также <strong>не осуществляет деятельность, связанную с публикацией нелицензионного контента</strong>, незаконно украденного и находящегося под защитой правообладателей.
        </p>
        <p>
          Если Вы являетесь обладателем авторских прав на какой-либо видеоматериал, который отображен на данном сайте, и желаете скрыть данную информацию,
          отправьте письмо в электронном виде со следующей информацией:
        </p>
        <ul>
          <li>
            Документ, свидетельствующий о наличии у Вас исключительных прав на записи, отображенные на сайте: это может быть отсканированная копия с печатью либо контактная информация, которая позволяет идентифицировать Вас в качестве обладателя исключительных прав на соответствующие записи.
          </li>
          <li>
            Прямые ссылки на страницы ресурса, информация с которых является объектом претензии.
          </li>
          <li>
            Информацию отправьте с Вашего официального электронного адреса.
          </li>
        </ul>
        <p>
          Важно: будут рассмотрены только обращения, которые документально подтверждены в соответствии с действующими нормами, любые другие обращения Администраторы будут игнорировать.
        </p>
        <p>
          Если <strong>Вы обладаете правами на показ</strong> художественных и документальных фильмов, мультфильмов, у Вас есть собственный плеер, мы готовы размещать лицензионный контент на нашем сайте.
        </p>
        <p>
          Все предложения направляйте на адрес электронной почты <a href="mailto:abuse@anime777.ru">abuse@anime777.ru</a>
        </p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  head() {
    return {
      title: 'Авторские права и отказ от ответственности - ANIME777',
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://unotalone.su' + this.$route.fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'На данной странице подробно изложены авторские права и отказ от ответственности.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'авторские права, отказ от ответственности'
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
.copyright
  position relative
  p
    margin-top 10px
  ul
    margin 20px 0
  li
    margin-left 20px
    margin-top 10px
</style>
