<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <section class="streamers">
      <input type="button" value="Get streamers" @click="getData">
      <h1>Streamers</h1>

      <div>
        <h3>Add channel</h3>

        <input type="text" v-model="channelName" placeholder="Channel name" />
        <input type="text" v-model="channelOwner" placeholder="Channel owner id" />
        <button @click="getChannelInfo">Info</button>
        <button v-if="channelInfo" @click="addChannel">Add</button>
      </div>

      <div v-if="channelInfo">
        <h3>Channel Info</h3>
        {{ channelInfo}}
      </div>

      <div>
        <div v-for="item in items" :key="`streamer-${item.channel_name}`">
          {{ item }}
        </div>
      </div>
    </section>
  </main>
  <main v-else>
    <h1>Access denied</h1>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    filterDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      items: [],
      channelOwner: '',
      channelName: '',
      channelInfo: null
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async addChannel() {
      if (!this.channelName || !this.channelOwner) return
      const body = {
        user_id: this.channelOwner,
        channel_name: this.channelName,
        photo: this.channelInfo[0].profile_image_url
      }
      const { data } = await this.$axios.post(`/api/admin/streamers?key=${this.key}`, body)
      console.log('addChannel', data)

      this.channelInfo = null

      if (data.error) {
        return console.log('streamers not found')
      }

      this.getData()
    },
    async getChannelInfo() {
      if (!this.channelName) return
      const { data } = await this.$axios.get(`/api/admin/streamers/channel?key=${
        this.key
      }&channel=${this.channelName}`)

      console.log('data', data)

      this.channelInfo = data
    },
    async getData() {
      const { data } = await this.$axios.get(
        `/api/admin/streamers?key=${this.key}`
      )
      // console.log('data', data)
      if (data.length) {
        this.items = data
      } else {
        console.log('streamers not found')
      }
    }
  }
}
</script>

