<template>
  <div class="signin">
    <div class="left">
      <nuxt-link to="/" tag="div" class="logo" />
      <div class="bg" />
      <div v-if="isDesktop" class="advantages">
        <div class="item">
          <img src="/static_files/icons/advantages/premiere.svg" alt="premiere">
          <strong>{{ $t('signin_premiers') }}</strong>
          <p>{{ $t('signin_premiers_p') }}</p>
        </div>
        <div class="item">
          <img src="/static_files/icons/advantages/full-hd.svg" alt="full-hd">
          <strong>{{ $t('signin_quality') }}</strong>
          <p>{{ $t('signin_quality_p') }}</p>
        </div>
        <div class="item">
          <img src="/static_files/icons/advantages/devices.svg" alt="devices">
          <strong>{{ $t('signin_devices') }}</strong>
          <p>{{ $t('signin_devices_p') }}</p>
        </div>
      </div>
      <div class="footer">
        <a href="mailto:support@unotalone.su">
          <img src="/static_files/icons/email.svg">
          <span>support@unotalone.su</span>
        </a>
      </div>
    </div>
    <div class="right">
      <nuxt-link :to="localePath(ref.includes('/vmeste') ? '/' : ref)" tag="i" class="icon-close" />
      <h1>
        {{ $t('signin_h1') }}
      </h1>
      <div class="checkboxes">
        <!-- <label for="termofuse">
          <input type="checkbox" id="termofuse" v-model="checkbox['termofuse']">
          <span>
            Я принимаю условия
            <nuxt-link to="/privacy">Политики конфиденциальности</nuxt-link> и
            <nuxt-link to="/termsofuse">Пользовательского соглашения</nuxt-link>
          </span>
        </label>
        <label for="mailing">
          <input type="checkbox" id="mailing" v-model="checkbox['mailing']">
          <span>
            Я соглашаюсь получать рассылки и специальные предложения
          </span>
        </label> -->
        <p>
          {{ $t('signin_terms') }} <nuxt-link to="/privacy">{{ $t('signin_privacy') }}</nuxt-link> {{ $t('signin_and') }}
          <nuxt-link to="/termsofuse">{{ $t('signin_termsofuse') }}</nuxt-link>
        </p>
        <label for="mailing">
          <input type="checkbox" id="mailing" v-model="checkbox['mailing']">
          <span>
            {{ $t('signin_subscribe') }}
          </span>
        </label>
      </div>
      <div class="socials">
        <strong>{{ $t('signin_soc') }}</strong>
        <div class="items">
          <div @click="login('google')" class="item">
            <img src="/static_files/icons/signin/google.png" alt="google">
          </div>
          <div @click="login('mailru')" class="item">
            <img src="/static_files/icons/signin/mailru.png" alt="mailru">
          </div>
          <div @click="login('yandex')" class="item">
            <img src="/static_files/icons/signin/yandex.png" alt="yandex">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  head() {
    // const fullPath = `https://unotalone.su/signin`
    const title = this.$t('signin_head_title')
    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: fullPath
      //   }
      // ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: title
        }
      ]
    }
  },
  middleware: 'checkin',
  layout: 'clean',
  computed: {
    ...mapState(['user', 'isDesktop']),
    lang () {
      return this.$i18n.locale
    },
  },
  asyncData({ query, $axios, redirect, route }) {
    const ref = '/'
    // if (query.ref) {
    //   const id = query.ref.includes('id=') ? query.ref.replace(/\D/g, '') : ''
    //   if (id) {
    //     const { data } = await $axios.get(`/api/film?id=${id}`)
    //     if (data.error) {
    //       return redirect('/signin')
    //     } else if (!data.blocked.length) {
    //       ref = query.ref
    //     } else {
    //       return redirect('/signin')
    //     }
    //   } else {
    //     ref = query.ref.includes('profile') ? '/chat' : query.ref
    //   }
    // }
    return {
      ref,
      checkbox: {
        termofuse: false,
        mailing: false
      },
    }
  },
  mounted() {
    // if (this.$route.query.ref) {
    //   this.ref = this.$route.query.ref // this.$route.query.ref.includes('/vmeste?id') ? '/' : this.$route.query.ref
    // }

    // if (this.user) {
    //   location.href = '/chat' // this.ref
    // }
//     else {
//       const rsyaMobileFS = document.createElement('script')
//       rsyaMobileFS.innerHTML = `
// window.yaContextCb.push(()=>{
// Ya.Context.AdvManager.render({
//   type: 'fullscreen',
//   blockId: 'R-A-2003630-2'
// })
// })`
//       document.body.appendChild(rsyaMobileFS)
//     }
  },
  methods: {
    login(social) {
      // if (!this.checkbox.termofuse) {
      //   this.$store.commit('setError', {
      //     status: 'warn',
      //     msg: 'Примите условия политики конфиденциальности и пользовательского соглашения'
      //   })
      //   setTimeout(() => this.$store.commit('clearError'), 3000)
      //   const elem = document.getElementById('termofuse')
      //   if (elem) {
      //     elem.style.border = `1px solid rgba(251, 45, 127, 1)`
      //     elem.style.boxShadow = `0 0 20px 0px rgba(251, 45, 127, 1)`
      //   }
      // } else if (!this.checkbox.mailing) {
      //   this.$store.commit('setError', { status: 'warn', msg: 'Подпишитесь на рассылку' })
      //   setTimeout(() => this.$store.commit('clearError'), 2000)
      //   const elem = document.getElementById('mailing')
      //   if (elem) {
      //     elem.style.border = `1px solid rgba(251, 45, 127, 1)`
      //     elem.style.boxShadow = `0 0 20px 0px rgba(251, 45, 127, 1)`
      //   }
      // } else {

      // }
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=900,height=600,left=10,top=10`
      window.open(`/api/auth/${social}`, `Sign in site - ${social}`, params)
    }
  }
}
</script>

<style src="~/assets/signin.styl" lang="stylus">
