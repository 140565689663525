<template>
  <main class="index-page">
    <section>
      <LazyHydrate when-idle>
        <StatsSlider :items="stats" />
      </LazyHydrate>
    </section>

    <!-- <div style="text-align:center;margin:50px 0px 30px;">
      <button @click="goToManual" class="glow-on-hover" type="button">Создать комнату</button>
    </div> -->

    <section class="manual">
      <h1>
        {{ $t('indexh1') }}
      </h1>
      <p class="desc">
        {{ $t('indexmanualdesc') }}
      </p>
      <ul class="table">
        <li class="item">
          <div>
            <span class="fig">1) {{ $t('indexmanualstep1') }}</span>
            <SH :type="`rooms`" />
            <p style="padding:0px 10px 20px;">{{ $t('indexmanualstep1p') }}</p>
          </div>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              2) {{ $t('indexmanualstep2') }}
            </figcaption>
            <a href="/static_files/cinema/manual_search.jpg" target="_blank">
              <img :data-src="'/static_files/cinema/manual_search.jpg'" src="/static_files/no-poster.png" style="max-width: 60%;" loading="lazy" class="lazyload" alt="Создание комнаты для совместного просмотра - изображение 2" />
            </a>
          </figure>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              3) {{ $t('indexmanualstep3') }}
            </figcaption>
            <a href="/static_files/cinema/manual_share.jpg" target="_blank">
              <img :data-src="'/static_files/cinema/manual_share.jpg'" src="/static_files/no-poster.png" style="max-width: 90%;" loading="lazy" class="lazyload" alt="Создание комнаты для совместного просмотра - изображение 3" />
            </a>
          </figure>
        </li>
      </ul>
    </section>

    <section v-if="recommended.length" class="list">
      <div class="h2">
        <nuxt-link to="/popular" style="text-decoration:underline;">{{ $t('indexrecommends') }}</nuxt-link>
        <!-- <nuxt-link to="/popular" style="text-decoration:underline;">Популярное кино</nuxt-link> у пользователей сайта -->
      </div>
      <LazyHydrate when-idle>
        <Slider :videos="{ items: recommended }" />
      </LazyHydrate>
    </section>

    <section class="index-desc">
      <div class="h2">
        {{ $t('indexdesch2') }}
      </div>
      <p>
        {{ $t('indexdesch2p') }}
      </p>
      <figure>
        <img
          src="/static_files/no-poster.png"
          :data-src="isDesktop ? `/static_files/index-page.jpg` : `/static_files/index-page_mobile.jpg` "
          loading="lazy"
          class="lazyload"
          :alt="$t('indexfigcaption')"
        >
        <figcaption>{{ $t('indexfigcaption') }}</figcaption>
      </figure>
      <div>
        <p>
          {{ $t('indexdescp1') }}
        </p>
        <p>
          {{ $t('indexdescp2') }}
        </p>
        <p>
          {{ $t('indexdescp3') }}
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import { mapState } from 'vuex'

export default {
  head() {
    const title = this.$t('indexheadtitle')
    const description = this.$t('indexheaddesc')

    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: 'https://unotalone.su'
      //   }
      // ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
      ]
    }
  },
  components: {
    LazyHydrate,
    SH: () => import('~/components/SH.vue'),
    Slider: () => import('~/components/Slider.vue'),
    StatsSlider: () => import('~/components/StatsSlider.vue'),
  },
  computed: mapState(['isDesktop']),
  async asyncData({ $axios }) {
    const { data } = await $axios.get('/api/index')
    return {
      recommended: data.recommended,
      stats: data.stats,
    }
  },
  methods: {
    goToManual() {
      const elem = document.querySelector('.manual .desc')
      if (elem) {
        elem.scrollIntoView()
        
        const input = document.querySelector('.manual input')
        if (input) input.focus()
      }
    }
  },
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
