<template>
  <div v-if="payload.visible" class="search-hints">
    <div class="form">
      <i class="icon-ssearch" />
      <input
        ref="ssearch"
        v-model="searchQuery"
        :class="{ active: searchQuery }"
        @input="trottled"
        @focus="focused = true"
        @blur="focused = false"
        type="search"
        name="ssearch"
        autocomplete="off"
        placeholder="Начните вводить"
      >
    </div>
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <div>Ничего не найдено</div>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="item in searchHints"
          :to="`/${item.type}/${
            item.type === 'film'
              ? postUrl(item.id, item.title)
              : postUrl(item.id, item.title)
          }`"
          :key="`sh-${item.id}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" :alt="['ru', 'uk', 'be'].includes(lang) ? item.film.title_ru : item.film.title_en || item.film.title_ru">
          </div>
          <p>
            <strong>{{ ['ru', 'uk', 'be'].includes(lang) ? item.film.title_ru : item.film.title_en || item.film.title_ru }}</strong>
          </p>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      require: true,
      default: () => ({
        visible: false, type: 'db', popular: [], text: ''
      })
    }
  },
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    },
    lang () {
      return this.$i18n.locale
    },
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  mounted() {
    if (this.payload.text) {
      this.searchQuery = this.payload.text
      this.getSearchHints()
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}&type=${this.payload.type}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.searchHints = data
      }
    }
  }
}
</script>
