<template>
  <div class="trailers">
    <strong v-if="videos.totalResults">Найдено {{ videos.totalResults }} видео</strong>
    <transition-group v-if="videos.items.length" name="fadeInFast" tag="ul" class="items">
      <li v-for="(v, i) in videos.items" :key="`amv-${text}-${i}`" @click="videoItem = '' + i">
        <img
          :data-src="v.thumbnails.medium.url"
          :alt="v.title"
          src="/static_files/no-poster.png"
          class="lazyload"
        >
        <strong>
          {{ v.title.length > 30 ? v.title.slice(0, 27) : v.title }}...
        </strong>
      </li>
    </transition-group>
    <i v-else class="loader" />
    <div
      v-if="videos.items.length && videos.pageToken"
      @click="showMore(videos.pageToken)"
      class="show-more"
    >
      <button>
        {{ $t('show_more') }}
        <i v-if="!loading" class="arrow" />
        <i v-else class="loader" />
      </button>
    </div>
    <transition name="fade">
      <div v-if="videoItem" @click="videoItem = null" class="popup">
        <div class="actions">
          <i @click="videoItem = null" class="close" />
        </div>
        <div onclick="event.stopPropagation()" class="modal">
          <div v-if="+(videoItem - 1) >= 0" @click="videoItem = '' + (+videoItem - 1)" class="more up">
            <i class="arrow"/>
            <img
              :src="videos.items[+videoItem - 1].thumbnails.medium.url"
              alt=""
            >
          </div>
          <div v-else class="more up" />
          <div class="player">
            <iframe
              :src="`https://www.youtube.com/embed/${
                videos.items[videoItem].id
              }?enablejsapi=1&autoplay=1&mute=1`"
              :title="videos.items[videoItem].title"
              frameborder="0"
              allowfullscreen
            />
            <i class="loader" />
          </div>
          <div v-if="videoItem + 1" @click="videoItem = '' + (+videoItem + 1)" class="more down">
            <i class="arrow"/>
            <img
              :src="videos.items[+videoItem + 1].thumbnails.medium.url"
              alt=""
            >
          </div>
          <div v-else class="more down" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: { text: { type: String, required: true, default: 'кино' } },
  watch: {
    text() {
      this.getVideos()
    },
    videoItem() {
      if (this.videoItem) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  mounted() {
    this.getVideos()
  },
  data() {
    return {
      videoItem: null,
      videos: { items: [] },
      gets: {},
      loading: false
    }
  },
  methods: {
    async getVideos() {
      const { data } = await this.$axios.get(`/api/amv?name=${
        encodeURIComponent(this.text)
      }`)
      console.log('data', data)
      if (data.error) {
        this.$store.commit('setError', data.error.message)
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.videos = data
        // this.$nextTick(() => this.lazyLoadImgs())
      }
    },
    async showMore(pageToken) {
      if (!this.text.trim() || this.gets.showMoreVideos) return
      this.gets.showMoreVideos = true
      this.loading = true
      const { data } = await this.$axios.get(`/api/amv?name=${
        encodeURIComponent(this.text)
      }&pageToken=${pageToken}`)
      if (data.error) {
        this.$store.commit('setError', data.error.message)
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const videos = this.videos.items.concat(data.items)
        this.videos.pageToken = data.pageToken
        this.videos.items = videos
        this.videos.totalResults = data.totalResults
        this.loading = false
      }
      delete this.gets.showMoreVideos
      // this.$nextTick(() => this.lazyLoadImgs())
    }
  }
}
</script>

<style lang="stylus">
.trailers
  margin-top 30px
  .popup
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background-color rgba(0 0 0 0.9)
    z-index 12
    .actions
      text-align right
      padding 20px
      i
        display inline-block
        width 24px
        height 24px
        padding 10px
        background url('/static_files/icons/close-dark.svg') no-repeat center
        background-size 24px
        opacity .75
        cursor pointer
        transition opacity .2s ease
        &:hover
          opacity 1
    .modal
      position fixed
      width 64vw
      height 100vh
      top 50%
      left 50%
      margin -36vw 0 0 -32vw
      .player
        position relative
        background-color #222
        height 36vw
        .loader
          position absolute
          top calc(50% - 25px)
          left calc(50% - 25px)
        iframe
          position relative
          z-index 1
      .more
        position relative
        width 64vw
        height 36vw
        transition opacity .2s ease
        cursor pointer
        &:hover > img
          opacity .75
        &:hover > i
          opacity 1
        img
          width 100%
          height 100%
          opacity .35
        .arrow
          display inline-block
          position absolute
          left 50%
          margin-left -20px
          width 40px
          height 40px
          background-size 40px
          opacity .5
          z-index 1
      .up
        position relative
        margin-top -18vw
        .arrow
          bottom 40px
          background-image url('/static_files/icons/chevron-left-dark.svg')
          transform rotateZ(90deg)
      .down
        position relative
        margin-bottom -18vw
        .arrow
          top 40px
          background-image url('/static_files/icons/chevron-right-dark.svg')
          transform rotateZ(90deg)
      iframe
        width 64vw
        height 36vw
        margin 10px 0
  .items
    list-style none
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 40px 20px
    margin-top 20px
    li
      position relative
      width 100%
      height 160px
      background-color #f1f1f1
      border-radius 5px
      opacity .9
      overflow hidden
      transition all .2s ease-out
      cursor pointer
      &:hover
        opacity 1
        border-radius 0px
        transform scale(1.05)
      &:hover > img
        transform scale(1.05)
      &:hover > strong
        opacity 0
      img
        width 100%
        min-height 100%
        object-fit cover
      strong
        display block
        width 100%
        position absolute
        padding 5px 10px 10px
        left 0
        bottom 0px
        font-size 1.4rem
        color #eee
        background-color rgba(0 0 0 0.55)
        transition opacity .2s ease
</style>
