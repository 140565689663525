<template>
  <section class="comments">
    <div class="comments-body">
      <!-- <div class="h3 block-comments">Комментарии</div> -->
      <div v-if="comments.length" class="comments-list">
        <div
          v-for="comm in comments.filter(el => !el.parent_id)"
          :key="`comment-${comm._id}}${comm.edited ? '-' + comm.edited : ''}`"
          :data-comment-id="comm._id"
          class="comments-item"
        >
          <div class="avatar">
            <img
              v-if="comm.user && comm.user.length"
              :src="comm.user[0].photo"
              alt=""
            >
            <img v-else :data-src="comm.user_avatar" alt="" src="/static_files/noavatar.svg" class="lazyload">
          </div>
          <div class="comment-body">
            <div class="comments-header">
              <strong>
                {{ comm.user && comm.user.length ? comm.user[0].name : comm.user_name }}
              </strong>
              <div
                :datetime="new Date(comm.date).getFullYear() + '-' + new Date(comm.date).getMonth() + '-' + new Date(comm.date).getDate()"
                class="time"
              >
                {{ comm.date | filterDate }}
              </div>
              <i v-if="comm.edited" :title="`Изменен` + comm.edited | filterDate" />
            </div>
            <p>{{ comm.text }}</p>
            <div class="comments-footer">
              <!-- <reactions :item="{ id: comm._id, reactions: comm.reactions, owner_id: comm.user_id }" /> -->
              <template v-if="isOwner(comm.user_id)">
                <span @click="setParentComment(comm._id, comm.user_id, comm.user && comm.user.length ? comm.user[0].name : comm.user_name)">
                  &#128172; Ответить
                </span>
                <span @click="editCommentFunc(comm)">Редактировать</span>
                <span @click="delComment(comm)">Удалить</span>
              </template>
              <template v-else>
                <span @click="setParentComment(comm._id, comm.user_id, comm.user && comm.user.length ? comm.user[0].name : comm.user_name)">
                  &#128172; Ответить
                </span>
                <span v-if="profileOwner" @click="delProfileComment(comm)">Удалить</span>
              </template>
            </div>
            <div v-if="comments.filter(el => el.parent_id && el.parent_id === comm._id).length" class="comment-childs">
              <div
                v-for="child in comments.filter(el => el.parent_id && el.parent_id === comm._id)"
                :key="`child-comment-${child._id}${child.edited ? '-' + child.edited : ''}`"
                :data-comment-id="child._id"
                class="comments-item"
              >
                <div class="avatar">
                  <img
                    v-if="child.user && child.user.length"
                    :src="child.user[0].photo"
                    alt=""
                  >
                  <img v-else :data-src="child.user_avatar" alt="" src="/static_files/noavatar.svg" class="lazyload">
                </div>
                <div class="comment-body">
                  <div class="comments-header">
                    <span><i class="icon-reply" /> {{ comm.user_name }},</span>
                    <strong>
                      {{ child.user && child.user.length ? child.user[0].name : child.user_name }}
                    </strong>
                    <div
                      :datetime="new Date(child.date).getFullYear() + '-' + new Date(child.date).getMonth() + '-' + new Date(child.date).getDate()"
                      class="time"
                    >
                      {{ child.date | filterDate }}
                    </div>
                  </div>
                  <p>{{ child.text }}</p>
                  <div class="comments-footer">
                    <!-- <reactions :item="{ id: child._id, reactions: child.reactions, owner_id: child.user_id }" /> -->
                    <template v-if="isOwner(child.user_id)">
                      <span @click="editCommentFunc(child)">Редактировать</span>
                      <span @click="delComment(child)">Удалить</span>
                    </template>
                    <template v-else>
                      <!-- <span @click="setParentComment(child._id, child.user_id, child.user_name)">&#128172; Ответить</span> -->
                      <span v-if="profileOwner" @click="delProfileComment(child)">Удалить</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else>Напишите первый комментарий</p>

      <div v-if="user" class="comment-form">
        <div v-if="showSmiles" class="emojis">
          <i
            @click="showSmiles = !showSmiles"
            class="icon-close"
            title="Закрыть"
          />
          <div class="emoji-list">
            <img
              v-for="emo in emoji"
              :key="`comments-emoji-${emo}`"
              :src="`/static_files/onion/${emo}.gif`"
              @click="appendEmoji(emo)"
            />
          </div>
        </div>

        <ul class="bb-codes">
          <li title="Курсив" @click="editor('i')">I</li>
          <li title="Жирный" @click="editor('b')">B</li>
          <li title="Изображение" @click="editor('img')">IMG</li>
          <!-- <li title="Заголовок">
            <select v-model="Hn" @change="editor(Hn)">
              <option value="">Размер</option>
              <option value="h2">Заголовок 2</option>
              <option value="h3">Заголовок 3</option>
              <option value="h4">Заголовок 4</option>
            </select>
          </li> -->
          <li title="Цвет">
            <input
              type="color"
              v-model="fontColor"
              @change="editor('color', fontColor)"
              class="color"
            />
          </li>
          <!-- <li @click="showSmiles = !showSmiles" title="Эмоции">
            <i
              :class="{ active: showSmiles }"
              class="icon-message-smile"
            />
          </li> -->
        </ul>

        <div class="form-textarea">
          <div :class="`${user.gender ? `gender-${user.gender}` : ''}`" class="avatar">
            <img v-if="user.photo" :src="user.photo" alt="">
            <img v-else src="/static_files/noavatar.svg" alt="">
          </div>
          <textarea
            v-if="!showEditComment"
            v-model="commentText"
            placeholder="Ваш комментарий"
            rows="1"
            maxlength="500"
          />
          <textarea
            v-else
            v-model="editCommentText"
            placeholder="Ваш комментарий"
            rows="1"
            maxlength="500"
          />
        </div>

        <div v-if="!showEditComment" class="buttons">
          <!-- <span v-if="commentText.length" @click="prerender">Предпросмотр</span> -->
          <button @click="createComment">
            Написать
          </button>
        </div>
        <div v-else class="buttons">
          <button @click="editComment">
            Изменить
          </button>
          <span @click="editCommentCancel">Отмена</span>
        </div>

        <!-- <div style="margin: 14px 0 32px;">
          <button @click="logout" class="logout">Выйти из аккаунта</button>
        </div> -->

        <p class="hint">
          Выделите участок текста и выберите действие в редакторе.
          <ul>
            <li>I - курсивный шрифт</li>
            <li>В - жирный шрифт</li>
            <li>IMG - вставка изображения</li>
            <li>[ – ] - выделение цветом</li>
            <li>:) - вставка эмоции</li>
          </ul>
        </p>
      </div>
      <div v-else style="margin-top: 32px;">
        Чтобы оставить комментарий необходимо <strong>войти на сайт</strong>.
        <div class="checkboxes">
          <label for="termofuse">
            <input type="checkbox" id="termofuse" v-model="checkbox['termofuse']">
            <span>
              Я принимаю условия
              <nuxt-link to="/termsofuse">Пользовательского соглашения</nuxt-link>
            </span>
          </label>
          <label for="mailing">
            <input type="checkbox" id="mailing" v-model="checkbox['mailing']">
            <span>
              Я соглашаюсь получать рассылки и специальные предложения
            </span>
          </label>
        </div>
        <div class="socials">
          <strong>Используйте почту для входа</strong>
          <div class="items">
            <div @click="login('google')" class="item">
              <img src="/static_files/icons/signin/google.png" alt="google">
            </div>
            <div @click="login('mailru')" class="item">
              <img src="/static_files/icons/signin/mailru.png" alt="mailru">
            </div>
            <div @click="login('yandex')" class="item">
              <img src="/static_files/icons/signin/yandex.png" alt="yandex">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="related.length" class="comments-side">
      <div class="h3 block-comments">Последние комментарии</div>
      <div v-for="item in related" :key="`commets-related-${item._id}`" class="item">
        <div class="avatar">
          <img :src="item.user && item.user.length ? item.user[0].photo : item.user_avatar" alt="">
        </div>
        <div>
          <strong>
            {{ item.user && item.user.length ? item.user[0].name : item.user_name }}
            &#8594;
            <nuxt-link :to="item.post_link" class="title">
              {{ item.post_title }}
            </nuxt-link>
          </strong>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'

function OnInput() {
  this.style.height = 'auto'
  this.style.height = (this.scrollHeight) + 'px'
}

export default {
  components: {
    // Reactions: () => import('~/components/Reactions.vue')
  },
  props: { id: { type: String, required: true, default: '' } },
  filters: {
    filterDate(value) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  // watch: {
  //   user() {
  //     this.$nextTick(() => this.parseBB())
  //   }
  // },
  computed: {
    ...mapState(['user']),
    profileOwner() {
      return this.user
        ? this.user.id === this.$route.params.id
        : false
    }
  },
  mounted() {
    this.getComments()
    const tx = document.getElementsByTagName('textarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;')
      tx[i].addEventListener('input', OnInput, false)
    }
  },
  beforeDestroy() {
    const tx = document.getElementsByTagName('textarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;')
      tx[i].removeEventListener('input', OnInput)
    }
  },
  data() {
    const emoji = [
      '_)', '_D', '_-D', '_lol_', '_ololo_', '_evil_', '+_+', '_cool_', '_thumbup_',
      '_yahoo_', '_tea2_', '_star_', '_oh_', '_shy_', '_shy2_', '_hurray_', '_-P',
      '_roll_', '_!_', '_watching_', '_love_', '_love2_', '_bunch_', '_perveted_',
      '_(', '_very-sad_', '_depressed_', '_depressed2_', '_hopeless_', '_very-sad2_',
      '_-(', '_cry_', '_cry6_', '_Cry2_', '_Cry3_', '_Cry4_', '_-o', '_shock_',
      '_shock2_', '_scream_', '_dont-want_', '_noooo_', '_scared_', '_shocked2_',
      '_shocked3_', '_shocked4_', '_tea-shock_', '_frozen3_', '_angry4_', '_revenge_',
      '_evil2_', '_twisted_', '_angry_', '_angry3_', '_angry5_', '_angry6_', '_cold_',
      '_strange4_', '_ball_', '_evil3_', '_8)_', '_oh2_', '_ooph_', '_wink_',
      '_dunno_', '_dont-listen_', '_hypno_', '_advise_', '_bored_', '_disappointment_',
      '_hunf_', '_hot_', '_hot2_', '_hot3_', '_stress_', '_strange3_', '_strange2_',
      '_strange1_', '_Bath2_', '_strange_', '_hope_', '_hope3_', '_diplom_', '_hi_',
      '_bye_', '_sleep_', '_bow_', '_Warning_', '_Ban_', '_Im-dead_', '_sick_', '_s1_',
      '_s3_', '_s2_', '_happy_cry_', '_ill_', '_sad2_', '_bullied_', '_bdl2_',
      '_Happy-Birthday_', '_flute_', '_cry5_', '_gaze_', '_hope2_', '_sleepy_',
      '_study_', '_study2_', '_study3_', '_gamer_', '_animal_', '_caterpillar_',
      '_cold2_', '_shocked_', '_frozen_', '_frozen2_', '_kia_', '_interested_',
      '_happy_', '_happy3_', '_water_', '_dance_', '_liar_', '_prcl_', '_play_',
      '_s4_', '_s_', '_bath_', '_kiss_', '_whip_', '_relax_', '_smoker_', '_smoker2_',
      '_bdl_', '_cool2_', '_V_', '_V2_', '_V3_', '_sarcasm_', '_angry2_', '_kya_'
    ]
    return {
      emoji,
      showSmiles: false,
      fontColor: '',
      Hn: '',
      comments: [],
      related: [],
      commentText: '',
      comment: null,
      showEditComment: false,
      editCommentText: '',
      prerenderText: '',
      parentComment: null,
      gets: {},
      checkbox: {
        termofuse: false,
        mailing: false
      }
    }
  },
  methods: {
    login(social) {
      if (!this.checkbox.termofuse) {
        this.$store.commit('setError', {
          status: 'warn',
          msg: 'Примите условия пользовательского соглашения'
        })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        const elem = document.getElementById('termofuse')
        if (elem) {
          elem.style.border = `1px solid #e00a1e`
          elem.style.boxShadow = `0 0 20px 0px #e00a1e`
        }
      } else if (!this.checkbox.mailing) {
        this.$store.commit('setError', { status: 'warn', msg: 'Подпишитесь на рассылку' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        const elem = document.getElementById('mailing')
        if (elem) {
          elem.style.border = `1px solid #e00a1e`
          elem.style.boxShadow = `0 0 20px 0px #e00a1e`
        }
      } else {
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=900,height=600,left=10,top=10`
        window.open(`/api/auth/${social}`, `Sign in site - ${social}`, params)
        this.getComments()
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
    },
    reply(name) {
      this.commentText += `${name}, `
    },
    setParentComment(parentId, userId, userName) {
      if (!this.user) {
        this.$store.commit('setShowAuthForm', true)
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      this.parentComment = {
        id: parentId,
        user_id: userId
      }
      this.commentText += `${userName}, `
      const form = document.querySelector('.form-textarea')
      if (form) {
        form.scrollIntoView()
        const doc = document.documentElement
        doc.scrollTo(0, doc.scrollTop - 500)
        const textarea = document.querySelector('.comment-form textarea')
        if (textarea) textarea.focus()
      }
    },
    appendEmoji(e) {
      // console.log(e)
      if (this.showEditComment) {
        this.editCommentText += `[::${e}::] `
      } else {
        this.commentText += `[::${e}::] `
        const textarea = document.querySelector('.comment-form textarea')
        if (textarea) textarea.focus()
      }
    },
    editor(tag, val = '') {
      const textarea = document.querySelector('.comment-form textarea')
      const start = textarea.selectionStart
      const finish = textarea.selectionEnd
      const sel = textarea.value.substring(start, finish)
      this.commentText = this.commentText.replace(sel, `[::${tag}${val ? `="${val}"` : ''}::]${sel}[::/${tag}::]`)
    },
    parseBB() {
      const list = document.querySelectorAll('.comments p')
      if (list) {
        Array.from(list).forEach((el) => {
          if (el.textContent.match(/\[:.*:\]/)) {
            const str = el.textContent
              .replace(/(<a|<script|keydown|keyup|click|contextmenu|mouseover|mouseout|mousedown|mouseup|mousemove|submit|focus)/g, '')
              .replace(/\[::color="/g, `<span style="color:`)
              .replace(/"::\]/g, `">`)
              .replace(/\[::\/color::\]/g, '</span>')
              .replace(/\[::i::\]/g, '<i>')
              .replace(/\[::\/i::\]/g, '</i>')
              .replace(/\[::b::\]/g, '<b>')
              .replace(/\[::\/b::\]/g, '</b>')
              // .replace(/\[::img::\]/g, '<img src="')
              // .replace(/\[::\/img::\]/g, '">')
              .replace(/\[::/g, '<img src="/static_files/onion/')
              .replace(/::\]/g, '.gif" class="emoji" />')
            el.innerHTML = str
          }
        })
      }
    },
    prerender() {
      if (!this.commentText) {
        this.$store.commit('setError', 'Пустой комментарий')
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      this.prerenderText = this.commentText
        .replace(/\[::i::\]/g, '<i>')
        .replace(/\[::\/i::\]/g, '</i>')
        .replace(/\[::b::\]/g, '<b>')
        .replace(/\[::\/b::\]/g, '</b>')
        .replace(/\[::img::\]/g, '<img src="')
        .replace(/\[::\/img::\]/g, '">')
        .replace(/\[::color="/g, `<span style="color:`)
        .replace(/"::\]/g, `">`)
        .replace(/\[::\/color::\]/g, '</span>')
        .replace(/\[::/g, '<img src="/static_files/onion/')
        .replace(/::\]/g, '.gif" class="emoji" />')
    },
    isOwner(userId) {
      return this.user ? this.user.id === userId : false
    },
    editCommentCancel() {
      this.showEditComment = false
      this.editCommentText = ''
      this.comment = null
    },
    editCommentFunc(comment) {
      const form = document.querySelector('.form-textarea')
      if (form) {
        form.scrollIntoView()
        const doc = document.documentElement
        doc.scrollTo(0, doc.scrollTop - 500)
      }
      this.showEditComment = true
      this.editCommentText = comment.text
      this.comment = comment
    },
    async editComment() {
      if (this.user) {
        if (this.comment.text === this.editCommentText) {
          this.$store.commit('setError', 'Изменений не найдено')
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else if (!this.editCommentText.trim()) {
          this.$store.commit(
            'setError',
            'Сообщение не может быть пустым'
          )
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          const body = {
            id: this.comment._id,
            text: this.editCommentText
          }
          const { data } = await this.$axios.put(`/api/comments/comments`, body)
          if (data) {
            this.showEditComment = false
            this.editCommentText = ''
            this.comment = null
            this.$store.commit(
              'setError',
              { status: 'ok', msg: 'Комментарий успешно изменен' }
            )
            setTimeout(() => this.$store.commit('clearError'), 2000)
            this.getComments()
          } else {
            this.$store.commit(
              'setError',
              { status: 'warn', msg: 'Время на редактирование сообщения истекло (5 мин.)' }
            )
            setTimeout(() => this.$store.commit('clearError'), 3000)
          }
        }
      }
    },
    async delProfileComment(comment) {
      if (this.user) {
        this.showEditComment = false
        const { data } = await this.$axios.delete(
          `/api/comments/comments?id=${comment._id}`
        )
        // console.log(data)
        if (data.error) {
          this.$store.commit(
            'setError',
            { status: 'warn', msg: data.error.message }
          )
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.$store.commit(
            'setError',
            { status: 'ok', msg: 'Комментарий удален' }
          )
          setTimeout(() => this.$store.commit('clearError'), 1000)
          this.getComments()
        }
      }
    },
    async delComment(comment) {
      if (this.user) {
        this.showEditComment = false
        const { data } = await this.$axios.delete(
          `/api/comments/comments?id=${comment._id}&user_id=${this.user.id}`
        )
        // console.log(data)
        if (data) {
          this.$store.commit(
            'setError',
            { status: 'ok', msg: 'Комментарий успешно удален' }
          )
          setTimeout(() => this.$store.commit('clearError'), 2000)
          this.getComments()
        } else {
          this.$store.commit(
            'setError',
            { status: 'warn', msg: 'Время на удаление сообщения истекло (5 мин.)' }
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      }
    },
    async createComment() {
      if (!this.user) {
        this.$store.commit(
          'setError',
          'Для добавления комментария необходимо войти на сайт'
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (!this.user.name) {
        this.$store.commit(
          'setError',
          'Необходимо установить логин в свое профиле'
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (!this.commentText.trim()) {
        this.$store.commit(
          'setError',
          'Сообщение не может быть пустым'
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.commentText.trim().length > 500) {
        this.$store.commit(
          'setError',
          {
            status: 'info',
            msg: 'Комментарий слишком длинный, макс. 500 символов'
          }
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const body = {
          id: this.id,
          parent_id: this.parentComment ? this.parentComment.id : null,
          user_id: this.user.id,
          user_name: this.user.name,
          user_avatar: this.user.photo,
          user_group: this.user.group,
          text: this.commentText.trim()
        }
        const { data } = await this.$axios.post(`/api/comments/comments`, body)
        this.commentText = ''
        this.parentComment = null
        this.showSmiles = false
        data.user = [{
          id: this.user.id,
          name: this.user.name,
          photo: this.user.photo,
          group: this.user.group,
          artifact: this.user.artifact
        }]
        this.comments.push(data)
        this.$nextTick(() => this.parseBB())
      }
    },
    async getComments() {
      const { data } = await this.$axios.get(`/api/comments/comments?id=${this.id}`)
      // console.log('data', data)
      this.comments = data.comments
      this.related = data.related
      if (data.user) {
        this.$store.commit('setUser', data.user)
      }
      this.$nextTick(() => this.parseBB())
    }
  }
}
</script>

<style src="~/assets/comments.styl" lang="stylus" />
