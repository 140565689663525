<template>
  <div class="premium-status">
    <div v-if="user" style="margin: 10px 0px 20px;">
      <a href="/donate#donate" style="background-color:#fad200;padding:4px 12px;border-radius:20px;color:#000;">
        <strong>{{ $t('on_balance') }}</strong> {{ user.balance.toFixed(2) }} {{ $t('coins') }}
      </a>
    </div>

    <div style="margin: 10px 0px 24px;">
      <div class="h2">{{ $t('premium_h2') }}</div>
      <p>{{ $t('premium_p') }}</p>
    </div>

    <div style="overflow:auto">
      <table border-collapse="collapse">
        <caption style="padding:10px 20px;font-size:2.2rem;color:#fff;background-color:#7e7990;">
          {{ $t('premium_tariffs') }}
        </caption>
        <tr>
          <td></td>
          <td style="font-size:2rem;color:#000;background-color:#ddd;">
            {{ $t('premium_without_sub') }}
          </td>
          <td style="position:relative;font-size:2rem;color:#000;background-color:#fad200;">
            <strong>
              Premium
              <!-- <sup style="position:absolute;top:-8px;margin-left:2px;color:red;font-size:4rem;">+</sup> -->
            </strong>
          </td>
        </tr>
        <tr>
          <td style="font-weight:bold;color:#fad200;">
            {{ $t('premium_tariffs_faster_website') }}
          </td>
          <td><div class="minus"/></td>
          <td><div class="plus"/></td>
        </tr>
        <!-- <tr>
          <td style="font-weight:bold;">
            реклама перед началом просмотра
          </td>
          <td><div class="plus"/></td>
          <td><div class="minus"/></td>
        </tr> -->
        <tr>
          <td style="font-weight:bold;">
            {{ $t('premium_tariffs_player_ads') }}
          </td>
          <td><div class="plus"/></td>
          <td><div class="minus"/></td>
        </tr>
        <tr>
          <td style="font-weight:bold;">
            {{ $t('premium_tariffs_flyfoll') }}
          </td>
          <td><div class="plus"/></td>
          <td><div class="minus"/></td>
        </tr>
        <!-- <tr>
          <td style="font-weight:bold;">
            совместный просмотр без рекламы в плеере
          </td>
          <td><div class="minus"/></td>
          <td><div class="plus"/></td>
        </tr> -->
        <tr>
          <td style="font-weight:bold;">
            {{ $t('premium_tariffs_banners') }}
          </td>
          <td><div class="plus"/></td>
          <td><div class="minus"/></td>
        </tr>
        <tr>
          <td style="font-weight:bold;">
            {{ $t('premium_tariffs_stickers') }}
            <sup style="cursor:pointer;" :title="$t('premium_tariffs_stickers_p')">?</sup>
          </td>
          <td><div class="minus"/></td>
          <td><div class="plus"/></td>
        </tr>
        <tr>
          <td style="font-weight:bold;">
            {{ $t('premium_tariffs_video_quality') }}
          </td>
          <td style="color:rgba(255, 255, 255, 0.25);font-size:3rem;">HD 720р</td>
          <td style="color:#9aae6b;font-size:3rem;">4k 2160p</td>
          <!-- <td style="color:#9aae6b;font-size:3rem;">FullHD 1080р</td> -->
        </tr>
      </table>
    </div>

    <div style="margin: 20px 0px 20px;">
      <strong>{{ $t('premium_sub_1') }}</strong>
      <p>{{ $t('premium_sub_2') }}</p>
    </div>

    <div class="tariffs-wrapper">
      <ul id="tariffs" class="tariffs">
        <!-- <li :style="`background-image: url(/static_files/bill/premium_1.jpg)`"> -->
        <li>
          <div class="header-premium" style="position:relative;">
            Start
            <span>{{ $t('premium_sub') }}</span>
          </div>

          <div class="body-premium">
            <span>+30 {{ $t('premium_tariffs_views') }}<sub>{{ $t('premium_tariffs_with_out_ads') }}</sub></span>
            <div class="sitecoin">
              <i />
              <span>50 {{ $t('coins') }}</span>
            </div>
          </div>

          <div class="footer-premium">
            <button @click="premiumPeriod = 50" class="btn">
              {{ $t('active') }}
            </button>
            <p>
              {{ $t('premium_on_1month') }}
            </p>
          </div>
        </li>
        <li>
          <div class="header-premium" style="position:relative;">
            Popular
              <img src="/static_files/icons/popular.png" alt=""
                style="position:absolute;top:-10px;right:-10px;width:44px;height:44px;">
            <span>{{ $t('premium_sub') }}</span>
          </div>

          <div class="body-premium">
            <span>+90 {{ $t('premium_tariffs_views') }}<sub>{{ $t('premium_tariffs_with_out_ads') }}</sub></span>
            <div class="sitecoin">
              <i />
              <span>100 {{ $t('coins') }}</span>
            </div>
          </div>

          <div class="footer-premium">
            <button @click="premiumPeriod = 100" class="btn">
              {{ $t('active') }}
            </button>
            <p>
              {{ $t('premium_on_1month') }}
            </p>
          </div>
        </li>
        <li>
          <div class="header-premium" style="position:relative;">
            Pro
            <span>{{ $t('premium_sub') }}</span>
          </div>

          <div class="body-premium">
            <span>+210 {{ $t('premium_tariffs_views') }}<sub>{{ $t('premium_tariffs_with_out_ads') }}</sub></span>
            <div class="sitecoin">
              <i />
              <span>200 {{ $t('coins') }}</span>
            </div>
          </div>

          <div class="footer-premium">
            <button @click="premiumPeriod = 200" class="btn">
              {{ $t('active') }}
            </button>
            <p>
              {{ $t('premium_on_1month') }}
            </p>
          </div>
        </li>
      </ul>
      <ul class="tariffs king">
        <!-- <li :style="`background-image: url(/static_files/bill/premium_king.jpg)`"> -->
        <li>
          <div class="header-premium" style="position:relative;">
            Priority
              <img src="/static_files/icons/premium-quality.svg" alt=""
                style="position:absolute;top:-10px;right:-10px;width:44px;height:44px;">
            <span>{{ $t('premium_sub') }}</span>
          </div>

          <div class="body-premium">
            <span>+1000 {{ $t('premium_tariffs_views') }}<sub>{{ $t('premium_tariffs_with_out_ads') }}</sub></span>
            <sup>
              {{ $t('premium_tariffs_share') }}
            </sup>
            <div class="sitecoin">
              <i />
              <span>500 {{ $t('coins') }}</span>
              <div>
                <s style="font-size:1.2rem;opacity:0.5;">600 {{ $t('coins') }}</s>
              </div>
            </div>
          </div>

          <div class="footer-premium">
            <button @click="premiumPeriod = 500" class="btn">
              {{ $t('active') }}
            </button>
            <p style="color:#10c44c;font-size:1.4rem;">
              {{ $t('premium_on_1year') }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div style="margin: 20px 0px 20px;">
      <strong>{{ $t('premium_views_runout_strong') }}</strong>
      <p>{{ $t('premium_views_runout_p') }}</p>
    </div>

    <ul id="views" class="views">
      <li @click="viewsVal = '5'">
        <strong>+5 {{ $t('premium_tariffs_views') }}</strong>
        <span>
          <img src="/static_files/icons/coin-donate.svg" alt="">
          10 {{ $t('coins') }}
        </span>
      </li>      
      <li @click="viewsVal = '50'">
        <strong>+50 {{ $t('premium_tariffs_views') }}</strong>
        <span>
          <img src="/static_files/icons/coin-donate.svg" alt="">
          50 {{ $t('coins') }}
        </span>
      </li>      
      <li @click="viewsVal = '150'">
        <strong>+150 {{ $t('premium_tariffs_views') }}</strong>
        <span>
          <img src="/static_files/icons/coin-donate.svg" alt="">
          100 {{ $t('coins') }}
        </span>
      </li>      
      <li @click="viewsVal = '1000'">
        <strong>
          Беззаботный 1000
          <sup onclick="document.querySelector('.star').scrollIntoView()">*</sup>
        </strong>
        <span>
          <img src="/static_files/icons/coin-donate.svg" alt="">
          350 {{ $t('coins') }}
        </span>
        <s style="font-size:1.2rem;opacity:0.5;">400 {{ $t('coins') }}</s>
      </li>      
    </ul>

    <p class="start" style="margin-bottom:40px;font-size:1.4rem;opacity:0.65;">
      <b style="color:red;">*</b> {{ $t('premium_tariffs_1000') }}
      <!--Цена снижена в рамках акции. Срок действия акции ограничен. -->
    </p>

    <div v-if="viewsVal" class="modal">
      <div class="h3">
        {{ $t('premium_views_active') }}
      </div>

      <div>
        <p>
          +{{viewsVal}} {{ $t('premium_views_p') }}
        </p>
        <div style="margin-top:10px;">
          <button @click="getViews()" class="dbtn green">
            {{ $t('active') }}
          </button>
        </div>
        <div style="margin-top:10px;font-size:1.4rem;cursor:pointer;">
          <span @click="viewsVal = null">{{ $t('cancel') }}</span>
        </div>
      </div>
    </div>

    <div v-if="premiumPeriod" class="modal">
      <div class="h3">
        {{ $t('premium_active') }}
      </div>
      <i class="premium-icon" />
      <div>
        <p>
          {{ $t('premium_active_p') }}
        </p>
        <div v-if="!showFriend" style="margin-top:10px;">
          <button @click="buyPremium()" class="dbtn green">
            {{ $t('premium_active_self') }}
          </button>
          <button @click="showFriend = !showFriend" class="dbtn green">
            {{ $t('premium_active_friend') }}
          </button>
        </div>
        <div v-if="showFriend" style="margin-top:10px;">
          <input type="text" v-model="friendID" placeholder="ID">
          <button @click="buyPremium()" class="dbtn green">
            {{ $t('ok') }}
          </button>
        </div>
        <div style="margin-top:10px;font-size:1.4rem;cursor:pointer;">
          <span @click="premiumPeriod = null">{{ $t('cancel') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  filters: {
    filterDate(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const month = new Date(val).getMonth()
      const day = new Date(val).getDate()
      const hour = new Date(val).getHours() < 10 ? `0${new Date(val).getHours()}` : new Date(val).getHours()
      const min = new Date(val).getMinutes() < 10 ? `0${new Date(val).getMinutes()}` : new Date(val).getMinutes()
      return `${day} ${months[month]} ${hour}:${min}`
    }
  },
  computed: mapState(['user', 'isDesktop']),
  watch: {
    premiumPeriod(val) {
      if (!val) {
        this.showFriend = false
        this.friendID = null
      }
    }
  },
  data() {
    return {
      viewsVal: null,
      premiumPeriod: null,
      showModal: false,
      userFriends: [],
      gets: {},
      showFriend: false,
      friendID: null
    }
  },
  mounted() {
    if (!this.isDesktop) {
      const elem = document.querySelector('.premium-status .tariffs-wrapper')
      if (elem) {
        elem.scroll(235, 0)
      }
    }
  },
  methods: {
    async getViews() {
      if (!this.user || !this.viewsVal) return
      const { data } = await this.$axios.post(`/api/user/views`, { count: +this.viewsVal })
      console.log('data', data)
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: 'Просмотры добавлены' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
        const user = this.user
        user.views += +this.viewsVal
        user.balance -= +data.price
        this.$store.commit('setUser', user)
      }
      this.viewsVal = null
    },
    async getFriends() {
      const { data } = await this.$axios.get(`/api/user/friends`)
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.userFriends = data
      }
    },
    async buyPremium() {
      if (this.gets.buyPremium) return

      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        this.$store.commit('setShowAuthForm', true)
        return
      }

      // console.log('this.friendID.match(/[0-9a-z]{24}/)', this.friendID.match(/[0-9a-z]{24}/))

      if (this.friendID && !this.friendID.toLowerCase().trim().match(/[0-9a-z]{24}/)) {
        this.$store.commit('setError', { status: 'info', msg: 'Неверный ID' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      const friendID = this.friendID ? this.friendID.toLowerCase().trim() : 'self'

      const body = {
        period: this.premiumPeriod,
        user: friendID
      }

      this.gets.buyPremium = true

      const { data } = await this.$axios.post(`/api/user/premium`, body)

      delete this.gets.buyPremium

      this.showModal = false
      this.friendID = null

      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        // let price = null

        // if (+this.premiumPeriod === 1) {
        //   price = 1
        // } else if (+this.premiumPeriod === 30) {
        //   price = 30
        // } else if (+this.premiumPeriod === 50) {
        //   price = 50
        // }

        // this.userData.balance -= price

        // const user = this.user
        // user.group = data.group
        // this.$store.commit('setUser', user)
        this.$store.commit('setError', { status: 'ok', msg: 'Подписка подключена' })
        setTimeout(() => {
          this.$store.commit('clearError')
          location.reload()
        }, 2000)
      }
    }
  }
}
</script>

<style lang="stylus">
.premium-status
  padding: 0px
  .friends-list
    margin-top: 10px
    max-height: 100px
    overflow-y: auto
    .item
      display: grid
      grid-template-columns: 32px auto
      grid-gap: 10px
      align-items: center
      cursor: pointer
      .poster
        width: 32px
        height: 32px
        border-radius: 50%
        overflow: hidden
        img
          width: 100%
          min-height: 100%
          object-fit: cover
      strong
        font-size: 1.4rem
  .modal
    display grid
    align-items center
    justify-items center
    text-align: center
    position fixed
    top 50%
    left 50%
    margin -200px 0 0 -200px
    width 400px
    height 400px
    padding 20px
    color: #f2f2f2
    background-color #312b45
    border-radius 20px
    box-shadow 0 0 20px 0 rgba(0, 0, 0, 0.5)
    z-index 5
    &.md
      margin -250px 0 0 -250px
      width 500px
      height 500px
      img
        width 200px
        height 200px
    h3
      color #fff
    .premium-icon
      display inline-block
      width 100px
      height 100px
      background-image url('/static_files/icons/premium-quality.svg')
      background-size 100px
    .actions
      margin-top: 10px
      button
        background-color #fff
        color #000
        sup
          background-color #fd8087
          color #fff
          padding 2px 10px
          border-radius 20px
      span
        margin-left 10px
        font-size 1.4rem
        color #000
        opacity .75
        cursor pointer
        transition opacity .2s ease
        &:hover
          opacity 1
  table
    margin-bottom 40px
    width 100%
    tr
      td
        padding 10px 20px
        border none
        text-align center
        .plus
          // color: #9aae6b
          // font-size: 3rem
          display: inline-block
          width: 32px
          height: 32px
          background-image: url('/static_files/icons/check-green.png')
          background-repeat: no-repeat
          background-size: 24px
        .minus
          // color: rgba(255, 255, 255, 0.25)
          // font-size: 3rem
          display: inline-block
          width: 32px
          height: 32px
          background-image: url('/static_files/icons/close-dark.svg')
          background-repeat: no-repeat
          background-size: 24px
          opacity: 0.3
  ul.views
    margin: 10px auto 10px
    display: grid
    grid-template-columns repeat(4, 1fr)
    grid-gap: 20px
    li
      padding: 10px 20px
      border-radius: 10px
      border: 2px solid rgba(255, 255, 255, 0.35)
      transition: border 0.2s ease
      cursor: pointer
      &:hover
        border: 2px solid #fad200
      strong
        margin-bottom: 4px
        display: block
        font-size: 3rem
      span
        display: grid
        grid-template-columns: 22px auto
        grid-gap: 4px
        align-items: center
        font-size: 1.4rem
        opacity: 0.9
        img
          display: inline-block
          width: 20px
          height: 20px
        
  ul.tariffs
    margin: 60px auto 60px
    display: grid
    grid-template-columns repeat(3, 280px)
    grid-gap: 80px
    justify-content: center
    list-style: none
    &.king
      grid-template-columns: auto
      li
        margin-top: 40px
        width: 360px
        height: 440px
        opacity: 1
        border-color: #10c44c
        .header-premium
          background-color: rgba(0, 0, 0, 0.65) // rgba(164, 42, 2, 0.75) // #a42a02
        &:hover
          border-color: lime
    li
      position relative
      height 400px
      color #fff
      border-radius 0 0 20px 20px
      background-size cover
      box-shadow 0 0 20px 1px rgba(0 0 0 0.35)
      text-align center
      opacity: 0.65
      border: 2px solid rgba(255, 255, 255, 0.15)
      cursor default
      transition all .2s ease-out
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background: #1e1a2a // linear-gradient(0deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%)
        border-radius 20px
      &:hover
        box-shadow 0 10px 20px 1px rgba(0 0 0 0.35)
        transform translateY(-5px)
        opacity 1
      &:hover > .footer-premium .btn
        background-color #fad200
        color #000
      .header-premium
        position relative
        top 0
        padding 5px 10px 10px
        width 100%
        font-size 3rem
        font-weight bold
        color #fff
        background-color rgba(0, 0, 0, 0.65)
        border-radius 0px 0px 100% 100%
        text-transform uppercase
        span
          display block
          font-weight normal
          font-size 1.4rem
          opacity .9
      div
        width 100%
        text-align center
        z-index 1
        &.body-premium
          position absolute
          top calc(50% - 30px)
          span
            font-size 2.4rem
            text-transform uppercase
            sub
              display: block
              font-size: 1rem
          .sitecoin
            display block
            margin-top 20px
            span
              font-size 1.6rem
              text-transform capitalize
            i
              display: inline-block
              width: 24px
              height: 24px
              background-image: url('/static_files/icons/coin-donate.svg')
              background-size: 24px
              vertical-align: middle
        &.footer-premium
          position absolute
          bottom 20px
          p
            font-size: 1.2rem
            opacity: 0.75
          .btn
            display inline-block
            position relative
            padding 10px 20px
            font-family 'Raleway', sans-serif
            font-size 1rem
            color #fff
            background-color #312b45
            border none
            border-radius 30px
            cursor pointer
            overflow hidden
            transition all .2s ease
            z-index 1
            &:hover:before
              webkit-transform: skewX(-45deg) translateX(13.5em);
              transform: skewX(-45deg) translateX(22.5em);
              -webkit-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
            &:before
              content: "";
              background-color: rgba(255, 255, 255, 0.15);
              height: 100%;
              width: 3em;
              display: block;
              position: absolute;
              top: 0;
              left: -4.5em;
              -webkit-transform: skewX(-45deg) translateX(0);
              transform: skewX(-45deg) translateX(0);
              -webkit-transition: none;
              transition: none;
    li:nth-child(2)
      transform: scale(1.15)
      opacity 1
      &:after
        background: #1e1a2a // linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)
      .header-premium
        background-color: #7e7991 // rgba(250, 210, 0, 0.85) // #fad200
        // color #000
</style>
