<template>
  <div class="cln" :class="`${
      !$device.isDesktop ? 'mobile' : ''
    }`">
    <nuxt class="content-scroll" />
    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Error: () => import('~/components/Error.vue')
  },
  computed: mapState(['error']),
  mounted() {
    const scripts = document.querySelectorAll(`script`)
    scripts.forEach(el => {
      if (el.src.includes(`vak345.com`)) {
        document.body.removeChild(el)
      }
    })

    const blocks = document.querySelectorAll('div')
    blocks.forEach(el => {
      if (el.style.zIndex === '2147483647') {
        console.log(el)
        document.body.removeChild(el)
      }
    })
  }
}
</script>

<style lang="stylus">
.cln
  // padding: 10px
  background-color: #100e19
</style>
