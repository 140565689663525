<template>
  <div class="content articles">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link to="/" itemprop="item">
          <span itemprop="name">Главная</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>
    </div>

    <h1>Статьи</h1>

    <div class="articles-list mix">
      <div v-if="articles[0]" class="article full">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="articles[0].poster" :alt="articles[0].title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ articles[0].date | filterDate }}</time>
          <strong class="h1">{{ articles[0].title }}</strong>
        </div>
        <nuxt-link :to="`/articles/${articles[0].pretty}`">{{ articles[0].title }}</nuxt-link>
      </div>
      <div v-if="articles[1]" class="article short">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="articles[1].poster" :alt="articles[1].title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ articles[1].date | filterDate }}</time>
          <strong class="h1">{{ articles[1].title }}</strong>
        </div>
        <nuxt-link :to="`/articles/${articles[1].pretty}`">{{ articles[1].title }}</nuxt-link>
      </div>
      <div v-if="articles[2]" class="article short">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="articles[2].poster" :alt="articles[2].title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ articles[2].date | filterDate }}</time>
          <strong class="h1">{{ articles[2].title }}</strong>
        </div>
        <nuxt-link :to="`/articles/${articles[2].pretty}`">{{ articles[2].title }}</nuxt-link>
      </div>
    </div>
    <div class="articles-list single-full">
      <div v-for="item in articles.slice(3, 6)" :key="`article-${item._id}`" class="article full">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="item.poster" :alt="item.title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ item.date | filterDate }}</time>
          <strong class="h1">{{ item.title }}</strong>
        </div>
        <nuxt-link :to="`/articles/${item.pretty}`">{{ item.title }}</nuxt-link>
      </div>
    </div>
    <div class="articles-list single-short">
      <div v-for="item in articles.slice(6)" :key="`article-${item._id}`" class="article short">
        <div class="header">
          <img src="/static_files/no-poster.png" :data-src="item.poster" :alt="item.title" decoding="async" class="lazyload">
        </div>
        <div class="body">
          <time>{{ item.date | filterDate }}</time>
          <strong class="h1">{{ item.title }}</strong>
        </div>
        <nuxt-link :to="`/articles/${item.pretty}`">{{ item.title }}</nuxt-link>
      </div>
    </div>
    <div v-if="!lastPage && articles.length >= 10" class="show-more">
      <button @click="showMore(++page)">
        Больше материалов
      </button>
    </div>
  </div>
</template>

<script>
export default {
  head() {
    const fullPath = `https://unotalone.su/articles`
    return {
      title: `Статьи по теме кино и о всем том, что с ним связано${+this.page > 1 ? `. Страница ${this.page}` : ''}`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `кино, фильмы, сериалы, подборки`
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Статьи по теме кино и о всем том, что с ним связано${+this.page > 1 ? `. Страница ${this.page}` : ''}`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: ''
        },
        {
          hid: 'description',
          name: 'description',
          content: `Каталог статей о новинках кино, популярных сериалах, подборки и другие кинотемы.${+this.page > 1 ? ` Страница ${this.page}` : ''}`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Каталог статей о новинках кино, популярных сериалах, подборки и другие кинотемы.${+this.page > 1 ? ` Страница ${this.page}` : ''}`
        }
      ]
    }
  },
  filters: {
    filterDate(value) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  async asyncData({ $axios, query, redirect, error }) {
    if (Object.keys(query).length) {
      return redirect(`/`)
    }
    const { data } = await $axios.get(`/api/articles`)
    if (data.error) {
      error(data.error)
    } else {
      return {
        articles: data,
        page: 1,
        gets: {},
        lastPage: false
      }
    }
  },
  mounted() {
    // this.lazyLoadImgs()
  },
  methods: {
    async showMore(page) {
      if (this.gets.showMore) return
      this.gets.showMore = true
      const { data } = await this.$axios.get(`/api/articles?page=${page}`)
      if (!data.error) {
        const arr = this.articles.concat(data)
        this.articles = arr
      }
      if (data.length < 10) this.lastPage = true
      delete this.gets.showMore
      // this.$nextTick(() => this.lazyLoadImgs())
    }
  }
}
</script>

<style src="~/assets/articles.styl" lang="stylus">
