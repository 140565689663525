<template>
  <main v-if="access" class="cp donate">
    <cp-menu />
    <h1>Donate</h1>
    <section>
      <div class="form">
        <label for="user-id">
          <strong>user-id</strong>
          <input id="user-id" type="text" v-model="userId">
          <!-- <button v-if="userId" @click="getUserInfo">Get user info</button> -->
        </label>
      </div>
      <div v-if="userInfo" style="margin-top:20px;" class="user-info">
        <div>
          <label for="amount">
            <strong>Amount</strong>
            <input id="amount" type="number" v-model="amount">
          </label>
          <button v-if="amount" @click="setBalance('+')">Plus</button>
          <button v-if="amount" @click="setBalance('-')">Minus</button>
        </div>
        <div>
          <img :src="userInfo.photo" style="width:100px;">
        </div>
        <div>
          <strong>id</strong>
          <a :href="`https://unotalone.su/users/${userInfo._id}`" target="_blank">
            {{ userInfo._id }}
          </a>
        </div>
        <div>
          <strong>name</strong>
          <span>{{ userInfo.name }}</span>
          <input id="new-name" type="text" v-model="newName">
          <button v-if="newName.length" @click="setNewName">Set</button>
        </div>
        <div>
          <strong>email</strong>
          <span>{{ userInfo.email }}</span>
        </div>
        <div>
          <strong>group</strong>
          <span>{{ userInfo.group }}</span>
        </div>
        <div>
          <strong>balance</strong>
          <span>{{ userInfo.balance }}</span>
        </div>
        <div>
          <strong>wallet</strong>
          <span>{{ userInfo.wallet }}</span>
          <button @click="getWalletHistory">Get</button>
        </div>
        <div v-if="walletHistory.length">
          <div v-for="item in walletHistory" :key="`wallet-history-${item._id}`" :title="new Date(item.date)">
            {{ item }}
          </div>
        </div>
        <div>
          <strong>change group</strong>
          <span>
            <input type="date" v-model="premiumDate">
            <input type="text" v-model="userGroup">
            <button @click="changeGroup">Set</button>
          </span>
        </div>
        <div>
          <strong>del wallet</strong>
          <span>
            <button @click="delWallet">Del</button>
          </span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  watch: {
    userId(val) {
      if (val) this.getUserInfo()
    },
    userInfo(val) {
      this.userGroup = val.group
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      amount: 0,
      userId: null,
      userInfo: null,
      userGroup: 1,
      premiumDate: '',
      walletHistory: [],
      newName: '',
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async setNewName() {
      await this.$axios.post(`/api/admin/newname?key=${this.key}`, { user_id: this.userId, newname: this.newName })
      this.getUserInfo()
    },
    async getWalletHistory() {
      const { data } = await this.$axios.get(`/api/admin/wallet?key=${this.key}&user_id=${this.userId}`)
      
      this.walletHistory = data
    },
    async delWallet() {
      const { data } = await this.$axios.delete(`/api/admin/wallet?key=${this.key}&user_id=${this.userId}`)
      
      this.getUserInfo()

      this.$store.commit('setError', { status: 'info', msg: data })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async changeGroup() {
      const url = `/api/admin/change-user-group?key=${this.key}`

      const body = {
        user_id: this.userId,
        group: this.userGroup,
        premium_date: new Date(this.premiumDate).getTime()
      }

      const { data } = await this.$axios.post(url, body)
      
      this.getUserInfo()

      this.$store.commit('setError', { status: 'info', msg: data.result })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async getUserInfo() {
      const { data } = await this.$axios.get(`/api/admin/get-user-info?key=${this.key}&id=${this.userId}`)
      if (data) {
        this.userInfo = data
      } else {
        this.$store.commit('setError', { status: 'info', msg: 'Пользователь не найден' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    async setBalance(op) {
      if (+this.amount < 1) {
        this.$store.commit('setError', { status: 'info', msg: 'Неверная сумма' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
        return
      } else if (!this.userId) {
        this.$store.commit('setError', { status: 'info', msg: 'Не заполнен ID' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
        return
      } else if (op === '-' && this.userInfo.balance < +this.amount) {
        this.$store.commit('setError', { status: 'info', msg: 'Сумма больше баланса' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
        return
      }

      const body = {
        amount: +this.amount,
        user_id: this.userId,
        op
      }
      const { data } = await this.$axios.post(`/api/admin/coin?key=${this.key}`, body)
      
      this.getUserInfo()

      this.amount = 0

      this.$store.commit('setError', { status: 'info', msg: data.result })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    }
  }
}
</script>

<style lang="stylus">
</style>
