<template>
  <div :class="type" class="search-hints">
    <input
      ref="ssearch"
      v-model="searchQuery"
      :class="{ active: searchQuery }"
      @input="trottled"
      @focus="focused = true"
      @blur="focused = false"
      type="search"
      name="ssearch"
      autocomplete="off"
      :placeholder="type === 'db' ? $t('search_kino') : $t('search_what_watch')"
    >
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>{{ $t('notfound') }}</span>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="(item, i) in searchHints"
          :to="`${
            type === 'db'
              ? `/catalog/${postUrl(item.id, item.title_ru)}`
              : `/vmeste?id=${item.id}`
          }`"
          :key="`sh-${i}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" :alt="['ru', 'uk', 'be'].includes(lang) ? item.title_ru : item.title_en || item.title_ru">
          </div>
          <p>
            <strong>{{ ['ru', 'uk', 'be'].includes(lang) ? item.title_ru : item.title_en || item.title_ru }}</strong>
            <span v-if="item.year">{{ item.year }}</span>
            <span v-if="item.genres"> {{ item.genres.slice(0, 3).join(', ') }}</span>
          </p>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: { type: { type: String, require: true, default: 'films' } },
  watch: {
    searchQuery(val) {
      if (val.match(/vmeste\/.*/)) {
        location.href = val
      }
    },
    changeRoute() {
      // clearTimeout(this.shTimeout)
      // this.shTimeout = setTimeout(() => {
      //   this.searchQuery = ''
      //   this.searchHints = []
      // }, 5000)

      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    changeRoute() {
      return this.$route.fullPath
    },
    lang () {
      return this.$i18n.locale
    },
  },
  data() {
    return {
      searchQuery: '',
      searchHints: [],
      shTimeout: null,
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`unotalone:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    },
  }
}
</script>
