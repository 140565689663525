<template>
  <main>
    <section>
      <h1>Ваш код пары</h1>
      <div style="margin: 10px 0px;">
        <input type="text" v-model="syncCode" readonly>
      </div>
      <p>
        Введите его на Smart TV.
      </p>
    </section>
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  layout: 'clean',
  head() {
    const fullPath = `https://unotalone.su/sync`

    return {
      title: 'Синхронизация',
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Синхронизация'
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Синхронизация'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'Синхронизация'
        }
      ]
    }
  },
  async asyncData({ $axios }) {
    const { data } = await $axios.post(`/api/user/sync`)
    return {
      syncCode: data.error ? 'Войдите на сайт' : data
    }
  }
}
</script>
