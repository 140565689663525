<template>
  <div class="section">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link :to="localePath('/')" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_home') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>

      <!-- <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="`/catalog?type=${type}`" itemprop="item">
          <span itemprop="name">{{ type }}</span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div> -->

      <div v-if="film.material_data.genres && film.material_data.genres.length" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog?page=1&genre=${
         film.material_data.genres[0]
        }`)" itemprop="item">
          <span itemprop="name">
            {{ lang === 'en' ? translateGenre([film.material_data.genres[0]]) : film.material_data.genres[0] }}
          </span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog/${postUrl(film.kinopoisk_id, film.title_ru)}`)" itemprop="item">
          <span itemprop="name">{{ ['ru', 'uk', 'be'].includes(lang) ? film.title_ru : film.title_en || film.title_ru }}</span>
        </nuxt-link>
        <meta itemprop="position" content="3">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <span itemprop="name">{{ section.h }}</span>
        <meta itemprop="position" content="4">
      </div>
    </div>

    <div class="catmenu">
      <ul :style="`width:${200 * 6}px`">
        <li :class="{ active: currentMenuItem === 'description' }">
          <nuxt-link :to="localePath(`/catalog/${postUrl(film.kinopoisk_id, film.title_ru)}`)">{{ $t('story') }}</nuxt-link>
        </li>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/frames`)" :class="{ active: currentMenuItem === 'frames' }">
          {{ $t('frames') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/trailers`)" :class="{ active: currentMenuItem === 'trailers' }">
          {{ $t('trailers') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/sap`)" :class="{ active: currentMenuItem === 'sap' }">
          {{ $t('sap') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/facts`)" :class="{ active: currentMenuItem === 'facts' }">
          {{ $t('facts') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/reviews`)" :class="{ active: currentMenuItem === 'reviews' }">
          {{ $t('reviews') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="localePath(`/catalog/${film.kinopoisk_id}/related`)" :class="{ active: currentMenuItem === 'related' }">
          {{ $t('recommends') }}
        </nuxt-link>
        <!-- <li @click="goTo('frames')" :class="{ active: currentMenuItem === 'frames' }">
          {{ $t('frames') }}
        </li>
        <li @click="goTo('trailers')" :class="{ active: currentMenuItem === 'trailers' }">
          {{ $t('trailers') }}
        </li>
        <li @click="goTo('sap')" :class="{ active: currentMenuItem === 'sap' }">
          {{ $t('sap') }}
        </li>
        <li @click="goTo('facts')" :class="{ active: currentMenuItem === 'facts' }">
          {{ $t('facts') }}
        </li>
        <li @click="goTo('reviews')" :class="{ active: currentMenuItem === 'reviews' }">
          {{ $t('reviews') }}
        </li>
        <li @click="goTo('related')" :class="{ active: currentMenuItem === 'related' }">
          {{ $t('recommends') }}
        </li> -->
      </ul>
    </div>

    <h1>
      {{ section.h }}. {{ ['ru', 'uk', 'be'].includes(lang) ? film.title_ru : film.title_en || film.title_ru }}
    </h1>

    <div class="block">
      <FilmSap v-if="section.name === 'sap'" :kpid="film.kinopoisk_id" />
      <FilmReviews v-else-if="section.name === 'reviews'" :kpid="film.kinopoisk_id" />
      <FilmFacts v-else-if="section.name === 'facts'" :kpid="film.kinopoisk_id" />
      <FilmFrames v-else-if="section.name === 'frames'" :kpid="film.kinopoisk_id" />
      <FilmTrailers v-else-if="section.name === 'trailers'" :text="film.title_ru" />
      <FilmRelated v-else-if="section.name === 'related' && relatedGenre" :payload="{ kpid: film.kinopoisk_id, genre: relatedGenre }" />
      <!-- <Merch v-else-if="section.name === 'merch'" :title="film.title_ru" /> -->
    </div>
  </div>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  components: {
    // Merch: () => import('~/components/Merch.vue'),
    FilmSap: () => import('~/components/FilmSap.vue'),
    FilmReviews: () => import('~/components/FilmReviews.vue'),
    FilmFacts: () => import('~/components/FilmFacts.vue'),
    FilmFrames: () => import('~/components/FilmFrames.vue'),
    FilmTrailers: () => import('~/components/FilmTrailers.vue'),
    FilmRelated: () => import('~/components/FilmRelated.vue')
  },
  head() {
    const fullPath = `https://unotalone.su/catalog/${this.film.kinopoisk_id}/${this.section.name}`
    const title = this.$i18n.locale === 'en'
      ? this.film.title_en ? this.film.title_en : this.film.title_ru
      : `${this.film.title_ru}${
      this.film.title_en ? ` / ${this.film.title_en}` : ''
    }`
    return {
      title: `${this.section.title}. ${title} ${
        this.film.material_data.year ? `(${this.film.material_data.year})` : ''
      } ${
        this.film.material_data.countries && this.film.material_data.countries.length
          ? this.film.material_data.countries[0]
          : ''
      } #${this.film.kinopoisk_id}`,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: fullPath
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${this.section.title} ${this.film.title_ru} ${
            this.film.material_data.year ? this.film.material_data.year : ''
          }`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${this.section.title}. ${title} ${
            this.film.material_data.year ? `(${this.film.material_data.year})` : ''
          } ${
            this.film.material_data.countries && this.film.material_data.countries.length
              ? this.film.material_data.countries[0]
              : ''
          } #${this.film.kinopoisk_id}`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${this.section.title}. ${title} ${
            this.film.material_data.year ? `(${this.film.material_data.year})` : ''
          }: ${this.section.title}`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `${this.section.title}. ${this.section.title} ${title} ${
            this.film.material_data.year ? `(${this.film.material_data.year})` : ''
          }`
        }
      ]
    }
  },
  computed: {
    relatedGenre() {
      const genre =
        this.film.material_data && this.film.material_data.genres && this.film.material_data.genres.length
          ? Math.floor(Math.random() * this.film.material_data.genres.length)
          : ''
      return genre
    },
    lang() {
      return this.$i18n.locale
    },
  },
  async asyncData({ params, $axios, error, i18n }) {
    if (!params.section) {
      error({ message: 'Incorrect section' })
    }

    const { data } = await $axios.get(`/api/film?id=${params.id}`)

    const typeName = 'Кино'
    const nameEn = 'films'
    
    // if (data.type === 'anime') {
    //   typeName = 'Аниме'
    //   nameEn = 'anime'
    // } else if (data.type === 'anime-serial') {
    //   typeName = 'Многосерийное аниме'
    //   nameEn = 'anime-serial'
    // }

    let section = ''

    if (params.section === 'facts') {
      section = {
        h: i18n.t('facts'),
        name: params.section,
        title: i18n.t('facts')
      }
    } else if (params.section === 'frames') {
      section = {
        h: i18n.t('frames'),
        name: params.section,
        title: i18n.t('frames')
      }
    } else if (params.section === 'merch') {
      section = {
        h: 'Мерчи',
        name: params.section,
        title: 'мерчи'
      }
    } else if (params.section === 'related') {
      section = {
        h: i18n.t('recommends'),
        name: params.section,
        title: i18n.t('recommends')
      }
    } else if (params.section === 'reviews') {
      section = {
        h: i18n.t('reviews'),
        name: params.section,
        title: i18n.t('reviews')
      }
    } else if (params.section === 'sap') {
      section = {
        h: i18n.t('sap'),
        name: params.section,
        title: i18n.t('sap')
      }
    } else if (params.section === 'trailers') {
      section = {
        h: i18n.t('trailers'),
        name: params.section,
        title: i18n.t('trailers')
      }
    }

    return {
      section,
      type: {
        id: data.type,
        name: typeName,
        en: nameEn
      },
      film: data,
      currentMenuItem: section.name
    }
  },
  methods: {
    goTo(section) {
      this.$router.push(`/catalog/${this.film.kinopoisk_id}/${section}`)
    }
  }
}
</script>

<style src="~/assets/section.styl" lang="stylus">
