<template>
  <main class="app-page">
    <section>
      <h1>{{ $t('app_h1') }}</h1>

      <div class="wrapper">
        <div class="block">
          <h2>Apple iOS (Safari)</h2>
          <ol>
            <li>{{ $t('app_goto_safari') }} <a href="https://unotalone.su">{{ $t('app_link') }}</a>.</li>
            <li>{{ $t('app_safari_share') }}</li>
            <li>{{ $t('app_safari_add') }}</li>
          </ol>

          <div class="spoiler">
            <a href="/static_files/app/amZr5q-pRFQ3I.jpg" target="_blank">
              <img src="/static_files/app/amZr5q-pRFQ3I.jpg" alt="">
            </a>
            <a href="/static_files/app/am4ua7GvD_p4g.jpg" target="_blank">
              <img src="/static_files/app/am4ua7GvD_p4g.jpg" alt="">
            </a>
          </div>
        </div>

        <div class="block">
          <h2>Google Android (Chrome)</h2>
          <ol>
            <li>{{ $t('app_goto_chrome') }} <a href="https://unotalone.su">{{ $t('app_link') }}</a>.</li>
            <li>{{ $t('app_chrome_menu') }}</li>
            <li>{{ $t('app_chrome_install') }}</li>
          </ol>

          <div class="spoiler">
            <a href="/static_files/app/amWEfe1.jpg" target="_blank">
              <img src="/static_files/app/amWEfe1.jpg" alt="">
            </a>
            <a href="/static_files/app/amAedvfer23.jpg" target="_blank">
              <img src="/static_files/app/amAedvfer23.jpg" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  head() {
    const fullPath = `https://unotalone.su/app/`
    const title = this.$t('app_head_title')
    const description = this.$t('app_head_description')
    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: fullPath
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `приложение для совместного просмотра una`
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
.app-page
  padding: 10px 10px 200px
  .wrapper
    .block
      margin-top: 30px
      ol
        margin-left: 20px
        margin-top: 30px
        li
          margin: 5px 0px
      .spoiler
        margin-top: 5px
        img
          width: 100px
          margin: 5px
          transition: opacity 0.2s ease
          &:hover
            opacity: 0.9
</style>