<template>
  <button
    v-if="!favActive"
    @click="setFav"
    :data-fav-id="payload.film.id"
    :class="{ 'btn': payload.button }"
    class="wi favorite mini"
    title="Добавить в избранное"
  >
    <img src="/static_files/icons/favourite.png" alt="">
    <template v-if="isDesktop && payload.button">{{ $t('fav_to') }}</template>
  </button>
  <button
    v-else
    @click="removeFav"
    :data-fav-id="payload.film.id"
    :class="{ 'btn': payload.button }"
    class="wi favorite"
    title="Убрать из избранного"
  >
    <img src="/static_files/icons/favourite-red.png" alt="">
    <template v-if="isDesktop && payload.button">{{ $t('fav_remove') }}</template>
  </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    payload: {
      type: Object,
      require: true,
      default: () => ({
        film: { title: '', title_en: '', poster: '', sh_id: null, kinopoisk_id: null, id: null },
        button: false,
        blocked: false
      })
    }
  },
  computed: {
    ...mapState(['user', 'isDesktop']),
    favActive() {
      return this.user ? this.user?.favs.includes(this.payload.film.id) : null
    }
  },
  methods: {
    async setFav() {
      if (!this.user) {
        return this.$router.push(`/signin?ref=${encodeURI(this.$route.fullPath)}`)
      }
      const { data } = await this.$axios.post(`/api/favs`, this.payload.film)
      if (!data.error) {
        const user = this.user
        user.favs.push(this.payload.film.id)
        this.$store.commit('setUser', user)
        const img = document.createElement('img')
        img.className = 'flyposter'
        img.src = this.payload.film.poster
        const parent = document.querySelector(`[data-video-id="${this.payload.film.id}"]`)
        if (parent) {
          parent.appendChild(img)
          setTimeout(() => {
            parent.removeChild(img)
          }, 2000)
        }
      }
    },
    async removeFav() {
      if (!this.user) {
        return this.$router.push(`/signin?id=${this.payload.film.id}`)
      }
      const { data } = await this.$axios.delete(`/api/favs?id=${this.payload.film.id}`)
      if (!data.error) {
        const user = this.user
        const favs = user.favs.filter(el => el !== this.payload.film.id)
        user.favs = favs
        this.$store.commit('setUser', user)
        const videoElem = document.querySelector(`.content.favs .videos .item[data-video-id="${this.payload.film.id}"]`)
        if (videoElem) {
          document.querySelector(`.videos`).removeChild(videoElem)
        }
      }
    }
  }
}
</script>

<style lang="stylus">
</style>
