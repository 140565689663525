<template>
  <div id="movie_video"></div>
</template>

<script>
export default {
  watch: {
    route(val) {
      if (!this.showAd) this.startAd()
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      vid: 'b08e6ab2afe193afb9c016f90a835b1d',
      showAd: false,
    }
  },
  mounted() {
    if (!this.$device.isDesktop) {
      setTimeout(() => {
        this.startAd()
      }, 10 * 1000)
    } else {
      this.startAd()
    }
  },
  methods: {
    startAd() {
      if (this.showAd) return
      this.showAd = true
      const script = document.createElement('script')
      script.src = `https://vak345.com/s.js?v=${this.vid}`
      script.setAttribute('async', 'true')
      document.body.appendChild(script)
    }
  }
}
</script>
