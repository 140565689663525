<template>
  <div />
</template>

<script>
export default {
  head() {
    return {
      title: `Проверка авторизации`
    }
  },
  layout: 'clean',
  mounted() {
    if (window.opener) {
      // window.opener.history.go(-1)
      setTimeout(() => {
        window.opener.location.reload()
        window.close()
      }, 100)
    } else {
      location.href = '/'
    }
  }
}
</script>
