<template>
  <div class="favs">
    <h1>Избранное</h1>
    <template v-if="favs.length">
      <div class="share">
        <label for="share">
          <input
            id="share"
            :value="fullPath"
            type="text"
            readonly
          />
          <button @click="filmShare" class="wi">
            <img src="/static_files/icons/upload.png" alt="">
            Поделиться списком
          </button>
        </label>
      </div>

      <videoComponent :videos="favs.slice(0, (24 * itemsI))" />
      <div v-if="favs.length > 24 * itemsI" class="show-more">
        <button @click="++itemsI">
          {{ $t('show_more') }}
          <i class="arrow" />
        </button>
      </div>
    </template>
    <p v-else>Список закладок пуст. Перейти в <nuxt-link to="/catalog">Каталог</nuxt-link>.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  middleware: 'auth',
  head() {
    const fullPath = `https://unotalone.su/profile/favorites`
    return {
      title: `Избранное`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Список избранного`
        }
      ]
    }
  },
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  computed: {
    ...mapState(['user', 'isDesktop']),
    fullPath() {
      return `https://unotalone.su/favorites/${this.user.id}`
    }
  },
  async asyncData({ $axios, redirect }) {
    const { data } = await $axios.get('/api/favs')
    if (data.error) return redirect(`/signin`)
    return {
      favs: data,
      itemsI: 1
    }
  },
  mounted() {
    document.body.style.overflow = ''
  },
  methods: {
    filmShare() {
      if (!this.isDesktop && navigator.share) {
        navigator.share({
          title: `${this.user.name}: список избранного кино`,
          text: 'Делюсь с тобой своим списком избранного кино',
          url: this.fullPath,
        })
        .then(() => console.log('Successfully share'))
        .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('Share not supported on this browser, do it the old way.')
        const input = document.querySelector('#share')
        if (input) {
          input.select()
          document.execCommand('copy')
          this.$store.commit('setError', { status: 'ok', msg: 'Ссылка скопирована' })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.favs
  padding: 10px
  p a
    display: inline-block
    padding-bottom: 2px
    border-bottom: 1px solid rgba(255 255 255 .35)
    transition: border-bottom .2s ease
    &:hover
      border-bottom: 1px solid #fff
  h1
    margin-bottom: 20px
  .share
    width: 240px
    margin-bottom: 32px
    input
      position: absolute
      left: -9999px
</style>
