<template>
  <main>
    <WantFriends style="padding:10px;" />

    <p style="padding:10px;margin:0px 0px 10px;">
      Не пропустите сообщения от друзей и выход новых серий любимых сериалов.
      <nuxt-link to="/profile/notifi">Активируйте уведомления</nuxt-link> от нашего бота в ТГ и будь всегда на связи.
    </p>

    <section class="friends">
      <div class="catmenu">
        <span @click="catmenu = 'friends'" :class="{ active: catmenu === 'friends' }">
          Друзья <sup v-if="friends.length">{{ friends.length }}</sup>
        </span>
        <span @click="catmenu = 'friendly'" :class="{ active: catmenu === 'friendly' }">
          Запросы <sup v-if="friendly.length">{{ friendly.length }}</sup>
        </span>
      </div>

      <template v-if="catmenu === 'friends'">
        <div v-if="friends.length" class="list">
          <div v-for="item in friends" :key="`friends-${item._id}`" class="item">
            <div class="poster">
              <img :src="item.photo" :alt="item.name">
            </div>
            <div class="body">
              <strong @click="getInfo(item)">{{ item.name }}</strong>
              <div style="display:inline-block;position:relative;top:3px;">
                <button @click="createChat(item._id, user.noti.messages.filter(el => el === item._id))" class="wi">
                  <img src="/static_files/icons/sending.png" alt="">
                  Чат
                </button>
                <sup
                  v-if="user.noti.messages.includes(item._id)"
                  style="position:absolute;top:0px;left:0px;padding:0px 6px;background:#fb2d7f;border-radius:50%;"
                >
                  {{ user.noti.messages.filter(el => el === item._id).length }}
                </sup>
              </div>
              <div style="display:inline-block;position:relative;top:3px;">
                <button @click="deleteFriend(item._id)" class="wi">
                  <img src="/static_files/icons/delete-friend.png" alt="">
                  Удалить из друзей
                </button>
              </div>
            </div>
          </div>
        </div>
        <p v-else>У вас нет друзей. <nuxt-link to="/chat">Начните знакомиться</nuxt-link> прямо сейчас!</p>
      </template>
      <template v-if="catmenu === 'friendly'">
        <div v-if="friendly.length" class="list friendly">
          <div v-for="item in friendly" :key="`friendly-${item._id}`" class="wrapper">
            <div v-if="item.from && item.from.length" class="item">
              <div class="poster">
                <img :src="item.from[0].photo" :alt="item.from[0].name">
              </div>
              <strong>{{ item.from[0].name }}</strong>
            </div>
            <div class="body">
              <!-- <div>{{ user.id === item.from[0]._id ? 'Исходящая заявка' : 'Входящая заявка'  }}</div> -->
              <div style="display: inline-block;">
                <button v-if="item.from[0]._id !== user.id" @click="approveFriend(item)" class="wi">
                  <img src="/static_files/icons/check.png" alt="">
                  Принять
                </button>
              </div>
              <div style="display: inline-block;">
                <button @click="removeFriendly(item._id)" class="wi">
                  <img src="/static_files/icons/remove.png" alt="">
                  Отклонить
                </button>
              </div>
            </div>
            <div v-if="item.to && item.to.length" class="item">
              <div class="poster">
                <img :src="item.to[0].photo" :alt="item.to[0].name">
              </div>
              <strong>{{ item.to[0].name }}</strong>
            </div>
          </div>
        </div>
        <p v-else>Запросы не найдены</p>
      </template>

      <client-only>
        <div v-if="chatRommId" class="popup-chat">
          <button @click="chatRommId = ''" class="wi">
            <img src="/static_files/icons/up-arrow.png" alt="">
            Закрыть чат
          </button>
          <Chat :event="{ id: chatRommId, owner: null, header: true }" />
        </div>
      </client-only>
    </section>

    <div class="wf">
      <transition name="toTop">
        <div v-if="userInfo" class="popup">
          <div @click="userInfo = null" class="close" />
          <div class="user-info">
            <div class="name">
              <strong v-if="userInfo.name">{{ userInfo.name }}</strong>
              <span v-if="userInfo.age && new Date().getFullYear() - new Date(userInfo.age).getFullYear()">
                {{ new Date().getFullYear() - new Date(userInfo.age).getFullYear() }}
              </span>
            </div>
            <!-- <div style="margin: 10px 0px;">
              <button @click="addFriend(userInfo.id)" class="wi fill">
                <img src="/static_files/icons/add-friend.png" alt="">
                Дружить
              </button>
            </div> -->
            <div class="body">
              <div v-if="userInfo.address && userInfo.address.value" class="row">
                <span>Живет в </span>
                <strong>{{ userInfo.address.value }}</strong>
              </div>
              <div v-if="userInfo.about" class="row">
                <strong>Обо мне</strong>
                <p>{{ userInfo.about }}</p>
              </div>
              <div v-if="userInfo.interests" class="interests row">
                <strong>Мои интересы</strong>
                <span
                  v-for="int in userInfo.interests"
                  :key="`userinfo-int-${int.id}`"
                >
                  {{ int.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex';

export default {
  middleware: 'auth',
  components: {
    Chat: () => import('~/components/Chat.vue'),
    WantFriends: () => import('~/components/WantFriends.vue'),
  },
  filters: {
    filterDate(value) {
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      const year = new Date(value).getFullYear()
      return `${day}.${month}.${year} ${hour}:${min}`
    }
  },
  head() {
    const fullPath = `https://unotalone.su/profile/friendly`
    return {
      title: `Дружба`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Находите новых друзей и общайтесь с ними в чате`
        }
      ]
    }
  },
  watch: {
    chatRommId(val) {
      console.log('Starting chat', val)
      if (val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  computed: mapState(['user']),
  async asyncData({ $axios }) {
    const { data } = await $axios.get(`/api/friends/`)
    return {
      friends: data.friends,
      friendly: data.friendly,
      chatRommId: '',
      catmenu: 'friends',
      userInfo: null,
    }
  },
  methods: {
    async addFriend(userId) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
      } else {
        const { data } = await this.$axios.post('/api/friends/friendly', { to: userId })
        if (data.error) {
          this.$store.commit('setError', { status: 'info', msg: data.error.message })
        } else {
          this.friendly = data
          this.$store.commit('setError', { status: 'ok', msg: this.$t('request_sent') })
          this.$socket.emit(`unotalone:friendly`, { to: userId, from: { name: this.user.name, id: this.user.id } })
        }
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async getInfo(user) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/wf/info?user_id=${user._id}`)
      this.loading = false
      if (!data.error) {
        if (data.name) {
          data.name += ` aka ${user.name}`
        } else {
          data.name = user.name
        }
        this.userInfo = data
      } else {
        this.$store.commit('setError', { status: 'info', msg: 'Пользователь не заполнил анкету' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    createChat(userId, lost = []) {
      this.chatRommId = [userId, this.user.id].sort((a, b) => a > b ? -1 : 1).join('-')
      if (lost.length) {
        this.$axios.delete(`/api/friends/lost`)
        const user = this.user
        const notiMessages = user.noti.messages.filter(el => el !== lost[0])
        user.noti.messages = notiMessages
        this.$store.commit('setUser', user)
      }
    },
    async deleteFriend(userId) {
      const confirmResponse = confirm('Вы уверены?')
      if (!confirmResponse) return

      const { data } = await this.$axios.delete(`/api/friends?user_id=${userId}`)
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: 'Вы больше не дружитесь' })
        setTimeout(() => {
          this.$store.commit('clearError')
          location.reload()
        }, 2000)

        // const user = this.user
        // const friends = user.friends.filter(el => el !== userId)
        // user.friends = friends
        // this.$store.commit('setUser', user)
      }
    },
    async approveFriend(friendly) {
      const obj = {
        friendly_id: friendly._id,
      }
      const { data } = await this.$axios.put('/api/friends/friendly', obj)
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => {
          this.$store.commit('clearError')
        }, 2000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: `Вы подружились` })
        setTimeout(() => {
          this.$store.commit('clearError')
          location.reload()
        }, 2000)
      }
    },
    async removeFriendly(id) {
      await this.$axios.delete(`/api/friends/friendly?id=${id}`)
      this.$store.commit('setError', { status: 'ok', msg: `Предложение отклонено` })
      setTimeout(() => {
        this.$store.commit('clearError')
        location.reload()
      }, 2000)
    }
  }
}
</script>

<style lang="stylus">
.friends
  padding: 10px
  .popup-chat
    position: fixed
    padding: 10px
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.95)
    z-index: 10
    .chat
      width: 100%
      padding: 0px 20vw
      .chat-smiles
        max-width: 400px
  button
    padding: 4px 8px
    font-size: 1.2rem
  section
    margin-bottom: 40px
  .catmenu
    margin-bottom: 20px
    span
      padding: 10px
      border-bottom: 2px solid rgba(255, 255, 255, 0.1)
      cursor: pointer
      &.active
        border-bottom: 2px solid #fb2d7f
      &:hover
        border-bottom: 2px solid #fb2d7f
  .list
    &.friendly
      .wrapper
        width: 50%
        display: grid
        grid-template-columns: repeat(3, 1fr)
        align-items: center
    .item
      padding: 10px 0px
      display: grid
      grid-template-columns: 32px auto
      grid-gap: 10px
      align-items: center
      .poster
        display: inline-block
        width: 32px
        height: 32px
        border-radius: 50%
        border: 2px solid rgba(255, 255, 255, 1)
        overflow: hidden
        img
          width: 100%
          min-height: 100%
          object-fit: cover
    strong
      margin-right: 10px
      cursor: help
    .body
      display: block
      font-size: 1.4rem
</style>
