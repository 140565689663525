<template>
  <main class="error-page">
    <section>
      <div class="terminal">
        <h1>Ошибка <span class="errorcode">{{ error.statusCode }}</span></h1>
        <p class="output">{{ error.message }}</p>

        <template v-if="error.statusCode === 401">
          <div class="auth-social output">
            <span @click="authSocial('google')" class="google"><i /> Google</span>
            <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
            <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
          </div>
        </template>
        <template v-else>
          <p class="output">Вернитесь на <a href="https://unotalone.su">Главную страницу</a></p>
          <p class="output">
            Вы можете связаться с нами в <a href="https://t.me/r0n1x">ТГ @r0n1x</a>
            или <a href="https://vk.com/r0n1x">ВК @r0n1x</a>,
            а так же по эл.почте <a href="mailto:@support@unotalone.su">support@unotalone.su</a>
          </p>
        </template>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: { error: { type: Object, default: () => ({}) } },
  head() {
    return {
      title: `Упс... Ошибка ${this.error.statusCode} - ${this.error.message}`,
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `Error 404`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Сожалеем, но ${this.error.message}. Воспользуйтесь поиском по сайту или перейдите на главную страницу.`
        }
      ]
    }
  },
  methods: {
    authSocial(social) {
      location.href = `/api/auth/${social}`
    }
  }
}
</script>

<style lang="stylus">
.error-page
  margin: 0px 0px 100px
  // box-sizing: border-box;
  // height: 100vh;
  // background-color: #000000;
  // background-image: radial-gradient(#11581E, #041607), url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  // background-repeat: no-repeat;
  // background-size: cover;
  color: rgba(128, 255, 128, 0.8);
  text-shadow:
    0 0 1ex rgba(51, 255, 51, 1),
    0 0 2px rgba(255, 255, 255, 0.8);

  h1
    margin-bottom: 30px

  p
    margin: 15px 0px;

  .auth-social
    grid-template-columns repeat(2, 1fr)

  .noise {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: .02;
  }

  .overlay {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background:
        repeating-linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0) 100%);
    background-size: auto 4px;
    z-index: 1;
  }

  .overlay::before {
    content: "";
    pointer-events: none;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        0deg,
        transparent 0%,
        rgba(32, 128, 32, 0.2) 2%,
        rgba(32, 128, 32, 0.8) 3%,
        rgba(32, 128, 32, 0.2) 3%,
        transparent 100%);
    background-repeat: no-repeat;
    animation: scan 7.5s linear 0s infinite;
  }

  @keyframes scan {
    0%        { background-position: 0 -100vh; }
    35%, 100% { background-position: 0 100vh; }
  }

  .terminal {
    box-sizing: inherit;
    // position: absolute;
    // height: 100%;
    // width: 1000px;
    max-width: 100%;
    padding: 4rem;
    text-transform: uppercase;
  }

  .output {
    color: rgba(128, 255, 128, 0.8);
    text-shadow:
        0 0 1px rgba(51, 255, 51, 0.4),
        0 0 2px rgba(255, 255, 255, 0.8);
  }

  .output::before {
    content: "> ";
  }

</style>
