<template>
  <main class="guess">
    <section v-if="currentGuess">
      <!-- <h1>Guess</h1> -->
      <div class="wrapper">
        <div id="player" class="iframe"></div>
        <div class="answers">
          <div v-for="(a, i) in currentGuess.answers" :key="`answer-${a}`" @click="postAnswer(i)" class="item">
            {{ a }}
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <h1>Нет новых видео</h1>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  meta: {
    i18n: false,
  },
  computed: mapState(['user', 'playerjsAdded']),
  async asyncData({ $axios }) {
    const { data } = await $axios.get(`/api/guess/last`)
    return {
      player: null,
      currentGuess: data.length ? data[0] : null,
      loading: false,
    }
  },
  mounted() {
    if (this.currentGuess) this.playVideo()
  },
  methods: {
    async postAnswer(answer) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.loading) return
      this.loading = true
      const body = {
        id: this.currentGuess._id,
        answer
      }
      const { data } = await this.$axios.post(`/api/guess/`, body)
      this.loading = false
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
      } else {
        this.$store.commit('setError', { status: 'ok', msg: data })
      }
      setTimeout(() => {
        this.$store.commit('clearError')
        this.getGuess()
      }, 2000)
    },
    async getGuess() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/guess/last`)
      this.loading = false
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.currentGuess = data[0]
      }
    },
    playVideo() {
      if (!this.playerjsAdded) {
        const playerjs = document.createElement('script')
        playerjs.src = `/static_files/playerjs.js`
        document.head.appendChild(playerjs)
      }

      const params = {
        id:"player",
        file: `https://www.youtube.com/watch?v=${this.currentGuess.youtube_id}`,
        default_quality: '480p',
        cuid: this.currentGuess._id,
      }

      // const checkAdBlock = document.querySelector('#yandex_rtb_R-A-2003630-7')
      // if (checkAdBlock && (!checkAdBlock.children.length || checkAdBlock.children[0].tagName === 'LINK')) {
      //   params.forbidden_quality = '720p, 1080p, Авто'
      // } else if (this.user.group === 1) {
      //   params.forbidden_quality = '1080p, Авто'
      // }

      setTimeout(() => {
        this.$store.commit('setPlayer', true)
        /* eslint-disable */
        this.player = new Playerjs(params)
        // this.player.api('play')
      }, 200)
    },
  }
}
</script>

<style lang="stylus">
.guess
  padding: 10px
  .iframe
    width: 100%
    height: 40vw
  .answers
    margin-top: 10px
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 10px
    .item
      padding: 20px
      color: rgba(255, 255, 255, 0.35)
      border: 1px solid rgba(255, 255, 255, 0.35)
      border-radius: 4px
      text-align: center
      cursor: pointer
      transition: all 0.2s ease
      &:hover
        color: rgba(255, 255, 255, 1)
        border: 1px solid rgba(255, 255, 255, 1)
</style>