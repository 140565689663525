<template>
  <main class="films4k">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link :to="localePath('/')" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_home') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog`)" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_catalog') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <span itemprop="name">{{ $t('breadcrumbs_movie4k') }}</span>
        <meta itemprop="position" content="3">
      </div>
    </div>

    <section>
      <h1>{{ $t('movie4k_h1') }}</h1>

      <VideoComponent :videos="items" />
      <div
        @click="showMore(++page)"
        class="show-more"
      >
        <button v-if="!loading">{{ $t('show_more') }} <i class="arrow" /></button>
        <button v-else><i class="loader" /></button>
      </div>

      <div class="desc">
        <p>{{ $t('movie4k_desc') }}</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  head() {
    const title = this.$t('movie4k_head_title')
    const description = this.$t('movie4k_head_description')
    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: 'https://unotalone.su/films-4k'
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `фильмы сериалы 4k смотреть онлайн`
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://unotalone.su/films-4k'
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        }
      ]
    }
  },
  async asyncData({ $axios, error }) {
    const { data } = await $axios.get(`/api/4k?page=1`)
    // const { data } = await $axios.get(`/api/catalog?page=1&year=${new Date().getFullYear()}`)
    if (data.error) {
      error(data.error)
    } else {
      return {
        items: data,
        page: 1,
        loading: false,
      }
    }
  },
  methods: {
    async showMore(page) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/4k?page=${page}`)
      // const { data } = await this.$axios.get(`/api/catalog?page=${page}&year=${new Date().getFullYear()}`)
      this.items = this.items.concat(data)
      this.loading = false
    },
  },
}
</script>

<style lang="stylus">
.films4k
  padding: 10px
  h1
    margin: 20px 0 20px
  .desc
    margin-top: 40px
</style>
