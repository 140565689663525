<template>
  <div v-if="articles && articles.length" class="last-articles">
    <h2>
      <nuxt-link to="/articles">Статьи</nuxt-link>
    </h2>
    <div class="list">
      <article v-for="item in articles" :key="`last-articles-item-${item._id}`" class="item">
        <div class="header">
          <img
            :data-src="item.poster"
            :alt="item.title" src="/static_files/no-poster.png"
            decoding="async"
            loading="lazy"
            class="lazyload"
          >
        </div>
        <div class="body">
          <time>{{ item.date | filterDate }}</time>
          <h1>{{ item.title }}</h1>
          <div class="owner">
            <div
              class="avatar"
            >
              <img
                :data-src="item.owner && item.owner.length ? item.owner[0].photo : '/static_files/noavatar.svg'"
                src="/static_files/noavatar.svg"
                :alt="item.owner && item.owner.length ? item.owner[0].name : 'Noname'"
                loading="lazy"
                class="lazyload"
              >
            </div>
            <strong>{{ item.owner && item.owner.length ? item.owner[0].name : 'Noname' }}</strong>
            <!-- <nuxt-link :to="`/users/${item.owner && item.owner.length ? item.owner[0]._id : null}`" /> -->
          </div>
        </div>
        <nuxt-link :to="`/articles/${item.pretty}`" />
      </article>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: { type: Object, default: () => ({ kpid: '' })}
  },
  filters: {
    filterDate(value) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  data() {
    return {
      articles: [],
      visible: 0
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let url = '/api/articles'
      if (this.payload.kpid) url += `/${this.payload.kpid}`
      const { data } = await this.$axios.get(url)
      this.articles = data
    }
  }
}
</script>

<style src="~/assets/last-articles.styl" lang="stylus" />
