<template>
  <div class="wf">
    <div class="h2">
      <!-- Хочу дружить -->
      <!-- <div style="display:inline-block;position:relative;top:2px;">
        <img
          @click="refreshLenta"
          src="/static_files/icons/refresh.png"
          alt=""
          style="margin:0px 5px;width:22px;heigth:22px;border-radius:4px;cursor:pointer;"
        >
      </div> -->
      <div style="display:inline-block;position:relative;top:3px;">
        <button @click="showWF = !showWF" class="wi pink">
          <img src="/static_files/icons/small-talk.png" alt="">
          <!-- Найти друга -->
          {{ $t('dating') }}
        </button>
      </div>
      <div style="display:inline-block;">
        <input type="text" v-model="searchUserQuery" @input="trottledUser" :placeholder="$t('search_friend_name')">
      </div>
      <div v-if="searchUserQuery && searchUserHints.length" class="sh-user-hints">
        <span v-if="serachUserCount">Найдено {{ serachUserCount }}</span>
        <div
          v-for="item in searchUserHints"
          :key="`sh-user-${item._id}`"
          class="item"
        >
          <div class="poster">
            <img :src="item.photo" :alt="item.name">
          </div>
          <strong>
            {{ item.name }}
            <i v-if="item.wf && item.wf.length && item.wf[0].approve" />
          </strong>
          <div class="atf">
            <button @click="addFriend(item._id)" class="wi mini">
              <img src="/static_files/icons/add-friend.png" alt="">
              {{ $t('befriends') }}
            </button>
          </div>
        </div>
        <button v-if="searchUserHints.length >= 50" @click="getSearchUserHints(++searchUserPage)" class="mini">
          {{ $t('show_more') }} ({{ searchUserHints.length }} / {{ serachUserCount }})
        </button>
      </div>
    </div>

    <div class="wrap">
      <div :style="`width:${(wantFriends.length + 1) * sliderWidth}px`" class="list">
        <div @click="addToWf" class="item add">
          <div class="wrapper">
            <div class="avatar">
              <img src="/static_files/icons/plus.png" alt="">
            </div>
          </div>
          <strong v-if="!wantPaid">+В ленту</strong>
          <strong v-else>+За 1 Монету</strong>
        </div>
        <template v-if="wantFriends.length">
          <div
            v-for="item in wantFriends" :key="`wf-${item.user[0]._id}`"
            @click="getInfo(item.user[0])"
            class="item"
          >
            <!-- <i v-if="item.user[0].group === 777" class="crown" :class="{ 'patron': item.user[0].patron }" /> -->
            <div class="wrapper" :class="{ 'premium': item.user[0].group === 777 }">
              <div class="avatar">
                <img :src="item.user[0].photo" :alt="item.user[0].name">
              </div>
            </div>
            <strong>
              {{ item.user[0].name }}
              <i v-if="item.wf && item.wf.length && item.wf[0].approve" />
            </strong>
          </div>
        </template>
        <!-- <template v-else>
          <div
            v-for="item in 20" :key="`wf-${item}`"
            class="item fake"
          >
            <div class="wrapper">
              <div class="avatar">
                <img src="/" alt="User">
              </div>
            </div>
            <strong></strong>
          </div>
        </template> -->
      </div>
    </div>

    <transition name="toTop">
      <div v-if="fillInfo || userInfo" class="popup" style="z-index:11;">
        <div @click="closePopup" class="close" />
        <div v-if="fillInfo" class="card">
          <div class="footer">
            <div class="filling-info">
              <strong>Процент заполнения профиля</strong>

              <div class="progress">
                <span :style="`width:${userInfoFillingProcent}%;`">
                  {{ userInfoFillingProcent }}%
                </span>
              </div>
              <p style="font-size:1.2rem;color:rgba(255,255,255,0.5);">
                Пересчитывается после заполнения полей и прохождения модерации.
              </p>
            </div>
            <div style="display:inline-block;">
              <button v-if="cardStep > 0" @click="--cardStep" class="wi">
                <img src="/static_files/icons/up-arrow.png" alt="" style="transform:rotateZ(-90deg);">
                Назад
              </button>
            </div>
            <div style="display:inline-block;">
              <button v-if="cardStep < 9" @click="++cardStep" class="wi">
                <img src="/static_files/icons/up-arrow.png" alt="" style="transform:rotateZ(90deg);">
                Продолжить
              </button>
            </div>
            <div style="display:inline-block;">
              <button @click="setMyCard" class="wi">
                <img src="/static_files/icons/save-file.png" alt="">
                Сохранить
              </button>
            </div>
          </div>
          <div class="catmenu">
            <ul :style="`width:${82 * 9}px`">
              <li :class="{ active: currentCat === 'name' }" @click="currentCat = 'name'">Имя</li>
              <li :class="{ active: currentCat === 'age' }" @click="currentCat = 'age'">Возраст</li>
              <li :class="{ active: currentCat === 'gender' }" @click="currentCat = 'gender'">Пол</li>
              <li :class="{ active: currentCat === 'address' }" @click="currentCat = 'address'">Живу в городе</li>
              <li :class="{ active: currentCat === 'about' }" @click="currentCat = 'about'">Обо мне</li>
              <li :class="{ active: currentCat === 'interests' }" @click="currentCat = 'interests'">Интересы</li>
              <li :class="{ active: currentCat === 'photos' }" @click="currentCat = 'photos'">Фотографии</li>
              <li :class="{ active: currentCat === 'genres' }" @click="currentCat = 'genres'">Любимые жанры</li>
              <li :class="{ active: currentCat === 'films' }" @click="currentCat = 'films'">Хочу посмотреть</li>
            </ul>
          </div>
          <div class="body">
            <template v-if="currentCat === 'name'">
              <input type="text" v-model="userCard.name" placeholder="Имя">
            </template>
            <template v-if="currentCat === 'age'">
              <div style="margin-bottom:5px;">
                <strong>День вашего рождения</strong>
              </div>
              <input type="date" v-model="userCard.age">
            </template>
            <template v-if="currentCat === 'gender'">
              <select  v-model="userCard.gender">
                <option value="">Выберите</option>
                <option value="male">Муж.</option>
                <option value="female">Жен.</option>
              </select>
            </template>
            <template v-if="currentCat === 'address'">
              <div v-if="userCard.address.value" style="margin-bottom:20px;">
                Вы указали: <input type="text" :value="userCard.address.value" readonly>
              </div>
              <input type="text" v-model="searchQueryKladr" @input="trottledKladr" placeholder="Название города">
              <div v-if="searchHintsKladr.length" class="hints">
                <div
                  v-for="item in searchHintsKladr"
                  :key="`hint-${item.id}`"
                  @click="setAddress(item)"
                  class="item"
                >
                  {{ item.value }}
                </div>
              </div>
            </template>
            <template v-if="currentCat === 'about'">
              <textarea v-model="userCard.about" rows="5" placeholder="Необязательное поле. Если не знаете что написать лучше оставьте пустым." style="resize:vertical;"></textarea>
            </template>
            <template v-if="currentCat === 'interests'">
              <div class="interests-set">
                <span v-for="int in userCard.interests" :key="`int-set-${int.id}`">
                  {{ int.name }}
                  <div @click="removeInterests(int)" class="remove" />
                </span>
              </div>
              <div class="interests">
                <span
                  v-for="int in interests"
                  :key="`int-${int.id}`"
                  @click="addInterests(int)"
                  :class="{ active: userCard.interests.find(el => el.id === int.id) }"
                >
                  {{ int.name }}
                </span>
              </div>
            </template>
            <template v-if="currentCat === 'photos'">
              <div class="photos-list">
                <input
                  ref="fileUpload"
                  type="file"
                  style="display:none;"
                  accept="image/gif, image/png, image/jpeg, image/jpg"
                  @change="uploadFile($event)"
                >
                <div class="item">
                  <img @click="attachFile" src="/static_files/icons/plus.png" alt="" style="opacity:0.5;">
                </div>
                <template v-if="userCard.photos.length">
                  <div v-for="item in userCard.photos" :key="`photo-${item}`" class="item">
                    <div @click="removePhoto(item)" class="remove"></div>
                    <img :src="item" alt="">
                  </div>
                </template>
              </div>
            </template>
            <template v-if="currentCat === 'genres'">
              <div class="interests">
                <span
                  v-for="g in genres"
                  :key="`genre-${g}`"
                  @click="addGenre(g)"
                  :class="{ active: userCard.genres.find(el => el === g) }"
                >
                  {{ g }}
                </span>
              </div>
            </template>
            <template v-if="currentCat === 'films'">
              <div :class="type" class="search-hints">
                <input
                  ref="ssearch"
                  v-model="searchQuery"
                  :class="{ active: searchQuery }"
                  @input="trottled"
                  @focus="focused = true"
                  @blur="focused = false"
                  type="search"
                  name="ssearch"
                  autocomplete="off"
                  placeholder="Что хотели бы посмотреть или любимые фильмы"
                >
                <div v-if="searchQuery.length > 2" class="datalist">
                  <div v-if="!searchHints.length" class="no-match">
                    <span>Ничего не найдено</span>
                  </div>
                  <ul v-else>
                    <li
                      v-for="(item, i) in searchHints"
                      :key="`sh-${i}`"
                      @click="addFilm(item.kinopoisk_id)"
                      tag="li"
                    >
                      <div class="poster">
                        <img :src="item.poster" alt="">
                      </div>
                      <p>
                        <strong>{{ item.title }}</strong>
                        <span v-if="item.year">{{ item.year }}</span>
                        <span v-if="item.genres"> {{ item.genres.join(', ') }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="userCard.films.length" class="films-list">
                <div v-for="item in userCard.films" :key="`film-${item}`" @click="removeFilm(item)" class="item">
                  <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item}.jpg`" alt="">
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="userInfo" class="user-info">
          <div v-if="userInfo.photos && userInfo.photos.length" class="poster">
            <img :src="userInfo.photos[0]" :alt="userInfo.name">
          </div>
          <div class="name">
            <strong v-if="userInfo.name">{{ userInfo.name }}</strong>
            <span v-if="userInfo.age && new Date().getFullYear() - new Date(userInfo.age).getFullYear()">
              {{ new Date().getFullYear() - new Date(userInfo.age).getFullYear() }}
            </span>
          </div>
          <div style="margin: 10px 0px;">
            <button @click="addFriend(userInfo.id)" class="wi mini">
              <img src="/static_files/icons/add-friend.png" alt="">
              Дружить
            </button>
          </div>
          <div class="body">
            <div v-if="userInfo.address && userInfo.address.value" class="row">
              <span>Живет в </span>
              <strong>{{ userInfo.address.value }}</strong>
            </div>
            <div v-if="userInfo.about" class="row">
              <strong>Обо мне</strong>
              <p>{{ userInfo.about }}</p>
            </div>
            <div v-if="userInfo.interests && userInfo.interests.length" class="interests row">
              <strong>Мои интересы</strong>
              <span
                v-for="int in userInfo.interests"
                :key="`userinfo-int-${int.id}`"
              >
                {{ int.name }}
              </span>
            </div>
            <div v-if="userInfo.genres && userInfo.genres.length" class="interests row">
              <strong>Любимые жанры</strong>
              <span
                v-for="g in userInfo.genres"
                :key="`userinfo-g-${g}`"
              >
                {{ g }}
              </span>
            </div>
            <div v-if="userInfo.films && userInfo.films.length" class="films row">
              <strong>Хочу посмотреть</strong>
              <a
                v-for="f in userInfo.films"
                :key="`userinfo-f-${f}`"
                :href="`/catalog/${f}`"
                target="_blank"
              >
                <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${f}.jpg`" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="toTop">
      <div v-if="showWF" class="popup tinder">
        <div @click="showWF = false" class="close" />
        <div v-if="!userCardApprove" style="padding:30px 20px 20px;height:100%;">
          <p>
            Если вы видите это сообщение, значит хотите найти себе друга для совместного просмотра?!
            Но для начала нужно заполнить анкету.
          </p>
          <ol style="margin: 10px 20px;">
            <li>Укажите свое <u>реальное имя</u> и <u>дату рождения</u></li>
            <li>Расскажите <u>в каком городе</u> вы живете</li>
            <li>Можете рассказать о себе в пункте <u>"Обо мне"</u></li>
            <li>А также не забудьте выбрать <u>"Интересы"</u>, так у вас будет больше шансов найти близкого по духу человека</li>
            <li>К тому же вы можете загрузить свои <u>фотографии</u></li>
          </ol>
          <div>
            <strong>Более полное заполнение анкеты повысит шансы на успешную модерацию!</strong>
          </div>
          <div style="text-align:center;">
            <button @click="getMyCard" style="margin:20px 0px;font-size:2rem;padding:10px 60px;">
              Заполнить анкету
            </button>
          </div>
          <div style="margin-top:10px;text-align:center;">
            <strong>Настоятельно Рекомендуем!</strong>
            <a href="/profile/notifi" target="_blank" style="display:block;margin:5px 0px;background-color:#fff;color:red; text-decoration: underline;">
              Включить уведомления
            </a>
            о запросах дружбы.
          </div>
          <p style="margin-top:20px;font-size:1.4rem;color:rgba(255,255,255,0.5);">
            Обратите внимание. Все анкеты проходят модерацию. Если вы уже заполнили анкету, но все еще
            видите это сообщение, ваша анкета находится на модерации, наберитесь терпения.
          </p>
        </div>
        <div v-else-if="currentInform" :class="{ inform: currentInform }" style="height:100%;">
          <div
            :style="`${
              usersCards[currentCardIndex].photos && usersCards[currentCardIndex].photos.length
                ? `background-image:url(${usersCards[currentCardIndex].photos[currentPhotoIndex]});
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;`
                : `background-image:url(${usersCards[currentCardIndex].user[0].photo});
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;`
            }`"
            class="body"
          >
            <div class="arrows">
              <div @click="changePhoto('prev')" class="arrow prev"></div>
              <div @click="changePhoto('next')" class="arrow next"></div>
            </div>
            <div
              v-if="usersCards[currentCardIndex].photos &&
                usersCards[currentCardIndex].photos.length &&
                usersCards[currentCardIndex].photos.length > 1"
              class="photos-pagi"
              :style="`grid-Template-columns:repeat(${usersCards[currentCardIndex].photos.length}, 1fr);`"
            >
              <div
                v-for="i in usersCards[currentCardIndex].photos.length"
                :key="`photo-${i}`"
                :class="{ active: i === currentPhotoIndex + 1 }"
                @click="currentPhotoIndex = (i - 1)"
                class="item"
              ></div>
            </div>
          </div>
          <div class="info">
            <div @click="currentInform = null" class="icon-inform cl"></div>
            <div class="name">
              <strong>{{ usersCards[currentCardIndex].name ? usersCards[currentCardIndex].name : usersCards[currentCardIndex].user[0].name }}</strong>
              <div v-if="usersCards[currentCardIndex].age" class="age">
                {{ new Date().getFullYear() - new Date(usersCards[currentCardIndex].age).getFullYear() }}
              </div>
            </div>
            <div v-if="usersCards[currentCardIndex].address.value" class="address">
              <img src="/static_files/icons/home.png" alt="">
              Живет в {{ usersCards[currentCardIndex].address.value }}
            </div>
            <div v-if="usersCards[currentCardIndex].about" class="about">
              <strong>Обо мне</strong>
              {{ usersCards[currentCardIndex].about }}
            </div>
            <div v-if="usersCards[currentCardIndex].interests.length" class="interests">
              <strong>Мои интересы</strong>
              <span v-for="item in usersCards[currentCardIndex].interests" :key="`interest-${item.id}`">
                {{ item.name }}
              </span>
            </div>
            <div v-if="usersCards[currentCardIndex].genres.length" class="interests">
              <strong>Любимые жанры</strong>
              <span v-for="item in usersCards[currentCardIndex].genres" :key="`genres-${item}`">
                {{ item }}
              </span>
            </div>
            <div v-if="usersCards[currentCardIndex].films.length" class="films">
              <strong>Хочу посмотреть</strong>
              <a
                v-for="item in usersCards[currentCardIndex].films"
                :key="`films-${item}`"
                :href="`/catalog/${item}`"
                target="_blank"
              >
                <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item}.jpg`" alt="">
              </a>
            </div>
          </div>
          <div class="actions">
            <!-- <div class="item return">
              <img @click="returnCard" src="/static_files/icons/go-back-arrow.png" alt="">
            </div> -->
            <div @click="cardAction('dislike', usersCards[currentCardIndex].id)" class="item dislike">
              <img src="/static_files/icons/cross-button.png" alt="">
            </div>
            <div @click="superLike" class="item superlike">
              <img src="/static_files/icons/star.png" alt="">
            </div>
            <div @click="cardAction('like', usersCards[currentCardIndex].id)" class="item like">
              <img src="/static_files/icons/like.png" alt="">
            </div>
            <!-- <div @click="boostCard" class="item booster">
              <img src="/static_files/icons/thunder.png" alt="">
            </div> -->
          </div>
        </div>
        <div v-else-if="usersCards.length" style="height:100%;">
          <div
            :style="`${
              usersCards[currentCardIndex].photos && usersCards[currentCardIndex].photos.length
                ? `background-image:url(${usersCards[currentCardIndex].photos[currentPhotoIndex]});
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;`
                : `background-image:url(${usersCards[currentCardIndex].user[0].photo});
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;`
            }`"
            class="body"
          >
            <div class="arrows">
              <div @click="changePhoto('prev')" class="arrow prev"></div>
              <div @click="changePhoto('next')" class="arrow next"></div>
            </div>
            <div
              v-if="usersCards[currentCardIndex].photos &&
                usersCards[currentCardIndex].photos.length &&
                usersCards[currentCardIndex].photos.length > 1"
              class="photos-pagi"
              :style="`grid-Template-columns:repeat(${usersCards[currentCardIndex].photos.length}, 1fr);`"
            >
              <div
                v-for="i in usersCards[currentCardIndex].photos.length"
                :key="`pagi-${usersCards[currentCardIndex]._id}-${i}`"
                :class="{ active: i === currentPhotoIndex + 1 }"
                @click="currentPhotoIndex = (i - 1)"
                class="item"
              ></div>
            </div>
            <div class="info">
              <div @click="currentInform = usersCards[currentCardIndex]" class="icon-inform"></div>
              <div class="name">
                <strong>{{ usersCards[currentCardIndex].name ? usersCards[currentCardIndex].name : usersCards[currentCardIndex].user[0].name }}</strong>
                <div v-if="usersCards[currentCardIndex].age" class="age">
                  {{ new Date().getFullYear() - new Date(usersCards[currentCardIndex].age).getFullYear() }}
                </div>
              </div>
              <template v-if="currentPhotoIndex === 0">
                <div v-if="usersCards[currentCardIndex].about" class="about">
                  {{ 
                    usersCards[currentCardIndex].about.length > 100
                      ? `${usersCards[currentCardIndex].about.slice(0, 100)}...`
                      : usersCards[currentCardIndex].about
                   }}
                </div>
              </template>
              <template v-else-if="currentPhotoIndex === 1">
                <div v-if="usersCards[currentCardIndex].address.value" class="address">
                  <img src="/static_files/icons/home.png" alt="">
                  Живет в {{ usersCards[currentCardIndex].address.value }}
                </div>
              </template>
              <template v-else-if="currentPhotoIndex === 2">
                <div v-if="usersCards[currentCardIndex].interests.length" class="interests">
                  <span v-for="item in usersCards[currentCardIndex].interests" :key="`interest-${item.id}`">
                    {{ item.name }}
                  </span>
                </div>
              </template>
              <template v-else-if="currentPhotoIndex === 3">
                <div v-if="usersCards[currentCardIndex].genres.length" class="interests">
                  <span v-for="item in usersCards[currentCardIndex].genres" :key="`genres-${item}`">
                    {{ item }}
                  </span>
                </div>
              </template>
              <template v-else-if="currentPhotoIndex === 4">
                <div v-if="usersCards[currentCardIndex].films.length" class="films">
                  <a
                    v-for="item in usersCards[currentCardIndex].films"
                    :href="`/catalog/${item}`"
                    :key="`films-${item}`"
                    target="_blank"
                  >
                    <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item}.jpg`" alt="">
                  </a>
                </div>
              </template>
            </div>
            <div class="actions">
              <div class="item return">
                <img @click="returnCard" src="/static_files/icons/go-back-arrow.png" alt="">
              </div>
              <div @click="cardAction('dislike', usersCards[currentCardIndex].id)" class="item dislike">
                <img src="/static_files/icons/cross-button.png" alt="">
              </div>
              <div @click="superLike" class="item superlike">
                <img src="/static_files/icons/star.png" alt="">
              </div>
              <div @click="cardAction('like', usersCards[currentCardIndex].id)" class="item like">
                <img src="/static_files/icons/like.png" alt="">
              </div>
              <div @click="boostCard" class="item booster">
                <img src="/static_files/icons/thunder.png" alt="">
              </div>
            </div>
          </div>
          <div class="footer">
            <!-- <img @click="getMyCard" src="/static_files/icons/account.png" alt="">
            <img @click="getMyCard" src="/static_files/icons/account.png" alt="">
            <img @click="getMyCard" src="/static_files/icons/account.png" alt=""> -->
            <img @click="getMyCard" src="/static_files/icons/user.png" alt="">
          </div>
        </div>
        <div v-else>
          <div style="margin-top:20px;padding:10px;">
            <p style="margin-bottom:10px;">
              Анкеты закончились
            </p>
            <button @click="getMyCard" class="wi">
              <img src="/static_files/icons/pencil.png" alt="">
              Моя анкета
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // props: {
  //   wantFriends: {
  //     type: Array, required: true, default: () => [],
  //   }
  // },
  watch: {
    currentInform(val) {
      if (val) {
        document.body.style.overflow ='hidden'
      } else {
        document.body.style.overflow =''
      }
    },
    showWF(val) {
      if (val) {
        if (!this.fillInfo) {
          this.checkApprove()
        } else {
          this.usersCards = []
          this.currentCardIndex = 0
        }
      }
    },
    cardStep(val) {
      switch (val) {
        case 0:
          this.currentCat = 'name'
          break;
        case 1:
          this.currentCat = 'age'
          break;
        case 2:
          this.currentCat = 'gender'
          break;
        case 3:
          this.currentCat = 'address'
          break;
        case 4:
          this.currentCat = 'about'
          break;
        case 5:
          this.currentCat = 'interests'
          break;
        case 6:
          this.currentCat = 'photos'
          break;
        case 7:
          this.currentCat = 'genres'
          break;
        case 8:
          this.currentCat = 'films'
          break;
        default:
          this.currentCat = 'name'
          break;
      }
    },
    fillInfo(val) {
      if (!val) {
        this.currentCat = 'name'
        this.cardStep = 0
      } else {
        this.userInfo = null
      }
    },
    userInfo(val) {
      if (val) this.fillInfo = false
    }
  },
  computed: mapState(['user', 'isDesktop']),
  data() {
    return {
      page: 0,
      wantFriends: [],
      genres: [
        'боевик',
        'военный',
        'драма',
        'комедия',
        'музыка',
        'семейный',
        'фантастика',
        'мультфильм',
        'фэнтези',
        'мелодрама',
        'аниме',
        'детектив',
        'криминал',
        'триллер',
        'приключения',
        'вестерн',
        'история',
        'документальный',
        'ужасы',
        'спорт',
        'биография',
        'ток-шоу',
        'мюзикл'
      ],
      userInfoFillingProcent: 0,
      currentInform: null,
      currentPhotoIndex: 0,
      currentCardIndex: 0,
      currentCard: null,
      usersCards: [],
      userCardApprove: false,
      showWF: false,
      sliderWidth: 145,
      loading: false,
      wantPaid: false,
      userInfo: null,
      fillInfo: false,
      currentCat: 'name',
      userCard: {
        name: '',
        age: '',
        gender: '',
        address: { id: '', value: '', zip: '' },
        about: '',
        interests: [],
        photos: [],
        films: [],
        genres: [],
        approve: 0,
      },
      cardStep: 0,
      interests: [
          { id: 0, name: 'Рыбалка' },
          { id: 1, name: 'Диггерство' },
          { id: 2, name: 'Охота' },
          { id: 3, name: 'Игра в музыкальной группе' },
          { id: 4, name: 'Ведение блога' },
          { id: 5, name: 'Пикник' },
          { id: 6, name: 'Выпивка' },
          { id: 7, name: 'Боулинг, бильярд или дартс' },
          { id: 8, name: 'Волонтерство' },
          { id: 9, name: 'Поход' },
          { id: 10, name: 'Квест' },
          { id: 11, name: 'Кемпинг' },
          { id: 12, name: 'Театр и опера' },
          { id: 13, name: 'Гадание или хиромантия' },
          { id: 14, name: 'Сигареты и трубка' },
          { id: 15, name: 'Райтер' },
          { id: 16, name: 'Боди-арт' },
          { id: 17, name: 'Садоводство' },
          { id: 18, name: 'Просмотр фильмов и сериалов' },
          { id: 19, name: 'Тусовка' },
          { id: 20, name: 'Животные' },
          { id: 21, name: 'Поход в кафе и бары' },
          { id: 22, name: 'Концерты' },
          { id: 23, name: 'Фестивали' },
          { id: 24, name: 'Рэп' },
          { id: 25, name: 'Автомобильные поездки' },
          { id: 26, name: 'Сбор конструктора' },
          { id: 27, name: 'Сурвайвинг' },
          { id: 28, name: 'Пикап' },
          { id: 29, name: 'Исследование мест' },
          { id: 30, name: 'Массаж' },
          { id: 31, name: 'Геология' },
          { id: 32, name: 'Гемпинг' },
          { id: 33, name: 'Болельшик' },
          { id: 34, name: 'Пляж и загар' },
          { id: 35, name: 'Баня или сауна' },
          { id: 36, name: 'Ставки на спорт' },
          { id: 37, name: 'Замки из песка' },
          { id: 38, name: 'Радиолюбительство' },
          { id: 39, name: 'Путешествия' },
          { id: 40, name: 'Шоппинг' },
          { id: 41, name: 'Комиксы и манга' },
          { id: 42, name: 'Диджеинг' },
          { id: 43, name: 'Коммуникация' },
          { id: 44, name: 'Бег' },
          { id: 45, name: 'Ходьба' },
          { id: 46, name: 'Единоборства' },
          { id: 47, name: 'Тренажерный зал' },
          { id: 48, name: 'Воркаут' },
          { id: 49, name: 'Йога' },
          { id: 50, name: 'Ролики или коньки' },
          { id: 51, name: 'Велоспорт' },
          { id: 52, name: 'Альпинизм' },
          { id: 53, name: 'Лыжи или сноуборд' },
          { id: 54, name: 'Футбол' },
          { id: 55, name: 'Самокат' },
          { id: 56, name: 'Верховая езда' },
          { id: 57, name: 'Дайвинг' },
          { id: 58, name: 'Медитация' },
          { id: 59, name: 'Танцы' },
          { id: 60, name: 'Фитнесс или пилатес' },
          { id: 61, name: 'Бодибилдинг' },
          { id: 62, name: 'Рисование' },
          { id: 63, name: 'Компьютерные игры' },
          { id: 64, name: 'Писательство' },
          { id: 65, name: 'Фотография' },
          { id: 66, name: 'Подкасты' },
          { id: 67, name: 'Игра на музыкальном инструменте' },
          { id: 68, name: 'Религия' },
          { id: 69, name: 'Пение' },
          { id: 70, name: 'Косплей' },
          { id: 71, name: 'Астрономия' },
          { id: 72, name: 'Программирование' },
          { id: 73, name: 'Стендап' },
          { id: 74, name: 'Выпечка' },
          { id: 75, name: 'Криптовалюты' },
          { id: 76, name: 'Чтение книг' },
          { id: 77, name: 'Копирайтинг' },
          { id: 78, name: 'Киберспорт и стриминг' },
          { id: 79, name: 'Парфюмерия' },
          { id: 80, name: 'Бизнес' },
      ],
      searchQueryKladr: '',
      searchHintsKladr: [],
      searchQuery: '',
      searchHints: [],
      searchUserQuery: '',
      searchUserHints: [],
      searchUserPage: 0,
      serachUserCount: 0,
    }
  },
  mounted() {
    this.getData()
    if (!this.isDesktop) {
      this.sliderWidth = 135
    }
  },
  methods: {
    async getData() {
      const { data } = await this.$axios.get(`/api/want`)
      // console.log('data', data)
      this.wantFriends = data
      this.$emit('wflength', this.wantFriends.length)
    },
    trottledUser() {
      if (!this.searchUserQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchUserQuery &&
          this.searchUserQuery !== '' &&
          this.searchUserQuery.length > 2
        ) {
          this.getSearchUserHints()
        }
      }, 300)
      oldValue = this.searchUserQuery
      this.searchUserPage = 0
    },
    getSearchUserHints(page = 0) {
      const body = {
        value: this.searchUserQuery,
        page,
      }
      this.$socket.emit(`unotalone:shuser`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.serachUserCount = cb.count
          this.searchUserHints = page ? this.searchUserHints.concat(cb.result) : cb.result
        }
      })
    },
    removeFilm(id) {
      this.userCard.films = this.userCard.films.filter(el => el !== id)
    },
    addFilm(id) {
      if (!this.userCard?.films.includes(id)) {
        this.userCard.films.push(id)
      }
    },
    addGenre(genre) {
      if (this.userCard?.genres.includes(genre)) {
        this.userCard.genres = this.userCard.genres.filter(el => el !== genre)
      } else {
        this.userCard.genres.push(genre)
      }
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`unotalone:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    },
    userInfoFilling() {
      this.userInfoFillingProcent = 0

      if (this.userCard.name) {
        this.userInfoFillingProcent += 5
      }
      if (this.userCard.age) {
        this.userInfoFillingProcent += 5
      }
      if (this.userCard.gender) {
        this.userInfoFillingProcent += 5
      }
      if (this.userCard.address) {
        this.userInfoFillingProcent += 10
      }
      if (this.userCard.about) {
        this.userInfoFillingProcent += 5
      }
      if (this.userCard.interests.length) {
        if (this.userCard.interests.length < 6) {
          this.userInfoFillingProcent += (this.userCard.interests.length * 3)
        } else {
          this.userInfoFillingProcent += 15
        }
      }
      if (this.userCard.photos.length) {
        if (this.userCard.photos.length < 6) {
          this.userInfoFillingProcent += (this.userCard.photos.length * 5)
        } else {
          this.userInfoFillingProcent += 25
        }
      }
      if (this.userCard.genres.length) {
        if (this.userCard.genres.length < 6) {
          this.userInfoFillingProcent += (this.userCard.genres.length * 3)
        } else {
          this.userInfoFillingProcent += 15
        }
      }
      if (this.userCard.films.length) {
        if (this.userCard.films.length < 6) {
          this.userInfoFillingProcent += (this.userCard.films.length * 3)
        } else {
          this.userInfoFillingProcent += 15
        }
      }
    },
    returnCard() {
      if (this.usersCards[this.currentCardIndex - 1]) {
        --this.currentCardIndex
      }
    },
    boostCard() {
      this.$store.commit('setError', { status: 'info', msg: 'В разработке' })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    superLike() {
      this.$store.commit('setError', { status: 'info', msg: 'В разработке' })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    changePhoto(op) {
      if (!this.usersCards[this.currentCardIndex].photos || !this.usersCards[this.currentCardIndex].photos.length) {
        return
      }
      if (op === 'prev') {
        if (this.usersCards[this.currentCardIndex].photos[this.currentPhotoIndex - 1]) {
          --this.currentPhotoIndex
        }
      } else if (op === 'next') {
        if (this.usersCards[this.currentCardIndex].photos[this.currentPhotoIndex + 1]) {
          ++this.currentPhotoIndex
        }
      }
    },
    async removePhoto(id) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.delete(`/api/wf/photos?id=${id}`)
      this.loading = false
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const photos = this.userCard.photos.filter(el => el !== id)
        this.userCard.photos = photos
      }
    },
    attachFile() {
      this.$refs.fileUpload.click()
    },
    uploadFile(event, typeImg) {
      if (!this.user) return

      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight
              let finalFile = ''

              let imageWidth = image.width
              let imageHeight = image.height

              if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                imageHeight *= maxWidth / imageWidth
                imageWidth = maxWidth
              } else if (imageHeight > maxHeight) {
                imageWidth *= maxHeight / imageHeight
              }

              const canvas = document.createElement('canvas')
              canvas.width = imageWidth
              canvas.height = imageHeight

              const ctx = canvas.getContext('2d')
              ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

              finalFile = canvas.toDataURL(fileType)

              // The resized file ready for upload
              const fileName = `${vm.user.id}_${Date.now()}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=photos`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })

              vm.userCard.photos.push(data.url)
            }
          }
        } else {
          this.$store.commit(
            'setError',
            '.jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    async checkApprove() {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/wf/info/my`)
      this.loading = false
      if (data.approve) {
        this.userCardApprove = data.approve
        this.getCards()
      }
    },
    async cardAction(action = 'like', to = null) {
      if (!action || !to) return
      if (this.usersCards[this.currentCardIndex + 1]) {
        ++this.currentCardIndex
        await this.$axios.post(`/api/wf/cards`, { action, to })
      } else {
        await this.$axios.post(`/api/wf/cards`, { action, to })
        this.getCards()
        // const { data } = await this.$axios.get(`/api/wf/cards`)
        // this.usersCards = data
        // this.currentCardIndex = 0
      }
    },
    async getCards() {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      const { data } = await this.$axios.get(`/api/wf/cards?page=${this.page++}`)
      this.usersCards = data
      if (data.length) {
        // this.currentCard = data[0]
        this.currentCardIndex = 0
      }
    },
    async refreshLenta() {
      const { data } = await this.$axios.get(`/api/wf/want`)
      this.wantFriends = data
    },
    async addFriend(userId) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
      } else {
        const { data } = await this.$axios.post('/api/friends/friendly', { to: userId })
        if (data.error) {
          this.$store.commit('setError', { status: 'info', msg: data.error.message })
        } else {
          this.friendly = data
          this.$store.commit('setError', { status: 'ok', msg: 'Запрос отправлен' })
          this.$socket.emit(`unotalone:friendly`, { to: userId, from: { name: this.user.name, id: this.user.id } })
        }
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    closePopup() {
      this.fillInfo = false
      this.userInfo = null
    },
    setAddress(item) {
      this.userCard.address = item
      this.searchQueryKladr = ''
      this.searchHintsKladr = []
    },
    async setMyCard() {
      if (!this.user) {
        this.$store.commit('setError', {
          status: 'info',
          msg: this.$t('auth_needded')
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.userCard.name) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Имя - обязательное поле.'
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (this.userCard.name.length > 100) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Лимит символов в Имени превышен.'
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      // if (!this.userCard.age) {
      //   this.$store.commit('setError', {
      //     status: 'info',
      //     msg: 'Давай укажим возраст. Это важно!'
      //   })
      //   setTimeout(() => this.$store.commit('clearError'), 2000)
      //   return
      // }

      if (this.userCard.about.length > 500) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Обо мне. Лимит 1000 символов превышен.'
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.userCard.address.value) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Укажи город проживания. Возможно счастье рядом!'
        })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (!this.userCard.interests.length) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Ты точно интересный человек! Расскажите про свои интересы.'
        })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (!this.userCard.genres.length) {
        this.$store.commit('setError', {
          status: 'info',
          msg: 'Кажется вы забыли указать любимые жанры.'
        })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }

      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.post(`/api/wf/info`, this.userCard)
      this.loading = false
      if (data.error) {
        this.$store.commit('setError', {
          status: 'warn',
          msg: data.error.message
        })
      } else {
        this.fillInfo = false
        this.$store.commit('setError', {
          status: 'ok',
          msg: 'Отправлено на модерацию. Ты еще можешь изменить анкету.'
        })
        this.userInfoFilling()
      }
      setTimeout(() => this.$store.commit('clearError'), 3000)
    },
    async getMyCard() {
      if (!this.user) {
        this.$store.commit('setError', {
          status: 'info',
          msg: this.$t('auth_needded')
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/wf/info/my`)
      this.loading = false
      if (data) {
        this.userCard = {
          name: data.name || '',
          age: data.age || '',
          gender: data.gender || '',
          address: data.address || { id: '', value: '', zip: '' },
          about: data.about || '',
          interests: data.interests || [],
          photos: data.photos || [],
          films: data.films || [],
          genres: data.genres || [],
          approve: data.approve
        }
        this.userInfoFilling()
      }
      this.fillInfo = true
    },
    trottledKladr() {
      if (!this.searchQueryKladr.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQueryKladr &&
          this.searchQueryKladr !== '' &&
          this.searchQueryKladr.length > 2
        ) {
          this.getSHKladr()
        }
      }, 200)
      oldValue = this.searchQueryKladr
    },
    getSHKladr() {
      const body = {
        value: this.searchQueryKladr
      }
      this.$socket.emit(`unotalone:kladr`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHintsKladr = cb
        }
      })
    },
    removeInterests(int) {
      const interests = this.userCard.interests.filter(el => el.id !== int.id)
      this.userCard.interests = interests
    },
    addInterests(int) {
      if (this.userCard.interests.length < 5 && !this.userCard.interests.find(el => el.id === int.id)) {
        this.userCard.interests.push(int)
      }
    },
    async getInfo(user) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/wf/info?user_id=${user._id}`)
      this.loading = false
      if (data.error) {

        this.$store.commit('setError', {
          status: 'info',
          msg: data.error.message
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)

        this.userInfo = {
          id: user._id,
          name: user.name,
        }
      } else {
        data.name += ` @${user.name}`
        this.userInfo = data
      }
    },
    async addToWf() {
      if (!this.user) {
        this.$store.commit('setError', {
          status: 'info',
          msg: this.$t('auth_needded')
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      if (this.loading) return
      this.loading = true
      let url = `/api/wf/want`
      if (this.wantPaid) url += `?paid=1`
      const { data } = await this.$axios.post(url)
      this.loading = false
      if (data.error) {
        this.$store.commit('setError', {
          status: 'info',
          msg: data.error.message
        })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        this.wantPaid = true
      } else if (!this.wantPaid) {
        this.wantFriends.unshift({ user: [{ _id: this.user.id, name: this.user.name, photo: this.user.photo }] })
      }
    }
  }
}
</script>

<style src="~/assets/wf.styl" lang="stylus" />
