<template>
  <nav>
    <ul class="cp-menu">
      <nuxt-link to="/4wg8a/articles" tag="li">
        articles
      </nuxt-link>
      <nuxt-link to="/4wg8a/donate" tag="li">
        donate
      </nuxt-link>
      <nuxt-link to="/4wg8a/unsubs" tag="li">
        unsubs
      </nuxt-link>
      <nuxt-link to="/4wg8a/wf" tag="li">
        wf
      </nuxt-link>
      <nuxt-link to="/4wg8a/vmeste" tag="li">
        vmeste
      </nuxt-link>
      <nuxt-link to="/4wg8a/streamers" tag="li">
        streamers
      </nuxt-link>
      <nuxt-link to="/4wg8a/comments" tag="li">
        comments
      </nuxt-link>
      <nuxt-link to="/4wg8a/chat" tag="li">
        chat
      </nuxt-link>
      <nuxt-link to="/4wg8a/feedback" tag="li">
        feedback
      </nuxt-link>
      <nuxt-link to="/4wg8a/kino" tag="li">
        kino
      </nuxt-link>
      <nuxt-link to="/4wg8a/desc" tag="li">
        desc
      </nuxt-link>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  mounted() {
    document.body.style.overflow = ''
  },
  methods: {
    async makeHeapdump() {
      const { data } = await this.$axios.get(`/api/admin/heapdump?key=${this.key}`)
      console.log(data)
    }
  }
}
</script>

<style lang="stylus">
.cp
  padding: 40px
  &.content-scroll
    height: 100vh
  section
    margin: 40px 0px
    border-bottom: 2px solid #000
.cp-menu
  margin-bottom 20px
  li
    display inline-block
    text-transform uppercase
    margin-right 10px
    cursor pointer
    font-weight bold
    &:hover
      text-decoration underline
@media all and (max-width: 1240px) {
  body {
    background: #100e19 !important;
  }
  section {
    padding: 10px !important;
    margin: 20px 0 !important;
  }
  main {
    width: 100% !important;
    margin: 20px auto !important;
  }
  h1, .h1 {
    font-size: 2.4rem !important;
  }
  h2, .h2 {
    font-size: 2.2rem !important;
  }
  h3, .h3 {
    font-size: 2rem !important;
  }
  .cp.tv .timeline {
    width: 100% !important;
  }
  .cp .chat-messages .rooms, .cp-events, .cp.tv .body {
    grid-template-columns: auto !important;
  }
}

</style>
