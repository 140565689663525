<template>
  <div class="names">
    <h1>Персоны / Имена актеров</h1>
    <div class="filters">
      <!-- <label for="profession"></label>
      <select name="profession" id="profession" v-model="profession" @change="getData(1)">
        <option value="">Все</option>
        <option value="DIRECTOR">Режиссер</option>
        <option value="ACTOR">Актер</option>
      </select> -->
      <SearchFilms :payload="{ visible: true, type: 'persons', popular: [], text: '' }" class="relative" />
    </div>
    <div class="items">
      <div v-for="item in items" :key="`person-${item.personId}`" class="item">
        <div class="poster">
          <img
            :data-src="item.posterUrl"
            src="/static_files/no-poster.png"
            :alt="`${item.nameRu}/${item.nameEn}`"
            class="lazyload"
            loading="lazy"
          >
        </div>
        <strong>
          {{ item.nameRu }}
          <template v-if="item.nameRu && item.nameEn">/</template>
          {{ item.nameEn }}
        </strong>
        <nuxt-link :to="`/name/${postUrl(item.personId, item.nameRu || item.nameEn)}`" class="film-link">
          {{ item.nameRu }}
          <template v-if="item.nameRu && item.nameEn">/</template>
          {{ item.nameEn }}
        </nuxt-link>
      </div>
    </div>
    <div class="show-more">
      <button @click="getData(++page)">
        {{ $t('show_more') }}
        <i v-if="!loading" class="arrow" />
        <i v-else class="loader" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  head() {
    const fullPath = `https://unotalone.su/name`
    return {
      title: `Список персон: режиссеры, актеры - биографии и фильмографии на UNOTALONE`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `режиссеры актеры биографии фильмографии`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Вашему вниманию самый полный список режиссеров и актеров. Их биографии и фильмографии.`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Список персон: режиссеры, актеры - биографии и фильмографии на KIOFF`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Вашему вниманию самый полный список режиссеров и актеров. Их биографии и фильмографии.`
        }
      ]
    }
  },
  components: {
    SearchFilms: () => import('~/components/SearchFilms.vue')
  },
  async asyncData({ $axios, error}) {
    const { data } = await $axios.get(`/api/persons`)
    if (data.error) {
      error(data.error)
    } else {
      return {
        page: 1,
        items: data,
        loading: false,
        profession: ''
      }
    }
  },
  methods: {
    async getData(page) {
      if (this.loading) return
      this.loading = true
      let url = `/api/persons?page=${page}`
      if (this.profession) url += `&profession=${this.profession}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        console.error(data.error)
      } else {
        const items = page > 1 ? this.items.concat(data) : data
        this.items = items
      }
      this.loading = false
    }
  }
}
</script>

<style lang="stylus">
.names
  margin: 20px
  .search-name
    margin-top: 20px
    input
      width: 100%
  .items
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 32px 20px
    .item
      margin-bottom: 20px
      position: relative
      display: grid
      grid-template-columns: 100px auto
      grid-gap: 20px
      align-items: center
      .poster
        width: 100px
        height: 100px
        overflow: hidden
        border-radius: 50%
        img
          width: 100%
          min-height: 100%
          object-fit: cover
          background: url('/static_files/icons/default-cast.svg') center
      a
        position: absolute
        top: 0px
        left: 0px
        width: 100%
        height: 100%
        z-index: 1
</style>
