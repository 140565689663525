<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <section class="Vmeste">
      <input type="button" value="Get Vmeste" @click="getData">
      <h1>Vmeste</h1>

      <div>
        <input type="date" v-model="toDate" />
        <input type="text" v-model="kinoId" />
        <button @click="addTo">ADD</button>
      </div>

      <div v-if="items.list.length">
        List
        <div v-for="item in items.list" :key="`vmeste-${item._id}`">
          <img style="width:100px;" :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${item.kinopoisk_id}.jpg`" alt="">
          <strong>{{ item }}</strong>
          <button @click="deleteItem('list', item._id)">DELETE</button>
        </div>
      </div>

      <div v-if="items.history.length">
        History
        <div v-for="item in items.history" :key="`vmeste-history-${item._id}`">
          {{ item }}
          <button @click="deleteItem('history', item._id)">DELETE</button>
        </div>
      </div>

      <div>{{ errorMsg }}</div>
    </section>
  </main>
  <main v-else>
    <h1>Access denied</h1>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    filterDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      items: {
        list: [],
        history: [],
      },
      toDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      kinoId: null,
      errorMsg: ''
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async deleteItem(type, id) {
      const body = {
        type, id
      }
      const { data } = await this.$axios.post(`/api/admin/vmeste/delete?key=${this.key}`, body)
      console.log(data)
      this.getData()
    },
    async addTo() {
      if (!this.kinoId) return

      const body = {
        kinopoisk_id: this.kinoId,
        date: this.toDate
      }
      const { data } = await this.$axios.post(`/api/admin/vmeste?key=${this.key}`, body)
      this.errorMsg = data
      this.getData()
    },
    async getData() {
      const { data } = await this.$axios.get(
        `/api/admin/vmeste?key=${this.key}`
      )
      // console.log('data', data)
      this.items = data
    }
  }
}
</script>
