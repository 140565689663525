<template>
  <main class="promocodes">
    <section>
      <h1>Промокоды</h1>
      <div>
        <label for="promocode">
          <input id="promocode" type="text" v-model="promocode" placeholder="Введите промокод" />
        </label>
        <button @click="send">Активировать</button>
      </div>
    </section>
    <section>
      <h2>Активированные промокоды</h2>
      <div v-if="promocodes.length" class="list">
        <div v-for="item in promocodes" :key="`promocode-${item._id}`" class="item">
          <strong>{{ item.code }}</strong>
          <ul>
            <li>{{ item.date }}</li>
            <li v-if="item.type === 'coin'">{{ item.coin_count }} Монет</li>
            <li v-else>Premium подписка на {{ item.premium_days / (24 * 60 * 60 * 1000) }} дн.</li>
          </ul>
        </div>
      </div>
      <p v-else>
        Поищите промокоды в нашей группе <a href="https://vk.com/anime_7_7_7" target="_blank">https://vk.com/anime_7_7_7</a>
      </p>
    </section>
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  head() {
    return {
      title: 'Промокоды'
    }
  },
  async asyncData({ $axios }) {
    const { data } = await $axios.get('/api/promocodes')
    return {
      promocodes: data,
      promocode: ''
    }
  },
  methods: {
    async send() {
      const body = {
        code: this.promocode.trim()
      }
      const { data } = await this.$axios.post('/api/promocodes', body)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
      } else {
        this.$store.commit('setError', { status: 'ok', msg: data.message })
        this.promocode = ''
        this.promocodes.push(data.data)
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    }
  }
}
</script>

<style lang="stylus">
.promocodes
  h1, h2
    margin-bottom: 24px
  .list
    .item
      margin-bottom: 15px
    ul
      margin-left: 20px
</style>