<template>
  <main class="tg_notifi">
    <h1>Уведомления</h1>
    <input
      :data-user-id="user.id"
      :value="`@${user.id}`"
      type="text"
      readonly
      style="position:absolute;top:-9999px;opacity:0;"
    />
    <ol>
      <li>
        Для начала зарегистрируйтесь у нашего бота <a :href="`https://t.me/unotalone_bot?start=${user.id}`" target="_blank">@unotalone_bot</a>
        нажав <b>Start</b> и отправив ему сообщение
        <span
          @click="shareID"
          style="display:inline-block;padding:2px 10px;font-weight:400;background-color:#3a3352;border-radius:4px;cursor:pointer;"
        >@{{ user.id }}</span>
        ("cобачка" обязательна!)
      </li>
      <li>
        Установите переключатели ниже в положение ВКЛ
      </li>
      <li>
        Заполните список тайтлов (сериалов), о выходе серий которых хотите получать уведомления
      </li>
    </ol>

    <div class="notifi">
      <div class="item">
        <strong>Запросы дружбы</strong>
        <div class="toggle" style="display:inline-block;margin-left:10px;">
          <input type="checkbox" id="friendly" v-model="tgNotifi.friendly" @change="setNotifi" />
          <label for="friendly" style="margin-left:5px;"><span></span></label>
        </div>
      </div>
      <div class="item">
        <strong>Личные сообщения</strong>
        <div class="toggle" style="display:inline-block;margin-left:10px;">
          <input type="checkbox" id="message" v-model="tgNotifi.message" @change="setNotifi" />
          <label for="message" style="margin-left:5px;"><span></span></label>
        </div>
      </div>
    </div>

    <section style="margin-top: 40px;">
      <h2>Список отслеживаемого кино (сериалы)</h2>

      <p>
        Начните вводить название сериала, о выходе новых серий которого хотите получать уведомления.
      </p>

      <div class="search-hints">
        <input
          v-model="searchQuery"
          :class="{ active: searchQuery }"
          @input="trottled"
          type="search"
          autocomplete="off"
          placeholder="Введите название кино..."
        >
        <div v-if="searchQuery.length > 2" class="datalist">
          <div v-if="!searchHints.length" class="no-match">
            <span>Ничего не найдено</span>
          </div>
          <ul v-else>
            <li
              v-for="(item, i) in searchHints"
              :key="`article-sh-${i}`"
              @click="addRelease(item.id)"
            >
              <div class="poster">
                <img :src="item.poster" alt="">
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span v-if="item.year">{{ item.year }}</span>
                <span v-if="item.genres"> {{ item.genres.join(', ') }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="tgNotifiRelease.length" class="release">
        <div class="list">
          <div v-for="i in tgNotifiRelease" :key="`release-${i}`"  class="item">
            <div @click="removeRelease(i)" class="remove"></div>
            <nuxt-link :to="`/catalog/${i}`">
              <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${i}.jpg`" alt="">
            </nuxt-link>
          </div>
        </div>
        <!-- <div v-if="user && JSON.stringify(tgNotifiRelease) !== JSON.stringify(user.tg_notifi_release)">
          <button @click="setRelease">
            Сохранить список
          </button>
        </div> -->
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  middleware: 'auth',
  head() {
    const fullPath = `https://unotalone.su/profile/notifi`
    return {
      title: `Уведомления`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Уведомления`
        }
      ]
    }
  },
  computed: {
    ...mapState(['user', 'isDesktop'])
  },
  data() {
    return {
      searchQuery: '',
      searchHints: [],
      tgNotifi: { friendly: false, message: false },
      tgNotifiRelease: [],
    }
  },
  mounted() {
    this.tgNotifi = this.user.tg_notifi ? this.user.tg_notifi : { friendly: false, message: false }
    this.tgNotifiRelease = this.user.tg_notifi_release ? this.user.tg_notifi_release.slice() : []
  },
  methods: {
    shareID() {
      const input = document.querySelector(`[data-user-id="${this.user.id}"]`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'ID скопирован' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    async setRelease() {
      await this.$axios.post(`/api/user/tg/release`, this.tgNotifiRelease)
      const user = this.user
      user.tg_notifi_release = this.tgNotifiRelease
      this.$store.commit('setUser', user)
      // this.$store.commit('setError', { status: 'ok', msg: 'Список обновлен' })
      // setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    addRelease(id) {
      if (this.tgNotifiRelease.includes(id)) return
      this.tgNotifiRelease.push(id)
      this.setRelease()
      // const user = this.user
      // user.tg_notifi_release = this.tgNotifiRelease
      // this.$store.commit('setUser', user)
    },
    removeRelease(id) {
      console.log('id', id)
      const user = this.user
      this.tgNotifiRelease = this.tgNotifiRelease.filter(el => el !== id)
      user.tg_notifi_release = this.tgNotifiRelease
      this.$store.commit('setUser', user)
      this.setRelease()
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`unotalone:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    },
    async setNotifi() {
      const { data } = await this.$axios.post(`/api/user/tg/notifi`, this.tgNotifi)
      console.log('data', data)
      const user = this.user
      user.tg_notifi = this.tgNotifi
      this.$store.commit('setUser', user)
    },
  }
}
</script>

<style lang="stylus">
.tg_notifi
  padding: 10px
  ol
    margin-left: 20px
  h1
    margin-bottom: 30px
  h2
    margin-bottom: 20px
  .search-hints
    margin-top: 10px
    input
      min-width: 200px
  .notifi
    .item
      margin: 10px 0px
  .release
    margin-top: 10px
    .item
      display: inline-block
      position: relative
      margin: 0px 10px 10px 0px
      width: 120px
      height: 180px
      overflow: hidden
      .remove
        position: absolute
        display: inline-block
        top: 0px
        left: 0px
        width: 30px
        height: 30px
        padding: 10px
        background-color: rgba(0, 0, 0, 0.5)
        background-image: url('/static_files/icons/close-dark.svg')
        background-size: 10px
        background-repeat: no-repeat
        background-position: center
        cursor: pointer
        z-index: 1
      img
        width: 100%
        min-height: 100%
        object-fit: cover

</style>
