<template>
  <div class="error">
    <div class="modal">
      <!-- <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
          stroke="green"  stroke-width="3" stroke-dasharray="100">
        <path id="check" d="M 12,22 L 22,31 L 36,13"
              stroke-dashoffset="0">
          <animate attributeName="stroke-dashoffset"
                  from="100" to="0" dur="1s"></animate>
        </path>
      </svg> -->
      <i v-if="error.status === 'load'" :class="loader" />
      <i v-else-if="error.status === 'coin'" class="coin" />
      <svg
        v-else-if="error.status === 'ok'"
        style="margin: auto; display: block; shape-rendering: auto;"
        width="60px"
        height="60px"
        viewBox="0 0 60 60"
        fill="none"
        stroke="#8bc34a"
        stroke-width="3"
        stroke-dasharray="100"
      >
        <path id="check" d="M 5,35 L 30,55 L 55,5" stroke-dashoffset="0" stroke-width="7">
          <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.5s"></animate>
        </path>
      </svg>
      <svg
        v-else-if="error.status === 'warn'"
        style="margin: auto; display: block; shape-rendering: auto;"
        width="60px" height="60px"
        viewBox="0 0 60 60"
        fill="none"
        stroke="#b65e54"
        stroke-width="3"
        stroke-dasharray="100"
      >
        <path id="fail" d="M 5,5 L 55,55 M 55,5 L 5,55" stroke-dashoffset="0" stroke-width="7">
          <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.5s"></animate>
        </path>
      </svg>
      <svg
        v-else-if="error.status === 'info'"
        style="margin: auto; display: block; shape-rendering: auto;"
        width="60px" height="60px"
        viewBox="0 0 60 60"
        fill="none"
        stroke="#03a9f4"
        stroke-width="3"
        stroke-dasharray="100"
      >
        <path id="fail" d="M 30,0 L 30,7 M 30,15 L 30,60" stroke-dashoffset="0" stroke-width="7">
          <animate attributeName="stroke-dashoffset" from="100" to="0" dur="0.5s"></animate>
        </path>
      </svg>
      <span v-else>INFO</span>
      <!-- <i class="loader" /> -->
      <strong>{{ error.msg }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: { error: { type: Object, required: true, default: () => ({}) } },
  computed: {
    loader() {
      const loads = ['spookyghost', 'bluecat', 'cutiefox', 'kakidog', 'midorikappa', 'pinkpig']
      return loads[Math.floor(Math.random() * loads.length)]
    }
  }
}
</script>

<style lang="stylus">
.error
  position fixed
  top 0px
  left 0px
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.55)
  z-index 13
  animation fade .5s ease
  i
    display inline-block
    width 100px
    height 100px
    background-size 100px
    &.spookyghost
      background-image url('/static_files/spinners/spookyghost.svg')
    &.bluecat
      background-image url('/static_files/spinners/bluecat.svg')
    &.cutiefox
      background-image url('/static_files/spinners/cutiefox.svg')
    &.kakidog
      background-image url('/static_files/spinners/kakidog.svg')
    &.midorikappa
      background-image url('/static_files/spinners/midorikappa.svg')
    &.pinkpig
      background-image url('/static_files/spinners/pinkpig.svg')
    &.coin
      background-image url('/static_files/icons/sitecoin-donate.svg')
  .modal
    display grid
    grid-template-columns auto
    align-items center
    justify-items center
    position absolute
    top 50%
    left 50%
    width 200px
    height 200px
    margin -100px 0 0 -100px
    font-size 1.5rem
    color #000
    background-color #fff
    border-radius 20px
    padding 20px
    text-align center
    .loader
      width 80px
      height 80px
      background-size 80px 80px
    strong
      display block
      margin-top 10px
</style>
