<template>
  <article class="article">
    <header>
      <ul v-if="payload.article.tags.length" class="tags">
        <li v-for="tag in payload.article.tags" :key="`tag-${payload.article._id}-${tag.id}`">
          <nuxt-link :to="`/tag/${tag.id}-${tag.name}`">
            <span>#</span>{{ tag.name }}
          </nuxt-link>
        </li>
      </ul>

      <h1 itemprop="headline">
        {{ payload.article.title }}
      </h1>

      <p class="short" itemprop="description alternativeHeadline">
        {{ payload.article.short }}
      </p>

      <time>{{ payload.article.date | filterDate }}</time>

      <a :href="payload.article.poster" target="_blank" style="text-align:center;">
        <img
          :data-src="payload.article.poster"
          src="/static_files/no-poster.png"
          :alt="`${payload.article.title} - постер к новости`"
          loading="lazy"
          class="poster lazyload"
        >
      </a>
    </header>

    <div v-if="payload.recomendations.length" class="recomendations">
      <div v-for="item in payload.recomendations" :key="`recomendation-${payload.article._id}-${item._id}`" class="item">
        <nuxt-link :to="`/articles/${item.pretty}`">
          {{ item.title }}
        </nuxt-link>
      </div>
    </div>

    <!-- <div v-if="!isDesktop" class="ads in-content">
      <div class="banner">
        <Rsya :payload="{ blockid: 'R-A-1970027-1', page: callid }" />
      </div>
    </div> -->

    <div itemprop="articleBody" v-html="payload.article.text" class="description" />

    <!-- <div v-if="payload.article.films.length" class="related films">
      <h4>Кино в статье</h4>
      <ul>
        <li v-for="item in payload.article.films" :key="`films-${item.kinopoisk_id}`">
          <img :src="item.material_data.poster" :alt="item.title_ru">
          <nuxt-link :to="`/catalog/${item.kinopoisk_id}`">
            {{ item.title_ru }}
          </nuxt-link>
        </li>
      </ul>
    </div> -->

    <div v-if="payload.related.length" class="related">
      <h4>Материалы по теме</h4>
      <ul>
        <li v-for="item in payload.related" :key="`related-${payload.article._id}-${item._id}`">
          <nuxt-link :to="`/articles/${item.pretty}`">
            {{ item.title }}
          </nuxt-link>
        </li>
      </ul>
    </div>

    <div class="comments-block">
      <button v-if="!showComments" @click="showComments = true" class="dbtn red" style="margin-top: 14px;">
        Комментировать <span v-if="payload.article.comments">({{ payload.article.comments }})</span>
      </button>
      <Comments v-else :id="`article-${payload.article._id}`" />
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: { payload: { type: Object, require: true, default: () => ({ article: {}, related: [], recomendations: [] }) } },
  components: {
    // Rsya: () => import('~/components/Rsya.vue'),
    Comments: () => import('~/components/Comments.vue')
  },
  filters: {
    filterDate(value) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} ${hour}:${min}`
    }
  },
  computed: {
    ...mapState(['isDesktop'])
  },
  data() {
    return {
      showComments: false,
      callid: 1
    }
  },
  mounted() {
    const list = document.querySelectorAll('.article')
    if (list) this.callid = list.length
  }
}
</script>

<style src="~/assets/article.styl" lang="stylus" />
