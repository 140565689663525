<template>
  <main class="history">
    <section v-if="calendar.length">
      <h1>История просмотров за 30 дней</h1>

      <div>
        <button @click="clearHistory">Очистить</button>
      </div>

      <div class="calendar">
        <div v-for="item in calendar" :key="`ca-${item.date}`">
          <div :title="item.count" class="column">
            <u :style="`position:absolute;left:50%;bottom:${(item.count / columnMax) * 100}%;`">{{ item.count }}</u>
            <span :style="`height:${(item.count / columnMax) * 100}%`" />
          </div>
          <div class="date">{{ item.date | filterDateShort }}</div>
        </div>
      </div>

      <div class="list">
        <div v-for="(anime, i) in items" :key="`kino-${anime.kino_id}`" class="anime">
          <div class="poster">
            <img :src="filmPoster(anime)" :alt="anime.title_ru">
          </div>
          <div class="body">
            <div class="title">
              <nuxt-link :to="`/vmeste?id=${anime.kino_id}`">{{ anime.title_ru }}</nuxt-link>
              <span>{{ anime.title_en }}</span>
            </div>
            <div class="items">
              <div v-for="item in items[i].items" :key="`item-${item.date}`" class="item">
                <span v-if="item.date">{{ item.date | filterDate }}</span>
                <span v-if="item.se.translation">{{ item.se.translation }}</span>
                <span v-if="item.se.season">Сезон {{ item.se.season }}</span>
                <span v-if="item.se.episode">Серия {{ item.se.episode }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <h1>История просмотров за 30 дней</h1>
      <p>
        Начните смотреть уже сейчас. Подберите для себя фильм из нашего
        <nuxt-link to="/catalog">Каталога кино.</nuxt-link>
      </p>
    </section>
  </main>
</template>

<script>
export default {
  middleware: 'auth',
  head() {
    const fullPath = `https://unotalone.su/profile/history`
    return {
      title: `История просмотров`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `История просмотров`
        }
      ]
    }
  },
  filters: {
    filterDate(val) {
      const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
      const month = new Date(val).getMonth() + 1 < 10 ? `0${new Date(val).getMonth() + 1}` : new Date(val).getMonth() + 1
      const year = new Date(val).getFullYear() < 10 ? `0${new Date(val).getFullYear()}` : new Date(val).getFullYear()

      return `${day}.${month}.${year}`
    },
    filterDateShort(val) {
      const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
      
      return `${day}`
    }
  },
  async asyncData({ $axios, redirect, error }) {
    const { data } = await $axios.get(`/api/user/history`)
    if (data.error) return error(data.error)
    const calendar = data.calendar.slice(0)
    return {
      items: data.list,
      calendar: data.calendar,
      columnMax: data.calendar.length
        ? calendar.sort((a, b) => a.count > b.count ? -1 : 1)[0].count
        : 0
    }
  },
  methods: {
    async clearHistory() {
      await this.$axios.delete('/api/user/history')
      location.reload()
    }
  }
}
</script>

<style lang="stylus">
.history
  padding: 10px
  .calendar
    margin: 20px 0px 40px
    width: 100%
    display: grid
    grid-template-columns: repeat(30, 1fr)
    // grid-gap: 10px
    .column
      position: relative
      height: 200px
      span
        position: absolute
        bottom: 0px
        display: block
        width: 100%
        background-color: #1da1f2
    u
      font-size: 1rem
    .date
      font-size: 1rem
      text-align: center
  h1
    margin-bottom: 30px
  .list
    .anime
      margin-bottom: 40px
      display: grid
      grid-template-columns: 80px auto
      grid-gap: 10px
      .poster
        width: 80px
        height: 115px
        overflow: hidden
        img
          width: 100%
          min-height: 100%
          object-fit: cover
      .body
        .title span
          font-size: 1.4rem
          color: rgba(255, 255, 255, 0.59)
        .items
          margin-top: 10px
          max-height: 200px
          font-size: 1.4rem
          overflow-y: auto
          -webkit-overflow-scrolling touch
          &::-webkit-scrollbar-track
            width: 17px
            background rgba(255, 255, 255, 0)
          &::-webkit-scrollbar
            width: 17px
          &::-webkit-scrollbar-thumb
            background rgba(255, 255, 255, 0.4)
          &:hover::-webkit-scrollbar-thumb
            background rgba(255, 255, 255, 0.4)
          .item
            padding: 5px
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 10px
            &:nth-child(odd)
              background-color: rgba(29, 161, 242, 0.1)
            &:nth-child(1)
              background-color: rgba(251, 45, 127, 0.1)
</style>