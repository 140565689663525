<template>
  <div class="slider-wrapper">
    <i v-if="collection.length > 7" class="before" />
    <div class="slider">
      <ul :style="`width:${collection.length * (20 + itemRect.width)}px;`">
        <li v-for="(film, i) in collection" :key="`slider-${film.id}-${i}`">
          <nuxt-link :to="localePath(`/catalog/${postUrl(film.id || film.kinopoisk_id, film.title_ru)}`)">
            <div :style="`width:${itemRect.width}px;height:${itemRect.height}px;`" class="poster">
              <img
                :data-src="filmPoster(film)"
                :alt="'Постер к ' + film.title_ru"
                src="/static_files/no-poster.png"
                loading="lazy"
                class="lazyload"
              >
            </div>
            <strong :style="`width:${itemRect.width}px;`" class="name-ru">
              {{ ['ru', 'uk', 'be'].includes(lang) ? film.title_ru : film.title_en ? film.title_en : film.title_ru }}
            </strong>
          </nuxt-link>
        </li>
        <!-- <li v-if="collection.length >= 7" class="collection-show-more">
          <i v-if="collection.length < videos.items.length" class="collection-show-more-plus" @click="sliderArrows('right', $event)" />
          <i v-else class="collection-show-more-minus" @click="sliderArrows('left', $event)" />
        </li> -->
      </ul>
    </div>
    <div v-if="collection.length > 6" class="arrows">
      <i v-if="collection.length > 7" class="larrow" @click="sliderArrows('left', $event)" />
      <i class="rarrow" @click="sliderArrows('right', $event)" />
    </div>
    <i v-if="collection.length < 60" class="after" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: { videos: { type: Object, required: true, default: () => ({ items: [] }) } },
  data() {
    return {
      collection: [],
      csPos: 0,
      sPos: 0
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    itemRect() {
      return this.isDesktop ? { width: 170, height: 240 } : { width: 170, height: 240 }
    },
    lang () {
      return this.$i18n.locale
    },
  },
  fetch() {
    this.collection = this.videos.items.slice(0, 20)
  },
  mounted() {
    // this.lazyLoadImgs()

    // if (this.isDesktop) {
    //   const carousel = document.querySelector('.slider')
      
    //   if (carousel) {
    //     carousel.addEventListener('mouseenter', this.mouseEnter)
    //     carousel.addEventListener('mouseleave', this.mouseLeave)
    //   }
    // }
  },
  methods: {
    mouseEnter(event) {
      this.csPos = pageYOffset
      document.body.style.overflow = 'hidden'
      document.querySelector('.slider').addEventListener('scroll', this.scrolling)
      window.addEventListener('mousewheel', this.wheel)
    },
    mouseLeave(event) {
      document.body.style.overflow = ''
      document.querySelector('.slider').removeEventListener('scroll', this.scrolling)
      window.removeEventListener('mousewheel', this.wheel)
    },
    scrolling(event) {
      document.documentElement.scroll(0, this.csPos)
    },
    wheel(event) {
      const carousel = document.querySelector('.slider')

      if (event.deltaY >= 0 && this.sPos < (this.itemRect.width * this.collection.length)) {
        this.sPos += this.itemRect.width
      } else if (this.sPos >= this.itemRect.width) {
        this.sPos -= this.itemRect.width
      }
      
      if (carousel) carousel.scroll(this.sPos, 0)
    },
    sliderArrows(side, event) {
      let elem = null
      if (event.target.parentElement.className === 'collection-show-more') {
        elem = event.target.parentElement.parentElement.parentElement
        if (side === 'left') {
          this.collection = this.videos.items.slice(0, 10)
          elem.scrollTo(0, 0)
        } else if (side === 'right') {
          if (this.itemRect.width === 190) {
            this.collection = this.videos.items.slice(0, this.collection.length + 5)
            this.$nextTick(() => elem.scrollTo(elem.scrollLeft + this.itemRect.width * 7, 0))
          } else {
            this.collection = this.videos.items.slice(0, this.collection.length + 10)
          }
        }
      } else {
        elem = event.target.parentElement.previousElementSibling
        if (side === 'left') {
          if (this.collection.length >= 11)
            this.collection = this.videos.items.slice(0, this.collection.length - 5)
          elem.scrollTo(elem.scrollLeft - 195 - this.itemRect.width * 5, 0)
        } else if (side === 'right') {
          if (this.collection.length < this.videos.items.length)
            this.collection = this.videos.items.slice(0, this.collection.length + 6)
          elem.scrollTo(elem.scrollLeft + 195 + this.itemRect.width * 6, 0)
        }
      }
      // this.$nextTick(() => this.lazyLoadImgs())
    }
  }
}
</script>
