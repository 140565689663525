<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <div class="create-message">
      <input type="text" v-model="message" placeholder="Сообщение от админа">
      <button @click="sendMessage">send</button>
    </div>
    <section class="chat-rooms">
      <h2>Chat rooms</h2>
      <input type="button" value="Get rooms" @click="getRooms">
      <div v-for="item in rooms" :key="`cp-rooms-${item._id}`" class="room">
        <button @click="deleteRoom(item._id)">Delete</button>
        <nuxt-link :to="`/vmeste/${item._id}`" target="_blank">{{ item.film.title_ru }}</nuxt-link>
      </div>
    </section>
    <section class="chat-messages">
      <h2>Chat Messages</h2>
      <div>
        <input type="button" value="Get messages" @click="getMessages">
        <input type="button" value="Clear" @click="clearMessages">
        <input type="button" value="Save" @click="saveMessages">
      </div>
      
      <div class="rooms">
        {{ messagesCount }}
        <div v-for="(room, k) in messages" :key="`message-room-${k}`" class="room">
          <h3>
            Комната
            <nuxt-link :to="`/vmeste/${k}`" target="_blank">{{ k }}</nuxt-link>
            <button @click="roomMessagesClean(k)">clean</button>
          </h3>
          <div class="messages">
            <div v-for="item in messages[k].slice(0, 20 * messagesI)" :key="`message-${item.message.date}`" class="message">
              <time>{{ item.message.date | filterDate }}</time>
              <span>
                <img :src="item.message.user.photo" alt="">
                <nuxt-link :to="`/users/${item.message.user.id}`" target="_blank">{{ item.message.user.name }}</nuxt-link>
              </span>
              <p>
                {{ item.message.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button v-if="messages.length" @click="++messagesI">{{ $t('show_more') }}</button>
    </section>
    <section>
      <h2>ChatBans</h2>
      <div>
        <button @click="getBans">Get Bans</button>
      </div>
      <div>
        <input type="text" v-model="userBan" />
        <button @click="postBan">Ban</button>
      </div>

      <div v-if="bans.length" class="bans">
        <div v-for="item in bans" :key="`ban-${item.user_id}`" class="item">
          {{ item }}
          <button @click="delBan(item.user_id)">Unban</button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import socket from '~/plugins/socket-client'

export default {
  layout: 'clean',
  components: {
    cpMenu: () => import('~/components/cpMenu.vue')
  },
  filters: {
    filterDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      messages: [],
      messagesD: [],
      messagesI: 1,
      rooms: [],
      bans: [],
      message: '',
      userBan: '',
      messagesCount: 0
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) {
          this.access = true
          // setInterval(() => this.getMessages(), 10 * 1000)
        }
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async postBan() {
      if (!this.userBan) return
      await this.$axios.post(
        `/api/admin/chatban?key=${this.key}`, { user_id: this.userBan }
      )
      this.userBan = ''
      this.getBans()
    },
    async delBan(id) {
      if (!id) return
      await this.$axios.delete(
        `/api/admin/chatban?user_id=${id}&key=${this.key}`
      )
      this.getBans()
    },
    async getBans() {
      const { data } = await this.$axios.get(
        `/api/admin/chatban?key=${this.key}`
      )
      this.bans = data
    },
    async roomMessagesClean(k) {
      await this.$axios.delete(
        `/api/admin/chat-messages/${k}?key=${this.key}`
      )
      this.getMessages()
    },
    sendMessage() {
      if (!this.message.trim()) return
      const message = {
        text: this.message.trim(),
        user: {
          id: null,
          name: 'Alon',
          photo: '/bot-avatar.jpg',
          group: -1
        }
      }
      this.$socket.emit(`unotalone:message:admin`, message)
      this.message = ''
    },
    parseBB() {
      const list = document.querySelectorAll('.chat-messages p')
      if (list) {
        Array.from(list).forEach((el) => {
          const checkBB = el.textContent.match(/\[::.*::\]/) || el.textContent.match(/^@.*,/)

          // console.log('checkBB', checkBB)

          if (checkBB) {
            const p = el
            let str = p.textContent
              .replace(/\[::color/g, `<span style="color`)
              .replace(/;::\]/g, `">`)
              .replace(/\[::\/color::\]/g, `</span>`)
              .replace(/\[::click::\]/g, `<u onclick="location.reload()" style="color:rgba(29,141,262,1);cursor:pointer;">`)
              .replace(/\[::\/click::\]/g, `</u>`)
              .replace(/\[::u::\]/g, `<u>`)
              .replace(/\[::\/u::\]/g, `</u>`)
              .replace(/\[::link="/g, `<a href="`)
              .replace(/"::\]/g, `"target="_blank">`)
              .replace(/\[::\/link::\]/g, '</a>')
              .replace(/\[::/g, `<img src="/static_files/stikers/`)
              .replace(/::\]/g, `" class="chat-emoji" />`)
            
            const findAddress = el.textContent.match(/^@.*,/)
            if (findAddress) {
              str = str.replace(
                findAddress[0],
                `<span style="color:rgba(29,141,262,1);font-weight:700;">${findAddress[0]}</span>`
              )
            }

            p.innerHTML = str
          }
        })
      }
    },
    deleteRoom(roomId) {
      this.$axios.delete(`/api/admin/chat-rooms?key=${this.key}&room_id=${roomId}`)
      this.getRooms()
    },
    async getRooms() {
      const { data } = await this.$axios.get(`/api/admin/chat-rooms?key=${this.key}`)
      if (data.length) {
        this.rooms = data
      } else {
        this.$store.commit('setError', { status: 'info', msg: 'Комнаты не найдены' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    async saveMessages() {
      const { data } = await this.$axios.put(
        `/api/admin/chat-messages?key=${this.key}`
      )
      this.messages = data
    },
    async clearMessages() {
      const { data } = await this.$axios.delete(
        `/api/admin/chat-messages?key=${this.key}`
      )
      this.messages = data
    },
    async getMessages() {
      const { data } = await this.$axios.get(
        `/api/admin/chat-messages?key=${this.key}`
      )
      // console.log('data', data)

      this.messages = null
      this.messagesCount = data.length

      if (data.length) {
        const rooms = {}
        data.forEach(msg => {
          if (rooms[msg.room]) {
            rooms[msg.room].push(msg)
          } else {
            rooms[msg.room] = [msg]
          }
        })
        Object.keys(rooms).forEach(room => {
          rooms[room].sort((a, b) => a.message.date > b.message.date ? -1 : 1)
        })

        // console.log('rooms', rooms)

        this.messages = rooms

        this.$nextTick(() => this.parseBB())
      } else {
        console.log('Messages not found')
      }
    },
    showMore(i) {
      this.messages = this.messagesD.slice(0 * i, 50 * i)
      this.$nextTick(() => this.parseBB())
    }
  }
}
</script>

<style lang="stylus">
.cp
  .chat-messages
    h1
      margin-bottom 30px
    .create-message
      margin-bottom 30px
    .rooms
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 40px 10px
      .room
        padding 10px
        border 1px solid #eee
        .messages
          max-height 400px
          overflow-y auto
          .message
            margin 5px 0px 10px
            p
              white-space pre-wrap
              word-wrap break-word
            img
              width: 40px
              height: 40px
              &.chat-emoji
                width: 200px
                height: 200px
.chat-rooms
  margin-bottom 30px
  .room
    position relative
    margin 20px 0 10px
</style>
