<template>
  <main class="chat-page">
    <LazyHydrate when-idle>
      <WantFriends @wflength="foo($event)" />
    </LazyHydrate>
    <section>
      <h1>{{ $t('chatpage_h1') }}</h1>
      <!-- <Chat :style="`height: calc(100vh - ${wfLength ? '370' : '155'}px);`" :event="{ id: 'global', owner: null, header: true }" /> -->
      <LazyHydrate when-idle>
        <Chat :event="{ id: 'global', owner: null, header: true }" />
      </LazyHydrate>

      <!-- <div class="desc">
        <p>Знакомства онлайн - это удобный и эффективный способ найти свою вторую половинку или просто интересного собеседника.</p>
        <p>Наш сайт предлагает широкий выбор профилей пользователей, которые ищут отношения, дружбу или просто общение.</p>
        <p>Вы можете создать свой профиль, загрузить фотографии, описать свои интересы и предпочтения, а также использовать поиск для нахождения подходящих кандидатов.</p>
        <p>Мы гарантируем конфиденциальность и безопасность ваших данных, а также предоставляем удобный интерфейс и возможность общаться с другими пользователями через чат или личные сообщения.</p>
        <p>Начните свою историю любви или просто найдите новых друзей на нашем сайте знакомств онлайн!</p>
      </div> -->
    </section>
  </main>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate,
    Chat: () => import('~/components/Chat.vue'),
    WantFriends: () => import('~/components/WantFriends.vue'),
  },
  head() {
    const title = this.$t('chatpage_head_title')
    const description = this.$t('chatpage_head_description')
    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: 'https://unotalone.su/chat'
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `чат, чат для знакомств, знакомства бесплатно, голосовой чат, знакомства онлайн`
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://unotalone.su/chat'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: '/static_files/og_images/chat.jpg'
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: title
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: '/static_files/og_images/chat.jpg'
        }
      ]
    }
  },
  data() {
    return {
      wfLength: 0,
    }
  },
  methods: {
    foo(event) {
      this.wfLength = event
    }
  }
}
</script>

<style lang="stylus">
.chat-page
  position: relative
  padding: 10px
  .desc
    margin-top: 40px
  h1
    margin-top: 30px
  .chat
    position: relative
    top: 0px
    width: 100%
    min-height: 400px
    .chat-smiles
      max-width: 400px
    .chat-area
      height: calc(100% - 90px - 43px)
      .messages
        height: calc(100% - 43px)
</style>