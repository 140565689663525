<template>
  <main class="vpn">
    <section>
      <h1>Как включить VPN прокси в браузере</h1>
      <ol>
        <li>
          <p>
            В любой непонятной ситуации: не открывается сайт или получаете ошибку во время
            воспроизведения видео в плеере, а может быть видеоконтент заблокирован в вашем
            регионе, на помощь приходит VPN. Например, расширение
            <a href="https://zenmate.com/" rel="nofollow noreferrer" target="_blank">VPN бесплатно ZenMate - Free VPN</a>
          </p>
          <ul>
            <li>Для браузера Chrome, Яндекс.Браузер <a href="https://vk.cc/c59Y4v" rel="nofollow noreferrer" target="_blank">https://vk.cc/c59Y4v</a></li>
            <li>Для браузера Опера <a href="https://vk.cc/c7zE0U" rel="nofollow noreferrer" target="_blank">https://vk.cc/c7zE0U</a></li>
            <li>Для браузера Firefox <a href="https://vk.cc/c7zEMA" rel="nofollow noreferrer" target="_blank">https://vk.cc/c7zEMA</a></li>
          </ul>
          <figure>
            <img :data-src="'/static_files/vpn/zenmate.jpg'" src="/static_files/no-poster.png" alt="ZenMate - Free VPN" class="lazyload">
            <figcaption>ZenMate - Free VPN</figcaption>
          </figure>
        </li>
        <li>
          <p>
            На мобильных устройствах (и на ПК) <strong>бесплатный VPN доступен в браузере Alloha</strong>.
          </p>
          <ul>
            <li>Android <a href="https://vk.cc/c9tvTW" rel="nofollow noreferrer" target="_blank">https://vk.cc/c9tvTW</a></li>
            <li>iOS <a href="https://vk.cc/c9tw1t" rel="nofollow noreferrer" target="_blank">https://vk.cc/c9tw1t</a></li>
          </ul>
          <p>
            Данный браузер абсолютно бесплатен и легок в освоении. Официальный сайт <a href="https://alohabrowser.com/" rel="nofollow noreferrer" target="_blank">https://alohabrowser.com/</a>
          </p>
          <figure>
            <img :data-src="'/static_files/vpn/allohabrowser.jpg'" src="/static_files/no-poster.png" alt="Alloha Browser" class="lazyload">
            <figcaption>Alloha Browser</figcaption>
          </figure>
        </li>
      </ol>
    </section>
  </main>
</template>

<script>
export default {
  head() {
    return {
      title: `Как включить VPN прокси в браузере: Яндекс, Chrome, Опера, Firefox`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://unotalone.su/vpn'
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `как включить vpn, впн для браузера`
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Как включить VPN прокси в браузере: Яндекс, Chrome, Опера, Firefox`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://unotalone.su/vpn'
        },
        {
          hid: 'og:tags',
          property: 'og:tags',
          content: `как включить vpn, впн для браузера`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Рассказываем и показыаем, как установить и активировать VPN (впн) прокси в браузерах Яндекс, Chrome, Опера, Firefox.`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Рассказываем и показыаем, как установить и активировать VPN (впн) прокси в браузерах Яндекс, Chrome, Опера, Firefox.`
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
.vpn
  padding: 10px
  figure
    display: block
    margin-top: 20px
    figcaption
      font-size: 1.4rem
      margin-top: 5px
      opacity: 0.55
    img
      display: inline-block
      max-width: 100%
  h1
    margin-bottom: 32px
  ol
    display: inline-block
    margin-left: 20px
    li
      margin: 20px 0
    ul
      margin-left: 20px
      li, p
        margin: 5px 0
</style>