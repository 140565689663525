<template>
  <div v-if="toggleFeedback" class="feedback" @click="hideFeedback">
    <div onclick="event.stopPropagation()" class="feedback-modal">
      <header>
        <span>Обратная связь</span>
        <i class="icon-close" @click="hideFeedback" />
      </header>
      <p>
        Если у вас техническая проблема, хотите оставить отзыв о работе сервиса или предложить улучшение – заполните форму ниже. <br>
        <strong>Нам очень важно получать обратную связь от наших пользователей. Спасибо!</strong>
      </p>
      <div class="feedback-body">
        <div class="block">
          <span><strong>Оцените нашу работу</strong><sup>*</sup></span>
          <form onsubmit="event.preventDefault()">
            <label>
              <input type="radio" name="radFeedback" @change="setFbScore('0')">
              <!--i class="icon-emo-good" /--> Отлично!
            </label>
            <label>
              <input type="radio" name="radFeedback" @change="setFbScore('1')">
              <!--i class="icon-emo-normal" /--> Средне
            </label>
            <label>
              <input type="radio" name="radFeedback" @change="setFbScore('2')">
              <!--i class="icon-emo-bad" /--> Плохо
            </label>
          </form>
        </div>
        <div class="block">
          <span>
            <strong>Что Вам понравилось или НЕ понравилось?<sup>*</sup></strong><br>
            <span style="font-size:1.2rem;opacity:0.75;">
              Мы открыты для нового. Отправляйте свои идеи, а мы постараемся их реализовать.
            </span>
          </span>
          <textarea
            v-model="fbText"
            rows="7"
          />
        </div>
        <div class="block">
          <span>
            <strong>Как с вами связаться<sup>*</sup></strong><br>
            <span style="font-size:1.2rem;opacity:0.75;">
              Ваша страница в телеграм или вконтакте для получения ответа.
            </span>
          </span>
          <input
            v-model="fbContact"
            placeholder="ТГ или ВК"
          >
        </div>
        <div class="block">
          <span><strong>Прикрепить скриншот</strong></span>
          <input
            v-show="!loadingFbFile"
            ref="fbUpload"
            type="file"
            accept="image/png, image/jpeg"
            @change="fbUpload"
          >
          <i v-show="loadingFbFile" class="sloader" />
        </div>

        <p>
          Если у вас <strong>срочное сообщение</strong>, пишите напрямую в ВКонтакте
          <a href="https://vk.com/r0n1x" target="_blank" rel="noopener nofollow noreferrer">https://vk.com/r0n1x</a>
          или Телеграм
          <a href="https://t.me/r0n1x" target="_blank" rel="noopener nofollow noreferrer">https://t.me/r0n1x</a>
        </p>

        <span style="font-size:1.2rem;opacity:0.75;">
          <sup>*</sup>
          Поля, отмеченные звездочкой, обязательны к заполнению.
        </span>
      </div>
      <div class="footer">
        <div>
          <span @click="hideFeedback">Отмена</span>
          <button @click="sendFeedback">
            Отправить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['user', 'toggleFeedback']),
  data() {
    return {
      loadingFbFile: false,
      fbScore: '',
      fbText: '',
      fbContact: '',
      fbFileURL: null,
    }
  },
  mounted() {
    this.fbText = navigator.userAgent
  },
  methods: {
    hideFeedback() {
      this.$store.commit('toggleFeedback', false)
    },
    setFbScore(score) {
      this.fbScore = score
      // console.log('fbScore', this.fbScore)
    },
    async fbUpload(event) {
      // console.log('fbUpload')
      const user = this.$store.state.user
      if (user) {
        const file = event.target.files[0]
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          const imageSize = file.size / 1024
          const imageExt = file.name.slice(file.name.lastIndexOf('.'))
          // console.log(imageExt, imageSize)
          if (
            (imageExt === '.jpg' || imageExt === '.jpeg' || imageExt === '.png') &&
            imageSize < 5000
          ) {
            this.loadingFbFile = true
            const bodyFormData = new FormData()
            bodyFormData.append('image', file)
            const { data } = await this.$axios({
              method: 'post',
              url: '/upload?type=fbs',
              data: bodyFormData,
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            this.fbFileURL = data.url
            // console.log('fileURL', this.fbFileURL)
            this.loadingFbFile = false
            this.$store.commit('setError', { status: 'ok', msg: 'Файл успешно прикреплен' })
            setTimeout(() => this.$store.commit('clearError'), 2000)
          } else {
            this.$store.commit(
              'setError',
              'Файл не соответствует условиям: формат .jpg или .png размером до 5mb'
            )
            setTimeout(() => this.$store.commit('clearError'), 3000)
          }
        }
      } else {
        this.$store.commit(
          'setError',
          'Для прикрепления файла необходимо авторизироваться'
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    async sendFeedback() {
      if (!this.user) {
        this.$store.commit(
          'setError',
          {
            status: 'info',
            msg: this.$t('auth_needded')
          }
        )
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.fbScore) {
        this.$store.commit('setError', { msg: 'Оцените нашу работу' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.fbText.trim()) {
        this.$store.commit('setError', { msg: 'Вы забыли написать сообщение' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.fbContact.trim()) {
        this.$store.commit('setError', { status: 'info', msg: 'Оставьте свои Контакты' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      // if (
      //   !(this.fbContact.match(/https:\/\/t\.me\/.*/) || this.fbContact.match(/https:\/\/vk\.com\/.*/))
      // ) {
      //   this.$store.commit('setError', { status: 'info', msg: 'Неверный формат контакта' })
      //   setTimeout(() => this.$store.commit('clearError'), 2000)
      //   return
      // }

      const obj = {
        score: this.fbScore,
        text: this.fbText,
        contact: this.fbContact,
        img: this.fbFileURL,
        user: this.user ? this.user : null,
        url: location.href
      }
      await this.$axios.post(`/api/feedback`, obj)
      this.fbScore = ''
      this.fbText = ''
      this.fbContact = ''
      this.fbFileURL = null
      this.$store.commit(
        'setError',
        {
          status: 'ok',
          message: 'Спасибо за ваш отзыв. Ваше мнение очень важно для нас!'
        }
      )
      setTimeout(() => this.$store.commit('clearError'), 3000)
      this.hideFeedback()
    }
  }
}
</script>

<style lang="stylus">
.feedback
  position fixed
  width 100%
  height 100%
  left 0
  top 0
  background-color rgba(0, 0, 0, 0.75)
  z-index 5
  a
    border none
  .feedback-modal
    position relative
    top 120px // 5vh
    margin 0 auto
    width 960px
    max-height calc(100vh - 200px)
    color #f2f2f2
    background-color #100e19
    border-radius 6px
    overflow-y auto
    sup
      color:red;
      font-weight:bold;
      font-size:1.6rem;
    header
      padding 15px 20px
      border-radius 5px 5px 0px 0px
      background-color #312b45
      color #fff
      font-size 2rem
      font-family 'Raleway', sans-serif
      .icon-close
        display inline-block
        position absolute
        top 12px
        right 10px
        width 10px
        height 10px
        padding 15px
        background rgba(255, 255, 255, 0.15) url('/static_files/icons/close-dark.svg') center center no-repeat
        background-size 10px
        cursor pointer
        transition all .2s ease
        &:hover
          background-color rgba(255 255 255 0.1)
          border-radius 50%
    p
      padding 20px
      margin 20px
      font-size: 1.4rem
      border 1px solid rgba(255, 255, 255, 0.59)
    .feedback-body
      padding 10px 30px
      p
        margin: 20px 0px
      a
        color #cd5457
        font-weight bold
      .block
        display grid
        grid-template-columns repeat(2, 1fr)
        grid-gap 20px
        align-items start
        margin 15px 0
      textarea
        padding 5px 10px
        border 1px solid rgba(255, 255, 255, 0.59)
        width 100%
      input
        border 1px solid rgba(255, 255, 255, 0.59)
        width 100%
      form
        display inline-block
        display grid
        grid-template-columns repeat(3, 1fr)
        justify-items center
        label
          margin 0 10px
          display grid
          grid-template-columns 20px 30px auto
          align-items center
      i
        display inline-block
        width 24px
        height 24px
        &.icon-emo-good
          background-image url('/static_files/icons/045-cat-9.svg')
          background-size 24px 24px
        &.icon-emo-normal
          background-image url('/static_files/icons/030-cat-23.svg')
          background-size 24px 24px
        &.icon-emo-bad
          background-image url('/static_files/icons/032-cat-21.svg')
          background-size 24px 24px
    .footer
      border-radius 0px 0px 5px 5px
      background-color #312b45
      color #fff
      text-align right
      button
        padding 8px 20px
        color #fff
        font-size 1.6rem
        background-color #7e7990
        border: none
        border-radius: 0px
        &:hover
          background-color rgba(251, 45, 127, 1)
      span
        margin-right 20px
        cursor pointer
        &:hover
          border-bottom 1px dashed
</style>
