<template>
  <div v-if="items.length" class="reviews">
    <div class="items">
      <div
        v-for="item in items"
        :key="`review-${item.reviewId}`"
        :data-id="item.reviewId"
        :class="item.reviewType ? item.reviewType.toLowerCase() : ''"
        class="item"
      >
        <div class="header">
          <div class="poster">
            <img src="/static_files/icons/default-cast.svg" :alt="item.reviewAutor">
          </div>
          <div>
            <div>
              <strong>{{ item.reviewAutor }}</strong> &bull;
              <span>{{ item.reviewData | filterDate }}</span>
            </div>
            <strong class="title">{{ item.reviewTitle }}</strong>
          </div>
        </div>
        <p>{{ item.reviewDescription }}</p>
        <div class="shadow" />
        <button @click="showFullReview(item.reviewId)">
          Читать полностью
          <i class="arrow" />
        </button>
      </div>
    </div>
    <div v-if="items.length >= 10" class="show-more">
      <button @click="getData(++page)">
        {{ $t('show_more') }}
        <i v-if="!loading" class="arrow" />
        <i v-else class="loader" />
      </button>
    </div>
  </div>
  <div v-else-if="noData"><p>Материалы не найдены</p></div>
  <div v-else><i class="loader" /></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: { kpid: { type: String, require: true, default: '' } },
  filters: {
    filterDate(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(val).getDate()
      const month = new Date(val).getMonth()
      const year = new Date(val).getFullYear()
      const hour =
        new Date(val).getHours() >= 10
          ? new Date(val).getHours()
          : '0' + new Date(val).getHours()
      const min =
        new Date(val).getMinutes() >= 10
          ? new Date(val).getMinutes()
          : '0' + new Date(val).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: mapState(['user']),
  data() {
    return {
      items: [],
      page: 1,
      gets: {},
      loading: false,
      noData: false
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.user || (this.user.group !== 777 && this.user.group !== 0)) {
        const reviewsList = document.querySelectorAll('.reviews .items .item')

        if (!reviewsList.length || reviewsList.length < 3) return

        const rsyaReviews = document.createElement('script')
        rsyaReviews.innerHTML = ``

        for(let i = 0,  j = 0; i < reviewsList.length; i++) {
          if (!(i % 4)) {
            console.log('i', i)

            ++j

            const div = document.createElement('div')
            div.id = `yandex_rtb_R-A-1740435-11-${j}`
            div.style.margin = '20px 0px'
            
            reviewsList[i].after(div)

            rsyaReviews.innerHTML += `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-1740435-11-${j}',
    blockId: 'R-A-1740435-11',
    pageNumber: ${j}
  })
})`
          }
        }

        document.body.appendChild(rsyaReviews)
      }
    }, 1000)
  },
  methods: {
    showFullReview(id) {
      const elem = document.querySelector(`[data-id="${id}"]`)
      if (elem) elem.classList.add('full')
    },
    async getData(page) {
      if (this.gets.getData) return
      this.gets.getData = true
      this.loading = true
      const { data } = await this.$axios.get(`/api/filmdata/reviews?kpid=${this.kpid}&page=${page}`)
      const items = this.items.concat(data.items)
      this.items = items
      this.loading = false
      delete this.gets.getData
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/filmdata/reviews?kpid=${this.kpid}&page=${1}`)
    if (!data.items.length) {
      this.noData = true
    } else {
      this.items = data.items
    }
  }
}
</script>

<style lang="stylus">
.reviews
  .items
    // display: grid
    // grid-template-columns: repeat(2, 1fr)
    // grid-gap: 40px 32px
    .item
      position: relative
      margin-bottom: 40px
      padding: 10px 10px 20px
      border-radius: 4px
      overflow: hidden
      border-bottom: 2px solid rgba(255, 255, 255, 0.1)
      &.full
        p
          height: auto
        .shadow
        button
          display: none
      button
        display: inline-block
        padding: 0
        font-size: 1.4rem
        opacity: 0.55
        background-color: transparent
        border: none
        cursor: pointer
        &:hover
          opacity: 0.75
      i
        display: inline-block
        width: 14px
        height: 14px
        background-image: url('/static_files/icons/chevron-right-dark.svg')
        background-size: 14px
        transform: rotateZ(90deg)
        vertical-align: middle
      &.negative
        // border: 2px solid #ffe3e3
        // background-color: #ffe3e3
        &::before
          display: block
          content: ''
          position: absolute
          left: -1px
          top: 0px
          background-color: #ffe3e3
          width: 12px
          height: 12px
        .shadow
          position: relative
          background-color: #100e19
          box-shadow: 0 -4px 40px 40px #100e19
      &.positive
        // border: 2px solid #daf1db
        // background-color: #daf1db
        &::before
          display: block
          content: ''
          position: absolute
          left: -1px
          top: 0px
          background-color: #daf1db
          width: 12px
          height: 12px
        .shadow
          position: relative
          background-color: #100e19
          box-shadow: 0 -4px 40px 40px #100e19
      &.neutral
        // border: 2px solid #d5d5d5
        // background-color: #d5d5d5
        &::before
          display: block
          content: ''
          position: absolute
          left: -1px
          top: 0px
          background-color: #d5d5d5
          width: 12px
          height: 12px
        .shadow
          position: relative
          background-color: #100e19
          box-shadow: 0 -4px 40px 40px #100e19
      .header
        margin-bottom: 20px
        display: grid
        grid-template-columns: 52px auto
        grid-gap: 10px
        align-items: center
        .poster
          position: relative
          width: 52px
          height: 52px
          background-color: #f1f1f1
          border-radius: 50%
          overflow: hidden
          img
            width: 100%
            height: 100%
        strong
          font-size: 1.4rem
        span
          margin-left: 4px
          font-size: 1.4rem
          color: rgba(255, 255, 255, 0.59)
        .title
          display: block
          margin: 4px 0 4px
          font-size: 1.6rem
      p
        line-height: 2.8rem
        height: 110px
        overflow: hidden
        white-space: pre-wrap
        word-wrap: break-word
      .shadow
        position: relative
        background-color: #fff
        box-shadow: 0 -4px 40px 40px #fff
</style>
