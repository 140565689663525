<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <section class="Unsubs">
      <input type="button" value="Get Unsubs" @click="getData">
      <h1>Unsubs</h1>

      <div>
        <div v-for="item in items" :key="`email-${item.date}`">
          {{ item }}
          <button @click="deleteUnsub(item.email)">DELETE</button>
        </div>
      </div>
    </section>
    <section>
      <h2>Unsub</h2>
      <input type="text" v-model="unsubEmail" />
      <button @click="addToUnsub">ADD</button>
      <button @click="deleteUnsub(unsubEmail)">DELETE</button>
    </section>
  </main>
  <main v-else>
    <h1>Access denied</h1>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    filterDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      items: [],
      unsubEmail: ''
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async deleteUnsub(email) {
      if (!email) return

      const body = {
        email: email
      }
      const { data } = await this.$axios.post(`/api/admin/unsub/delete?key=${this.key}`, body)
      console.log(data)
      this.getData()
    },
    async addToUnsub() {
      if (!this.unsubEmail) return

      const body = {
        email: this.unsubEmail
      }
      const { data } = await this.$axios.post(`/api/admin/unsub?key=${this.key}`, body)
      console.log(data)
      this.getData()
    },
    async getData() {
      const { data } = await this.$axios.get(
        `/api/admin/unsub?key=${this.key}`
      )
      // console.log('data', data)
      if (data.length) {
        this.items = data
      } else {
        console.log('unsub not found')
      }
    }
  }
}
</script>

