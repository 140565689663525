<template>
  <main class="content">
    <section>
      <h1>Список тегов</h1>
      <div class="list">
        <nuxt-link
          v-for="tag in tags"
          :key="`tag-${tag.id}`"
          :to="`/tag/${tag.id}-${tag.name}`"
          class="item"
        />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  async asyncData({ $axios, query, redirect }) {
    if (Object.keys(query).length) {
      return redirect(`/`)
    }
    const { data } = await $axios.get('/api/articles/tags')
    return {
      tags: data
    }
  }
}
</script>