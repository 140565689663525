<template>
  <main>
    <section>
      <h1>Проверка перевода...</h1>
    </section>
  </main>
</template>

<script>
export default {
  head() {
    return {
      title: `Подтверждение перевода`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://unotalone.su/dobro/success'
        }
      ]
    }
  },
  layout: 'clean',
  asyncData({ query }) {
    return {
      billId: query.bid
    }
  },
  mounted() {
    setTimeout(() => {
      this.getBillInfo()
    }, 1000)
  },
  methods: {
    async getBillInfo() {
      const { data } = await this.$axios.get(`/api/p2p/billinfo?id=${this.billId}`)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
      } else if (data.status === 'ok') {
        this.$store.commit('setError', { status: 'ok', msg: `Перевод успешно совершен` })
      }
      setTimeout(() => {
        this.$store.commit('clearError')
        window.close()
      }, 2000)
    }
  }
}
</script>
