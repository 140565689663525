<template>
  <main class="popular">
    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link :to="localePath('/')" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_home') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog`)" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_catalog') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <span itemprop="name">{{ $t('breadcrumbs_popular') }}</span>
        <meta itemprop="position" content="3">
      </div>
    </div>

    <section>
      <h1>{{ $t('popular_h1') }}</h1>
      <!-- <h1>{{ $t('popular_h1') }} {{ month }} {{ new Date().getFullYear() }}</h1> -->

      <VideoComponent :videos="items.slice(0, 24 * videoI)" />
      <div
        @click="++videoI"
        class="show-more"
      >
        <button>{{ $t('show_more') }} <i class="arrow" /></button>
      </div>

      <div class="desc">
        <p>{{ $t('popular_desc') }}</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  head() {
    const title = this.$t('popular_head_title') + new Date().getFullYear()
    const description = this.$t('popular_head_description') + new Date().getFullYear()

    return {
      title: title,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: 'https://unotalone.su/popular'
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `топ лучшего кино`
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://unotalone.su/popular'
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description,
        }
      ]
    }
  },
  async asyncData({ $axios, error }) {
    const { data } = await $axios.get(`/api/popular`)
    if (data.error) {
      error(data.error)
    } else {
      const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
      return {
        items: data,
        videoI: 1,
        month: months[new Date().getMonth()]
      }
    }
  },
}
</script>

<style lang="stylus">
.popular
  padding: 10px
  .desc
    margin-top: 60px
    p
      margin: 10px 0px
  h1
    margin: 20px 0 20px
</style>
