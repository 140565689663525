<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <section class="commnts">
      <input type="button" value="Get comments" @click="getData">

      <h1>Комментарии</h1>

      <div v-if="comments.length" class="list">
        <article
          v-for="(comment, index) in comments"
          :key="index"
        >
          <header>
            <a
              :href="`/${
                comment.id.slice(0, comment.id.lastIndexOf('-'))
              }/${
                comment.id.slice(comment.id.lastIndexOf('-') + 1)
              }`"
              target="_blank"
            >
              <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${comment.id.slice(comment.id.indexOf('-') + 1)}.jpg`" alt="Film poster">
            </a>
            <img :src="comment.user_avatar || '/static_files/noavatar.svg'" alt="user avatar">
            <strong>{{ comment.user_name }}</strong>
            <time
              :datetime="new Date(comment.date).getFullYear() + '-' + new Date(comment.date).getMonth() + '-' + new Date(comment.date).getDate()"
              class="time"
            >
              {{ comment.date | dateFilter }}
            </time>
          </header>
          <p>{{ comment.text }}</p>
          <span style="rgba(251, 45, 127, 1);" @click="action(comment, 'delete')">Удалить</span>
        </article>
      </div>
    </section>
  </main>
  <main v-else>
    <h1>Access denied</h1>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    dateFilter(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      comments: []
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async getData() {
      const { data } = await this.$axios.get(
        `/api/admin/comments?key=${this.key}`
      )
      // console.log('data', data)
      if (data.length) {
        this.comments = data
      } else {
        console.log('Comments not found')
      }
    },
    async action(comment, action) {
      console.log(comment, action)
      if (action === 'delete') {
        await this.$axios.delete(
          `/api/admin/comments?key=${this.key}&id=${comment._id}`
        )
      }
    }
  }
}
</script>

<style lang="stylus">
.cp
  .commnts
    .list article
      padding 10px
      margin 10px 0
      header
        img
          width 50px
          height 50px
      p
        white-space pre-wrap
        word-wrap break-word
        margin-top 10px
</style>
