<template>
  <div class="chat">
    <div v-if="user">
      <audio ref="chatNotifi">
        <source src="/static_files/chat_notifi.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
      <audio ref="snowball">
        <source src="/static_files/6b6ecb47a88075b.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
    <!-- <audio id="audio" controls style="display:block;position:absolute;top: 20px;z-index:1;right:0px;"></audio> -->

    <div id="yandex_rtb_R-A-6711067-6-2" class="rsya-inf"></div>

    <div v-if="showShare" class="share-popup">
      <button @click="showShare = false" class="wi mini">
        <img src="/static_files/icons/up-arrow.png" alt="">
        {{ $t('close') }}
      </button>
      <div>
        <input
          :data-share-id="room.id"
          :value="`${room.id === 'global' ? `https://unotalone.su/chat` : `https://unotalone.su/vmeste/${room.id}`}`"
          type="text"
          readonly
        />
        <button @click="filmShare">{{ $t('share') }}</button>
      </div>
      <div v-if="friends.length">
        <p style="margin: 10px 0px;font-size:1.4rem; opacity: 0.75;">
          {{ $t('chat_invite_p') }}
        </p>
        <div v-for="item in friends" :key="`share-friend-${item._id}`">
          {{ item.name }}
          <button @click="inviteFriend(item._id)" class="mini">{{ $t('invite') }}</button>
        </div>
      </div>
      <div v-else>
        <p style="margin: 10px 0px;font-size:1.4rem;">
          {{ $t('chat_friendlist_empty') }}
          <nuxt-link :to="localePath('/chat')">{{ $t('chat_start_chat1') }}</nuxt-link> {{ $t('chat_start_chat2') }}
        </p>
      </div>
    </div>

    <div class="chat-header">
      <template v-if="event.header && !$route.name.includes('profile-friendly')">
        <i
          @click="mute = !mute"
          class="icon-mute"
          :class="{ 'active': mute }"
          title="Заглушить звук новых сообщений"
        />

        <div @click="showShare = true" class="film-share">
          <div class="btn"></div>
        </div>
        <!-- <i @click="voiceText = !voiceText" class="icon-voice" :class="{ 'active': voiceText }" /> -->
      </template>

      <div v-if="members.users.length" class="members-online">
        <ul
          :style="!Object.keys(playerTimers).length ? `width:${38 * members.users.length}px;` : `width:${86 * members.users.length}px;`"
        >
          <li
            v-for="item in members.users"
            :key="`member-${item.user.id}`"
            :data-user-id="item.user.id"
            :title="item.user.name"
            class="item"
            :class="{'wtime': $route.name.includes('vmeste-id') && !!playerTimers[item.user.id]}"
          >
            <div
              class="wrapper"
              :class="{
                'owner': room.owner && room.owner.id === item.user.id,
                'premium': item.user.group === 777
              }"
            >
              <div class="avatar">
                <img :src="item.user.photo" :alt="item.user.name">
              </div>
            </div>
            <span v-if="room.owner && playerTimers[item.user.id]">
              {{ playerTimers[item.user.id] | filterActionTimeline }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="chat-area">
      <div v-if="currentUser" class="short-user-menu">
        <button @click="currentUser = null" class="wi">
          <img src="/static_files/icons/up-arrow.png" alt="">
          {{ $t('close') }}
        </button>
        <button
          v-if="user && user?.friends.includes(currentUser.id)"
          @click="deleteFriend(currentUser.id)"
          class="wi"
        >
          <img src="/static_files/icons/delete-friend.png" alt="">
          {{ $t('friends_delete') }}
        </button>
        <button v-else @click="addFriend(currentUser.id)" class="wi">
          <img src="/static_files/icons/add-friend.png" alt="">
          {{ $t('befriends') }}
        </button>
        <button @click="whisper(currentUser)" class="wi">
          <img src="/static_files/icons/sending.png" alt="">
          {{ $t('pm') }}
        </button>
        <!-- <button @click="sendPMUser = user._id" class="blue">Написать письмо</button> -->
      </div>

      <div class="site-hint hidechatcommands">
        <i @click="closeHint('hidechatcommands')" />
        <img src="/static_files/icons/application-2.png" alt="" style="width:16px;height:16px;">
        {{ $t('chat_commands') }}
      </div>

      <div v-if="!isDesktop" style="position: relative;">
        <nuxt-link :to="localePath(`/signin?ref=${encodeURI($route.fullPath)}`)" tag="div" v-if="!user" class="chat-login">
          <strong>{{ $t('signIn') }}</strong>
        </nuxt-link>
        <div v-else-if="adBlock" class="chat-login">
          <strong>
            Доступ к чату ограничен.
            <nuxt-link to="/adblock">Отключите AdBlock</nuxt-link>
          </strong>
        </div>
        <div v-else-if="isBanned" class="chat-login">
          <strong>{{ $t('chat_banned') }}</strong>
        </div>
        <div
          v-else-if="user && !isBanned"
          class="create-message"
        >
          <div v-if="editMessageVal" class="quote">
            <div>
              <i @click="editMessageVal = null" class="cancel" />
            </div>
            <div>
              <strong>{{ $t('chat_message_edit') }}</strong>
              <p>{{ editMessageVal.text }}</p>
            </div>
          </div>
          <div v-if="messageQuote" class="quote">
            <div>
              <i @click="qouteClean" class="cancel" />
            </div>
            <div>
              <strong>{{ $t('chat_message_quote') }} | {{ messageQuote.user.name }}</strong>
              <p>{{ messageQuote.text }}</p>
            </div>
          </div>
          <span v-if="pm" class="pm">
            {{ pm.name }}
            <i @click="whisperCancel" />
          </span>
          <!-- <div v-if="addressBot && user" class="bot-commands"> -->
          <div v-if="showCommands && user" class="bot-commands">
            <div style="margin-left: 5px;">
              <label for="fr">
                <!-- <input id="fr" type="range" min="12" max="20" step="1" v-model="chatFontSize" @change="changeFontSize"> -->
                <span @click="--chatFontSize">-</span>
                <span>{{ chatFontSize }}</span>
                <span @click="++chatFontSize">+</span>
              </label>
              <select style="margin-left: 10px;" v-model="chatFontFamily" @change="changeFontFamily">
                <option value="Oswald">Шрифт</option>
                <option value="Roboto">Roboto</option>
                <option value="Open+Sans">Open Sans</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Raleway">Raleway</option>
              </select>
            </div>
            <div style="margin-left: 5px;">
              <input
                ref="fileUpload"
                type="file"
                style="display:none;"
                accept="image/gif, image/png, image/jpeg, image/jpg"
                @change="uploadFile($event)"
              >
              <div v-if="$route.name.includes('profile-friendly')" style="margin-bottom: 5px;">
                <span @click="attachFile">{{ $t('chat_attach_file') }}</span>
              </div>
              <div v-if="$route.name.includes('vmeste-id')" style="margin-top: 10px;">
                <input type="number" v-model="shareViewsCount" min="1" :max="user.views">
                <span @click="shareViews">{{ $t('chat_views_share') }}</span>
              </div>
              <div v-if="$route.name.includes('vmeste-id')" style="margin-top: 10px;">
                <span @click="throwSnowball">{{ $t('chat_throw_snowball') }}</span>
              </div>
            </div>
            <div v-if="$route.name.includes('vmeste-id')" class="reactions">
              <img @click="sendReaction('like')" src="/static_files/reactions/like.gif" alt="">
              <img @click="sendReaction('dislike')" src="/static_files/reactions/dislike.gif" alt="">
              <img @click="sendReaction('angry')" src="/static_files/reactions/angry.gif" alt="">
              <img @click="sendReaction('haha')" src="/static_files/reactions/haha.gif" alt="">
              <img @click="sendReaction('sad')" src="/static_files/reactions/sad.gif" alt="">
              <img @click="sendReaction('wow')" src="/static_files/reactions/wow.gif" alt="">
            </div>
            <!-- <template v-if="isSerial && user.id === event.owner.id">
              <span @click="sendCommand('пред')">пред</span>
              <span @click="sendCommand('след')">след</span>
            </template> -->
          </div>

          <div class="input">
            <div style="position:relative;">
              <!-- <template v-if="$route.name === 'vmeste-id'">
                <span
                  v-if="!pm && !messageQuote && !recording && !addressBot"
                  @click="address('Alon')"
                  style="position:absolute;left:3px;top:3px;cursor:pointer;color:rgba(251, 45, 127, 1);"
                >@</span>
                <span
                  v-else-if="addressBot" @click="address('Alon')"
                  style="position:absolute;left:6px;top:3px;cursor:pointer;color:rgba(251, 255, 255, 1);"
                >x</span>
              </template> -->
              <template>
                <span
                  v-if="!showCommands"
                  @click="showCommands = true"
                  style="position:absolute;left:3px;top:5px;cursor:pointer;color:rgba(251, 45, 127, 1);"
                ><img src="/static_files/icons/application-2.png" alt="" style="width:14px;height:14px;"></span>
                <span
                  v-else
                  @click="showCommands = false"
                  style="position:absolute;left:5px;top:2px;cursor:pointer;"
                >
                  <img src="/static_files/icons/cancel-dark.svg" alt="" style="width:10px;height:10px;">  
                </span>
              </template>
              <input
                v-if="!recording"
                ref="chatInputMsg"
                v-model="message"
                class="input-message"
                type="text"
                maxlength="300"
                :placeholder="!pm ? $t('chat_input_you_message') : $t('chat_input_pm') + pm.name"
                @keyup.enter="sendMessage"
              >
              <input
                v-else
                ref="chatInputMsg"
                class="input-message"
                type="text"
                :placeholder="$t('chat_input_voicemode')"
                readonly
              >
              <!-- @focus="detectInputEnter(true)"
                @blur="detectInputEnter(false)" -->
            </div>
            <i
              @click="getStikers"
              class="icon-message-smile"
              :class="{ active: showSmiles }"
              title="Стикеры"
            />
            <template v-if="message.length && !recording">
              <i
                @click="sendMessage"
                class="icon-message-send"
                title="Отправка по нажатию Enter"
              />
            </template>
            <template v-else>
              <i
                v-if="!recording"
                @click="audioCall('start')"
                class="icon-mic-on"
                title="Запись сообщения"
              />
              <i
                v-else
                @click="audioCall('stop')"
                class="icon-mic-off"
                title="Остановить запись"
              />
            </template>
            <!-- <div v-else>
              <i
                v-if="!recording"
                @click="startRecognition"
                class="icon-mic-on"
                title="Запись сообщения"
              />
              <i
                v-else
                @click="stopRecognition"
                class="icon-mic-off"
                title="Остановить запись"
              />
            </div> -->
          </div>
        </div>

        <transition name="fadeInFast">
          <div v-if="showSmiles" class="chat-smiles">
            <div class="columns">
              <span @click="smilesColumn = 1" :class="{ 'active': smilesColumn === 1 }">1</span>
              <span @click="smilesColumn = 2" :class="{ 'active': smilesColumn === 2 }">2</span>
              <span @click="smilesColumn = 3" :class="{ 'active': smilesColumn === 3 }">3</span>
              <span @click="smilesColumn = 4" :class="{ 'active': smilesColumn === 4 }">4</span>
            </div>
            <i
              @click="showSmiles = false"
              class="icon-close"
              title="Закрыть"
            />
            <div class="emoji-list" :style="`grid-template-columns:repeat(${smilesColumn}, 1fr);`">
              <img
                v-for="item in stikers[smilesCat].items"
                :key="`chat-emoji-${item}`"
                :data-src="`/static_files/stikers/${stikers[smilesCat].name}/${item}`"
                @click="appendEmoji(`${stikers[smilesCat].name}/${item}`)"
                src="/static_files/no-poster.png"
                class="lazyload big"
              />
            </div>
            <div class="emoji-cats">
              <ul :style="`width:${50*(stikers.length+2)}px`">
                <li @click="smilesCat = '-1'" :class="{ active: smilesCat === '-1' }">
                  <img :src="`/static_files/stikers/fav.webp`" alt="">
                </li>
                <li v-for="(pack, i) in stikers" :key="`pack-${pack.name}`" @click="smilesCat = i" :class="{ active: smilesCat === i }">
                  <img :src="`/static_files/stikers/${pack.name}/${pack.items[0]}`" alt="">
                </li>
                <li v-if="user && user.group === 1" @click="botMsg(`Чтобы открыть дополнительные наборы стикеров, необходимо подключить Premium подписку.`)">
                  <img :src="`/static_files/stikers/request.png`" alt="">
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      
      <ul  class="messages">
        <li v-if="isDesktop && messages.length >= 20" @click="getMessages(++page)" class="show-more">
          <button>{{ $t('show_more') }}</button>
        </li>
        <li
          v-for="m in messages"
          :key="`chat-message-${m.date}`"
          :data-message-id="m.date"
          :class="{ 'active': user && user.id === m.user.id }"
          class="message"
        >
          <div class="li-wrapper">
            <div v-if="!user || (user && user.id !== m.user.id)" class="leftside">
              <div class="wrapper" :class="{ 'premium': m.user.group === 777 || m.user.group === -1 }">
                <div @click="showNicknames = !showNicknames" class="avatar">
                  <img :src="m.user.photo" :alt="m.user.name">
                </div>
              </div>
              <!-- <i v-if="user && (user.group === 0 || user.id === m.user.id)" @click="showActions(m.date)" class="actions-btn" /> -->
            </div>

            <div class="body">
              <div v-if="m.quote" class="quote">
                <strong>@{{ m.quote.user.name }}</strong>
                <p>{{ m.quote.text }}</p>
              </div>

              <div v-if="showNicknames" style="display: inline-block;">
                <div v-if="user && user.id !== m.user.id" class="device">
                  <i v-if="m.device === 'ios'" class="ios" />
                  <i v-else-if="m.device === 'android'" class="android" />
                  <i v-else-if="m.device === 'windows'" class="windows" />
                </div>
                
                <b v-if="user && m.pm && m.pm.id === user.id" style="color:deeppink;">
                  [{{ $t('chat_write_to_you') }}, <strong @click="showShortUserMenu($event, m.user)" class="author">{{ m.user.name }}</strong>]
                </b>
                <b v-else-if="user && m.pm && m.user.id === user.id" style="color:deeppink;">
                  [{{ $t('chat_you_write') }}, <strong @click="showShortUserMenu($event, m.user)" class="author">{{ m.pm.name }}</strong>]
                </b>
                <b v-else>
                  <!-- {{ room.id === 'global' ? `[Общая комната: ` : '' }} -->
                  <strong v-if="user && user.id !== m.user.id" @click="showShortUserMenu($event, m.user)" class="author">{{ m.user.name }}</strong>
                  <strong v-else-if="!user || user.id !== m.user.id">{{ m.user.name }}</strong>
                  <!-- {{ room.id === 'global' ? `]` : '' }} -->
                </b>
                <!-- <sup v-if="room.owner === m.user.id" class="green" title="Владелец комнаты">Владелец</sup> -->
                <template v-if="!user || user.id !== m.user.id">
                  <span v-if="m.user.group === 0" class="group admin" title="Администратор">{{ $t('chat_admin') }}</span>
                  <span v-else-if="m.user.group === -1" class="group bot" title="Бот">{{ $t('chat_bot') }}</span>
                  <span v-if="user && user.id !== m.user.id" style="display:inline-block;"></span>
                </template>
              </div>
              <p class="text" :style="`font-size:${chatFontSize}px;`">{{ m.text }}</p>

              <div v-if="m.user.group !== -1" class="footer">
                <div v-if="m.date" class="time">
                  {{ m.date | filterDate }}
                  {{ m.date | filterTime }}
                </div>

                <div v-if="user" class="actions">
                  <span v-if="user.id !== m.user.id" @click="quote(m)">{{ $t('to_answer') }}</span>
                  <span v-if="user.id === m.user.id" @click="editMessage(m)">{{ $t('to_edit') }}</span>
                  <span v-if="user.id === m.user.id || user.group === 0" @click="deleteMessage(m)">{{ $t('to_delete') }}</span>
                  <span v-if="banMembersList.includes(m.user.id) && (user.group === 0 || room.owner === user.id)"
                    @click="unbanMember(m)"
                    style="font-size:1.2rem;cursor:pointer;"
                  >
                    {{ $t('chat_unban') }}
                  </span>
                  <span v-else-if="!banMembersList.includes(m.user.id) && (user.group === 0 || room.owner === user.id)"
                    @click="banMember(m)"
                    style="font-size:1.2rem;cursor:pointer;"
                  >
                    {{ $t('chat_ban') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li v-if="!isDesktop && messages.length >= 20" @click="getMessages(++page)" class="show-more">
          <button>{{ $t('show_more') }}</button>
        </li>
      </ul>

      <div v-if="isDesktop" style="position: relative;">
        <nuxt-link :to="localePath(`/signin?ref=${encodeURI($route.fullPath)}`)" tag="div" v-if="!user" class="chat-login">
          <strong>{{ $t('signIn') }}</strong>
        </nuxt-link>
        <div v-else-if="adBlock" class="chat-login">
          <strong>
            Доступ к чату ограничен.
            <nuxt-link to="/adblock">Отключите AdBlock</nuxt-link>
          </strong>
        </div>
        <div v-else-if="isBanned" class="chat-login">
          <strong>{{ $t('chat_banned') }}</strong>
        </div>
        <div
          v-else-if="user && !isBanned"
          class="create-message"
        >
          <div v-if="editMessageVal" class="quote">
            <div>
              <i @click="editMessageVal = null" class="cancel" />
            </div>
            <div>
              <strong>{{ $t('chat_message_edit') }}</strong>
              <p>{{ editMessageVal.text }}</p>
            </div>
          </div>
          <div v-if="messageQuote" class="quote">
            <div>
              <i @click="qouteClean" class="cancel" />
            </div>
            <div>
              <strong>{{ $t('chat_message_quote') }} | {{ messageQuote.user.name }}</strong>
              <p>{{ messageQuote.text }}</p>
            </div>
          </div>
          <span v-if="pm" class="pm">
            {{ pm.name }}
            <i @click="whisperCancel" />
          </span>
          <!-- <div v-if="addressBot && user" class="bot-commands"> -->
          <div v-if="showCommands && user" class="bot-commands">
            <div style="margin-left: 5px;">
              <label for="fr">
                <!-- <input id="fr" type="range" min="12" max="20" step="1" v-model="chatFontSize" @change="changeFontSize"> -->
                <span @click="--chatFontSize">-</span>
                <span>{{ chatFontSize }}</span>
                <span @click="++chatFontSize">+</span>
              </label>
              <select style="margin-left: 10px;" v-model="chatFontFamily" @change="changeFontFamily">
                <option value="Oswald">Шрифт</option>
                <option value="Roboto">Roboto</option>
                <option value="Open+Sans">Open Sans</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Raleway">Raleway</option>
              </select>
            </div>
            <div style="margin-left: 5px;">
              <input
                ref="fileUpload"
                type="file"
                style="display:none;"
                accept="image/gif, image/png, image/jpeg, image/jpg"
                @change="uploadFile($event)"
              >
              <div v-if="$route.name.includes('profile-friendly')" style="margin-bottom: 5px;">
                <span @click="attachFile">{{ $t('chat_attach_file') }}</span>
              </div>
              <div v-if="$route.name.includes('vmeste-id')" style="margin-top: 10px;">
                <input type="number" v-model="shareViewsCount" min="1" :max="user.views">
                <span @click="shareViews">{{ $t('chat_views_share') }}</span>
              </div>
              <div v-if="$route.name.includes('vmeste-id')" style="margin-top: 10px;">
                <span @click="throwSnowball">{{ $t('chat_throw_snowball') }}</span>
              </div>
            </div>
            <div v-if="$route.name.includes('vmeste-id')" class="reactions">
              <img @click="sendReaction('like')" src="/static_files/reactions/like.gif" alt="">
              <img @click="sendReaction('dislike')" src="/static_files/reactions/dislike.gif" alt="">
              <img @click="sendReaction('angry')" src="/static_files/reactions/angry.gif" alt="">
              <img @click="sendReaction('haha')" src="/static_files/reactions/haha.gif" alt="">
              <img @click="sendReaction('sad')" src="/static_files/reactions/sad.gif" alt="">
              <img @click="sendReaction('wow')" src="/static_files/reactions/wow.gif" alt="">
            </div>
            <!-- <template v-if="isSerial && user.id === event.owner.id">
              <span @click="sendCommand('пред')">пред</span>
              <span @click="sendCommand('след')">след</span>
            </template> -->
          </div>

          <div class="input">
            <div style="position:relative;">
              <!-- <template v-if="$route.name === 'vmeste-id'">
                <span
                  v-if="!pm && !messageQuote && !recording && !addressBot"
                  @click="address('Alon')"
                  style="position:absolute;left:3px;top:3px;cursor:pointer;color:rgba(251, 45, 127, 1);"
                >@</span>
                <span
                  v-else-if="addressBot" @click="address('Alon')"
                  style="position:absolute;left:6px;top:3px;cursor:pointer;color:rgba(251, 255, 255, 1);"
                >x</span>
              </template> -->
              <template>
                <span
                  v-if="!showCommands"
                  @click="showCommands = true"
                  style="position:absolute;left:3px;top:5px;cursor:pointer;color:rgba(251, 45, 127, 1);"
                ><img src="/static_files/icons/application-2.png" alt="" style="width:14px;height:14px;"></span>
                <span
                  v-else
                  @click="showCommands = false"
                  style="position:absolute;left:5px;top:2px;cursor:pointer;"
                >
                  <img src="/static_files/icons/cancel-dark.svg" alt="" style="width:10px;height:10px;">  
                </span>
              </template>
              <input
                v-if="!recording"
                ref="chatInputMsg"
                v-model="message"
                class="input-message"
                type="text"
                maxlength="300"
                :placeholder="!pm ? $t('chat_input_you_message') : $t('chat_input_pm') + pm.name"
                @keyup.enter="sendMessage"
              >
              <input
                v-else
                ref="chatInputMsg"
                class="input-message"
                type="text"
                :placeholder="$t('chat_input_voicemode')"
                readonly
              >
              <!-- @focus="detectInputEnter(true)"
                @blur="detectInputEnter(false)" -->
            </div>
            <i
              @click="getStikers"
              class="icon-message-smile"
              :class="{ active: showSmiles }"
              title="Стикеры"
            />
            <template v-if="message.length && !recording">
              <i
                @click="sendMessage"
                class="icon-message-send"
                title="Отправка по нажатию Enter"
              />
            </template>
            <template v-else>
              <i
                v-if="!recording"
                @click="audioCall('start')"
                class="icon-mic-on"
                title="Запись сообщения"
              />
              <i
                v-else
                @click="audioCall('stop')"
                class="icon-mic-off"
                title="Остановить запись"
              />
            </template>
            <!-- <div v-else>
              <i
                v-if="!recording"
                @click="startRecognition"
                class="icon-mic-on"
                title="Запись сообщения"
              />
              <i
                v-else
                @click="stopRecognition"
                class="icon-mic-off"
                title="Остановить запись"
              />
            </div> -->
          </div>
        </div>

        <transition name="fadeInFast">
          <div v-if="showSmiles" class="chat-smiles">
            <div class="columns">
              <span @click="smilesColumn = 1" :class="{ 'active': smilesColumn === 1 }">1</span>
              <span @click="smilesColumn = 2" :class="{ 'active': smilesColumn === 2 }">2</span>
              <span @click="smilesColumn = 3" :class="{ 'active': smilesColumn === 3 }">3</span>
              <span @click="smilesColumn = 4" :class="{ 'active': smilesColumn === 4 }">4</span>
            </div>
            <i
              @click="showSmiles = false"
              class="icon-close"
              title="Закрыть"
            />
            <div class="emoji-list" :style="`grid-template-columns:repeat(${smilesColumn}, 1fr);`">
              <img
                v-for="item in stikers[smilesCat].items"
                :key="`chat-emoji-${item}`"
                :data-src="`/static_files/stikers/${stikers[smilesCat].name}/${item}`"
                @click="appendEmoji(`${stikers[smilesCat].name}/${item}`)"
                src="/static_files/no-poster.png"
                class="lazyload big"
              />
            </div>
            <div class="emoji-cats">
              <ul :style="`width:${50*(stikers.length+2)}px`">
                <li @click="smilesCat = '-1'" :class="{ active: smilesCat === '-1' }">
                  <img :src="`/static_files/stikers/fav.webp`" alt="">
                </li>
                <li v-for="(pack, i) in stikers" :key="`pack-${pack.name}`" @click="smilesCat = i" :class="{ active: smilesCat === i }">
                  <img :src="`/static_files/stikers/${pack.name}/${pack.items[0]}`" alt="">
                </li>
                <li v-if="user && user.group === 1" @click="botMsg(`Чтобы открыть дополнительные наборы стикеров, необходимо подключить Premium подписку.`)">
                  <img :src="`/static_files/stikers/request.png`" alt="">
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

/* eslint-disable */
// let mountedIndexChat = null

export default {
  props: { event: { type: Object, require: true, default: () => ({ id: null, owner: null, header: true }) } },
  filters: {
    filterActionTimeline(val) {
      if (!val) return
      val = Number(val);
      const h = Math.floor(val / 3600)
      const m = Math.floor(val % 3600 / 60)
      const s = Math.floor(val % 3600 % 60)

      const hDisplay = h >= 10 ? h : '0' + h
      const mDisplay = m >= 10 ? m : '0' + m
      const sDisplay = s >= 10 ? s : '0' + s
      return hDisplay + ":" + mDisplay + ":" + sDisplay 
    },
    filterTime(value) {
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      const sec =
        new Date(value).getSeconds() >= 10
          ? new Date(value).getSeconds()
          : '0' + new Date(value).getSeconds()
      return `${hour}:${min}:${sec}`
    },
    filterDate(value) {
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1 < 10 ? `0${new Date(value).getMonth() + 1}` : new Date(value).getMonth() + 1
      const year = '' + new Date(value).getFullYear()
      return `${day}.${month}.${year.slice(2)}`
    },
  },
  watch: {
    mute(val) {
      let str = `Звук уведомлений чата `
      if (!val) {
        str += 'включен'
      } else {
        str += 'выключен'
      }
      this.botMsg(str)
    },
    chatFontSize(val) {
      if (val < 11) {
        this.chatFontSize = 24
      } else if (val > 24) {
        this.chatFontSize = 11
      }

      // const chatMessages = document.querySelectorAll('.chat .messages .body p')
      // chatMessages.forEach(el => {
      //   el.style.fontSize = `${val}px`
      // })
    },
    tabActive(val) {
      if (!val) {
        // this.routeLeave()
      } else {
        this.routeEnter()
        // clearTimeout(this.timeouts.tabMinimize)
        // this.timeouts.tabMinimize = setTimeout(() => {
        //   this.botMsg(`Вы переключали вкладку и были переподключены.`)
        // }, 2 * 1000)
      }
    },
    whisperStore() {
      if (this.whisperStore) this.whisper(this.whisperStore)
    },
    showShare(val) {
      if (val) this.getFriendList()
    },
    // user() {
    //   this.initialize()
    // },
    changeRoute() {
      if (this.titleInterval) clearInterval(this.titleInterval)
    },
    sendPMUser() {
      if (this.sendPMUser) {
        this.$nextTick(() => {
          const modal = document.querySelector('.popup .modal')
          if (modal) {
            modal.style.marginTop = `-${modal.offsetHeight / 2}px`
          }
        })
      }
    },
    smilesCat(val) {
      setTimeout(() => {
        const smilesList = document.querySelector('.emoji-list')
        if (smilesList) {
          // this.lazyLoadImgs()
          // smilesList.removeEventListener('scroll', this.lazyLoadImgs)
          // smilesList.addEventListener('scroll', this.lazyLoadImgs)
          smilesList.scrollTo(0, 0)
        }
      }, 1000)
    },
    showSmiles(val) {
      setTimeout(() => {
        const smilesList = document.querySelector('.emoji-list')
        if (smilesList) {
          // this.lazyLoadImgs()
          // smilesList.removeEventListener('scroll', this.lazyLoadImgs)
          // smilesList.addEventListener('scroll', this.lazyLoadImgs)
        }
      }, 1000)
    },
    message(val) {
      if (!val) return
      this.addressBot = val.match(/^@Alon, /)
      if (val.includes('[::/link::]')) return
      const findSelfUrl = val.includes('https://unotalone.su')
      if (findSelfUrl) {
        const beforeUrl = val.slice(0, val.indexOf('https://unotalone.su'))
        val = val.replace(beforeUrl, '')
        const selfUrl = val.slice(0, val.indexOf(' ') + 1)
        val = val.replace(selfUrl, '')
        const afterUrl = val
        if (!selfUrl && afterUrl) {
          const wrapUrl = `[::link="${afterUrl}"::]Перейти[::/link::]`
          this.message = wrapUrl
        }
      }
    }
  },
  computed: {
    ...mapState(['user', 'isDesktop', 'whisperStore', 'inviteToStream', 'tabActive', 'playerTimers']),
    changeRoute() {
      return this.$route.fullPath
    },
    isSerial() {
      const elem = document.querySelector('[data-type]')
      if (!elem) return false
      return elem.dataset.type === '2' || elem.dataset.type === '4'
    }
  },
  data() {
    const route = this.$route
    const globalChat = !(
      route.name === 'tv' ||
      route.name === 'cinema-id' ||
      route.name === 'chat'
    )

    let room = { id: 'global', owner: null, title: 'Общая' }
    let messages = []

    if (this.event.id) {
      room = this.event
    }

    return {
      friends: [],
      showNicknames: true,
      chatFontSize: 15,
      chatFontFamily: 'Oswald',
      showCommands: false,
      timeouts: {
        tabAlert: null,
        tabMinimize: null
      },
      lastMessage: '',
      smilesColumn: 3,
      stikers: [],
      smilesCat: 0,
      socketId: null,
      mute: false,
      isBanned: false,
      showMembers: {
        total: false,
        inroom: false
      },
      globalChat,
      isChat: route.name === 'chat',
      room,
      messages,
      message: '',
      messageQuote: '',
      pm: null,
      showSmiles: false,
      membersD: null,
      members: {
        users: [],
        count: 0
      },
      messagesUnread: 0,
      gets: {},
      pageTitle: null,
      notifications: [],
      sendPMUser: null,
      PMtext: '',
      showShare: false,
      showRoomsList: false,
      spamMembersList: [],
      banMembersList: [],
      titleInterval: null,
      messagesPush: [],
      adBlock: false,
      playerStart: false,
      showChatRead: false,
      page: 1,
      recognition: null,
      recording: false,
      voiceText: false,
      addressBot: false,
      editMessageVal: null,
      reactionsTimeout: null,
      throwReactionsTimeout: false,
      throwSnowballTimeout: false,
      snowballTimeout: null,
      voiceUserTimeout: null,
      currentUser: null,
      shareViewsCount: 1,
      loading: false,
    }
  },
  mounted() {
    console.log('chat mounted', Date.now())
    
    this.routeEnter()
    // this.speechRecognition()

    this.pageTitle = document.title

    if (!this.user || this.user.group === 1) {
      const rsyaInf = document.createElement('script')
      rsyaInf.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-6711067-6-2',
    blockId: 'R-A-6711067-6',
    pageNumber: 2
  })
})`
      document.body.appendChild(rsyaInf)
    }

    if (this.user && !localStorage.getItem('hidechatcommands')) {
      const hint = document.querySelector(`.site-hint.hidechatcommands`)
      if (hint) {
        hint.style.display = 'block'
      }
    }
  },
  methods: {
    inviteFriend(userId) {
      const body = {
        from: { _id: this.user.id, name: this.user.name, photo: this.user.photo },
        to: userId,
        room_id: this.room.id,
      }
      this.$socket.emit(`unotalone:rooms:invite`, body, (cb) => {
        this.botMsg(cb)
        this.showShare = false

        this.$store.commit('setError', { status: 'ok', msg: this.$t('chat_invite_sended') })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      })
    },
    async getFriendList() {
      const { data } = await this.$axios.get(`/api/user/friends`)
      if (!data.error) {
        this.friends = data
      }
    },
    closeHint(className) {
      const hint = document.querySelector(`.site-hint.${className}`)
      if (hint) {
        console.log(hint)
        localStorage.setItem(className, 'true')
        hint.style.display = 'none'
      }
    },
    changeFontFamily(val) {
      const checkFontConnect = document.querySelector(`link[data-ff="${val.target.value}"]`)

      if (!checkFontConnect) {
        const link = document.createElement('link')
        link.dataset.ff = val.target.value
        link.href = `https://fonts.googleapis.com/css2?family=${val.target.value}:wght@300;500;700&display=swap`
        link.setAttribute('rel', 'stylesheet')
        document.head.appendChild(link)
      }

      const chatMessages = document.querySelector('.chat .messages')
      chatMessages.style.fontFamily = val.target.value.replace('+', ' ')
    },
    showShortUserMenu(event, user) {
      this.currentUser = user
      this.$nextTick(() => {
        const target = event.target
        const elem = document.querySelector('.short-user-menu')
        console.log('elem', elem)
        console.log('target', target)
        if (elem) {
          elem.style.left = target.getBoundingClientRect().left + 'px'
          elem.style.top = target.getBoundingClientRect().top + 'px'
        }
      })
    },
    attachFile() {
      if (this.$route.name.includes('chat')) return
      this.$refs.fileUpload.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 5000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight, finalFile

              let imageWidth = image.width
              let imageHeight = image.height

              if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                imageHeight *= maxWidth / imageWidth
                imageWidth = maxWidth
              } else if (imageHeight > maxHeight) {
                imageWidth *= maxHeight / imageHeight
                imageHeight = maxHeight
              }

              const canvas = document.createElement('canvas')
              canvas.width = imageWidth
              canvas.height = imageHeight

              const ctx = canvas.getContext('2d')
              ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

              finalFile = canvas.toDataURL(fileType)

              // The resized file ready for upload
              const fileName = `${vm.room.id}_${Date.now()}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=files`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              setTimeout(() => vm.$store.commit('clearError'), 1000)

              vm.message = `[::img::]${data.url}[::/img::]`
              vm.sendMessage()
            }
          }
        } else {
          this.$store.commit(
            'setError',
            '.gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    async deleteFriend(userId) {
      const { data } = await this.$axios.delete(`/api/friends?user_id=${userId}`)
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
      } else {
        const index = this.user.friends.findIndex(el => el === userId)
        this.user.friends.splice(index, 1)
        this.$store.commit('setError', { status: 'ok', msg: this.$t('dont_be_friends') })
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async addFriend(userId) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
      } else {
        const { data } = await this.$axios.post('/api/friends/friendly', { to: userId })
        if (data.error) {
          this.$store.commit('setError', { status: 'info', msg: data.error.message })
        } else {
          this.friendly = data
          this.$store.commit('setError', { status: 'ok', msg: this.$t('request_sent') })
          this.$socket.emit(`unotalone:friendly`, { to: userId, from: { name: this.user.name, id: this.user.id } })
        }
      }
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    throwSnowball() {
      if (!this.$route.name.includes('vmeste-id')) {
        this.botMsg(`Доступно в комнате просмотра.`)
        return
      }
      if (this.throwSnowballTimeout) return
      this.throwSnowballTimeout = true
      this.$socket.emit(`unotalone:snowball`, { room_id: this.room.id })
      setTimeout(() => {
        this.throwSnowballTimeout = false
      }, 1000)
    },
    sendReaction(reaction) {
      if (!this.$route.name.includes('vmeste-id')) {
        this.botMsg(`Доступно в комнате просмотра.`)
        return
      }
      if (this.throwReactionsTimeout) return
      this.throwReactionsTimeout = true
      this.$socket.emit(`unotalone:reactions`, { room_id: this.room.id, reaction, photo: this.user.photo })
      setTimeout(() => {
        this.throwReactionsTimeout = false
      }, 200)
    },
    shareViews() {
      if (this.loading) return
      if (!this.user || this.user.group === 1) {
        this.$store.commit('setError', { status: 'warn', msg: 'Необходима активная Premium подписка' })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }
      if (!+this.shareViewsCount || +this.shareViewsCount < 1) {
        this.$store.commit('setError', { status: 'warn', msg: 'Неверное кол-во просмотров' })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }
      if ((this.members.users.length * this.shareViewsCount) >= this.user.views) {
        this.$store.commit('setError', { status: 'warn', msg: 'Недостаточно просмотров' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      } else {
        const body = {
          room: this.room.id,
          user: this.user.id,
          users: this.members.users,
          count: +this.shareViewsCount,
        }

        this.loading = true

        this.$socket.emit('unotalone:shareviews', body, msg => {
          this.loading = false

          const user = this.user
          user.views = user.views - ((body.users.length - 1) * body.count)

          this.$store.commit('setUser', user)

          this.$store.commit('setError', { status: 'ok', msg })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        })
      }
    },
    audioCall(action) {
      if (this.$route.name.includes('chat')) {
        this.botMsg(`Не доступно в общей комнате.`)
        return
      }
      if (this.$device.isIos) {
        this.botMsg(`IPhone? Увы, сейчас можно только говорить, слушать не получится. Ищем решение.`)
        // return
      }
      if (action === 'start') {
        this.recording = true
        const time = 1000

        navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {

        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorder.start()

        let audioChunks = []

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data)
        })

        mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks)

          audioChunks = []

          const fileReader = new FileReader()
          fileReader.readAsDataURL(audioBlob)
          fileReader.onloadend = () => {
            if (!this.recording) return
            
            const base64String = fileReader.result
            this.$socket.emit('unotalone:voice', { room: this.room.id, user_id: this.user.id, stream: base64String })
          }

          mediaRecorder.start()

          setTimeout(() => mediaRecorder.stop(), time)
        })

        setTimeout(function () {
          mediaRecorder.stop()
        }, time)
        })
      } else {
        this.recording = false
      }
    },
    speechRecognition() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
      this.recognition = new SpeechRecognition()
      this.recognition.lang ='ru-RU'
      this.recognition.interimResults = false
      this.recognition.onresult = (event) => {
        if (this.recording) return
        const last = event.results.length - 1
        const text = event.results[last][0].transcript
        this.recording = false

        const message = {
          text,
          user: {
            id: this.user.id,
            name: this.user.name || 'No name',
            photo: this.user.photo || '/static_files/noavatar.svg',
            group: this.user.group,
            patron: this.user.patron
          },
          pm: null,
          quote: null
        }

        const obj = {
          room: this.room.id,
          message
        }

        this.$socket.emit(`unotalone:message:new`, obj)
      }
    },
    showActions(id) {
      const elem = document.querySelector(`[data-message-id="${id}"] .footer .actions`)

      if (!elem) return

      if (elem.style.display === 'block') {
        elem.style.display = ''
        return
      }

      const elemsList = document.querySelectorAll(`.message .footer .actions`)
      elemsList.forEach((el) => {
        el.style.display = 'none'
      })
      elem.style.display = 'block'
    },
    sendCommand(command) {
      this.message += command
      this.sendMessage()
    },
    botReply(text) {
      const synth = window.speechSynthesis
      const utterance = new SpeechSynthesisUtterance()
      utterance.text = text
      utterance.pitch = 1
      utterance.volume = 1
      synth.speak(utterance)
    },
    stopRecognition() {
      // console.log('stopRecognition')
      this.recording = false
      this.$refs.chatInputMsg.disabled = 0
      this.message = ''

      this.recognition.stop()
    },
    startRecognition() {
      // console.log('startRecognition')
      this.recording = true
      this.recognition.start()
      this.$refs.chatInputMsg.disabled = 1
      this.message = 'Идет запись...'
    },
    keyDetect(event) {
      if (event.code === 'ArrowUp') {
        this.message = this.lastMessage
      }
    },
    address(name) {
      if (this.message && this.message.includes(`@${name}`)) {
        this.message = ''
      } else {
        this.message += `@${name}, `
        if (this.isDesktop && this.$refs.chatInputMsg) {
          this.$refs.chatInputMsg.focus()
        }
      }
    },
    getStikers() {
      if (this.stikers.length) {
        this.showSmiles = !this.showSmiles

        if (localStorage.getItem('fast_stikers07')) {
          this.stikers['-1'] = JSON.parse(localStorage.getItem('fast_stikers07'))
        }

        return
      }

      this.$socket.emit(`unotalone:stikers:get`, (cb) => {
        this.stikers = cb
        if (localStorage.getItem('fast_stikers07')) {
          this.stikers['-1'] = JSON.parse(localStorage.getItem('fast_stikers07'))
        } else {
          this.stikers['-1'] = {
            name: '',
            items: []
          }
        }
        this.showSmiles = true
      })
    },
    botMsg(text = '') {
      const message = {
        date: Date.now() + Math.random(),
        room: this.room.id,
        text,
        user: {
          id: null,
          name: 'Alon',
          photo: '/static_files/bot-avatar.jpg',
          group: -1
        }
      }
      if (this.isDesktop) {
        this.messages.push(message)
      } else {
        this.messages.unshift(message)
      }

      this.$nextTick(() => this.parseBB())
      if (this.isDesktop) setTimeout(() => this.scrollChatMessages(), 100)
    },
    async getMessages(page) {
      if (this.gets.getMessages) return
      this.gets.getMessages = true

      const { data } = await this.$axios.get(`/api/cinema/${this.room.id}/messages?page=${page}`)
      
      if (!data.length) return
      delete this.gets.getMessages
      
      let messages = []
      
      if (!this.isDesktop) {
        messages = this.messages.concat(data).sort((a, b) => a.date > b.date ? -1 : 1)
      } else {
        messages = this.messages.concat(data).sort((a, b) => a.date < b.date ? -1 : 1)
      }
      
      this.messages = messages

      this.$nextTick(() => this.parseBB())
    },
    detectInputEnter(enter) {
      if (this.isDesktop) return
      const iframe = document.querySelector('.mobile .room-page .room-page-watch.resize .event-iframe .iframe')
      const chatHeader = document.querySelector('.chat-header').getBoundingClientRect().top
      // console.log('detectInputEnter', enter, iframe)
      if (!iframe) return
      if (enter) {
        iframe.classList.add('focused')
        iframe.style.top = chatHeader + 'px'
      } else {
        iframe.classList.remove('focused')
        iframe.style.top = ''
      }
    },
    filmShare() {
      if (!this.isDesktop && navigator.share) {
        navigator.share({
          // title: `Заходи ко мне в комнату, посмотрим кино вместе!`,
          // text: `Заходи ко мне в комнату, посмотрим кино вместе!`,
          title: `https://unotalone.su/vmeste/${this.room.id}`,
          text: `https://unotalone.su/vmeste/${this.room.id}`,
          url: `https://unotalone.su/vmeste/${this.room.id}`,
        })
        .then(() => console.log('Successfully share'))
        .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('Share not supported on this browser, do it the old way.')
        const input = document.querySelector(`[data-share-id="${this.room.id}"]`)
        if (input) {
          input.select()
          document.execCommand('copy')
          this.$store.commit('setError', { status: 'ok', msg: 'Ссылка скопирована' })
          setTimeout(() => this.$store.commit('clearError'), 1000)
        }
      }
      this.botMsg(`Ссылка на комнату https://unotalone.su/vmeste/${this.room.id}`)
    },
    routeLeave() {
      this.$socket.off(`unotalone:ping`)
      this.$socket.off(`unotalone:notification`)
      this.$socket.off(`unotalone:user:ban`)
      this.$socket.off(`unotalone:user:unban`)
      this.$socket.off(`unotalone:message:new`)
      this.$socket.off(`unotalone:message:push`)
      this.$socket.off(`unotalone:members`)
      this.$socket.off(`unotalone:send`)
      this.$socket.off(`unotalone:shareviews`)
      this.$socket.off(`unotalone:reactions`)
      this.$socket.off(`unotalone:snowball`)

      const obj = {
        room: this.room.id,
        user: this.user ? this.user : null
      }

      this.$socket.emit(`unotalone:user:leave`, obj, () => {})
    },
    routeEnter() {
      const obj = {
        room: this.room.id,
        user: null
      }

      if (this.user) {
        obj.user = {
          id: this.user.id,
          name: this.user.name || 'No name',
          photo: this.user.photo || '/static_files/noavatar.svg',
          group: this.user.group,
          gender: this.user.gender,
          aura_color: this.user.aura_color,
          pet: this.user.pet,
        }
      }
      
      this.$socket.emit(`unotalone:user:leave`, obj, () => {
        // window.removeEventListener('keydown', this.keyDetect)
        // window.removeEventListener('message', this.kodikMessageListener)

        this.$socket.off(`unotalone:ping`)
        this.$socket.off(`unotalone:notification`)
        this.$socket.off(`unotalone:user:ban`)
        this.$socket.off(`unotalone:user:unban`)
        this.$socket.off(`unotalone:message:new`)
        this.$socket.off(`unotalone:message:push`)
        this.$socket.off(`unotalone:members`)
        this.$socket.off(`unotalone:send`)
        this.$socket.off(`unotalone:shareviews`)
        this.$socket.off(`unotalone:reactions`)
        this.$socket.off(`unotalone:snowball`)
        this.$socket.off(`unotalone:rooms:invite`)

        this.$socket.on('unotalone:rooms:invite', (data) => {
          this.botMsg(`${data.user.name} приглашает вас посмотреть кино. [::link="https://unotalone.su/vmeste/${data.room_id}"::]Присоединиться[::/link::]`)
        })

        this.$socket.on('unotalone:snowball', (data) => {
          const snowballArea = document.querySelector('.snowball-area')
          if (snowballArea) {
            const snowball = document.createElement('div')
            snowball.classList.add('snowball')
            snowball.classList.add(`t${data.type}`)
            snowball.style.top = data.top
            snowball.style.left = data.left
            snowballArea.append(snowball)
            if (this.$device.isIos) {
              setTimeout(() => this.$refs.snowball.play(), 950)
            } else {
              this.$refs.snowball.play()
            }
            clearTimeout(this.snowballTimeout)
            this.snowballTimeout = setTimeout(() => {
              snowballArea.innerHTML = ''
            }, 60000)
          }
        })

        this.$socket.on('unotalone:reactions', (data) => {
          const reactionsBlock = document.querySelector('.reactions-block')
          const reaction = document.createElement('div')
          reaction.classList.add('reaction')
          reaction.classList.add(data.reaction)
          const img = document.createElement('img')
          img.src = data.photo
          reaction.append(img)
          reactionsBlock.append(reaction)
          clearTimeout(this.reactionsTimeout)
          this.reactionsTimeout = setTimeout(() => {
            reactionsBlock.innerHTML = ''
          }, 6000)
        })

        this.$socket.on('unotalone:send', (data) => {
          // console.log('voice send', data)
          if (this.mute) return
          const audio = new Audio(data) // new Audio()
          // const audioSource = document.querySelector('#audio source') // new Audio()
          // audio.autoplay = true
          audio.src = data.stream

          var promise = audio.play()

          if (promise !== undefined) {
            promise.catch(error => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
            }).then(() => {
                // Auto-play started
            });
          }

          const userElem = document.querySelector(`[data-user-id="${data.user_id}"]`)
          if (userElem) {
            userElem.classList.add('active')
            clearTimeout(this.voiceUserTimeout)
            this.voiceUserTimeout = setTimeout(() => {
              userElem.classList.remove('active')
            }, 2000)
          }
        })

        this.$socket.on(`unotalone:members`, members => {
          this.members = members
          this.$store.commit('setChatUsers', members)
        })
        
        this.$socket.emit(`unotalone:user:join`, obj, (data) => {
          // console.log('data', data)
          if (data.isBanned.error) {
            this.isBanned = true
          }

          if (this.isDesktop) {
            this.messages = data.messages.sort((a, b) => a.date < b.date ? -1 : 1)
          } else {
            this.messages = data.messages.sort((a, b) => a.date > b.date ? -1 : 1)
          }

          const dateDay = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
          const dateHour = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours()

          setTimeout(() => {
            this.botMsg(`${this.$t('chat_alon_msg_1')} [::link="https://t.me/r0n1x"::]Telegram[::/link::] | [::link="https://vk.com/r0n1x"::]ВКонтакте[::/link::] @r0n1x`)
          })

          if (this.$route.name.includes('vmeste-id')) {
            setTimeout(() => {
              const domain = location.hostname === 'localhost' ? 'http://localhost:3011' : 'https://unotalone.su'
              this.botMsg(`${this.$t('chat_alon_msg_roomlink')} ${domain}/vmeste/${this.room.id}`)
            })

            setTimeout(() => {
              // this.botMsg(`Вопросы и предложения пишите в ТГ [::link="https://t.me/r0n1x"::]@r0n1x[::/link::] или ВК [::link="https://vk.com/r0n1x"::]@r0n1x[::/link::]`)
              // if (!localStorage.getItem(`push_help_${dateHour}`)) {
              //   localStorage.setItem(`push_help_${dateHour}`, 'true')
              //   this.botMsg(`Вопросы и предложения пишите в ТГ [::link="https://t.me/r0n1x"::]@r0n1x[::/link::] или ВК [::link="https://vk.com/r0n1x"::]@r0n1x[::/link::]`)
              // }

              if (!localStorage.getItem(`push_anime_${dateDay}`)) {
                localStorage.setItem(`push_anime_${dateDay}`, 'true')
                this.botMsg(`${this.$t('chat_alon_msg_anime')} [::link="https://animeast.su"::]animeast.su[::/link::].`)
              } 
            })
            
            if (this.user) {
              setTimeout(() => {
                if (this.user.group === 1 && !localStorage.getItem(`push_premium_${dateDay}`)) {
                  localStorage.setItem(`push_premium_${dateDay}`, 'true')
                  this.botMsg(`${this.$t('premium_expired')}
                  [::link="https://unotalone.su/donate"::]${this.$t('premium_update')}[::/link::] ${this.$t('chat_alon_mgs_premium_update_2')}`)
                } else if (!this.user.views && !localStorage.getItem(`push_views_${dateHour}`)) {
                  localStorage.setItem(`push_views_${dateHour}`, 'true')
                  this.botMsg(`${this.$t('viewspack_runout')}. [::link="https://unotalone.su/donate#tariffs"::]${this.$t('viewspack_update')}[::/link::]`)
                } else if (this.user.views <= 5 && !localStorage.getItem(`push_views_${dateHour}`)) {
                  localStorage.setItem(`push_views_${dateHour}`, 'true')
                  this.botMsg(`${this.$t('viewspack_soonrunout')}. [::link="https://unotalone.su/donate#tariffs"::]${this.$t('viewspack_update')}[::/link::].`)
                }
              }, 5 * 1000)

              if (this.user.group === 1 && !localStorage.getItem(`adblock_${dateHour}`)) {
                setTimeout(() => {
                  const checkAdBlock = document.querySelector('#yandex_rtb_R-A-2003630-7')
                  if (checkAdBlock && (!checkAdBlock.children.length || checkAdBlock.children[0].tagName === 'LINK')) {
                    this.botMsg(`Кажется у вас включен [::link="https://unotalone.su/adblock"::]блокировщик рекламы[::/link::]? Качество видео ограничено до 480р.`)
                  }
                  localStorage.setItem(`adblock_${dateHour}`, 'true')
                }, 10 * 1000)
              }
            }
          } else if (this.$route.name.includes('chat')) {
            if (!localStorage.getItem(`push_activity_${dateDay}`)) {
              localStorage.setItem(`push_activity_${dateDay}`, 'true')

              setTimeout(() => {
                this.botMsg(`Ежедневно проявляйте [::link="https://unotalone.su/profile/activity"::]Активность[::/link::] и получайте вознаграждения!
А также присоединяйтесь к нам в соц.сетях (в разделе Активность): там мы публикуем новинки, интересное кино и новости.
К тому же наш [::link="https://t.me/unotalone_bot"::]бот тг[::/link::] всегда подскажет кто написал вам сообщение на сайте и прислал запрос дружбы,
и даже [::link="https://unotalone.su/profile/notifi"::]уведомит[::/link::] о выходе новых серий сериала, на который вы подписаны!`)
              })
            }

            if (this.user && !this.user.friends.length) {
              setTimeout(() => {
                this.botMsg(this.$t('chat_alon_msg_2'))
              })
            }
          }
          
          this.$nextTick(() => this.parseBB())

          if (this.isDesktop) setTimeout(() => this.scrollChatMessages(), 100)

          this.$socket.on(`unotalone:ping`, (res, cb) => {
            cb(this.tabActive)
          })

          // window.addEventListener('keydown', this.keyDetect)
          this.socketId = data.socket_id

          // if (window.addEventListener) {
          //   window.addEventListener('message', this.kodikMessageListener);
          // } else {
          //   window.attachEvent('onmessage', this.kodikMessageListener);
          // }

          this.$socket.on(`unotalone:notification`, (data) => {
            // console.log('notification data', data)
            this.notifications.push(data)
            if (!this.mute && this.$refs.chatNotifi) this.$refs.chatNotifi.play()
          })

          this.$socket.on(`unotalone:shareviews`, (data) => {
            this.$store.commit('setError', { status: 'info', msg: this.$t('chat_views_were_shared_with_you') })
            setTimeout(() => {
              this.$store.commit('clearError')
              location.reload()
            }, 2000)
          })

          this.$socket.on(`unotalone:user:unban`, (data) => {
            if (this.isDesktop) {
              this.messages.push(data)
            } else {
              this.messages.unshift(data)
            }
            this.isBanned = false
            this.$nextTick(() => this.parseBB())
          })

          this.$socket.on(`unotalone:user:ban`, (data) => {
            if (this.isDesktop) {
              this.messages.push(data)
            } else {
              this.messages.unshift(data)
            }
            this.isBanned = true
            this.$nextTick(() => this.parseBB())
          })

          this.$socket.on(`unotalone:message:push`, (data) => {
            // console.log(`unotalone:message:push`, data)
            // mpTimeout = null
            if (this.mute) return
            // this.messagesPush = null
            this.$nextTick(() => {
              this.messagesPush.push(data)
              setTimeout(() => {
                const newArr = this.messagesPush.filter(el => el.message.date !== data.message.date)
                this.messagesPush = newArr
                this.$nextTick(() => this.parseBB())
              }, 5 * 1000)
            })
          })

          this.$socket.on(`unotalone:message:delete`, (data) => {
            const messages = this.messages.filter(el => {
              // if (el.date === data.date) {
              //   el.text = 'Сообщение удалено'
              // }
              return el.date !== data.date
            })

            this.messages = messages

            this.$nextTick(() => this.parseBB())
          })

          this.$socket.on(`unotalone:message:edit`, (message) => {
            const messages = this.messages.filter(el => {
              if (el.date === message.date) {
                el.text = message.text
              }
              return el
            })

            this.messages = messages

            this.$nextTick(() => this.parseBB())
          })

          this.$socket.on(`unotalone:message:new`, (message) => {
            // console.log('message:new', message)
            if (this.isDesktop) {
              this.messages.push(message)
            } else {
              this.messages.unshift(message)
            }

            const checkStiker = message.text.match(/^\[::.*::\]$/)
            const checkOwner = this.user && this.user.id === message.user.id

            if (this.voiceText && !checkStiker && !checkOwner) this.botReply(message.text)

            if (this.user && this.user.id !== message.user.id) {
              if (
                !this.mute &&
                this.$refs.chatNotifi
              ) {
                this.$refs.chatNotifi.play()
              }
            }

            const checkMsgBot = message.text.match(/^@Alon,/)

            if (checkMsgBot) {
              const msg = message.text.replace('@Alon,', '')
              if (message.user.id === this.event.owner.id) {
                if (msg.includes('след') || msg.includes('next')) {
                  this.botMsg('Переключаю на следующую серию')
                  this.$store.commit('setPlayerAction', 'next')
                } else if (msg.includes('пред') || msg.includes('prev')) {
                  this.botMsg('Переключаю на предыдущую серию')
                  this.$store.commit('setPlayerAction', 'prev')
                } else if (msg.match(/\d+/)) {
                  const episode = msg.match(/\d+/)[0]
                  this.botMsg(`Включаю ${episode} серию`)
                  this.$store.commit('setPlayerAction', `episode_${episode}`)
                } else {
                  this.botMsg(
'Сейчас я умею только переключать серии. "след" - следующая серия, "пред" - предыдущая.'
                  )
                }
              }
            }

            this.$nextTick(() => this.parseBB())
            if (this.isDesktop) setTimeout(() => this.scrollChatMessages(), 100)
          })
        })
      })
    },
    deleteMessage(m) {
      this.$socket.emit(`unotalone:message:delete`, { room: this.room.id, date: m.date })
    },
    editMessage(m) {
      this.message = m.text
      this.editMessageVal = m
    },
    newMsgTitle() {
      if (!this.user) return
      const pageTitle = this.getPageTitle()
      clearInterval(this.titleInterval)
      this.titleInterval = setInterval(() => {
        if (!document.title.includes('сообщение')) {
          document.title = `${this.messagesUnread} сообщение`
        } else {
          document.title = pageTitle
        }
      }, 1000)
    },
    kodikMessageListener(message) {
      // console.log('kodikMessageListener', message.data)
      if (message.data && message.data.key) {
        if (
          message.data.key === 'kodik_player_pause' ||
          message.data.key === 'kodik_player_video_ended'
        ) {
          this.playerStart = false
        } else if (message.data.key.includes('kodik_player')) {
          this.playerStart = true
        }
      }
    },
    showMembersToggle(type) {
      if (type === 'total' && this.showMembers.total) {
        this.showMembers.total = false
        return
      }
      if (type === 'inroom' && this.showMembers.inroom) {
        this.showMembers.inroom = false
        return
      }
      if (type === 'total') {
        this.showMembers.inroom = false
        this.showMembers.total = true
      } else {
        this.showMembers.total = false
        this.showMembers.inroom = true
      }
    },
    async sendPM(pushId) {
      if (!this.PMtext) return
      const body = {
        to: this.sendPMUser,
        text: this.PMtext
      }
      const { data } = await this.$axios.post('/api/user/pm', body)
      // console.log('sendPM', data)
      if (data.error) {
        this.$store.commit('setError', { status: 'ok', msg: data.error.message })
      } else {
        this.$store.commit('setError', { status: 'ok', msg: this.$t('message_sended') })
      }

      setTimeout(() => this.$store.commit('clearError'), 1100)

      const obj = {
        date: Date.now(),
        type: 'pm',
        to: this.sendPMUser,
        from: {
          id: this.user.id,
          name: this.user.name,
          photo: this.user.photo,
          group: this.user.group
        },
        message: body.text,
        payload: null
      }
      this.$socket.emit(`unotalone:notification`, obj)

      this.sendPMUser = null
      this.PMtext = ''

      this.removePush(pushId)
    },
    removePush(date) {
      const pushIndex = this.notifications.findIndex(el => el.date === date)
      this.notifications.splice(pushIndex, 1)
    },
    getPageTitle() {
      return document.title
    },
    deleteRoom(roomId) {
      this.$axios.delete(`/api/rooms?room_id=${roomId}&user_id=${this.user.id}`)
      this.$store.commit('setError', { status: 'ok', msg: this.$t('room_deleted') })
      setTimeout(() => this.$store.commit('clearError'), 2000)
      this.goToRoom({ _id: 'global', owner: null, title: this.$t('leftside_rooms_public_room') })
    },
    parseBB() {
      const list = document.querySelectorAll('.messages li p')
      if (list) {
        Array.from(list).forEach((el) => {
          const checkBB = el.textContent.match(/\[::.*::\]/) || el.textContent.match(/^@.*,/)

          // console.log('checkBB', checkBB)

          if (checkBB) {
            const p = el
            let str = p.textContent
              .replace(/(<a|<script|keydown|keyup|click|contextmenu|mouseover|mouseout|mousedown|mouseup|mousemove|submit|focus)/g, '')
              // --- BB CODE
              .replace(/\[::color/g, `<span style="color`)
              .replace(/;::\]/g, `">`)
              .replace(/\[::\/color::\]/g, `</span>`)
              // .replace(/\[::@refresh::\]/g, `<u onclick="location.reload()" style="color:rgb(241, 41, 127);cursor:pointer;">`)
              // .replace(/\[::\/@refresh::\]/g, `</u>`)
              // .replace(/\[::@feedback::\]/g, `<u onclick="document.querySelector('.icon-fb').click()" style="color:rgb(241, 41, 127);cursor:pointer;">`)
              // .replace(/\[::\/@feedback::\]/g, `</u>`)
              .replace(/\[::u::\]/g, `<u>`)
              .replace(/\[::\/u::\]/g, `</u>`)
              .replace(/\[::b::\]/g, `<b>`)
              .replace(/\[::\/b::\]/g, `</b>`)
              .replace(/\[::i::\]/g, `<i>`)
              .replace(/\[::\/i::\]/g, `</i>`)
              .replace(/\[::img::\]/g, `<img src="`)
              .replace(/\[::\/img::\]/g, `" alt="">`)
              .replace(/\[::link="https:\/\/t.me/g, `<a href="https://t.me`)
              .replace(/\[::link="https:\/\/vk.com/g, `<a href="https://vk.com`)
              .replace(/\[::link="https:\/\/unotalone.su/g, `<a href="https://unotalone.su`)
              .replace(/\[::link="https:\/\/animeast.su/g, `<a href="https://animeast.su`)
              .replace(/"::\]/g, `">`)
              .replace(/\[::\/link::\]/g, '</a>')
              .replace(/\[::/g, `<img src="/static_files/stikers/`)
              .replace(/::\]/g, `" class="chat-emoji" />`)
            
            const findAddress = el.textContent.match(/^@.*,/)
            if (findAddress) {
              str = str.replace(
                findAddress[0],
                `<span style="color:rgb(241, 41, 127);font-weight:700;">${findAddress[0]}</span>`
              )
            }

            p.innerHTML = str
          }
        })
      }
    },
    appendEmoji(e) {
      // console.log(e)
      e = e.replace(/^\/+/, '')
      if (this.smilesCat === 'onion') {
        this.message += `[::${e}::] `
        if (this.isDesktop && this.$refs.chatInputMsg) {
          this.$refs.chatInputMsg.focus()
        }
      } else {
        this.message = `[::${e}::]`
        this.sendMessage()
        if (localStorage.getItem('fast_stikers07')) {
          const fastStikers = JSON.parse(localStorage.getItem('fast_stikers07'))
          if (!fastStikers.items.includes(e)) {
            fastStikers.items.push(e)
            localStorage.setItem('fast_stikers07', JSON.stringify(fastStikers))
          }
        } else {
          const obj =  {
            name: '',
            items: [e]
          }
          localStorage.setItem('fast_stikers07', JSON.stringify(obj))
        }
      }
    },
    async spamMember(msg) {
      await this.$axios.post(`/api/chat/spam`, msg)
      this.spamMembersList.push(msg.user.id)
      this.$store.commit('setError', { status: 'ok', msg: 'Жалоба отправлена' })
      setTimeout(() => this.$store.commit('clearError'), 1000)
    },
    async banMember(msg) {
      if (msg.user.group === 0) {
        this.$store.commit('setError', { status: 'warn', msg: 'Недостаточно прав' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
        return
      }
      const body = {
        room_id: this.room.id,
        user_id: msg.user.id,
        msg: msg.text
      }
      await this.$axios.post(`/api/chat/ban`, body)
      this.banMembersList.push(msg.user.id)
      this.$store.commit('setError', { status: 'ok', msg: 'Пользователь заблокирован' })
      setTimeout(() => this.$store.commit('clearError'), 1000)
      this.$socket.emit(`unotalone:user:ban`, msg)
    },
    async unbanMember(msg) {
      const body = {
        room_id: this.room.id,
        user_id: msg.user.id,
        msg: msg.text
      }
      await this.$axios.post(`/api/chat/unban`, body)
      const list = this.banMembersList.filter(el => el !== msg.user.id)
      this.banMembersList = list
      this.$store.commit('setError', { status: 'ok', msg: 'Пользователь разблокирован' })
      setTimeout(() => this.$store.commit('clearError'), 1000)
      this.$socket.emit(`unotalone:user:unban`, msg)
    },
    sendMessage() {
      if (!this.user) {
        this.$store.commit('setError', { status: 'warn', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      } else if (!this.user.id) {
        // location.reload()
        this.$store.commit('setError', { status: 'warn', msg: this.$t('page_reload') })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      } else if (!this.message.trim()) {
        // this.$store.commit('setError', { status: 'warn', msg: 'Сообщение не может быть пустым' })
        // setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      } else if (this.gets.msgtimeout) {
        this.$store.commit('setError', this.$t('chat_messages_flood'))
        setTimeout(() => this.$store.commit('clearError'), 1000)
      } else {
        if (this.editMessageVal) {
          const obj = {
            room: this.room.id,
            message: this.editMessageVal
          }
          
          obj.message.text = this.message

          this.$socket.emit(`unotalone:message:edit`, obj)

          this.editMessageVal = null
          this.messageQuote = null
          this.message = ''
          this.showSmiles = false
          
          return
        }

        this.gets.msgtimeout = true
        const message = {
          text: this.message.trim(),
          user: {
            id: this.user.id,
            name: this.user.name || 'No name',
            photo: this.user.photo || '/static_files/noavatar.svg',
            group: this.user.group,
            patron: this.user.patron,
            socket_id: this.socketId,
          },
          pm: null,
          quote: null,
          device: this.$device.isWindows
            ? 'windows'
            : this.$device.isIos
              ? 'ios'
              : this.$device.isAndroid
                ? 'android'
                : ''
        }

        if (this.messageQuote) {
          message.quote = this.messageQuote
        }

        if (this.pm) {
          message.pm = this.pm
        }

        const obj = {
          room: this.room.id,
          message
        }

        if (this.message.includes('@Alon,')) this.lastMessage = this.message

        this.$socket.emit(`unotalone:message:new`, obj)
        
        this.messageQuote = null
        this.message = ''
        this.showSmiles = false
        
        // this.$nextTick(() => this.parseBB())
        setTimeout(() => delete this.gets.msgtimeout, 300)
      }
    },
    scrollChatMessages() {
      if (document.querySelector('.messages')) {
        document.querySelector('.messages').scrollTop = document.querySelector('.messages').scrollHeight
      }
    },
    quote(m) {
      // this.message = `${name}, `
      this.messageQuote = m
      if (this.$refs.chatInputMsg) this.$refs.chatInputMsg.focus()
    },
    qouteClean() {
      this.messageQuote = null
    },
    whisper(pmUser) {
      // console.log('pmUser', pmUser)
      if (this.user) {
        if (pmUser.id === this.user.id) return
        this.pm = pmUser
        this.$nextTick(() => {
          if (this.$refs.chatInputMsg) this.$refs.chatInputMsg.focus()
        })
      } else {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    whisperCancel() {
      this.pm = null
      this.$store.commit('setWhisper', null)
    }
  }
}
</script>

<style src="~/assets/chat.styl" lang="stylus" />
