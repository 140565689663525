<template>
  <div v-if="items.length" class="frames">
    <div class="items">
      <div v-for="(item, i) in items.slice(0, moreI * 12)" :key="`frames-${item.image}`" class="item">
        <img @click="showModal(i)" src="/static_files/no-poster.png" :data-src="item.preview" class="lazyload" alt="No poster">
      </div>
    </div>
    <div v-if="items.length > 12 && moreI < items.length" class="show-more">
      <button @click="moreI = items.length">
        {{ $t('show_more') }}
        <i class="arrow" />
      </button>
    </div>

    <transition name="fadeInFast">
      <div v-if="currentImage !== null" class="modal">
        <div class="actions">
          <i @click="closeModal" class="icon-close" />
        </div>
        <div class="img" :style="`background-image:url(${items[currentImage].image})`" />
        <i class="loader" />
        <div class="buttons">
          <div v-if="currentImage > 0" @click="setFrame('prev')" class="prev">
            <img src="/static_files/icons/chevron-left-dark.svg" alt="">
          </div>
          <div v-if="currentImage < items.length - 1" @click="setFrame('next')" class="next">
            <img src="/static_files/icons/chevron-right-dark.svg" alt="">
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else-if="noData"><p>Материалы не найдены</p></div>
  <div v-else><i class="loader" /></div>
</template>

<script>
export default {
  props: { kpid: { type: String , require: true, default: () => '' } },
  data() {
    return {
      items: [],
      currentImage: null,
      moreI: 1,
      noData: false
    }
  },
  mounted() {
    window.addEventListener('keydown', this.closeEsc)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.closeEsc)
  },
  methods: {
    setFrame(val) {
      if (val === 'prev') {
        if (this.currentImage > 0) {
          --this.currentImage
        }
      } else if (val === 'next') {
        if (this.currentImage < this.items.length - 1) {
          ++this.currentImage
        }
      }
    },
    closeEsc(e) {
      if (e.keyCode === 27) {
        this.closeModal()
      }
    },
    closeModal() {
      this.currentImage = null
      document.body.style.overflow = ''
    },
    showModal(i) {
      this.currentImage = i
      document.body.style.overflow = 'hidden'
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/filmdata/frames?kpid=${this.kpid}`)
    if (data.items.length) {
      this.items = data.items
    } else {
      this.noData = true
    }
  }
}
</script>

<style src="~/assets/frames.styl" lang="stylus">
