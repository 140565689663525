<template>
  <main class="help">
    <section>
      <h1>Помощь</h1>
      <div class="fb">
        <h3>
          Не нашли ответа? Напишите нам!
        </h3>
        <button @click="$store.commit('toggleFeedback', true)">
          Создать обращение
        </button>
        на почту <a href="mailto:@support@unotalone.su" target="_blank">support@unotalone.su</a>
        или в соц.сети <a href="https://vk.com/r0n1x" target="_blank">ВКонтакте</a>
        <a href="https://t.me/r0n1x" target="_blank">Телеграм</a>
        @r0n1x
      </div>

      <ul>
        <!-- <div class="hr">
          <span>Обновление от 05.01.2023</span>
        </div>
        <li :class="{ active: currentSpoiler.includes('23_01_05_0') }" class="item">
          <strong @click="showSpoiler('23_01_05_0')">Кэшбэк с покупок</strong>
          <div class="spoiler" data-spoiler="23_01_05_0">
            <p>
              Вы можете <b>вернуть 5%</b> от суммы заказа в <a href="https://merch.unotalone.ru/" target="_blank">нашем мерч-магазине</a> на свой Баланс.
              Для этого напишите в <a href="https://vk.com/r0n1x/" target="_blank">ВК</a>
              или <a href="https://t.me/r0n1x/" target="_blank">ТГ</a> номер своего заказа и ID профиля на сайте.
            </p>
          </div>
        </li> -->
        <!-- <div class="hr">
          <span>Обновление от 31.12.2022</span>
        </div> -->
        <li :class="{ active: currentSpoiler.includes('22_12_31_10') }" class="item">
          <strong @click="showSpoiler('22_12_31_10')">Просмотры без рекламы на паузе</strong>
          <div class="spoiler" data-spoiler="22_12_31_10">
            <p>
              Если вы уже приобрели пакеты просмотров без рекламы (или вместе с Premium подпиской),
              но решите их сэкономить (смотрите соло и 1 рекламный ролик вам не мешает), вы можете это сделать.
            </p>
            <ol>
              <li>Разверните меню <b>Профиль</b> и нажмите на ползунок в блоке просмотров</li>
              <li>Повторный перевод ползунка вновь активирует функцию просмотра без рекламы</li>
            </ol>
            <p>
              Таким образом вы сможете сэкономить просмотры без рекламы для совместного просмотра с другом.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_9') }" class="item">
          <strong @click="showSpoiler('22_12_31_9')">Какой браузер лучше использовать?</strong>
          <div class="spoiler" data-spoiler="22_12_31_9">
            <ol>
              <li>
                <b>На ПК версии</b> мы рекомендуем использовать <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a>
              </li>
              <li>
                <b>Для просмотра на iOS</b> можете воспользоваться стандартным браузером Safari. Наш плеер нативно поддерживает его меню управления.
                Обратите внимание. Браузер Safari не поддерживает открытие доп.окон и у вас могут возникнуть проблемы при пополнеии баланса и скачивании кино.
                В таком случае используйте <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a> 
              </li>
              <li>
                <b>На платформе Android</b>, используйте Chrome или <a href="https://browser.yandex.ru/" target="_blank">Яндекс.Браузер</a>
              </li>
            </ol>
            <p>
              О найденных багах просим уведомлять через форму в конце этой страницы или напрямую в месcенджеры (контакты внизу страницы).
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_8') }" class="item">
          <strong @click="showSpoiler('22_12_31_8')">Надоели панели браузера в моб.версии сайта!</strong>
          <div class="spoiler" data-spoiler="22_12_31_8">
            <p>
              Чтобы убрать надоедливые панели браузера, установите наше мобильное приложение для Android (~5 Mb).
            </p>
            <p>
              <a href="https://unotalone.su/static_files/app/app-universal-release-bitrise-signed.apk" download>
                <img src="/static_files/google-play-badge.png" alt="" style="height:44px;">
              </a>
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_7') }" class="item">
          <strong @click="showSpoiler('22_12_31_7')">Мне мало места на экране!</strong>
          <div class="spoiler" data-spoiler="22_12_31_7">
            <p>
              На ПК версии сайта вы можете свернуть левую панель Активных комнат и строку верхнего Меню.
              Для этого, после пунктов верхнего меню, нажмите
              <img src="/static_files/icons/left-to-right-arrangement.png" alt="" style="width:14px;height:14px;transform:rotateZ(180deg);">
              Повторное нажатие вернет список комнат.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_6') }" class="item">
          <strong @click="showSpoiler('22_12_31_6')">Расскажите подробнее про подарки в 21:00?</strong>
          <div class="spoiler" data-spoiler="22_12_31_6">
            <p>Каждые 10 мин. вы можете получить подарок за просмотр.
              На ваш баланс активности будут зачислены баллы, а на счет вашего Реферера некоторый %.
            </p>
            <p>
              <b>Совет.</b> <nuxt-link to="/profile/ref">Приглашайте друзей</nuxt-link> и набирайте своих рефералов.
              Получайте % с их пополнений и активности на сайте.
            </p>
            <p><b>Обратите внимание!</b> Блокировщик рекламы должен быть отключен.</p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_5') }" class="item">
          <strong @click="showSpoiler('22_12_31_5')">Что такое "Кошелек"?</strong>
          <div class="spoiler" data-spoiler="22_12_31_5">
            <p>На нашем сайте вы можете зарабатывать реальные деньги.</p>
            <ol>
              <li><nuxt-link to="/common">Приходите на сеансы в 21:00</nuxt-link> по МСК и получайте подарки</li>
              <li><nuxt-link to="/profile/ref">Приводите друзей</nuxt-link> и получайте % от их пополнений и активности</li>
              <li>...другие возможности уже в разработке.</li>
            </ol>
            <p>
              Все набранные средства возвращаются в ваш <nuxt-link to="/profile/ref">Кошелек</nuxt-link>, которые вы можете вывести по запросу.
            </p>
            <p style="font-size:1.2rem;opacity:0.75;">
              Обратите внимание, данная функция в настоящий момент находится на стадии разработки.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_4') }" class="item">
          <strong @click="showSpoiler('22_12_31_4')">Как общаться только с другом?</strong>
          <div class="spoiler" data-spoiler="22_12_31_4">
            <ol>
              <li>
                Разверните меню Профиль
              </li>
              <li>
                Перейдите на страницу <b>Дружба</b>
              </li>
              <li>
                Из списка друзей выберите друга, с которым хотите пообщаться и кликните ЛС (Личные сообщения).
              </li>
            </ol>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_3') }" class="item">
          <strong @click="showSpoiler('22_12_31_3')">Как добавить друга "в друзья"?</strong>
          <div class="spoiler" data-spoiler="22_12_31_3">
            <p>
              На странице
              <nuxt-link to="/chat">Общего чата</nuxt-link>
              или в комнате совместного просмотра, кликните по Нику человека, с которым хотите дружить.
              В выпадающем меню выберите <b>+Дружить</b>. Будет отправлен запрос дружбы. Дождитесь одобрения запроса.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_2') }" class="item">
          <strong @click="showSpoiler('22_12_31_2')">Как сделать постоянную комнату?</strong>
          <div class="spoiler" data-spoiler="22_12_31_2">
            <p>
              На странице комнаты сверху в конце названия фильма есть иконка
              <img src="/static_files/icons/pin.png" alt="" style="width:14px;height:14px;">, кликните по ней, чтобы закрепить вашу комнату.
              <img src="/static_files/icons/pinned.png" alt="" style="width:14px;height:14px;"> Повторный клик поставит комнату на удаление.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_1') }" class="item">
          <strong @click="showSpoiler('22_12_31_1')">У меня нет друзей :(</strong>
          <div class="spoiler" data-spoiler="22_12_31_1">
            <p>
              <nuxt-link to="/chat">Добавляйтесь в ленту</nuxt-link>, заполняйте анкету (не обязательно), получайте новых друзей. 
            </p>
            <p>
              Доступно 1 добавление в сутки. Вы можете занять первое место в ленте повторно за 1 монету.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('22_12_31_0') }" class="item">
          <strong @click="showSpoiler('22_12_31_0')">Хочу подзаработать!</strong>
          <div class="spoiler" data-spoiler="22_12_31_0">
            <p>
              И у нас это возможно!
            </p>
            <ol>
              <li><nuxt-link to="/common">Приходите на сеансы в 21:00</nuxt-link> (по МСК) и за каждые 10 мин. просмотра получайте награды</li>
              <li><nuxt-link to="/profile/articles">Пишите полезные статьи</nuxt-link> для сайта (обзоры, подборки и т.д.) и получайте вознаграждения</li>
              <li><nuxt-link to="/profile/ref">Приводите друзей</nuxt-link> и получайте свой % от их пополнений и активности</li>
            </ol>
          </div>
        </li>
        <div class="hr"></div>
        <li :class="{ active: currentSpoiler.includes('10') }" class="item">
          <strong @click="showSpoiler('10')">Где отключить снег?</strong>
          <div class="spoiler" data-spoiler="10">
            <p>
              Для отключения эффекта "Снег":
            </p>
            <ol>
              <li><nuxt-link to="/signin">войдите на сайт (авторизируйтесь)</nuxt-link></li>
              <li>откройте меню Профиль</li>
              <li>нажмите на иконку <img src="/static_files/snow/snowflake.svg" alt="" style="display:inline-block;margin-right:2px;vertical-align:middle;width:14px;height:14px;">снежинки</li>
            </ol>
            <p>
              Для включения, повторно нажмите на иконку "снежинка".
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('0') }" class="item">
          <strong @click="showSpoiler('0')">Что нужно знать о совместном просмотре</strong>
          <div class="spoiler" data-spoiler="0">
            <ol>
              <li><u>Видеофайл не найден?</u> Попробуйте @Обновить страницу и запустить плеер повторно.</li>
              <li><u>Лаги / Залипания / Тормоза / Повторы?</u> Попросите Владельца комнаты уменьшить качество видео.</li>
              <li><u>В любой непонятной ситуации</u> обновите страницу и запустите плеер самостоятельно.</li>
              <li><u>НЕ РЕКОМЕНДУЕТСЯ</u> переключать вкладку и сворачивать браузер во время просмотра. Либо включите просмотр "картинка в картинке".</li>
            </ol>
            <p>
              !Обратите внимание, при смене вкладки и сворачивании браузера, события на сайте (время просмотра, сообщения и т.д.) перестают отслеживаться и возобновляются при возвращении внимания на сайт.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('7') }" class="item">
          <strong @click="showSpoiler('7')">Где найти друзей для совместного просмотра?</strong>
          <div class="spoiler" data-spoiler="7">
            <p>
              Заходите в нашу группу
              в Телеграм <a href="https://t.me/unotaloneru" target="_blank">@unotaloneru</a>
              или ВКонтакте <a href="https://vk.com/unotaloneru" target="_blank">@unotaloneru</a>.
              Там мы публикуем новинки кино и обновления сериалов.
              При переходе по ссылке вы попадете в "Общую комнату" для всех, кто перейдет из Телеграм.
              Смотрите новые серии сериалов вместе с другими участниками нашего проекта.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('8') }" class="item">
          <strong @click="showSpoiler('8')">Что такое Premium подписки и Пакеты просмотров?</strong>
          <div class="spoiler" data-spoiler="8">
            <p>
              Каждый запрос видеофайла фильма или серии сериала БЕЗ РЕКЛАМЫ оплачивается из приобретенного пакета.
            </p>
            <p>
              !Обратите внимание. Наш сервис полностью бесплатен. Премиум подписка нужна для отключения баннер-рекламы на сайте.
              А пакеты просмотров позволят вам смотреть видео без рекламы в плеере лучшего качества.
            </p>
            <p>
              Если вам не важна повышенная скорость работы сайта, но вы хотите смотреть видео в FullHD без рекламы - ваш выбор пакеты просмотров.
              Если же вам важен комфорт, воспользуйтесь <nuxt-link to="/donate">Премиум подписками</nuxt-link>, в которые так же включены просмотры без рекламы.
            </p>
            <p>
              Пакеты просмотров можно приобрести отдельно от Премиум подписки или по исчерпанию лимита.
            </p>
            <p class="start" style="font-size:1.2rem;opacity:0.75;">
              <b style="color:red;">**</b> Один запрос действителен в течении 6 часов или до смены вашего IP адреса (обычно раз в сутки или реже).
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('9') }" class="item">
          <strong @click="showSpoiler('9')">Хочу узнать подробнее про пополнение баланса</strong>
          <div class="spoiler" data-spoiler="9">
            <p>
              За каждый пожертвованный сайту рубль на баланс в качестве благодарности вернутся
              <img src="/static_files/icons/coin-donate.svg" alt="" style="display:inline-block;margin-right:2px;vertical-align:middle;width:14px;height:14px;">
              монеты.
            </p>
            <p>
              <u style="display:inline-block;padding:2px 8px;background-color:#fff;color:#100e19;">1 руб. = 1 монета</u>
            </p>
            <p>
              Монеты вы можете потратить на <nuxt-link to="/donate">Premium подписки и Пакеты просмотров</nuxt-link>.
            </p>
            <p>
              В процессе перевода могут возникнуть банковские задержки. В таком случае вам потребуется подтвердить платеж вручную.
              Для этого под формой перевода нажмите кнопку "Список переводов". Найдите свой перевод и нажмите "Проверить".
              Если это не помогло, обратитесь в нашу службу поддержки через форму обратной связи или напрямую в ВК или ТГ.
            </p>
            <p>
              За поддержку на общую сумму (единоразово или за несколько переводов) <u>свыше 5 000 руб.</u> Вы получите статус
              <b style="display:inline-block;padding:2px 10px;color:#fff;background-color:rgba(250, 162, 0, 1);">Patron</b> - особые привилегии на сайте и вечный Premium+.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('1') }" class="item">
          <strong @click="showSpoiler('1')">Собственные ссылки на видеофайл</strong>
          <div class="spoiler" data-spoiler="1">
            <p>
              Вы можете самостоятельно получить ссылку в разделе "Скачать" и вставить ее в поле.
            </p>
            <p>
              <a href="/static_files/cinema/_221207103449.jpg" target="_blank">
                <img src="/static_files/cinema/_221207103449.jpg" alt="" style="margin:5px 0px;max-width:50%;">
              </a>
            </p>
            <p>
              Вы можете <a :href="`https://yandex.ru/search/?text=скачать+фильм+mp4`" target="_blank">найти ссылку (клик)</a> на видеофайл в интернете. Пример ссылки https://site.ru/film-name-2022.mp4
            </p>
            <p>
              Не всегда – это можно сделать вручную, многие сайты защищают свои ссылки от использования на других ресурсах.
            </p>
            <p>
              В таком случае можно воспользоваться расширением Video Download Helper:
            </p>
            <ol>
              <li>после установки, перейдите на страницу видео и запустите его;</li>
              <li>нажмите на иконку расширения, чтобы активировать его;</li>
              <li>если удалось получить ссылку, скопируйте её;</li>
              <li>теперь вставьте в строчку поиска в комнате</li>
            </ol>
            <p>
              Ссылка автоматически установится всем участникам комнаты. Реклама в плеере отсутствует, синхронизация автоматическая.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('6') }" class="item">
          <strong @click="showSpoiler('6')">Можно ли смотреть вместе с другом ролики с YouTube?</strong>
          <div class="spoiler" data-spoiler="6">
            <p>
              Да, у нас вы можете посмотреть так же и ролики с видеосервиса YouTube.
            </p>
            <ol>
              <li>
                Найдите ролик, который хотите посмотреть
              </li>
              <li>
                Нажмите "Поделиться" или скопируйте ссылку из адресной строки браузер (ПК версия)
              </li>
              <li>
                В списке комнат (ПК - слева от контента. Мобил - нажмите "Комнаты", снизу браузера) переключите поле ввода на YouTube
                (см. соответствующую красную иконку)
              </li>
              <li>
                Вставьте ссылку в поле. Комната создатся автоматически
              </li>
            </ol>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('2') }" class="item">
          <strong @click="showSpoiler('2')">Что такое "Общая комната"?</strong>
          <div class="spoiler" data-spoiler="2">
            <p>
              Общая комната - здесь собираются, чтобы посмотреть кино вместе.
              Вы можете сделать ее своей и смотреть только со своими друзьями.
            </p>
            <p>
              Перейти в Общую комнату вы можете из наших групп в ВК и ТГ.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('3') }" class="item">
          <strong @click="showSpoiler('3')">У вас есть соц.сети?</strong>
          <div class="spoiler" data-spoiler="3">
            <p>
              Присоединяйся к нам в Телеграм <a href="https://t.me/unotaloneru" target="_blank">@unotaloneru</a> и ВКонтакте <a href="https://vk.com/unotaloneru" target="_blank">@unotaloneru</a>.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('4') }" class="item">
          <strong @click="showSpoiler('4')">Как не пропустить выход новинок?</strong>
          <div class="spoiler" data-spoiler="4">
            <p>
              Подпишитесь на наш Телеграм канал и смотрите новинки кино одним из первых <a href="https://t.me/unotaloneru" target="_blank">@unotaloneru</a>.
            </p>
          </div>
        </li>
        <li :class="{ active: currentSpoiler.includes('5') }" class="item">
          <strong @click="showSpoiler('5')">Могу ли я делиться своими просмотрами без рекламы?</strong>
          <div class="spoiler" data-spoiler="5">
            <p>
              Да, вы можете поделиться просмотрами со всеми участниками комнаты. Нажмите @ в чате. Каждому добавится +1 просмотр.
            </p>
          </div>
        </li>
      </ul>
    </section>

    <FB />
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  components: {
    FB: () => import('~/components/FB.vue'),
  },
  head() {
    const fullPath = `https://unotalone.su/help`
    return {
      title: `Помощь по сайту`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `помощь по сайту`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Самые популярные вопросы и темы по нашему проекту. Рекомендуем ознакомиться.`
        }
      ]
    }
  },
  data() {
    return {
      currentSpoiler: []
    }
  },
  mounted() {
    if (this.$route.query.p) {
      this.showSpoiler(this.$route.query.p)
    }
  },
  methods: {
    showSpoiler(id) {
      const elem = document.querySelector(`[data-spoiler="${id}"]`)
      if (elem) {
        if (elem.style.display === 'block') {
          elem.style.display = 'none'
          this.currentSpoiler = this.currentSpoiler.filter(el => el !== id)
        } else {
          elem.style.display = 'block'
          this.currentSpoiler.push(id)
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.help
  padding: 10px
  .hr
    position: relative
    margin: 30px 0px
    height: 2px
    background-color: #3a3352
    text-align: center
    span
      display: inline-block
      position: absolute
      top: -11px
      left: calc(50% - 100px)
      padding: 2px 20px
      width: 200px
      font-size: 1.4rem
      color: rgba(255, 255, 255, 1)
      background-color: #3a3352
  h1
    margin-bottom: 40px
  .fb
    margin: 20px 0px
    button
      display: block
      margin-top: 10px
    a
      margin-right: 5px
  ul
    list-style: none
    margin-left: 0px
    li.item
      margin-bottom: 10px
      padding: 10px
      border: 1px solid rgba(255, 255, 255, 0.3)
      border-radius: 4px
      &:hover > strong
        color: rgba(251, 45, 127, 1)
      &.active
        border: 1px solid rgba(251, 45, 127, 1)
        strong
          color: rgba(251, 45, 127, 1)
          &::before
            content: '-'
      strong
        display: inline-block
        font-size: 1.6rem
        cursor: pointer
        transition: color 0.2s ease
        &.important
          border-bottom: 1px solid rgba(251, 45, 127, 1)
        &::before
          display: inline-block
          content: '+'
          margin-right: 4px
          font-size: 1.6rem
          position: relative
          width: 14px
          height: 14px
      div.spoiler
        display: none
        margin-top: 10px
        ol
          margin: 10px 0px 10px 14px
        p
          margin: 5px 0px
</style>