<template>
  <main class="refs">
    <section>
      <h1>Реферальная система</h1>

      <div
        class="wallet"
        title="Ваши заработанные средства"
      >
        <img src="/static_files/icons/wallet.svg" alt="">
        <strong>{{ wallet }}</strong>
      </div>
      <div style="display:inline-block;position:relative;top:2px;">
        <button @click="exchange" class="wi">
          <img src="/static_files/icons/left-and-right-arrows.png" alt="">
          Вывести
        </button>
      </div>

      <div>
        Приглашай друзей и получай <s style="font-size:1.4rem;opacity:0.5;">5</s> 10%<sup style="color:red;">*</sup>
        на свой баланс с их <nuxt-link to="/donate">пополнений</nuxt-link> и <nuxt-link to="/profile/activity">активности</nuxt-link>.
      </div>

      <p style="font-size:1.2rem;opacity:0.75;">
        * Повышенный процент. Срок акции ограничен.
      </p>

      <div v-if="referer" style="margin:20px 0px;">
        Ваш реферер: {{ referer }}
      </div>
      <div style="margin:20px 0px;">
        Ваша реферальная ссылка. Приглашайте по ней на сайт:
        <div style="margin-top:10px;">
          <input type="text" :value="`https://unotalone.su?ref=${user.id}`" readonly @click="share" class="share-reflink">
        </div>
      </div>
    </section>

    <section>
      <h2>Ваши рефералы</h2>

      <div v-if="refs.length" class="items">
        <div v-for="item in refs" :key="`ref-${item.date}`" class="item">
          <span style="font-size:1.4rem;color:rgba(255,255,255,0.75);">{{ item.date | filterDate }}</span>
          <span>{{ item.referal }}</span>
        </div>
      </div>
      <p v-else>Не найдено</p>
    </section>

    <section>
      <h2>История</h2>

      <div v-if="refsHistory.length" class="items">
        <div v-for="item in refsHistory" :key="`ref-${item.date}`" class="item">
          <span style="font-size:1.4rem;color:rgba(255,255,255,0.75);">{{ item.date | filterDate }}</span>
          <span>{{  item.type === 'donate' ? 'Пополнение' : 'Просмотр' }}</span>
          <span>{{ item.referal }} </span>
          <strong>+{{ item.amount }}</strong>
        </div>
      </div>
      <p v-else>Не найдено</p>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex';

export default {
  middleware: 'auth',
  filters: {
    filterDate(value) {
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      const year = new Date(value).getFullYear()
      return `${day}.${month}.${year} ${hour}:${min}`
    }
  },
  head() {
    const fullPath = `https://unotalone.su/profile/ref`
    return {
      title: `Реферальная система`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Реферальная система`
        }
      ]
    }
  },
  computed: mapState(['user']),
  async asyncData({ $axios }) {
    const { data } = await $axios.get(`/api/user/ref`)
    return {
      referer: data.referer,
      refs: data.refs,
      refsHistory: data.refs_history,
      wallet: data.wallet,
    }
  },
  methods: {
    exchange() {
      this.$store.commit('setError', { status: 'info', msg: 'На балансе менее 100 Кредитов' })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    share() {
      const input = document.querySelector(`.share-reflink`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'Ссылка скопирована' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
  }
}
</script>

<style lang="stylus">
.refs
  padding: 10px
  .wallet
    display: inline-block
    margin-bottom: 20px
    padding: 8px 20px
    border-radius: 4px
    border: 1px solid rgba(255, 255, 255, 0.35)
    strong
      font-size: 2rem
    img
      display: inline-block
      margin-right: 4px
      width: 24px
      height: 24px
      position: relative
      top: 3px
  h1
    margin-bottom: 40px
  section
    margin-bottom: 40px
  .share-reflink
    width: 360px
</style>
