<template>
  <main class="room-page">
    <section
      v-if="eventRoom"
      :data-common="eventRoom.common ? eventRoom.kinopoisk_id : ''"
      :data-type="eventRoom.film.type"
      :class="{ 'resize': resizeIframe, 'turn': turnChat }"
      class="room-page-watch"
    >
      <div v-if="!isDesktop && !resizeIframe" @click="showBar = !showBar" class="settings">
        <img src="/static_files/icons/filter.png">
        <!-- {{ !showBar ? 'Управление' : 'Скрыть' }} -->
        Настройки просмотра
      </div>

      <div class="page-scroll">
        <template v-if="!user || user.group === 1">
          <div v-show="!resizeIframe" id="yandex_rtb_R-A-6711067-2" class="rsya-block"></div>
          <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" v-show="!resizeIframe" class="close-ad">
            {{ $t('advert_off') }}
          </nuxt-link>
        </template>

        <!-- :class="{ 'active': isOwner, 'common': eventRoom.vmeste }" -->
        <header v-show="!resizeIframe">
          <img @click="$router.push('/')" src="/static_files/icons/chevron-left-dark.svg" class="back">
          <div class="poster">
            <img
              :src="eventRoom.film.poster"
              :alt="`${eventRoom.film.title_ru}`"
            >
            <i v-if="eventRoom.hidden" class="icon-private" />
          </div>
          <div class="title">
            <h1>
              <nuxt-link :to="`/catalog/${eventRoom.kinopoisk_id}`">
                {{ 
                  ['ru', 'uk', 'be'].includes(lang)
                    ? eventRoom.film.title_ru
                      ? eventRoom.film.title_ru.length > 40 ? eventRoom.film.title_ru.slice(0, 40) + '...' : eventRoom.film.title_ru
                      : eventRoom.film.title_en
                    : eventRoom.film.title_en
                      ? eventRoom.film.title_en.length > 40 ? eventRoom.film.title_en.slice(0, 40) + '...' : eventRoom.film.title_en
                      : eventRoom.film.title_ru
                }}
              </nuxt-link>
              <sup
                v-if="!eventRoom.vmeste && user && user.id === eventRoom.owner.id"
                @click="roomPinned(eventRoom._id)"
                :class="{ active: !eventRoom.expireAt }"
                :title="`${eventRoom.expireAt ? $t('pinned') : $t('unpinned')}`"
              ></sup>
            </h1>

            <div>
              <strong v-if="eventRoom.vmeste">Сеанс в 21:00</strong>
              <strong v-else-if="eventRoom.common">
                {{ $t('leftside_rooms_public_room') }}
                <nuxt-link :to="`/vmeste?id=${eventRoom.kinopoisk_id}`">{{ $t('clone') }}</nuxt-link>
              </strong>
              <strong v-else>
                <client-only>
                  {{ $t('owner') }}: {{ eventRoom.owner ? eventRoom.owner.name : $t('leftside_rooms_public_room') }}
                </client-only>
                <!-- <nuxt-link :to="`/vmeste?id=${eventRoom.kinopoisk_id}&common`">Смотреть вместе</nuxt-link> -->
                <nuxt-link v-if="user && user.id !== eventRoom.owner.id" :to="`/vmeste?id=${eventRoom.kinopoisk_id}`">{{ $t('clone') }}</nuxt-link>
              </strong>
              <template v-if="changeSEdata">
                <span v-if="changeSEdata.translation">{{ changeSEdata.translation }}</span>
                <span v-if="changeSEdata.season">Сезон {{ changeSEdata.season }}</span>
                <span v-if="changeSEdata.episode">Серия {{ changeSEdata.episode }}</span>
              </template>
            </div>
          </div>
          <!-- <div v-if="!isDesktop">
            <img @click="showBar = !showBar" src="/static_files/icons/more-dark.png" class="settings">
          </div> -->
        </header>
        <!-- @mouseenter="resizeIframe ? playerControlShow = true : ''"
          @mouseleave="resizeIframe ? playerControlShow = false : ''" -->
        <div
          class="event-iframe buzzplayer-1239885"
        >
          <div v-if="eventRoom.vmeste && startFrom > 0" @click="getGift" class="gifts-block">
            <div class="box"></div>
            <div v-if="giftsTimer > 0" class="timer">
              {{  giftsTimer | filterActionTimeline }}
            </div>
            <div v-else class="timer">
              Забрать!
            </div>
          </div>

          <div v-if="eventRoom.vmeste && startFrom < 0" @click="beBack" class="waiting-start">
            <div class="timer">Начало через {{ startFrom | filterStartTimeline }}</div>
          </div>
          <div v-else-if="!iframeUrl && !freeIframeUrl" @click="getPayedVideo" class="start-watch">
            <button v-if="!loading">
              {{ $t('start_watch') }}
              <i class="icon-double-click" />
            </button>
            <button v-else>
              <i class="loader" />
            </button>
          </div>

          <!-- <div
            style="position: absolute; top: 0px; left: 0px; display: block; z-index: 2; width: 100%; height: 40px;"
          > -->
          <div
            style="position: absolute; top: 0px; left: 0px; display: block; width: 100%; z-index: 2;"
          >
            <div v-if="playerControlShow">
              <div @click="resizeIframe = !resizeIframe" class="icon-minimize">
                <i title="Свернуть" />
              </div>
              <div @click="turnChat = !turnChat" class="icon-chat-minimize" :class="{ active: turnChat }">
                <i title="Свернуть чат" />
              </div>
              <div v-if="isOwner && !eventRoom.youtube_id" @click="showInResize = !showInResize" class="icon-filters" :class="{ active: showInResize }">
                <i title="Управление" />
              </div>
              <div v-if="!isDesktop && hints.hidechat" class="site-hint hidechat">
                <i @click="closeHint('hidechat')" />
                <ul>
                  <li>
                    <img src="/static_files/icons/minimize-dark.svg" alt="">
                    {{ $t('minimize') }}
                  </li>
                  <li>
                    <img src="/static_files/icons/chevron-right-dark.svg" alt="">
                    {{ $t('chat_minimize') }}
                  </li>
                  <li>
                    <img src="/static_files/icons/filters.svg" alt="">
                    {{ $t('controls') }}
                  </li>
                </ul>

                <!-- <div @click="closeHint('hidechat')" class="close">Закрыть</div> -->
              </div>
              <div v-if="isDesktop" @click="showFullscreen = !showFullscreen" class="icon-fullscreen">
                <i title="Полный экран" />
              </div>


              <div v-if="ownerAction && !resizeIframe" @click="syncTimeline" class="owner-action">
                <b class="online"></b>
                <span class="message">{{ ownerAction.message }}</span>
                <span class="timeline">{{ ownerAction.timeline | filterActionTimeline }}</span>
              </div>
            </div>
          </div>

          <div v-if="!freeIframeUrl" id="player" class="iframe"></div>
          <iframe
            v-else-if="freeIframeUrl"
            :src="freeIframeUrl"
            :title="eventRoom.film.title_ru"
            frameBorder="0"
            allow="autoplay *; fullscreen *"
            allowfullscreen
            class="iframe"
          />
          <div v-show="!resizeIframe" class="iframe-bg"></div>
          <div v-show="!resizeIframe" class="iframe-bg-blur" :style="`background-image:url(${eventRoom.film.poster})`"></div>

          <i @click="resizeIframe = !resizeIframe" class="fullscreen" title="Развернуть" />

          <div class="reactions-block"></div>
          <div class="snowball-area"></div>
        </div>

        <div v-show="(isDesktop && (!resizeIframe || showInResize)) || (!isDesktop && (showBar || showInResize))" class="bar">
        <!-- <div v-show="!resizeIframe || showInResize" class="bar"> -->
          <div class="m">
            <button
              v-if="isOwner && !eventRoom.film.iframe && !eventRoom.youtube_id"
              @click="barSection = 'controls'"
              :class="{ active: barSection === 'controls' }"
              class="wi"
            >
              <img src="/static_files/icons/filter.png" alt="">
              <span>{{ $t('controls') }}</span>
            </button>
            <button
              v-if="eventRoom.history && eventRoom.history.length"
              @click="barSection = 'history'"
              :class="{ active: barSection === 'history' }"
              class="wi"
            >
              <img src="/static_files/icons/list.png" alt="">
              <span>{{ $t('history') }}</span>
            </button>
            <button @click="cleanChat" class="wi">
              <img src="/static_files/icons/clear-format.png" alt="">
              <span>{{ $t('chat_clear') }}</span>
            </button>
            <button
              v-if="isOwner"
              @click="removeRoom"
              class="wi"
            >
              <img src="/static_files/icons/remove.png" alt="">
              <span>{{ $t('delete') }}</span>
            </button>
            <button
              v-if="!eventRoom.youtube_id"
              @click="downloadFile"
              class="wi"
            >
              <img src="/static_files/icons/download.png" alt="">
              <span>{{ $t('download') }}</span>
            </button>
            <!-- <button
              v-if="user && user.tg_notifi_release && user.tg_notifi_release.length && user.tg_notifi_release.includes(eventRoom.kinopoisk_id)"
              @click="removeRelease(eventRoom.kinopoisk_id)"
              class="wi"
            >
              <img src="/static_files/icons/notification.png" style="background-color:red;" alt="">
              <span>
                Следите
              </span>
            </button>
            <button
              v-else-if="[2,4,5].includes(eventRoom.film.type)"
              @click="addRelease(eventRoom.kinopoisk_id)"
              class="wi"
            >
              <img src="/static_files/icons/notification.png" alt="">
              <span>
                Следить
              </span>
            </button> -->
          </div>

          <template v-if="isOwner && barSection === 'controls'">
            <div
              v-if="!eventRoom.film.iframe && !eventRoom.youtube_id"
              v-show="!resizeIframe || showInResize"
              class="controls"
            >
              <!-- <div v-if="!isDesktop" @click="showInResize = false" class="close"></div> -->

              <div v-show="showControls" style="margin-bottom: 10px;">
                <button
                  v-if="isOwner && !eventRoom.vmeste"
                  @click="hideRoom(!eventRoom.hidden)"
                  class="wi"
                  :style="`border-bottom: 1px solid ${eventRoom.hidden ? 'orange' : '#9aae6b'}`"
                >
                  <img v-if="eventRoom.hidden" src="/static_files/icons/unlock.png" alt="">
                  <img v-else src="/static_files/icons/apple.png" alt="">
                  <span>{{ eventRoom.hidden ? $t('room_private') : $t('room_public') }} {{ $t('room') }}</span>
                </button>
              </div>
              
              <div v-show="showControls || showInResize" class="change-se">
                <!-- <div style="display:inline-block;position:relative;top:3px;">
                  <button id="next" @click="hiRes(eventRoom.kinopoisk_id)" class="wi">
                    <img src="/static_files/icons/video-player.png" alt="">
                    Смотреть 4k
                  </button>
                </div> -->
                <div v-if="eventRoom.film.seasons" style="display:inline-block;">
                  <label for="translations">
                    <span>{{ $t('translation') }}</span>
                    <select v-if="eventEpisode" id="translations" name="translations" v-model="eventTranslation" @change="setSeason">
                      <option v-for="(t, key) in eventRoom.film.seasons[eventSeason].episodes[eventEpisode].translation" :key="`translation-${key}`" :value="key">
                        {{ t.translation }} {{ t.quality }}
                      </option>
                    </select>
                  </label>
                  <div :style="`${isDesktop ? 'display:inline-block;' : 'display:block;'}`">
                    <label for="seasons">
                      <span>{{ $t('season') }}</span>
                      <select id="seasons" name="seasons" v-model="eventSeason" @change="setSeason">
                        <option v-for="(s, key) in eventRoom.film.seasons" :key="`season-${key}`" :value="key">
                          {{ key }}
                        </option>
                      </select>
                    </label>
                    <label for="episodes">
                      <span>{{ $t('episode') }}</span>
                      <select v-if="eventSeason" id="episodes" name="episodes" v-model="eventEpisode" @change="setEpisode">
                        <option v-for="(e, key) in eventRoom.film.seasons[eventSeason].episodes" :key="`episode-${key}`" :value="key">
                          {{ key }}
                        </option>
                      </select>
                    </label>
                  </div>
                  <div style="display:inline-block;position:relative;top:3px;">
                    <button id="prev" @click="changeSE('prev')" class="wi">
                      <img src="/static_files/icons/up-arrow.png" alt="" style="transform:rotateZ(-90deg);">
                      {{ $t('prev') }}
                    </button>
                  </div>
                  <div style="display:inline-block;position:relative;top:3px;">
                    <button id="next" @click="changeSE('next')" class="wi">
                      <img src="/static_files/icons/up-arrow.png" alt="" style="transform:rotateZ(90deg);">
                      {{ $t('next') }}
                    </button>
                  </div>
                </div>
                <div v-else-if="eventRoom.film.translation_iframe" style="display:inline-block;">
                  <label for="translations">
                    <!-- <span>Озвучка</span> -->
                    <select id="translations" name="translations" v-model="eventTranslation" @change="setSE">
                      <option v-for="(t, key) in eventRoom.film.translation_iframe" :key="`translation-${key}`" :value="key">
                        {{ t.name }} {{ t.quality }}
                      </option>
                    </select>
                  </label>
                </div>
                <!-- <div style="display:inline-block;position:relative;top:3px;">
                  <button @click="getPayedVideo" class="wi">
                    <img src="/static_files/icons/check.png" alt="">
                    Установить
                  </button>
                </div> -->
                
                <!-- <div v-show="!resizeIframe" style="margin-top:5px;">
                  <button @click="cleanChat" class="dbtn">
                    Очистить чат
                  </button>
                  <button v-if="!eventRoom.vmeste" @click="hideRoom(!eventRoom.hidden)" class="dbtn red">
                    Сделать {{ eventRoom.hidden ? 'общей' : 'приватной' }}
                  </button>
                  <button v-if="!eventRoom.vmeste" @click="removeRoom" class="dbtn red">
                    Удалить комнату
                  </button>
                </div> -->
              </div>

              <div v-if="!eventRoom.vmeste" class="search-hints" style="margin-top:10px;">
                <div>
                  <input
                    v-model="searchQuery"
                    :class="{ active: searchQuery }"
                    @input="trottled"
                    type="search"
                    autocomplete="off"
                    :placeholder="$t('room_change_film')"
                    style="min-width:240px;"
                  >
                </div>

                <div v-if="searchQuery.length > 1" class="datalist">
                  <ul>
                    <li
                      v-for="item in searchHints"
                      :key="`cinema-id-sh-${item.id}`"
                      @click="setFilm(item.id)"
                    >
                      <div class="poster">
                        <img :src="item.poster" alt="">
                      </div>
                      <p>
                        <strong>{{ item.title }}</strong>
                        <span v-if="item.year">{{ item.year }}</span>
                        <span v-if="item.genres"> {{ item.genres.join(', ') }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div style="margin:20px 0px 10px;">
                {{ $t('get_link_videofile') }} <button @click="downloadFile" class="dbtn middle">{{ $t('get_link') }}</button> 
                <!-- <div>
                  <a href="/static_files/cinema/_221207103449.jpg" target="_blank">
                    <img src="/static_files/cinema/_221207103449.jpg" alt="" style="margin-top:5px;max-width:15%;opacity:0.75;">
                  </a>
                </div> -->
              </div>

              <div v-if="downloadLink" style="margin-bottom:10px;">
                Если новая вкладка не открылась автоматически, откройте ее самостоятельно
                <a :href="downloadLink" target="_blank">https:{{ downloadLink }}</a> (кликабельно)
              </div>
              <div style="margin-bottom:5px;">
                <input
                  type="text"
                  v-model="videoSrc"
                  :placeholder="eventRoom.videofile ? eventRoom.videofile : $t('videofile_link') + '.mp4'"
                  style="min-width:200px;"
                />
                <div style="display:inline-block;position:relative;top:2px;">
                  <button @click="setVideoSrc" class="wi">
                    <img src="/static_files/icons/check.png" alt="">
                    {{ $t('apply') }}
                  </button>
                  <button v-if="eventRoom.videofile || (eventRoom.film.iframe && !eventRoom.youtube_id)" @click="removeVideoSrc" style="display:inline-block;" class="wi">
                    <img src="/static_files/icons/video-player.png" alt="">
                    {{ $t('player_default') }}
                  </button>
                </div>
              </div>
              <!-- <p style="padding: 10px;">
                <nuxt-link to="/help">Подробнее читайте в Помощи</nuxt-link>
              </p> -->
            </div>
        
            <div
              v-if="!eventRoom.youtube_id"
              v-show="!resizeIframe"
              style="margin:10px;border: 2px solid rgba(255, 255, 255, 0.15);padding:4px 10px 6px;"
            >
              <p>
                <b @click="showPlayerError = !showPlayerError" style="cursor:pointer;border-bottom:2px dashed rgb(251, 45, 127);">
                  {{ showPlayerError ? $t('hide') : $t('player_not_work') }} 
                </b>
              </p>
              <div v-if="showPlayerError" style="margin-top: 10px;">
                Используйте приведенные ниже варианты решения (помогут вам в 99.99% случаев):
                <p style="margin-top: 5px;">
                  Включите VPN или используйте другой способ подключения к интернету.
                  <!-- <nuxt-link to="/vpn" tag="button" class="mini">Включите VPN</nuxt-link> или используйте другой способ подключения к интернету. -->
                </p>
                <p style="margin-top: 5px;">
                  <!-- 2) <b style="cursor:pointer;border-bottom: 2px dashed rgb(251, 45, 127);" @click="changePlayer">Переключить плеер</b> -->
                  <button @click="changePlayer" class="mini">Переключить плеер</button>
                  на резервный. Обратите внимание, автоматическая синхронизация в нем не доступна.
                </p>
                <p style="margin-top: 5px;">
                  <!-- - <b style="cursor:pointer;border-bottom: 2px dashed rgb(251, 45, 127);" @click="downloadFile">Получите прямую ссылку</b> -->
                  <button @click="downloadFile" class="mini">Получите прямую ссылку</button>
                  на видеофайл и вставьте ее в соответствующее поле под плеером. Данный вариант доступен ТОЛЬКО для Владельца комнаты. Обратите внимание,
                  видеофайл изменится для ВСЕХ участников комнаты. (Safari блокирует автоматическое открытие новых окон, разрешите открытие вручную)
                  <figure>
                    <a href="/static_files/videosource.jpg" target="_blank" style="display:block;margin:5px 0px 0px;">
                      <img src="/static_files/videosource.jpg" alt="" style="max-width:320px;height:auto;">
                    </a>
                    <figcaption style="opacity: 0.5;font-size:1.2rem;">Нажмите для увеличения изображения</figcaption>
                  </figure>
                  <ol style="margin:5px 0px 0px 30px;">
                    <li>На странице скачивания в плеере выберите качество видео (правый нижний угол)</li>
                    <li>Нажмите кнопку Скачать (левее от выбора качества видео)</li>
                    <li>Перейдите в список загрузок (правый верхний угол браузера)</li>
                    <li>Правый клик мыши (ПКМ) на скачиваемом файле, Скопировать ссылку</li>
                    <li>Вернитесь в комнату просмотра и вставьте ссылку в соответствующее поле под плеером</li>
                    <li>Нажмите Применить</li>
                  </ol>
                </p>
                <p style="margin-top: 5px;">
                  <button class="mini" @click="reloadPage">Обновите страницу</button> для возврата к стандартному плееру.
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="barSection === 'history'">
            <div v-if="eventRoom.history && eventRoom.history.length" v-show="!resizeIframe" class="watch-history">
              <div v-if="!isDesktop" @click="barSection = ''" class="close"></div>
              <!-- <h3>История просмотра</h3> -->
              <!-- <div @click="showHistory = !showHistory" style="width:100%;">
                <span style="color:rgba(251, 45, 127, 1);cursor:pointer;font-size:1.4rem;">
                  {{ !showHistory ? 'Показать &#128072;' : 'Скрыть' }}
                </span>
              </div> -->

              <div class="items" v-show="showHistory">
                <div v-for="item in eventRoom.history" :key="`history-${item.date}`" class="item">
                  <span v-if="item.date">{{ item.date | filterDate }}</span>
                  <span v-if="item.se.translation">{{ $t('translation') }} {{ item.se.translation }}</span>
                  <span v-if="item.se.season">{{ $t('season') }} {{ item.se.season }}</span>
                  <span v-if="item.se.episode">{{ $t('episode') }} {{ item.se.episode }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div v-if="eventRoom.streamers.length" v-show="!resizeIframe" class="streamers">
          <h3>{{ $t('watch_with') }}</h3>
          <div @click="showStreamers = !showStreamers" style="width:100%;">
            <span style="color:rgba(251, 45, 127, 1);cursor:pointer;font-size:1.4rem;">
              {{ !showStreamers ? $t('show') + '&#128072;' : $t('hide') }}
            </span>
          </div>

          <div v-show="showStreamers" class="items">
            <div
              @click="setStream('Alon')"
              :class="{ 'active': streamerName === 'Alon' }"
              class="item"
            >
              <div class="poster">
                <img :src="`/static_files/bot-avatar.jpg`" alt="">
              </div>
              <strong>Alon</strong>
            </div>
            <div
              v-for="item in eventRoom.streamers"
              :key="`streamer-${item.channel_name}`"
              @click="setStream(item.channel_name)"
              :class="{ 'active': streamerName === item.channel_name }"
              :data-channel="item.channel_name"
              style="display:none;"
              class="item"
            >
              <div class="poster">
                <img :src="item.photo" :alt="item.channel_name">
              </div>
              <strong>{{ item.channel_name }}</strong>
            </div>
          </div>
        </div>
      </div>
      <client-only>
        <Chat v-show="!turnChat" :event="{ id: eventRoom._id, owner: eventRoom.owner, header: !resizeIframe }" />
      </client-only>
    </section>
    <section v-else class="room-page-end">
      <p>Комната не найдена</p>
      <nuxt-link to="/">
        Список комнат
      </nuxt-link>
    </section>

    <!-- <section class="merch">
      <h2>
        <a
          href="https://merch.unotalone.ru/"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >Наши мерчи</a>
        <sup
          @click="$router.push('/help?p=23_01_05_0')"
          style="padding:2px 8px;
          font-size:1.2rem;
          color:#fff;
          background-color:rgba(251, 45, 127, 1);
          border-radius:10px;
          text-transform:uppercase;
          cursor:pointer;"
        >
          +5% кэшбэк
        </sup>
      </h2>
      <Merch :title="eventRoom.film.title_ru" />
    </section> -->

    <LastArticles :payload="{ kpid: eventRoom.kinopoisk_id }" />
  </main>
</template>

<script>
import { mapState } from 'vuex'
let mountedIndexCinema = null

export default {
  middleware: 'auth',
  components: {
    Chat: () => import('~/components/Chat.vue'),
    LastArticles: () => import('~/components/LastArticles.vue'),
    // Merch: () => import('~/components/Merch.vue'),
  },
  filters: {
    filterDate(val) {
      const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
      const month = new Date(val).getMonth() + 1 < 10 ? `0${new Date(val).getMonth() + 1}` : new Date(val).getMonth() + 1
      const year = new Date(val).getFullYear() < 10 ? `0${new Date(val).getFullYear()}` : new Date(val).getFullYear()
      
      return `${day}.${month}.${year}`
    },
    filterActionDate(val) {
      const hourDate = new Date(val).getHours() < 10 ? `0${new Date(val).getHours()}` : new Date(val).getHours()
      const minDate = new Date(val).getMinutes() < 10 ? `0${new Date(val).getMinutes()}` : new Date(val).getMinutes()
      const secDate = new Date(val).getSeconds() < 10 ? `0${new Date(val).getSeconds()}` : new Date(val).getSeconds()
      
      return `${hourDate}:${minDate}:${secDate}`
    },
    filterStartTimeline(val) {
      if (val > 0) {
        val = Number(val);
        const h = Math.floor(val / 3600)
        const m = Math.floor(val % 3600 / 60)
        const s = Math.floor(val % 3600 % 60)

        const hDisplay = h > 0 ? h + (h === 1 ? " ч. " : " ч. ") : ""
        const mDisplay = m > 0 ? m + (m === 1 ? " мин. " : " мин. ") : ""
        const sDisplay = s > 0 ? s + (s === 1 ? " сек." : " сек.") : ""
        return hDisplay + mDisplay + sDisplay 
      } else {
        const hourTimeline = Math.floor((-val / 60) / 60)
        const minTimeline = Math.floor((-val / 60) % 60)
        const secTimeline = Math.floor(-val % 60)
        return `${
          hourTimeline ? `${hourTimeline} ч.` : ''
        }${
          minTimeline ? ` ${minTimeline} мин.` : ''
        }${
          secTimeline ? ` ${secTimeline} сек.` : ''
        }`
      }
    },
    filterActionTimeline(val) {
      val = Number(val);
      const h = Math.floor(val / 3600)
      const m = Math.floor(val % 3600 / 60)
      const s = Math.floor(val % 3600 % 60)

      const hDisplay = h > 0 ? h + (h === 1 ? " ч. " : " ч. ") : ""
      const mDisplay = m > 0 ? m + (m === 1 ? " мин. " : " мин. ") : ""
      const sDisplay = s > 0 ? s + (s === 1 ? " сек." : " сек.") : ""
      return hDisplay + mDisplay + sDisplay 
    },
  },
  head() {
    if (this.eventRoom) {
      const day =
        new Date(this.eventRoom.date).getDate() === new Date().getDate()
          ? 'Сегодня в '
          : 'Вчера в '
      const hour = new Date(this.eventRoom.date).getHours()
      const minutes =
        new Date(this.eventRoom.date).getMinutes() > 9
          ? new Date(this.eventRoom.date).getMinutes()
          : '0' + new Date(this.eventRoom.date).getMinutes()
      const date = hour + ':' + minutes
      const description = this.eventRoom.film.material_data
        ? this.eventRoom.film.material_data.description.slice(0, 100)
        : ''
      const title = `Смотрим вместе ${this.eventRoom.film.title_ru} ${day.toLowerCase()} ${date}`
      return {
        title: title,
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: this.fullPath
          }
        ],
        meta: [
          {
            hid: 'keywords',
            name: 'keywords',
            content: `смотрим вместе ${this.eventRoom.film.title_ru}, ${this.eventRoom.film.title_ru} совместный просмотр`
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: this.fullPath
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: title
          },
          {
            hid: 'description',
            name: 'description',
            content:
              day +
              date +
              ' смотрим вместе ' +
              this.eventRoom.film.title_ru +
              '. ' +
              description
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content:
              day +
              date +
              ' смотрим вместе ' +
              this.eventRoom.film.title_ru +
              '. ' +
              description
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.eventRoom.film.poster || '/static_files/no-poster.png'
          }
        ]
      }
    } else {
      return {
        title: 'Комната не найдена'
      }
    }
  },
  watch: {
    // playerTimers: {
    //   handler(val){
    //     console.log('watch playerTimers', val)
    //   },
    //   deep: true
    // },
    startFrom(val) {
      // if ((this.kodikTime < val - 4) || (this.kodikTime > val + 4)) {
      //   const player = this.$refs.iframe || document.querySelector('.iframe')
      //   if (this.watchSE && player) {
      //     player.contentWindow.postMessage({ key: "kodik_player_api", value: { method: "seek", seconds: val } }, '*')
      //   }
      // }
    },
    // iframeUrl() {
    //   this.playVideo()
    // },
    tabActive(val) {
      if (!val) {
        // this.eventsLeave()
        
        clearInterval(this.intervals.gifts)

        this.$store.commit('setPlayerPlay', false)

        if (this.player) {
          this.player.api('pause')
        } else if (this.freeIframeUrl) {
          document.querySelector(".iframe").contentWindow.postMessage({"api":"pause"}, "*");
        }
      } else {
        this.eventsInit()

        this.giftsInit()
        // if (!this.freeIframeUrl) this.eventsInit()

        if (this.player) {
          this.player.api('play')
        } else if (this.freeIframeUrl) {
          document.querySelector(".iframe").contentWindow.postMessage({"api":"play"}, "*");
        }
      }
    },
    chatMembers(val) {
      if (val.users && val.users.length && this.eventRoom.streamers.length) {
        const list = document.querySelectorAll(`[data-channel]`)
        if (list.length) {
          list.forEach(ch => {
            ch.style.display = 'none'
          })
        }
        
        let find = 0
        
        val.users.forEach(m => {
          const elem = document.querySelector(`[data-channel="${m.user.name}"]`)
          if (elem) {
            ++find
            elem.style.display = 'grid'
          }
        })

        if (!find) {
          document.querySelector(`.streamers`).style.display = 'none'
        } else {
          document.querySelector(`.streamers`).style.display = 'block'
        }
      }
    },
    resizeIframe(val) {
      const mobileMenu = document.querySelector('.mobile .menu')
      if (mobileMenu) {
        if (!val) {
          mobileMenu.style.display = ''
        } else {
          mobileMenu.style.display = 'none'
          this.turnChat = false
        }
      
        // if (!val) {
        //   document.body.style.overflow = ''
        // } else {
        //   document.body.style.overflow = 'hidden'
        //   this.turnChat = false
        // }
      }

      // if (val) {
      //   this.playerControlTimeout = setTimeout(() => {
      //     this.playerControlShow = false
      //   }, 1000 * 5)
      // } else {
      //   clearTimeout(this.playerControlTimeout)
      //   this.playerControlShow = true
      // }
    },
    user(val) {
      if (!val) this.$router.push(`/signin?ref=${encodeURI(this.$route.fullPath)}`)
      mountedIndexCinema = null
      this.eventsInit()
    },
    showFullscreen(val) {
      this.$store.commit('setError', { status: 'info', msg: 'Нажмите F11 на клавиатуре' })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
  },
  computed: {
    ...mapState(['user', 'isDesktop', 'chatMembers', 'playerAction', 'playerPlay', 'tabActive', 'playerjsAdded']),
    lang () {
      return this.$i18n.locale
    },
    isOwner() {
      return this.user && this.eventRoom.owner && this.user.id === this.eventRoom.owner.id
    },
    fullPath() {
      return `https://unotalone.su/vmeste/${this.eventRoom._id}`
    },
    host() {
      return window.location.hostname
    }
  },
  async asyncData({ params, $axios, error, i18n }) {
    const { data } = await $axios.get(`/api/cinema?id=${params.id}`)
    // console.log(data)
    if (data.error) {
      error(data.error)
    } else {
      let translationId = ''
      let seasonId = ''
      let episodeId = ''
      // const iframeSrc = ''
      if (!data.film.seasons) {
        translationId = data.se.translation
          ? data.se.translation
          : Object.keys(data.film.translation_iframe)[0]
        // iframeSrc = data.film.translation_iframe[translationId].iframe
      } else if (data.film.seasons) {
        seasonId = data.se.season
          ? data.se.season
          : Object.keys(data.film.seasons)[0]
        episodeId = data.se.episode
          ? data.se.episode
          : Object.keys(data.film.seasons[seasonId].episodes)[0]
        translationId = data.se.translation
          ? data.se.translation
          : Object.keys(data.film.seasons[seasonId].episodes[episodeId].translation)[0]
        // iframeSrc = data.film.seasons[seasonId].episodes[episodeId].translation[translationId].iframe
      }

      // const iframeUrl = data.film.iframe_url

      const changeSEdata = !data.youtube_id
        ? {
            translation: data.film.seasons
              ? data.film.seasons[seasonId].episodes[episodeId].translation[translationId].translation
              : data.film.translation_iframe[translationId].name,
            season: seasonId,
            episode: episodeId
          }
        : {
            translation: 'YouTube',
            season: '',
            episode: ''
          }
        
      const startFrom = data.time ? data.time : 0
      // if (startFrom) data.film.iframe += `&start=${data.time}`

      return {
        payedSubtitle: null,
        payedPlaylist: null,
        playerControlTimeout: null,
        playerControlShow: true,
        autoNext: true,
        downloadLink: '',
        showStreamers: false,
        showBar: false,
        barSection: 'controls',
        showControls: true,
        showHistory: true,
        player: null,
        iframeUrl: '',
        freeIframeUrl: '',
        paid: 0,
        giftsTimer: 0,
        intervals: {
          gifts: null,
          start: null
        },
        // --- //
        startFrom,
        videoSrc: '',
        showFullscreen: false,
        showInResize: false,
        showPlayerError: false,
        loading: false,
        changeSEdata,
        hints: {
          hidechat: false
        },
        hidden: true,
        showShare: false,
        streamerName: 'Alon',
        eventRoom: data,
        rooms: [],
        room: data._id,
        isBanned: true,
        eventTranslation: translationId,
        eventSeason: seasonId,
        eventEpisode: episodeId,
        eventTime: '',
        watchSE: true,
        playerID: 0,
        eventDelay: null,
        eventTimer: 0,
        showMemberName: false,
        showCreateStream: false,
        searchQuery: '',
        searchHints: [],
        gets: {},
        userFriends: [],
        kodikTime: 0,
        showTurn: false,
        showDesc: false,
        // ownerTimeline: 0,
        eventDelayInterval: null,
        addToHistory: null,
        blockI: 0,
        showPremiumComponent: false,
        resizeIframe: false,
        turnChat: false,
        ownerAction: null,
        // playerTimers: {},
      }
    }
  },
  // created() {
  //   if (!this.user) {
  //     return this.$router.push('/signin')
  //   }
  // },
  mounted() {
    document.body.style.overflow =''
    
    if (mountedIndexCinema !== this.$route.fullPath) {
      // console.log('cinema id mounted')

      mountedIndexCinema = this.$route.fullPath

      const dateDay = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
      Object.keys(this.hints).forEach(type => {
        if (!localStorage.getItem(`hint_${type}_${dateDay}`)) {
          this.hints[type] = true
        }
      })

      this.eventsInit()

      if (this.eventRoom.vmeste) {
        this.intervals.start = setInterval(() => {
          const endTime = this.eventRoom.film.duration - this.startFrom
          if (endTime < 0) {
            this.startFrom = 0
            clearInterval(this.intervals.start)
            return
          }
          this.startFrom += 1
        }, 1000)
      }
    }

    // this.barSection = this.isDesktop ? 'controls' : ''
    
    this.showStreamers = this.isDesktop
    // this.showControls = this.isDesktop
    // this.showHistory = this.isDesktop

    setTimeout(() => {
      if (!this.user || this.user.group === 1) {
//       const buzzoolaAdUnits = document.createElement('script')
//       buzzoolaAdUnits.innerHTML = `
// (window.buzzoolaAdUnits = window.buzzoolaAdUnits || []).push({
//   slot: document.querySelector('.buzzplayer-1239885'),
//   placementId: 1239885
// });`
//       document.body.appendChild(buzzoolaAdUnits)

//       const buzzlibrary = document.createElement('script')
//       buzzlibrary.src = `https://tube.buzzoola.com/build/buzzlibrary.js`
//       buzzlibrary.setAttribute('defer', '')
//       document.body.appendChild(buzzlibrary)


        const rsyaHorizon = document.createElement('script')
        rsyaHorizon.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6711067-2',
      blockId: 'R-A-6711067-2'
    })
  })`
        document.body.appendChild(rsyaHorizon)
      }
    }, 200)
  },
  beforeDestroy() {
    // console.log('cinema id beforeDestroy')

    mountedIndexCinema = null

    window.removeEventListener("message", this.allohaPlayerOwnerEvents)
    window.removeEventListener("message", this.allohaPlayerEvents)

    if (this.user && this.user.id === this.eventRoom.owner.id) {
      const obj = {
        room_id: this.eventRoom._id,
        time: this.kodikTime
      }
      this.$socket.emit(`unotalone:kodik:pause`, obj)
    }
  },
  methods: {
    async hiRes(kpid) {
      const { data } = await this.$axios.get(`/api/hires?kp=${kpid}`)
      if (!data.error) {
        this.$socket.emit(`unotalone:videofile`, { room: this.eventRoom._id, videofile: data }, (cb) => {
          this.$store.commit('setError', { status: 'info', msg: cb })
          setTimeout(() => location.reload(), 1500)
        })
      } else {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 1500)
      }
    },
    addRelease(id) {
      if (!this.user) return
      const user = this.user
      if (user.tg_notifi_release.includes(id)) return
      user.tg_notifi_release.push(id)
      this.$axios.post(`/api/user/tg/release`, user.tg_notifi_release)
      this.$store.commit('setUser', user)
    },
    removeRelease(id) {
      if (!this.user) return
      const user = this.user
      const release = user.tg_notifi_release.filter(el => el !== id)
      user.tg_notifi_release = release
      this.$axios.post(`/api/user/tg/release`, user.tg_notifi_release)
      this.$store.commit('setUser', user)
    },
    async roomPinned(roomId) {
      if (this.user && this.user.id !== this.eventRoom.owner.id) {
        this.$store.commit('setError', { status: 'ok', msg: `Вы не владелец комнаты` })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (this.loading) return
      this.loading = true

      const { data } = await this.$axios.post(`/api/room-pinned`, { room_id: roomId })
      this.loading = false
      if (!data.error) {
        this.eventRoom.expireAt = data.expire
        this.$store.commit('setError', { status: 'ok', msg: `Комната ${data.expire ? 'откреплена' : 'закреплена'}` })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    giftsInit() {
      clearInterval(this.intervals.gifts)
      this.intervals.gifts = setInterval(() => {
        if (this.giftsTimer) {
          --this.giftsTimer
        }
      }, 1000)
    },
    async getGift() {
      if (this.giftsTimer) {
        this.$store.commit('setError', { status: 'info', msg: `Можно открыть через ${Math.ceil(this.giftsTimer / 60)} мин.` })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        // const checkAdBlock = document.querySelector('#yandex_rtb_R-A-6711067-2')
        // if (checkAdBlock && (!checkAdBlock.children.length || checkAdBlock.children[0].tagName === 'LINK')) {
        //   this.giftsTimer = 10 * 60
        //   this.$store.commit('setError', {
        //     status: 'info',
        //     msg: `Отключите блокировщик рекламы!`
        //   })
        //   setTimeout(() => this.$store.commit('clearError'), 2000)
        // } else {

        // }
        if (this.loading) return
        this.loading = true
        const body = {
          room_id: this.eventRoom._id,
          kinopoisk_id: this.eventRoom.kinopoisk_id
        }
        const { data } = await this.$axios.post(`/api/gifts`, body)
        this.loading = false
        if (data.error) {
          this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        } else {
          this.$store.commit('setError', { status: 'ok', msg: data.message })
          this.giftsTimer = 10 * 60
          this.giftsInit()
        }
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 300)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`unotalone:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    },
    setFilm(id) {
      if (this.gets.setFilm) return
      this.gets.setFilm = true
      const body = {
        room_id: this.eventRoom._id,
        film_id: id,
        user_id: this.user.id,
      }
      this.$socket.emit(`unotalone:room:change:film`, body)
    },
    removeVideoSrc() {
      this.$socket.emit(`unotalone:videofile:remove`, { room: this.eventRoom._id }, (cb) => {
        this.$store.commit('setError', { status: 'ok', msg: 'Ссылка удалена' + cb })
        setTimeout(() => location.reload(), 1500)
      })
    },
    setVideoSrc() {
      if (!this.videoSrc) {
        this.$store.commit('setError', { status: 'info', msg: 'Укажите ссылку на видеофайл' })
        setTimeout(() => this.$store.commit('clearError'), 1500)
        return
      }
      if (!this.videoSrc.match(/https:\/\/.*\..*\/.*\.mp4$/)) {
        this.$store.commit('setError', { status: 'info', msg: 'Неверная ссылка. Пример https://site.ru/film-name-2022.mp4' })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        return
      }
      this.$socket.emit(`unotalone:videofile`, { room: this.eventRoom._id, videofile: this.videoSrc }, (cb) => {
        this.$store.commit('setError', { status: 'ok', msg: 'Ссылка установлена' + cb })
        setTimeout(() => location.reload(), 1500)
      })
    },
    async downloadFile() {
      if (this.$device.isSafari) {
        this.$store.commit('setError', { status: 'info', msg: `Safari может блокировать открытие окон` })
        setTimeout(() => this.$store.commit('clearError'), 3000)
        // return
      }
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/download?id=${this.eventRoom.kinopoisk_id}`)
      this.loading = false
      if (!data.error) {
        this.downloadLink = data.replace('svetacdn.in', 'annacdn.cc')
        window.open(this.downloadLink)
      } else {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    cleanChat() {
      this.$socket.emit(`unotalone:chat:clean`, { id: this.eventRoom._id }, (cb) => {
        const chatMessages = document.querySelector('.chat .messages')
        if (chatMessages) chatMessages.innerHTML = ''
        this.$store.commit('setError', { status: 'ok', msg: 'Чат очищен' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      })
    },
    async changePlayer() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/player?kpid=${this.eventRoom.kinopoisk_id}`)
      this.loading = false
      // console.log(data)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.iframeUrl = ''
        this.freeIframeUrl = data
      }
    },
    setStream(channel) {
      if (this.streamerName === channel) return

      this.streamerName = channel
      if (channel === 'Alon') {
        this.iframeUrl = this.eventRoom.film.iframe_url
      } else {
        this.iframeUrl = `https://player.twitch.tv/?channel=${channel}&parent=${this.host}`
      }
    },
    beBack() {
      this.$store.commit('setError', { status: 'info', msg: 'Начало в 21:00.' })
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    // watchStart() {
    //   this.watchStarting = true
    //   const player = this.$refs.iframe || document.querySelector('.iframe')
    //   if (player) {
    //     player.contentWindow.postMessage({ key: "kodik_player_api", value: { method: "play" } }, '*')
    //   }
    // },
    reloadPage() {
      location.reload()
    },
    closeHint(type) {
      const dateDay = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
      localStorage.setItem(`hint_${type}_${dateDay}`, 'true')
      this.hints[type] = false
    },
    allohaPlayerEvents(event) {
      // console.log(event.data)
      if (event.data.event === 'time') {
        if (Math.floor(event.data.time) !== this.kodikTime) {
          this.kodikTime = Math.floor(event.data.time)

          const obj = {
            room_id: this.eventRoom._id,
            time: this.kodikTime,
            owner: false,
            user: {
              id: this.user.id,
              name: this.user.name,
              photo: this.user.photo,
            },
          }
          this.$socket.emit(`unotalone:kodik:time`, obj)
        }
      }
    },
    allohaPlayerOwnerEvents(event) {
      // console.log(event.data)
      if (event.data.event === 'time') {
        if (Math.floor(event.data.time) !== this.kodikTime) {
          this.kodikTime = Math.floor(event.data.time)

          const obj = {
            room_id: this.eventRoom._id,
            time: this.kodikTime,
            owner: true,
            user: {
              id: this.user.id,
              name: this.user.name,
              photo: this.user.photo,
            },
          }
          this.$socket.emit(`unotalone:kodik:time`, obj)

          this.ownerAction = {
            date: Date.now(),
            message: 'Время владельца',
            timeline: obj.time
          }
        }
      } else if (event.data.event === 'play') {
        const obj = {
          room_id: this.eventRoom._id,
          time: this.kodikTime
        }
        this.$socket.emit(`unotalone:kodik:play`, obj)

        this.ownerAction = {
          date: Date.now(),
          message: 'Воспроизведение',
          timeline: this.kodikTime
        }
      } else if (event.data.event === 'pause') {
        this.$store.commit('setPlayerPlay', false)

        const obj = {
          room_id: this.eventRoom._id,
          time: this.kodikTime
        }
        this.$socket.emit(`unotalone:kodik:pause`, obj)

        this.ownerAction = {
          date: Date.now(),
          message: 'Пауза',
          timeline: this.kodikTime
        }
      }
    },
    eventsInit() {
      this.$socket.off(`unotalone:kodik:time`)
      this.$socket.off(`unotalone:kodik:play`)
      this.$socket.off(`unotalone:kodik:pause`)
      this.$socket.off(`unotalone:room:change:se`)
      this.$socket.off(`unotalone:room:change:film`)
      this.$socket.off(`unotalone:videofile`)

      if (!this.eventRoom.owner) return

      this.$socket.on(`unotalone:room:change:film`, _ => {
        location.reload()
      })

      this.$socket.on('unotalone:videofile', (data) => {
        this.$store.commit('setError', { status: 'info', msg: 'Смена видеофайла' })
        setTimeout(() => location.reload(), 1500)
      })
      
      if (this.isOwner) {
        window.addEventListener("message", this.allohaPlayerOwnerEvents)

        const player = document.getElementById("player")
        if (player) {
          player.addEventListener("end", (event) => {
            // console.log('end', event)

            if (this.autoNext && this.eventRoom.film.seasons) {
              this.changeSE('next')
            }
          })

          // player.addEventListener("end", (event) => {
          //   console.log('end', event)
          // })

          player.addEventListener("play", (event) => {
            // console.log('play', event)
            const obj = {
              room_id: this.eventRoom._id,
              time: this.kodikTime
            }
            this.$socket.emit(`unotalone:kodik:play`, obj)

            this.ownerAction = {
              date: Date.now(),
              message: 'Воспроизведение',
              timeline: this.kodikTime
            }
          })
          player.addEventListener("pause", (event) => {
            // console.log('pause', event)
            this.$store.commit('setPlayerPlay', false)

            const obj = {
              room_id: this.eventRoom._id,
              time: this.kodikTime
            }
            this.$socket.emit(`unotalone:kodik:pause`, obj)

            this.ownerAction = {
              date: Date.now(),
              message: 'Пауза',
              timeline: this.kodikTime
            }
          })
          player.addEventListener("time", (event) => {
            // console.log('time', event)

            if (Math.floor(event.info) !== this.kodikTime) {
              this.kodikTime = Math.floor(event.info)
              const obj = {
                room_id: this.eventRoom._id,
                time: this.kodikTime,
                owner: true,
                user: {
                  id: this.user.id,
                  name: this.user.name,
                  photo: this.user.photo,
                },
              }
              this.$socket.emit(`unotalone:kodik:time`, obj)

              this.ownerAction = {
                date: Date.now(),
                message: 'Время владельца',
                timeline: obj.time
              }
            }
          })
        }
      } else {
        window.addEventListener("message", this.allohaPlayerEvents)

        const player = document.getElementById("player")
        if (player) {
          player.addEventListener("time", (event) => {
            // console.log('time', event)
            if (Math.floor(event.info) !== this.kodikTime) {
              this.kodikTime = Math.floor(event.info)

              const obj = {
                room_id: this.eventRoom._id,
                time: this.kodikTime,
                owner: false,
                user: {
                  id: this.user.id,
                  name: this.user.name,
                  photo: this.user.photo,
                },
              }
              this.$socket.emit(`unotalone:kodik:time`, obj)
            }
          })
        }

        this.$socket.on(`unotalone:room:change:se`, (data) => {
          // console.log(`unotalone:room:change:se`, data)
          this.eventTranslation = data.translation
          this.eventSeason = data.season
          this.eventEpisode = data.episode

          this.changeSEdata = {
            translation: this.eventRoom.film.seasons
              ? this.eventRoom.film.seasons[this.eventSeason].episodes[this.eventEpisode].translation[this.eventTranslation].translation
              : this.eventRoom.film.translation_iframe[this.eventTranslation].name,
            season: this.eventSeason,
            episode: this.eventEpisode
          }

          this.ownerAction = {
            date: Date.now(),
            message: `Переключение - ${
              this.changeSEdata.translation
            } ${
              data.season ? data.season + ' серия' : ''
            } ${
              data.episode ? data.episode + ' эпизод' : ''
            }`,
            timeline: 0
          }

          this.getPayedVideo()
        })

        this.$socket.on(`unotalone:chat:clean`, (data) => {
          const chatMessages = document.querySelector('.chat .messages')
          if (chatMessages) chatMessages.innerHTML = ''
        })

        if (this.eventRoom.vmeste) return

        this.$socket.on(`unotalone:kodik:pause`, (data) => {
          // if (!this.iframeUrl) return
          this.ownerAction = {
            date: Date.now(),
            message: 'Пауза',
            timeline: data
          }
          this.$store.commit('setPlayerPlay', false)
          if (this.freeIframeUrl) {
            document.querySelector(".iframe").contentWindow.postMessage({"api":"pause"}, "*");
            return
          }
          this.player.api('pause')
        })

        this.$socket.on(`unotalone:kodik:play`, (data) => {
          // if (!this.iframeUrl) return
          this.ownerAction = {
            date: Date.now(),
            message: 'Воспроизведение',
            timeline: data
          }
          this.$store.commit('setPlayerPlay', true)
          if (this.freeIframeUrl) {
            document.querySelector(".iframe").contentWindow.postMessage({"api":"play"}, "*");
            return
          }
          this.player.api('play')
        })
      }

      this.$socket.on(`unotalone:kodik:time`, (data) => {
        // if (!this.iframeUrl) return
        // this.playerTimers[data.user.id] = data
        this.$store.commit('setPlayerTimers', data)

        if (!this.isOwner && data.owner) {
          this.ownerAction = {
            date: Date.now(),
            message: 'Время владельца',
            timeline: data.time
          }
          
          if (!this.playerPlay) this.getPayedVideo()

          if (this.freeIframeUrl) {
            if (data.time > this.kodikTime + 2 || data.time < this.kodikTime - 2) {
              document.querySelector(".iframe").contentWindow.postMessage({"api":"seek","set":data.time}, "*");
              if (!this.playerPlay) {
                document.querySelector(".iframe").contentWindow.postMessage({"api":"play"}, "*");
              }
            }
            return
          }

          if (data.time > this.kodikTime + 2 || data.time < this.kodikTime - 2) {
            this.player.api('seek', data.time)
            if (!this.playerPlay) {
              this.player.api('play')
            }
          }
        }
      })
    },
    eventsLeave() {
      this.$socket.off(`unotalone:kodik:time`)
      this.$socket.off(`unotalone:kodik:play`)
      this.$socket.off(`unotalone:kodik:pause`)
      this.$socket.off(`unotalone:room:change:se`)
      this.$socket.off(`unotalone:room:change:film`)
    },
    syncTimeline() {
      if (this.eventRoom.vmeste) {
        // this.iframeUrl = this.eventRoom.film.iframe += `&start=${Math.floor((Date.now() - this.eventRoom.date) / 1000)}`
        this.player.api('seek', Math.floor((Date.now() - this.eventRoom.date) / 1000))
        if (!this.playerPlay) this.player.api('play')
      }
    },
    // setIframe() {
    //   this.iframeUrl = this.eventRoom.film.iframe
    //   this.playVideo()
    // },
    playVideo() {
      if (!this.playerjsAdded) {
        const playerjs = document.createElement('script')
        playerjs.src = `/static_files/playerjs.js`
        document.head.appendChild(playerjs)
      }
      
      const params = {
        id:"player",
        file: this.iframeUrl,
        // subtitle: this.payedSubtitle,
        default_quality: '480p',
        cuid: this.iframeUrl,
      }

      // console.log('params.', params)

      setTimeout(() => {
        this.$store.commit('setPlayer', true)
        /* eslint-disable */
        this.player = new Playerjs(params)
        if (this.eventRoom.vmeste) this.syncTimeline()
      }, 1000)
    },
    async getPayedVideo() {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      this.payedPlaylist = null

      if (this.eventRoom.youtube_id) {
        this.iframeUrl = this.eventRoom.film.iframe
        this.playVideo()
        return
      } else if (this.eventRoom.film.iframe) {
        this.freeIframeUrl = this.eventRoom.film.iframe
        this.playVideo()
        return
      } else if (this.eventRoom.videofile) {
        this.iframeUrl = this.eventRoom.videofile
        this.playVideo()
        return
      }

      if (this.loading) return
      this.loading = true

      const kpid = this.eventRoom.kinopoisk_id
      const se = { s: this.eventSeason, e: this.eventEpisode, t: this.eventTranslation, translation: '' }
      se.translation = this.eventRoom.film.seasons
        ? this.eventRoom.film.seasons[se.s].episodes[se.e].translation[se.t].translation
        : this.eventRoom.film.translation_iframe[se.t].name

      const obj = {
        room_id: this.eventRoom._id,
        kpid,
        title_ru: this.eventRoom.film.title_ru,
        title_en: this.eventRoom.film.title_en,
        se,
      }
      const { data } = await this.$axios.post(`/api/user/payed`, obj)
      // console.log('payed', data)
      this.loading = false

      if (!data.error) {
        this.freeIframeUrl = ''

        this.iframeUrl = data.playlist // ''

        // if (this.$device.isIos) {
        //   data.file.forEach(el => {
        //     this.iframeUrl += `${el.audio ? '{' + el.audio + '}' : '{}' }${el.h264};`
        //   })
        // } else {
        //   data.file.forEach(el => {
        //     this.iframeUrl += `${el.audio ? '{' + el.audio + '}' : '{}' }${el.av1 ? el.av1 : el.h264};`
        //   })
        // }

        // data.file.forEach(el => {
        //   const { audio, url } = el

        //   if (this.$device.isIos) {
            
        //     this.iframeUrl += `${el.audio ? '{' + el.audio + '}' : '{}' }${el.av1 ? el.av1 : el.h264};`
        //   }
        // })

        // console.log('this.iframeUrl', this.iframeUrl)

        this.payedPlaylist = data.playlist_file

        this.payedSubtitle = data.subtitle
        
        this.paid = data.paid

        this.playVideo()
        
        if (this.eventRoom.vmeste) {
          this.giftsTimer = 10 * 60
          this.giftsInit()
        }
      } else {
        this.iframeUrl = ''

        if (this.eventRoom.film.seasons) {
          const findItem = this.eventRoom.film.seasons[this.eventSeason].episodes[this.eventEpisode].translation[this.eventTranslation]
          this.freeIframeUrl = findItem.iframe + '&hidden=season,translation,episode'
        } else {
          const findItem = this.eventRoom.film.translation_iframe[this.eventTranslation]
          this.freeIframeUrl = findItem.iframe + '&hidden=season,translation,episode'
        }
      }
      this.$store.commit('setPlayerPlay', true)
      // if (this.isOwner) this.setSE()
    },
    setSeason() {
      this.eventEpisode = Object.keys(this.eventRoom.film.seasons[this.eventSeason].episodes)[0]
      this.setEpisode()
    },
    setEpisode() {
      if (!this.eventRoom.film.seasons[this.eventSeason].episodes[this.eventEpisode].translation[this.eventTranslation]) {
        this.eventTranslation = Object.keys(this.eventRoom.film.seasons[this.eventSeason].episodes[this.eventEpisode].translation)[0]
      }
      this.setSE()

      // this.getPayedVideo()
    },
    async setSE() {
      const obj = {
        room: this.room,
        kino_id: this.eventRoom.kinopoisk_id,
        translation: this.eventTranslation,
        season: this.eventSeason,
        episode: this.eventEpisode
      }

      this.$socket.emit(`unotalone:room:change:se`, obj)

      const translation = this.eventRoom.film.seasons
        ? this.eventRoom.film.seasons[this.eventSeason].episodes[this.eventEpisode].translation[this.eventTranslation].translation
        : this.eventRoom.film.translation_iframe[this.eventTranslation].name

      this.ownerAction = {
        date: Date.now(),
        message: `Переключение - ${
          translation
        } ${
          obj.season ? obj.season + ' серия' : ''
        } ${
          obj.episode ? obj.episode + ' эпизод' : ''
        }`,
        timeline: 0
      }

      this.changeSEdata = {
        translation,
        season: this.eventSeason,
        episode: this.eventEpisode
      }

      this.getPayedVideo()
    },
    changeSE(type) {
      if (type === 'next') {
        if (this.eventRoom.film.seasons[this.eventSeason].episodes[+this.eventEpisode+1]) {
          this.eventEpisode = '' + (+this.eventEpisode + 1)
          this.setEpisode()
          this.getPayedVideo()
        }
      } else if (type === 'prev') {
        if (this.eventRoom.film.seasons[this.eventSeason].episodes[+this.eventEpisode-1]) {
          this.eventEpisode = '' + (+this.eventEpisode - 1)
          this.setEpisode()
          this.getPayedVideo()
        }
      }
    },
    hideRoom(value) {
      const obj = {
        room_id: this.eventRoom._id,
        hidden: value
      }
      
      this.$socket.emit(`unotalone:room:hidden`, obj, (cb) => {
        this.eventRoom.hidden = cb.hidden
        this.$store.commit('setError', { status: 'ok', msg: `Комната ${ cb.hidden ? 'скрыта' : 'открыта' }` })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      })
    },
    async removeRoom() {
       if (this.isOwner) {
        if (this.eventRoom._id) {
          const confirmAlert = confirm('Удалить комнату?')
          if (!confirmAlert) return

          const url = `/api/cinema?id=${this.eventRoom._id}&user_id=${this.user.id}`
          const { data } = await this.$axios.delete(url)
          if (data.error) {
            this.$store.commit('setError', { status: 'warn', msg: data.error.message })
            setTimeout(() => this.$store.commit('clearError'), 1000)
          } else {
            this.$store.commit('setError', { status: 'ok', msg: 'Комната удалена' })
            setTimeout(() => {
              this.$store.commit('clearError')
              this.$router.push('/')
            }, 1000)
          }
        }
       }
    },
    copyLink() {
      const btn = document.querySelector('.copy-link')
      btn.disabled = true
      const input = document.querySelector('#share')
      input.select()
      document.execCommand('copy')
      btn.textContent = 'Ссылка скопирована'
      btn.style.backgroundColor = 'lightgreen'
      btn.style.color = '#000'
      setTimeout(() => {
        btn.textContent = 'Скопировать ссылку'
        btn.style.backgroundColor = ''
        btn.style.color = ''
        btn.disabled = false
      }, 2000)
    },
  }
}
</script>

<style src="~/assets/cinema-id.styl" lang="stylus">
