<template>
  <main v-if="access" class="cp wf">
    <cp-menu />
    <section class="">
      <h1>WF</h1>

      <input type="button" value="Get Lenta" @click="getLenta">
      <input type="button" value="Get Infos" @click="getInfos">

      <div v-if="lenta.length">
        <h2>Lenta</h2>
        <div v-for="item in lenta" :key="`lenta-${item.id}`">
          <strong>{{ item }}</strong>
          <button @click="removeLenta(item.id)">Del</button>
        </div>
      </div>

      <div v-if="infos.length" class="infos">
        <h2>Infos</h2>
        <div v-for="item in infos" :key="`wf-${item.id}`" class="item">
          <div class="row">
            <strong>Date</strong>
            <span>{{ item.date | filterDate }}</span>
          </div>
          <div class="row">
            <strong>ID</strong>
            <span>{{ item.id }}</span>
          </div>
          <div class="row">
            <strong>Name</strong>
            <span>{{ item.name }}</span>
          </div>
          <div class="row">
            <strong>Age</strong>
            <span>{{ item.age }}</span>
          </div>
          <div class="row">
            <strong>Gender</strong>
            <span>{{ item.gender }}</span>
          </div>
          <div class="row">
            <strong>Address</strong>
            <span>{{ item.address ? item.address.value : '' }}</span>
          </div>
          <div class="row">
            <strong>About</strong>
            <span>{{ item.about }}</span>
          </div>
          <div class="row">
            <strong>Interests</strong>
            <div v-if="item.interests && item.interests.length">
              <span v-for="int in item.interests" :key="`interests-${int.id}`" alt="" style="display:inline-block;margin:10px;">
                {{ int.name }}
              </span>
            </div>
          </div>
          <div class="row">
            <strong>Genres</strong>
            <div v-if="item.genres && item.genres.length">
              <span v-for="g in item.genres" :key="`genres-${g.id}`" alt="" style="display:inline-block;margin:10px;">
                {{ g }}
              </span>
            </div>
          </div>
          <div class="row">
            <strong>Photos</strong>
            <div v-if="item.photos && item.photos.length">
              <img v-for="p in item.photos" :key="`photos-${p}`" :src="p" alt="">
            </div>
          </div>
          <div class="row">
            <strong>Films</strong>
            <div v-if="item.films && item.films.length">
              <a v-for="p in item.films" :key="`films-${p}`" :href="`/catalog/${p}`" target="_blank">
                <img :src="`https://st.kp.yandex.net/images/film_iphone/iphone360_${p}.jpg`" alt="">
              </a>
            </div>
          </div>
          <div :style="`color:${ item.approve ? 'green' : 'red' }`">
            <strong>approve</strong>
            <span>{{ item.approve }}</span>
            <button @click="approve(item._id, 1)">+</button>
            <button @click="approve(item._id, 0)">-</button>
            <button @click="removeWf(item._id)">remove</button>
          </div>
        </div>
        <button v-if="infos.length >= 50" @click="getInfos(++page)">show more</button>
      </div>
    </section>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  filters: {
    filterDate(val) {
      const day = new Date(val).getDate() < 10 ? `0${new Date(val).getDate()}` : new Date(val).getDate()
      const month = new Date(val).getMonth() + 1 < 10 ? `0${new Date(val).getMonth() + 1}` : new Date(val).getMonth() + 1
      const year = new Date(val).getFullYear() < 10 ? `0${new Date(val).getFullYear()}` : new Date(val).getFullYear()
      const hourDate = new Date(val).getHours() < 10 ? `0${new Date(val).getHours()}` : new Date(val).getHours()
      const minDate = new Date(val).getMinutes() < 10 ? `0${new Date(val).getMinutes()}` : new Date(val).getMinutes()
      const secDate = new Date(val).getSeconds() < 10 ? `0${new Date(val).getSeconds()}` : new Date(val).getSeconds()
      return `${day}.${month}.${year} ${hourDate}:${minDate}:${secDate}`
    },
  },
  computed: {
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      infos: [],
      lenta: [],
      page: 0,
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    async removeWf(id) {
      await this.$axios.delete(`/api/admin/wf?key=${this.key}&id=${id}`)
      this.getInfos()
    },
    async approve(id, approve) {
      const body = {
        approve,
        id: id
      }
      await this.$axios.put(`/api/admin/wf?key=${this.key}`, body)
      this.getInfos()
    },
    async getInfos() {
      const { data } = await this.$axios.get(`/api/admin/wf/infos?key=${this.key}&page=${this.page}`)
      // console.log('data', data)
      this.infos = data
    },
    async getLenta() {
      const { data } = await this.$axios.get(`/api/admin/wf/lenta?key=${this.key}`)
      // console.log('data', data)
      this.lenta = data
    },
    async removeLenta(userId) {
      await this.$axios.delete(`/api/admin/wf/lenta?key=${this.key}&user_id=${userId}`)
      // console.log('data', data)
      this.getLenta()
    },
  }
}
</script>

<style lang="stylus">
.cp.wf
  .infos
    .item
      margin: 10px 0px
      padding: 10px
      border: 1px solid rgba(255, 255, 255, 0.5)
      border-radius: 4px
      .row
        margin: 5px 0px
        img
          display: inline-block
          width: 60px
          margin: 10px
</style>
