<template>
  <VideoComponent v-if="items.length" :videos="items" />
  <div v-else-if="noData"><p>Материалы не найдены</p></div>
  <div v-else><i class="loader" /></div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      require: true,
      default: () => ({ kpid: '', genre: '' })
    }
  },
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  data() {
    return {
      items: [],
      noData: false
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/filmdata/related?kpid=${
      this.payload.kpid
    }&genre=${this.payload.genre}`)
    if (!data.items.length) {
      this.noData = true
    } else {
      this.items = data.items
    }
  }
}
</script>
