<template>
  <main v-if="person" class="wrapper person">
    <div class="wrappper">
      <div class="poster">
        <img :data-src="person.posterUrl" src="/static_files/no-poster.png" class="lazyload" loading="lazy" :alt="`${person.nameRu} / ${person.nameEn}`">
      </div>
      <div class="info">
        <h1>{{ person.nameRu }}</h1>
        <h2 v-if="person.nameEn" class="name-en">{{ person.nameEn }}</h2>
      </div>
    </div>

    <div v-if="person.films && person.films.length" class="roles">
      <h2>Роли в кино</h2>
      <div class="items">
        <div v-for="item in person.films.slice(0, rolesI * 20)" :key="`person-rol-${item.filmId}`" class="item">
          <div class="poster">
            <img
              :data-src="`https://kinopoiskapiunofficial.tech/images/posters/kp/${item.filmId}.jpg`"
              src="/static_files/no-poster.png"
              :alt="item.nameRu"
              loading="lazy"
              class="lazyload"
            >
          </div>
          <div class="info">
            <nuxt-link v-if="checkFilm(item.filmId)" :to="`/catalog/${postUrl(item.filmId, item.nameRu ? item.nameRu : item.nameEn )}`">
              <template v-if="item.nameRu">{{ item.nameRu }}</template>
              <template v-if="item.nameRu && item.nameEn">/</template>
              <template v-if="item.nameEn">{{ item.nameEn }}</template>
            </nuxt-link>
            <strong v-else>
              <template v-if="item.nameRu">{{ item.nameRu }}</template>
              <template v-if="item.nameRu && item.nameEn">/</template>
              <template v-if="item.nameEn">{{ item.nameEn }}</template>
            </strong>
            <div class="meta">
              <span v-if="item.professionKey !== 'HERSELF' && item.professionKey !== 'HIMSELF'">
                {{ professionFilter(item.professionKey) }}
              </span>
              <span v-if="item.description">
                {{
                  item.description.includes('играет')
                    ? item.description.charAt(0).toUpperCase() + item.description.slice(1)
                    : item.description
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="rolesI * 20 < person.films.length" class="show-more inline">
        <button @click="++rolesI">
          {{ $t('show_more') }}
          <i class="arrow" />
        </button>
      </div>
    </div>

    <div v-if="person.filmography && person.filmography.length" class="films">
      <h2>Фильмография</h2>
      <video-component :videos="person.filmography" />
    </div>
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  components: {
    VideoComponent: () => import('~/components/VideoComponent.vue')
  },
  filters: {
    filterDate(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(val).getDate()
      const month = new Date(val).getMonth()
      const year = new Date(val).getFullYear()
      return `${day} ${months[month]} ${year}`
    }
  },
  head() {
    const fullPath = `https://unotalone.su/name/${this.person.personId}-${this.person.pretty}`
    return {
      title: `${this.person.nameRu} ${this.person.nameEn ? `(${this.person.nameEn})` : ''} ${this.person.birthday ? this.person.birthday : ''} роли в кино (фильмах и сериалах)`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${this.person.nameRu || this.person.nameEn} роли в кино (фильмах и сериалах)`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Фильмография, биография и фото ${this.person.nameRu} ${this.person.nameEn ? `(${this.person.nameEn})` : ''} ${this.person.birthday ? this.person.birthday : ''} роли в кино (фильмах и сериалах)`
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.person.posterUrl
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${this.person.nameRu} ${this.person.nameEn ? `(${this.person.nameEn})` : ''} ${this.person.birthday ? this.person.birthday : ''} роли в кино (фильмах и сериалах)`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Фильмография, Биография и фото ${this.person.nameRu} ${this.person.nameEn ? `(${this.person.nameEn})` : ''} ${this.person.birthday ? this.person.birthday : ''} роли в кино (фильмах и сериалах)`
        }
      ]
    }
  },
  async asyncData({ params, $axios, error, query, redirect }) {
    // if (!query || Object.keys(query).length) return redirect(`/name/${params?.id}`)

    const id = params.id.indexOf('-') >= 0
      ? parseInt(params.id.slice(0, params.id.indexOf('-')))
        ? params.id.slice(0, params.id.indexOf('-'))
        : params.id
      : params.id

    const { data } = await $axios.get(`/api/persons/${id}`)

    if (data.error) return error(data.error)

    const person = data

    return {
      person,
      getPhotos: false,
      rolesI: 1
    }
  },
  methods: {
    checkFilm(filmId) {
      return this.person.filmography.some(el => el.kinopoisk_id === '' + filmId)
    },
    professionFilter(val) {
      if (val === 'ACTOR') {
        return this.person.sex === 'MALE' ? 'Актер.' : 'Актриса.'
      } else if (val === 'OPERATOR') {
        return 'Оператор'
      } else if (val === 'COMPOSER') {
        return 'Композитор'
      } else if (val === 'WRITER') {
        return 'Сценарист'
      } else if (val === 'PRODUCER') {
        return 'Продюсер'
      }
    }
  }
}
</script>

<style lang="stylus">
.person
  padding: 20px
  .spouses
    margin-bottom: 56px
    h2
      margin-bottom: 32px
    ul
      list-style: none
      li
        margin: 14px 0
        strong
          font-weight: 500
        a
          display: inline-block
          padding-bottom: 2px
          font-weight: 500
          border-bottom: 1px solid rgba(255 255 255 .35)
          transition: border-bottom .2s ease
          &:hover
            border-bottom: 1px solid #fff
  .roles
    margin-bottom: 56px
    h2
      margin-bottom: 32px
    .items
      .item
        margin: 14px 0
        display: grid
        grid-template-columns: 60px auto
        grid-gap: 14px
        align-items: center
        strong
          font-weight: 500
        a
          display: inline-block
          padding-bottom: 2px
          font-weight: 500
          border-bottom: 1px solid rgba(255 255 255 .35)
          transition: border-bottom .2s ease
          &:hover
            border-bottom: 1px solid #fff
        .poster
          width: 60px
          height: 90px
          overflow: hidden
          background-color: #222
          img
            width: 100%
            min-height: 100%
            object-fit: cover
        .meta
          margin-top: 10px
          font-size: 1.4rem
          color: #808893
  h1
    margin-bottom: 10px !important
  .name-en
    font-size: 1.6rem
    color: #808893
  .wrappper
    display: grid
    grid-template-columns: 200px auto
    grid-gap: 32px
    margin: 40px 0px
    .poster
      position: relative
      width: 200px
      height: 320px
      overflow: hidden
      background-color: #222
      border-radius: 4px
      img
        width: 100%
        min-height: 100%
        object-fit: cover
    .row
      margin-bottom: 12px
      span
        margin-right: 5px
    ul
      margin-top: 32px
      margin-left: 20px
      li
        margin-bottom: 10px
        line-height: 2.8rem
  .films
    h2
      margin-bottom: 32px
</style>
