<template>
  <main>
    <section>
      <h1>Ошибка авторизации</h1>
      <div>{{ message }}</div>
    </section>
  </main>
</template>

<script>
export default {
  head() {
    return {
      title: `Ошибка авторизации`
    }
  },
  layout: 'clean',
  asyncData({ req }) {
    // console.log('req flash', req.flash())
    // const message = req?.flash()
    // console.log('message', message)
    return {
      message: '', // message?.error[0],
    }
  },
}
</script>
