<template>
  <main class="adblock">
    <section>
      <h1>
        Отключение блокировки рекламы в расширении AdBlock
      </h1>
      <p>
        Adblock и Adblock Plus - Расширение для браузеров и другого ПО на основе Gecko, Blink и Trident, позволяющее блокировать загрузку и показ рекламы как на страницах сайтов, так и в поисковой выдаче.
      </p>

      <div class="block">
        <h2>
          Как отключить Adblock в браузере Опера (Opera)
        </h2>
        <ol>
          <li>
            В браузер Опера расширение AdBlock устанавливается автоматически после установки самого браузера.
            Для отключения блокировки рекламы на нашем сайте кликните по иконке "щита" в правой части адресной строки.
            <a href="/static_files/adblock/opera_1.png" target="_blank">
              <img :data-src="`/static_files/adblock/opera_1.png`" src="/static_files/no-poster.png" class="lazyload" alt="Отключение блокировки рекламы AdBlock в браузере Опера">
            </a>
          </li>
          <li>
            Нажмите кнопку <strong>Отключить для этого сайта</strong>
          </li>
        </ol>
      </div>

      <div class="block">
        <h2>
          Как отключить Adblock в браузере Яндекс.Браузер (Yandex Browser)
        </h2>
        <ol>
          <li>
            В отличии от Оперы, в Яндекс.Браузере расширение AdBlock нужно устанавливать самостоятельно.
            Однако процесс отключения блокировки рекламы так же решается в 2 клика.
          </li>
          <li>
            В правой части окна браузера (справа от адресной строки) сначала нажмите на иконку расширения AdBlock
            потом "Приостановить на этом сайте" <strong>Всегда</strong>
            <a href="/static_files/adblock/yabrowser_1.png" target="_blank">
              <img :data-src="`/static_files/adblock/yabrowser_1.png`" src="/static_files/no-poster.png" class="lazyload" alt="Отключение блокировки рекламы AdBlock в браузере Яндекс.Браузер">
            </a>
          </li>
        </ol>
      </div>

      <div class="block">
        <h2>
          Как отключить Adblock в браузере Гугл Хром (Google Chrome)
        </h2>
        <ol>
          <li>
            Процесс отключения AbBlock для сайта аналогичен Яндекс.Браузеру.
            Открываем меню расширения по клику на иконку расширения и выбираем "Приостановить на этом сайте"
            <strong>Всегда</strong>
            <a href="/static_files/adblock/chrome_1.png" target="_blank">
              <img :data-src="`/static_files/adblock/chrome_1.png`" src="/static_files/no-poster.png" class="lazyload" alt="Отключение блокировки рекламы AdBlock в браузере Chrome">
            </a>
          </li>
        </ol>
      </div>
      <p>
        На данных примерах мы с вами <strong>рассмотрели процесс отключения блокировки рекламы</strong> в расширении AdBlock
        на разных браузерах.
      </p>
      <p>
        Если вам понравился наш сайт и вы хотите <strong>смотреть кино в HD 720 качестве, отключите блокировку рекламы!</strong>
        Ведь через просмотр рекламы вы помогаете сайту развиваться.
      </p>
      <p>
        Если вы все таки не хотите видеть рекламу, но хотите помогать сайту, специально для вас мы
        разработали систему <nuxt-link to="/donate">Premium-подписок</nuxt-link>. <strong>Всего за 50руб. в месяц</strong> вы получаете возможность
        смотреть любимое кино вместе с друзьями без рекламы! Да-да, реклама, в созданной вами комнате, отключается для всех ее участников!
      </p>
      <nuxt-link to="/donate" tag="button" class="dbtn blue" style="margin-top:10px;">
        Хочу помочь сайту!
      </nuxt-link>
    </section>
  </main>
</template>

<script>
export default {
  meta: {
    i18n: false,
  },
  head() {
    const fullPath = `https://unotalone.su/adblock/`
    return {
      title: `Отключение блокировки рекламы в расширении AdBlock`,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `отключение блокировки рекламы adblock chrome opera яндекс браузер`
        },
        {
          hid: 'description',
          name: 'description',
          content: `Если вам понравился наш сайт эта инструкция по отключению блокировки рекламы в расширении AdBlock специально для вас. Рассматриваем разные браузеры!`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Отключение блокировки рекламы в расширении AdBlock`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Если вам понравился наш сайт эта инструкция по отключению блокировки рекламы в расширении AdBlock специально для вас. Рассматриваем разные браузеры!`
        }
      ]
    }
  },
}
</script>

<style lang="stylus">
.adblock
  padding: 10px
  h1
    margin-bottom 20px
  p
    padding 10px 0
  .block
    margin-top 40px
    ol
      margin-top 20px
      margin-left 20px
      li
        margin 5px 0
        img
          display block
          margin 15px 0 30px
          max-width 50%
</style>
