<template>
  <div v-if="facts.length || bloopers.length" class="facts">
    <div v-if="facts.length">
      <h3>Факты</h3>
      <p v-for="(item, i) in facts.slice(0, moreFactsI * 8)" :key="`film-fact-${i}`" v-html="item.text" class="item" />
    </div>
    <div v-if="facts.length > 8 && (moreFactsI * 8 < facts.length)" class="show-more inline">
      <button @click="++moreFactsI">
        {{ $t('show_more') }}
        <i class="arrow" />
      </button>
    </div>
    <div v-if="bloopers.length" :style="`${facts.length ? 'margin-top: 32px;' : ''}`">
      <h3>Киноляпы</h3>
      <div class="alert">
        Внимание! Список ошибок в фильме может содержать спойлеры. Будьте осторожны.
      </div>
      <p v-for="(item, i) in bloopers.slice(0, moreBloopersI * 8)" :key="`film-blooper-${i}`" v-html="item.text" class="item" />
    </div>
    <div v-if="bloopers.length > 8 && (moreBloopersI * 8 < bloopers.length)" class="show-more inline">
      <button @click="++moreBloopersI">
        {{ $t('show_more') }}
        <i class="arrow" />
      </button>
    </div>
  </div>
  <div v-else-if="noData"><p>Материалы не найдены</p></div>
  <div v-else><i class="loader" /></div>
</template>

<script>
export default {
  props: { kpid: { type: String, require: true, default: '' } },
  data() {
    return {
      facts: [],
      bloopers: [],
      moreFactsI: 1,
      moreBloopersI: 1,
      noData: false
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/filmdata/facts?kpid=${this.kpid}`)
    if (!data.items.length) {
      this.noData = true
    } else {
      this.facts = data.items.filter(el => {
        el.text = el.text.replace(/\/film/g, '/catalog')
        return el.type.toLowerCase() === 'fact'
      })
      this.bloopers = data.items.filter(el => {
        el.text = el.text.replace(/\/film/g, '/catalog')
        return el.type.toLowerCase() === 'blooper'
      })
    }
  }
}
</script>

<style lang="stylus">
.facts
  .alert
    margin: 10px 0
    padding: 10px
    font-size: 1.4rem
    color: rgba(255 255 255 0.55)
    background-color: rgba(255 255 255 0.1)
    text-align: center
  p
    position: relative
    margin-bottom: 20px
    line-height: 2.8rem
    // &::before
    //   content: ''
    //   position: absolute
    //   top: 10px
    //   left: -10px
    //   width: 6px
    //   height: 6px
    //   background-color: #f2f2f2
    //   border-radius: 50%
    a
      padding-bottom: 2px
      border-bottom 1px solid rgba(255 255 255 .35)
      transition: all .2s ease
      &:hover
        border-bottom 1px solid #fff
</style>
